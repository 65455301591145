import { O } from '../../../features/ui'
import { TableName } from '../../../tables'
import { useLoaders } from '../../common'
import { useSystemStateHooks } from '../../system'
import { Check } from '../state/types'

export const useCheckLoaders = () => {
    const systemHooks = useSystemStateHooks()
    const loaders = useLoaders()

    return (companyId = systemHooks.companyId) => {
        const loadAllChecks = () => {
            const { loadAll } = loaders(companyId)
            return loadAll(TableName.CHECKS) as Promise<Check[]>
        }

        const loadCheck = (id: string) => {
            const { load } = loaders(companyId)
            return load(TableName.CHECKS, id) as Promise<O<Check>>
        }
        
        return {
            loadAllChecks,
            loadCheck
        }
    }
}
