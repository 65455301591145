import { useDefaultModuleActions } from "../../common"
import { useDataTypeFileActions } from "../../../features/data-types"
import { DynamoUserApi } from "../../../services"
import { TextParams } from "../../../state/reducers/types"
import { TableName } from "../../../tables"
import { DataType } from "../../../tables/types/types"
import { define } from "../../../utils/typeUtils"
import { useSystemStateHooks } from "../../system"
import { dedupe } from "../../../utils/listUtils"
import { Ticket } from "../state/types"

export const useTicketActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId

    const defaultModuleActions = useDefaultModuleActions()
    const dataTypeFileActions = useDataTypeFileActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId
        const { fetchAll, fetchByParams, fetchMultipleByParams, fetchByIds, save } = defaultModuleActions(DynamoUserApi, define(companyId))

        const fetchAllTickets = async (limit?: number, startKey?: TextParams) => {
            return fetchAll(TableName.TICKETS, limit, startKey)
        }

        const fetchTicket = (id: string) => {
            return fetchByParams(TableName.TICKETS, { id })
        }

        const fetchTickets = (ids: string[]) => {
            return fetchByIds(TableName.TICKETS, ids)
        }

        const fetchTicketsByParentId = (parentId: string) => {
            return fetchMultipleByParams(TableName.TICKETS, { parentId })
        }

        const countTickets = async (taxCodeValue: string) => {
            const tickets = (await fetchAllTickets()).dataTypes as Ticket[]
            const taxCodes = tickets.flatMap(ticket => ticket.taxItems.map(taxItem => taxItem.taxCode))
            return dedupe(taxCodes).filter(taxCode => taxCode === taxCodeValue).length
        }

        const saveTicket = async (ticket: Ticket) => {
            return save(TableName.TICKETS, ticket)
        }

        const saveTicketWithFile = async (
            ticket: Ticket,
            ticketFile: File,
        ) => {
            const { saveWithFile } = dataTypeFileActions(define(companyId))
            return saveWithFile(TableName.TICKETS, ticket, 'ticketUrl' as keyof DataType, ticketFile)
        }

        const removeTicket = (id: string) => {
            const { removeWithFile } = dataTypeFileActions(define(companyId))
            return removeWithFile(TableName.TICKETS, id, 'ticketUrl' as keyof DataType)
        }

        return {
            fetchAllTickets,
            fetchTicket,
            fetchTickets,
            fetchTicketsByParentId,
            countTickets,
            saveTicket,
            saveTicketWithFile,
            removeTicket
        }
    }
}
