import { O } from '../../../features/ui'
import { TableName } from '../../../tables'
import { useLoaders } from '../../common'
import { HoardItem, HoardOrder } from '../state/types'

export const useHoardLoaders = () => {
    const loaders = useLoaders()

    const loadAllHoards = () => {
        const { loadAll } = loaders()
        return loadAll(TableName.HOARD_ORDERS) as Promise<HoardOrder[]>
    }

    const loadHoard = (id: string) => {
        const { load } = loaders()
        return load(TableName.HOARD_ORDERS, id) as Promise<O<HoardOrder>>
    }

    const loadHoardItems = (hoardId: string) => {
        const { loadByParams } = loaders()
        return loadByParams(TableName.HOARD_ITEMS, { hoardId }) as Promise<HoardItem[]>
    }

    return {
        loadAllHoards,
        loadHoard,
        loadHoardItems
    }
}
