import { useDefaultModuleActions } from "../../common"
import { Data, RemoveChildrenFn, SaveChildrenFn, areDataTypesEqual, areDataTypesListsEqual, newId, useChildrenActions } from "../../../features/data-types"
import { DynamoUserApi } from "../../../services"
import { TextParams } from "../../../state/reducers/types"
import { TableName } from "../../../tables"
import { Currency } from "../../../tables/types/types"
import { define } from "../../../utils/typeUtils"
import { useSystemStateHooks } from "../../system"
import { Budget, BudgetItem } from "../state/types"
import { O } from "../../../features/ui"

export const useBudgetActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId

    const defaultModuleActions = useDefaultModuleActions()
    const { submitChildren } = useChildrenActions()

    return (paramCompanyId?: string) => {
        const companyId = define(paramCompanyId || stateCompanyId)
        const { fetchAll, fetchByParams, fetchMultipleByParams, count, save, saveMultiple, remove, removeMultiple } = defaultModuleActions(DynamoUserApi, companyId)

        const fetchAllBudgets = (limit?: number, startKey?: TextParams) => {
            return fetchAll(TableName.BUDGETS, limit, startKey)
        }

        const fetchBudget = (id: string) => {
            return fetchByParams(TableName.BUDGETS, { id })
        }

        const countBudgets = (propName: string, propValue: string) => {
            return count(TableName.BUDGETS, { [propName]: propValue })
        }

        const saveBudget = (budget: Budget) => {
            return save(TableName.BUDGETS, budget)
        }

        const saveBudgetWithItems = async (
            budget: Budget,
            budgetItemsData: Data[]
        ) => {
            const budgetItems = budgetItemsData.map(budgetItemData => {
                return (budgetItemData.id ? budgetItemData : {
                    ...budgetItemData,
                    companyId: define(companyId),
                    id: newId(),
                    budgetId: budget.id,
                    currency: Currency.ARS
                }) as BudgetItem
            })

            const stateBudget = await fetchBudget(budget.id) as O<Budget>
            const stateBudgetItems = await fetchBudgetItemsByOrder(budget.id)
            
            const budgetChanged = !stateBudget || !areDataTypesEqual(budget, stateBudget)
            const budgetItemsChanged = !areDataTypesListsEqual(budgetItems, stateBudgetItems)
            if (budgetChanged || budgetItemsChanged) {
                const savedBudget = await saveBudget(budget) as Budget
                await submitChildren(stateBudgetItems, budgetItems, saveBudgetItems as SaveChildrenFn, removeBudgetItems as RemoveChildrenFn)
                
                return savedBudget
            } else {
                return budget
            }
        }

        const removeBudget = async (id: string) => {
            await remove(TableName.BUDGETS, id)
            const budgetItems = await fetchBudgetItemsByOrder(id) as BudgetItem[]
            await removeBudgetItems(budgetItems)
        }

        const fetchBudgetItemsByOrder = (budgetId: string) => {
            return fetchMultipleByParams(TableName.BUDGET_ITEMS, { budgetId })
        }

        const countBudgetItems = (propName: string, propValue: string) => {
            return count(TableName.BUDGET_ITEMS, { [propName]: propValue })
        }

        const saveBudgetItems = (budgetItems: BudgetItem[]) => {
            return saveMultiple(TableName.BUDGET_ITEMS, budgetItems)
        }

        const removeBudgetItems = (budgetItems: BudgetItem[]) => {
            return removeMultiple(TableName.BUDGET_ITEMS, budgetItems.map(budgetItem => budgetItem.id))
        }

        return {
            fetchAllBudgets,
            fetchBudget,
            countBudgets,
            saveBudget,
            saveBudgetWithItems,
            removeBudget,
            fetchBudgetItemsByOrder,
            countBudgetItems
        }
    }
}
