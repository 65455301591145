import { DataType, TableName } from '../../../tables'
import { DynamoUserApi } from '../../../services'
import { define } from '../../../utils/typeUtils'
import { useSystemStateHooks } from '../../system'
import { useStateHooks } from '../../../state'
import { useDefaultDbActions } from './defaultDbActions'

export const useSaveActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId
    const { getUpdater, getRemover } = useStateHooks()

    const defaultDbActions = useDefaultDbActions()

    const updater = (table: TableName) => getUpdater(table) as any as (list: DataType[]) => void
    const remover = (table: TableName) => getRemover(table) as any as (ids: string[]) => void

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId
        const dbActions = defaultDbActions(DynamoUserApi, define(companyId))

        const save = async (
            table: TableName,
            dataType: DataType
        ) => {
            const list = [dataType]
            await dbActions.save(table, [dataType])
            updater(table)(list)
            return dataType
        }

        const remove = async (
            table: TableName,
            id: string
        ) => {
            const ids = [id]
            await dbActions.remove(table, ids)
            remover(table)(ids)
        }

        return {
            save,
            remove
        }
    }
}
