import { useSalesActions } from "../../../actions/salesActions"
import { HoardOrder, HoardType } from "../../../state/types"
import { Data } from "../../../../../features/ui"
import { define } from "../../../../../utils/typeUtils"
import { Currency } from "../../../../../tables/types/types"
import { DeliveryStatus, PaymentStatus } from "../../../../operations"
import { PurchasePricesData } from "../../../../products"
import { Emitter, Event } from "../../../../../features/event-emitter"
import { InvoiceStatus } from "../../../../administration"

export const useActions = () => {
    const salesActions = useSalesActions()
    
    const submitHoard = async (
        hoardData: Data,
        code: number,
        branchId: string,
        sellerId: string,
        customerId: string,
        hoardItemsData: Data[],
        purchasePricesList: PurchasePricesData,
        createMode: boolean
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const { companyId, id, type, paymentTypeId, deliveryStatus, paymentStatus, value } = hoardData
        const dueDate = define(hoardData.dueDate) as Date
        const hoard: HoardOrder = {
            companyId: define(companyId) as string,
            id: define(id) as string,
            code,
            type: define(type) as HoardType,
            creationDate: new Date().toISOString(),
            currency: Currency.ARS,
            dueDate: dueDate.toISOString(),
            sellerId,
            customerId,
            paymentTypeId: define(paymentTypeId) as string,
            branchId,
            deliveryStatus: deliveryStatus as DeliveryStatus || DeliveryStatus.PENDING,
            paymentStatus: paymentStatus as PaymentStatus || PaymentStatus.PENDING,
            invoiceStatus: InvoiceStatus.UNINVOICED,
            purchasePricesList,
            ...(type === HoardType.FINANCIAL && { value: define(value) as number }),
        }
        
        await salesActions().saveHoardWithItems(hoard, hoardItemsData, createMode)
        Emitter.emit(Event.DB_SUBMIT_DONE, createMode)
    }
    
    return {
        submitHoard
    }
}
