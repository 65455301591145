import { useNavigate } from "react-router-dom"
import { Action, Badge, ButtonKind, DetailFieldProps, DetailPanel, FieldType, FieldValue, ModalId, SpinnerDiv, Tab, UIPermission, useGetCrumbs, useUIActions } from "../../../../../features/ui"
import { TableName } from "../../../../../tables"
import { useSystemStateHooks } from "../../../../system"
import { Expense, Ticket } from "../../../state/types"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { define } from "../../../../../utils/typeUtils"
import { Labels, useDataTypesHooks } from "../../../../../features/data-types"
import { PaymentStatus } from "../../../../operations"
import { TicketList } from "../../ticket/ticket-list/ticketList"
import { useEffect, useState } from "react"
import { useTicketActions } from "../../../actions/ticketActions"
import { DownloadTicketModal } from "../../ticket/download-ticket-modal/downloadTicketModal"
import { firstItem, toList } from "../../../../../utils/listUtils"

export const SalaryDetail = () => {
  const { dataType } = useRoutesHooks().parseUrl()
  const expense = define(dataType) as Expense

  const [ticket, setTicket] = useState<Ticket>()
  const [currentTicket, setCurrentTicket] = useState<Ticket>()

  const { userHasPermission } = useSystemStateHooks()
  const readOnly = !userHasPermission(UIPermission.SAVE_EXPENSES)
  const { formatValue } = useDataTypesHooks()
  const navigate = useNavigate()

  const { toggleModal } = useUIActions()
  const ticketActions = useTicketActions()

  if (!expense) return <SpinnerDiv />

  const init = async () => {
    const { fetchTicketsByParentId } = ticketActions()
    const stateTickets = await fetchTicketsByParentId(expense.id) as Ticket[]
    setTicket(firstItem(stateTickets))
  }

  useEffect(() => {
    init()
  }, [])

  const crumbs = useGetCrumbs('Sueldos Liquidados', RoutePath.SALARIES, expense.name)

  const title = `Sueldo Liquidado: ${expense.name}`

  const fields: DetailFieldProps[] = [
    {
      name: 'code',
      type: FieldType.NUMBER,
      label: 'Código',
      render: (value?: FieldValue) => <>#{value}</>
    },
    {
      name: 'paymentDate',
      type: FieldType.DATE,
      label: 'Fecha de Pago',
      render: () => ticket ?
        <>{formatValue(new Date(ticket.dueDate), FieldType.DATE)}</> :
        <Badge label='Sin recibo' />
    },
    {
      name: 'branchId',
      type: FieldType.TABLE,
      table: TableName.BRANCHES,
      label: 'Sucursal'
    },
    {
      name: 'amount',
      type: FieldType.PRICE,
      label: 'Monto'
    },
    {
      name: 'paymentStatus',
      type: FieldType.TEXT,
      label: 'Estado de Pago',
      render: (value?: FieldValue) => <>{Labels.paymentStatusLabel(define(value) as PaymentStatus)}</>
    }
  ]
  if (expense.detail) {
    const detailField = {
      name: 'detail',
      type: FieldType.TEXT,
      label: 'Detalle'
    }
    fields.splice(4, 0, detailField)
  }

  const onEdit = () => navigate(RoutePath.EDIT)

  const onRemove = () => toggleModal(ModalId.REMOVE)

  const actions: Action[] = []
  const isPaymentPending = expense.paymentStatus === PaymentStatus.PENDING
  const isEditable = isPaymentPending
  if (!readOnly && isEditable) {
    const editAction = { label: 'Editar Gasto', icon: 'pencil-fill', onClick: onEdit }
    const removeAction = { label: 'Eliminar', kind: 'secondary' as ButtonKind, icon: 'trash-fill', onClick: onRemove }
    actions.push(editAction, removeAction)
  }

  const tabs: Tab[] = []
  if (ticket) {
    tabs.push({
      id: 'receipts',
      title: 'Recibos',
      content: <TicketList
        tickets={toList(ticket)}
        ticketLabel="Recibo"
        readOnly
        onChangeCurrentTicket={setCurrentTicket}
      />
    })
  }

  return (<>
    <DetailPanel
      tableName={TableName.EXPENSES}
      title={title}
      crumbs={crumbs}
      fields={fields}
      data={expense}
      icon='receipt'
      tabs={tabs}
      readOnly={readOnly}
      actions={actions}
    />
    <DownloadTicketModal
      ticket={currentTicket}
      ticketLabel="Recibo"
    />
  </>)
}
