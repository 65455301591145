import styles from './paymentTypeField.module.css'
import { FieldType, FieldValue, FormField, Option } from "../../../../../features/ui";
import { TableName } from "../../../../../tables";
import { define } from "../../../../../utils/typeUtils";
import { useDataTypesHooks } from "../../../../../features/data-types";
import { usePaymentTypeLoaders } from '../../../loaders/paymentTypeLoaders';

type Props = {
    defaultValue?: string,
    narrow?: boolean
    onChange?: (value?: FieldValue) => void
}

export const PaymentTypeField = (props: Props): FormField => {
    const { defaultValue, narrow = true, onChange } = props
    
    const { formatValue } = useDataTypesHooks()

    const paymentTypeLoaders = usePaymentTypeLoaders()

    const renderPaymentTypeOption = (option: Option, onClick?: (e: React.MouseEvent<HTMLElement>) => void) => {
        const { findPaymentType } = paymentTypeLoaders()
        const paymentType = define(findPaymentType(option.value))
        return (
            <div className={styles.paymentTypeOption} onClick={onClick}>
                <span>{paymentType.name}</span>
                <span className={styles.paymentTypeYield}>
                    {formatValue(paymentType.yield, FieldType.PERCENTAGE)}
                </span>
            </div>
        )
    }

    return {
        name: 'paymentTypeId',
        type: FieldType.TABLE,
        table: TableName.PAYMENT_TYPES,
        defaultValue,
        label: 'Medio de Pago',
        narrow,
        renderOption: renderPaymentTypeOption,
        onChange
    }
}
