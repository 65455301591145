import { useEffect, useState } from 'react'
import { DataType } from '../../../../../tables'
import { FieldType, FieldValue, FilterField, Filters, O } from '../../../../../features/ui'
import { SaleOperation, useSalesState } from '../../../../sales'
import { useSystemState } from '../../../../system'
import { useConstrufyAdminHooks } from '../../../hooks/construfyAdminHooks'
import { useConstrufyAdminFetchActions } from '../../../actions/construfyAdminFetchActions'

type Props = {
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const InvoiceFilters = (props: Props) => {
    const { onSearchStart, onSearchDone } = props

    const { company } = useSystemState()
    const { sales, hoards } = useSalesState()
    const [searchText, setSearchText] = useState<string>()

    const { fetchAllBranches, fetchAllProducts, fetchAllTaxes, fetchAllSales, fetchAllSaleItems, fetchAllHoards, fetchAllHoardItems, fetchEmployees, fetchCustomers, fetchPaymentTypes, fetchCustomerTypes } = useConstrufyAdminFetchActions()
    const { searchSalesAndHoards } = useConstrufyAdminHooks()

    const fetchAndSearch = async () => {
        onSearchStart()
        setTimeout(async () => {
            await fetchAllBranches()
            await fetchAllProducts()
            await fetchAllTaxes()
            const stateSales = await fetchAllSales()
            await fetchAllSaleItems()
            const stateHoards = await fetchAllHoards()
            await fetchAllHoardItems()
            const stateOperations = (stateSales as SaleOperation[]).concat(stateHoards)
            const sellerIds = stateOperations.map(operation => operation.sellerId)
            await fetchEmployees(sellerIds)
            const paymentTypeIds = stateOperations.map(operation => operation.paymentTypeId)
            await fetchPaymentTypes(paymentTypeIds)
            const customerIds = stateOperations.map(operation => operation.customerId)
            const stateCustomers = await fetchCustomers(customerIds)
            const customerTypeIds = stateCustomers.map(customer => customer.customerTypeId)
            await fetchCustomerTypes(customerTypeIds)
            search(stateOperations, searchText)
        }, 1000)
    }

    useEffect(() => {
        fetchAndSearch()
    }, [company])

    const search = (
        baseList: DataType[] = [],
        searchTextParam?: string
    ) => {
        onSearchStart()
        let list = baseList.filter(dataType => (dataType as SaleOperation).companyId === company?.id)
        list = searchSalesAndHoards(list, searchTextParam)
        setTimeout(() => onSearchDone(list), 100)
    }

    const handleSearch = (value?: FieldValue) => {
        const newSearchText = value as O<string>
        setSearchText(newSearchText)
        search((sales as DataType[]).concat(hoards), newSearchText)
    }

    const searchField: FilterField = {
        name: 'search',
        type: FieldType.TEXT,
        placeholder: 'Buscar por código, vendedor o cliente',
        icon: 'search',
        onChange: handleSearch
    }

    return (
        <Filters
            searchField={searchField}
            firstRowLength={3}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
