import { useNavigate } from "react-router-dom"
import { Emitter, Event } from "../../../../../features/event-emitter"
import { Data, ModalId, useUIActions } from "../../../../../features/ui"
import { Currency } from "../../../../../tables/types/types"
import { define } from "../../../../../utils/typeUtils"
import { useAdministrationState } from "../../../hooks/administrationHooks"
import { Expense } from "../../../state/types"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { PaymentStatus } from "../../../../operations"
import { useExpensesActions } from "../../../actions/expensesActions"

export const useActions = () => {
    const { currentEmployee } = useAdministrationState()

    const navigate = useNavigate()
    const { getRoutePath } = useRoutesHooks()

    const expensesActions = useExpensesActions()
    const { toggleModal } = useUIActions()
    
    const submitExpense = async (
        expenseData: Data,
        createMode: boolean,
        code: number,
        branchId: string,
        supplierId?: string,
        refundEmployeeId?: string,
        detail?: string
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const { companyId, id, name, expenseTypeId, amount, paymentStatus } = expenseData
        const date = define(expenseData.date) as Date
        const expense: Expense = {
            companyId: define(companyId) as string,
            id: define(id) as string,
            code,
            name: define(name) as string,
            expenseTypeId: define(expenseTypeId) as string,
            date: date.toISOString(),
            branchId,
            amount: define(amount) as number,
            currency: Currency.ARS,
            detail,
            paymentStatus: paymentStatus as PaymentStatus || PaymentStatus.PENDING,
            employeeId: define(currentEmployee).id,
            supplierId,
            refundEmployeeId
        }
        
        const savedExpense = await expensesActions().saveExpense(expense)

        Emitter.emit(Event.DB_SUBMIT_DONE, createMode, false)
        setTimeout(() => {
            navigate(getRoutePath(RoutePath.EXPENSES, savedExpense.id))
            setTimeout(() => toggleModal(ModalId.UPLOAD_EXPENSE_TICKET), 500)
        }, 1000)
    }
    
    return {
        submitExpense
    }
}
