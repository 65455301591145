import { useEffect, useState } from "react"
import { useDescriptorHooks } from "../../../../../features/data-types"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { PanelLoader, useGetCrumbs } from "../../../../../features/ui"
import { define } from "../../../../../utils/typeUtils"
import { SaleForm } from "./saleForm"
import { Budget, BudgetItem, SaleItem, SaleOrder } from "../../../state/types"
import { useSalesActions } from "../../../actions/salesActions"
import { TableName } from "../../../../../tables"

export const SaleFormLoader = () => {
    const { tableName, dataTypeId } = useRoutesHooks().parseUrl()
    const isSale = tableName === TableName.SALE_ORDERS

    const [ready, setReady] = useState(false)
    const [sale, setSale] = useState<SaleOrder>()
    const [saleItems, setSaleItems] = useState<SaleItem[]>()
    const [budget, setBudget] = useState<Budget>()
    const [budgetItems, setBudgetItems] = useState<BudgetItem[]>()

    const descriptorHooks = useDescriptorHooks()

    const salesActions = useSalesActions()

    const populate = async () => {
        const { fetchAllCustomers, fetchSale, fetchSaleItemsByOrder, fetchBudget, fetchBudgetItemsByOrder } = salesActions()
        await fetchAllCustomers()

        if (dataTypeId)  {
            if (isSale) {
                const stateSale = define(await fetchSale(dataTypeId)) as SaleOrder
                setSale(stateSale)
                const stateSaleItems = await fetchSaleItemsByOrder(dataTypeId) as SaleItem[]
                setSaleItems(stateSaleItems)
            } else {
                const stateBudget = define(await fetchBudget(dataTypeId)) as Budget
                setBudget(stateBudget)
                const stateBudgetItems = await fetchBudgetItemsByOrder(dataTypeId) as BudgetItem[]
                setBudgetItems(stateBudgetItems)
            }
        }
    }

    useEffect(() => {
        populate().then(() => setReady(true))
    }, [])

    const titleLabel = sale ? `: ${descriptorHooks.sale.code(sale) }` : (budget ? ` (Presupuesto #${budget.code})` : '')
    const title = `${sale ? 'Editar' : 'Crear'} Venta${titleLabel}`
    const crumbs = useGetCrumbs(
        'Ventas',
        RoutePath.SALES,
        sale ? descriptorHooks.sale.code(sale) : undefined,
        dataTypeId,
        sale ? 'Editar' : 'Crear'
    )

    return (
        <PanelLoader
            title={title}
            crumbs={crumbs}
            ready={ready}
            wide
        >
            <SaleForm
                tableName={define(tableName)}
                dataType={sale || budget}
                items={saleItems || budgetItems}
            />
        </PanelLoader>
    )
}
