import styles from './supplierForm.module.css'
import { DataTypeForm, Labels, validatePhone, validateTaxId } from "../../../../../features/data-types"
import { PanelWrapper, Data, FieldType, FormField, option, useGetCrumbs, NumberField, FieldValue, O } from "../../../../../features/ui"
import { useActions } from "./actions"
import { Supplier } from "../../../state/types"
import { useEffect, useState } from "react"
import { useGenerateNextValue } from "../../../../../tables/hooks"
import { TableName } from "../../../../../tables"
import { TaxCategory } from "../../../../operations"
import { isDefined } from "../../../../../utils/typeUtils"
import { useNavigate } from "react-router-dom"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"

type Props = {
    productSupplier?: boolean
}

export const SupplierForm = (props: Props) => {
    const { dataType } = useRoutesHooks().parseUrl()
    const supplier = dataType as O<Supplier>
    const createMode = !supplier
    const productSupplier = isDefined(props.productSupplier) ? !!props.productSupplier : (
        isDefined(supplier?.productSupplier) ? !!supplier?.productSupplier : true
    )

    const [code, setCode] = useState(supplier?.code || 1)
    const [paymentDays, setPaymentDays] = useState<O<number>>(supplier?.paymentDays)

    const navigate = useNavigate()
    const generateNextValue = useGenerateNextValue()

    const { submitSupplier } = useActions()

    const init = async () => {
        if (createMode) {
            setCode(await generateNextValue(TableName.SUPPLIERS, 'code'))
        }
    }

    useEffect(() => {
        init()
    }, [])

    const supplierLabel = supplier ? `: ${supplier.name}` : ''
    const title = `${createMode ? 'Crear' : 'Editar'} Proveedor${supplierLabel}`

    const crumbs = useGetCrumbs(
        'Proveedores',
        productSupplier ? RoutePath.SUPPLIERS : RoutePath.EXPENSE_SUPPLIERS,
        supplier?.name,
        undefined,
        createMode ? 'Crear' : 'Editar'
    )

    const onChangePaymentDays = (value?: FieldValue) => {
        setPaymentDays(value as O<number>)
    }

    const fields: FormField[] = [
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre',
            defaultValue: supplier?.name,
        },
        {
            name: 'taxId',
            type: FieldType.NUMBER,
            label: 'CUIT / CUIL',
            validate: validateTaxId
        },
        {
            name: 'taxCategory',
            type: FieldType.SELECT,
            label: 'Cateogría Fiscal',
            options: Object.values(TaxCategory).map(taxCategory => option(taxCategory, Labels.taxCategoryLabel(taxCategory))),
            defaultValue: supplier?.taxCategory,
        },
        {
            name: 'paymentDays',
            type: FieldType.NUMBER,
            label: 'Condición de Pago',
            render: () => (
                <>
                    <NumberField
                        id="paymentDays"
                        defaultValue={paymentDays}
                        min={1}
                        allowDecimals={false}
                        optional
                        className={styles.paymentDays}
                        onChange={onChangePaymentDays}
                    />
                    días
                </>
            )
        },
        {
            name: 'phone',
            type: FieldType.TEXT,
            label: 'Teléfono',
            defaultValue: supplier?.phone,
            optional: true,
            validate: validatePhone
        },
        {
            name: 'email',
            type: FieldType.TEXT,
            label: 'Email',
            defaultValue: supplier?.email,
            optional: true
        },
        {
            name: 'address',
            type: FieldType.TEXT,
            label: 'Dirección',
            defaultValue: supplier?.address,
            optional: true
        },
        {
            name: 'grossIncomeCode',
            type: FieldType.TEXT,
            label: 'N° de Inscripción a IIBB',
            defaultValue: supplier?.grossIncomeCode,
            optional: true
        }
    ]

    const onSubmit = (supplierData: Data) => {
        submitSupplier(supplierData, code, productSupplier, createMode, paymentDays)
    }

    const onCancel = () => navigate(-1)

    return (
        <PanelWrapper title={title} crumbs={crumbs}>
            <DataTypeForm
               formId="supplier-form"
               fields={fields}
               createMode={createMode}
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
        </PanelWrapper>
    )
}
