import { FieldType, FieldValue, FilterField, Filters, O, option } from "../../../../features/ui"
import { usePurchasesState } from "../../../purchases"
import { useProductsHooks, useProductsState } from "../../hooks/productsHooks"
import { useEffect, useState } from "react"
import { DataType } from '../../../../tables'
import { Product } from "../../state/types"

type Props = {
    products: Product[]
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const ProductFilters = (props: Props) => {
    const { products, onSearchStart, onSearchDone } = props

    const { categories } = useProductsState()
    const { suppliers } = usePurchasesState()

    const [searchText, setSearchText] = useState<string>()
    const [categoryId, setCategoryId] = useState<string>()
    const [supplierId, setSupplierId] = useState<string>()

    const { searchProducts } = useProductsHooks()

    useEffect(() => search(searchText, categoryId, supplierId), [products])

    const search = (searchTextParam?: string, categoryIdParam?: string, supplierIdParam?: string) => {
        onSearchStart()
        let productList = products.filter(product => !categoryIdParam || product.categoryId === categoryIdParam)
        productList = productList.filter(product => !supplierIdParam || product.supplierId === supplierIdParam)
        productList = searchProducts(productList, searchTextParam)
        setTimeout(() => onSearchDone(productList), 100)
    }

    const handleSearch = (value?: FieldValue) => {
        const newSearchText = value as O<string>
        setSearchText(newSearchText)
        search(newSearchText, categoryId, supplierId)
    }

    const onChangeCategory = (value?: FieldValue) => {
        const newCategoryId = value as O<string>
        if (newCategoryId !== categoryId) {
            setCategoryId(newCategoryId)
            search(searchText, newCategoryId, supplierId)
        }
    }

    const onChangeSupplier = (value?: FieldValue) => {
        const newSupplierId = value as O<string>
        if (newSupplierId !== supplierId) {
            setSupplierId(newSupplierId)
            search(searchText, categoryId, newSupplierId)
        }
    }

    const searchField: FilterField = {
        name: 'search',
        type: FieldType.TEXT,
        placeholder: 'Buscar por nombre, código o proveedor',
        icon: 'search',
        onChange: handleSearch
    }

    const filterFields: FilterField[] = [
        {
            name: 'category',
            type: FieldType.SELECT,
            options: categories.map(category => option(category.id, category.name)),
            label: 'Categoría',
            onChange: onChangeCategory
        },
        {
            name: 'supplier',
            type: FieldType.SELECT,
            options: suppliers.map(supplier => option(supplier.id, supplier.name)),
            label: 'Proveedor',
            onChange: onChangeSupplier
        }
    ]

    return (
        <Filters
            searchField={searchField}
            fields={filterFields}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
