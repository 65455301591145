import { useDefaultDbActions, useDefaultModuleActions } from "../../common"
import { fileSubPath, useFileActions } from "../../../features/files"
import { DynamoSystemApi } from "../../../services"
import { TextParams } from "../../../state/reducers/types"
import { TableName } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { useSystemState, useSystemStateActions } from "../state/hooks"
import { Company, Request, User } from "../state/types"
import { firstItem } from "../../../utils/listUtils"

export const useSystemActions = () => {
    const systemState = useSystemState()
    const stateCompany = systemState.company
    const stateCompanyId = stateCompany?.id

    const defaultDbActions = useDefaultDbActions()
    const defaultModuleActions = useDefaultModuleActions()
    const { setCompany, setUser, setConstrufyAdminId, updateCompanyUsers } = useSystemStateActions()
    const { saveFile, removeFile } = useFileActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId
        const { getAll, getByParams, getByIds, save, remove } = defaultDbActions(DynamoSystemApi)

        const fetchAllCompanies = async () => {
            return getAll(TableName.COMPANIES).then(response => response.dataTypes as Company[])
        }

        const fetchCompanyById = async (id: string) => {
            const companies = await getByParams(TableName.COMPANIES, { id }) as Company[]
            return firstItem(companies)
        }
        
        const fetchCompanyByCode = async (code: string, saveState = true) => {
            const companies = await getByParams(TableName.COMPANIES, { code }) as Company[]
            const company = firstItem(companies)
            saveState && company && setCompany(company)
            return company
        }

        const saveCompany = async (
            company: Company,
            logoFile?: File
        ) => {
            const updatedCompany = {
                ...company
            }
    
            if (logoFile) {
                const fileUrl = await saveFile(fileSubPath(TableName.COMPANIES, company.id), logoFile, true)
                updatedCompany.logoUrl = fileUrl
            } else if (stateCompany?.logoUrl) {
                await removeFile(stateCompany.logoUrl, true)
                delete updatedCompany.logoUrl
            }
    
            await save(TableName.COMPANIES, [updatedCompany])
            setCompany(updatedCompany)
        }

        const fetchUserById = async (id: string) => {
            const users = await getByParams(TableName.USERS, { id }) as User[]
            const user = firstItem(users)
            if (user) {
                setUser(user)
                return user
            }
        }

        const fetchUserByEmail = async (email: string) => {
            const users = await fetchUsersByEmail(email) as User[]
            return firstItem(users)
        }

        const fetchUsersByIds = async (ids: string[]) => {
            const users = await getByIds(TableName.USERS, ids) as User[]
            updateCompanyUsers(users)
            return users
        }

        const fetchUsersByEmail = (email: string) => {
            return getByParams(TableName.USERS, { email })
        }

        const saveUser = async (user: User, saveState = true) => {
            await save(TableName.USERS, [user])
            saveState && setUser(user)
            return user
        }

        const removeUser = (id: string) => {
            return remove(TableName.USERS, [id])
        }

        const fetchAdmins = async (id: string) => {
            return getByParams(TableName.ADMIN, { id })
        }

        const fetchAllRequests = async (limit?: number, startKey?: TextParams) => {
            const { fetchAll } = defaultModuleActions(DynamoSystemApi, define(companyId))
            const response = await fetchAll(TableName.REQUESTS, limit, startKey)
            const requests = response.dataTypes as Request[]
            await fetchUsersByIds(requests.map(request => request.userId))
            return response
        }

        const fetchRequest = async (requestCompanyId: string, userId: string) => {
            const { fetchByParams } = defaultModuleActions(DynamoSystemApi, define(companyId))
            return fetchByParams(TableName.REQUESTS, { companyId: requestCompanyId, userId })
        }

        const fetchRequests = async (userId: string, saveState = true) => {
            if (saveState) {
                const { fetchMultipleByParams } = defaultModuleActions(DynamoSystemApi, companyId)
                return fetchMultipleByParams(TableName.REQUESTS, { userId })
            } else {
                const { getByParams } = defaultDbActions(DynamoSystemApi, companyId)
                return getByParams(TableName.REQUESTS, { userId })
            }
        }

        const saveRequest = async (
            request: Request,
            saveState = true
        ) => {
            if (saveState) {
                const tenantModuleActions = defaultModuleActions(DynamoSystemApi, define(companyId))
                const savedRequest = await tenantModuleActions.save(TableName.REQUESTS, request) as Request
                return savedRequest
            } else {
                const tenantDbActions = defaultDbActions(DynamoSystemApi, define(companyId))
                const savedRequests = await tenantDbActions.save(TableName.REQUESTS, [request]) as Request[]
                return define(firstItem(savedRequests))
            }
        }

        const removeRequest = (id: string) => {
            const { remove } = defaultModuleActions(DynamoSystemApi, define(companyId))
            return remove(TableName.REQUESTS, id)
        }

        return {
            fetchAllCompanies,
            fetchCompanyById,
            fetchCompanyByCode,
            saveCompany,
            fetchUserById,
            fetchUserByEmail,
            fetchUsersByEmail,
            saveUser,
            removeUser,
            fetchAdmins,
            fetchAllRequests,
            fetchRequest,
            fetchRequests,
            saveRequest,
            removeRequest
        }
    }
}
