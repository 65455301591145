import styles from './companyElement.module.css'
import { Outlet } from "react-router-dom"
import { Sidebar, SpinnerModal, useUIHooks } from "../../../ui"
import { useLoad } from "./load"
import { useEffect, useState } from "react"
import { useSystemState, useSystemStateHooks } from "../../../../modules/system"
import { ElementLoader } from "../element-loader/elementLoader"

export const CompanyElement = () => {
    const { company } = useSystemState()
    const [ready, setReady] = useState(false)
    
    const load = useLoad()

    const menu = useUIHooks().getMenu(company?.name)
    const hasAccess = useSystemStateHooks().hasCompanyAccess()

    useEffect(() => {
        load().then(() => setReady(true))
    }, [])

    const marginClassName = hasAccess ? styles.leftMargin : ''
    
    return (
        <ElementLoader ready={ready}>
            <>
                {hasAccess && <Sidebar menu={menu} />}
                <div className={marginClassName}>
                    <Outlet />
                </div>
                <SpinnerModal />
            </>
        </ElementLoader>
    )
}
