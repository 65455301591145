import { O } from '../../../features/ui'
import { useStateHooks } from '../../../state'
import { TableName } from '../../../tables'
import { useLoaders } from '../../common'
import { useSystemStateHooks } from '../../system'
import { CreditCard } from '../state/types'

export const useCreditCardLoaders = () => {
    const systemHooks = useSystemStateHooks()
    const { getList, find } = useStateHooks()

    const loaders = useLoaders()

    return (companyId = systemHooks.companyId) => {
        const findAllCreditCards = () => getList(TableName.CREDIT_CARDS) as CreditCard[]

        const findCreditCard = (id?: string) => find(TableName.CREDIT_CARDS, id) as O<CreditCard>

        const loadAllCreditCards = () => {
            const { loadAll } = loaders(companyId)
            return loadAll(TableName.CREDIT_CARDS) as Promise<CreditCard[]>
        }

        const loadCreditCard = (id: string) => {
            const { load } = loaders(companyId)
            return load(TableName.CREDIT_CARDS, id) as Promise<O<CreditCard>>
        }
        
        return {
            findAllCreditCards,
            findCreditCard,
            loadAllCreditCards,
            loadCreditCard
        }
    }
}
