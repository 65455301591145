import { O } from '../../../features/ui'
import { useStateHooks } from '../../../state'
import { TableName } from '../../../tables'
import { useLoaders } from '../../common'
import { useSystemStateHooks } from '../../system'
import { CustomerType } from '../state/types'

export const useCustomerTypeLoaders = () => {
    const systemHooks = useSystemStateHooks()
    const { getList, find } = useStateHooks()

    const loaders = useLoaders()

    return (companyId = systemHooks.companyId) => {
        const findAllCustomerTypes = () => getList(TableName.CUSTOMER_TYPES) as CustomerType[]
    
        const findCustomerType = (id?: string) => find(TableName.CUSTOMER_TYPES, id) as O<CustomerType>
    
        const loadAllCustomerTypes = () => {
            const { loadAll } = loaders(companyId)
            return loadAll(TableName.CUSTOMER_TYPES) as Promise<CustomerType[]>
        }
        
        return {
            findAllCustomerTypes,
            findCustomerType,
            loadAllCustomerTypes
        }
    }
}
