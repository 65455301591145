import { useEffect, useState } from "react"
import { PurchaseItem, PurchaseOrder, usePurchasesActions } from "../../../../purchases"
import { define } from "../../../../../utils/typeUtils"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { useGetCrumbs, PanelLoader } from "../../../../../features/ui"
import { Data, useDescriptorHooks } from "../../../../../features/data-types"
import { useFileActions } from "../../../../../features/files"
import { useNavigate } from "react-router-dom"
import { usePriceBreakdownHooks } from "../../../../operations"
import { TicketForm } from "./ticketForm"
import { Expense, Ticket } from "../../../state/types"
import { useTicketActions } from "../../../actions/ticketActions"
import { useExpensesActions } from "../../../actions/expensesActions"
import { useTicketHooks } from "../../../hooks/ticketHooks"

export const TicketFormLoader = () => {  
  const { tableName, dataTypeId, secondDataTypeId } = useRoutesHooks().parseUrl()
  const parentTable = define(tableName)
  const parentId = define(dataTypeId)
  const ticketId = secondDataTypeId

  const [ready, setReady] = useState(false)
  const [parent, setParent] = useState<PurchaseOrder | Expense>()
  const [ticket, setTicket] = useState<Ticket>()
  const [ticketFile, setTicketFile] = useState<File>()
  const [defaultData, setDefaultData] = useState<Data>({})

  const navigate = useNavigate()
  const { getRoutePath } = useRoutesHooks()
  const { isPurchase } = useTicketHooks()
  const isPurchaseTicket = isPurchase(parentTable)
  const descriptorHooks = useDescriptorHooks()
  const { getPriceBreakdownFromItems } = usePriceBreakdownHooks()

  const purchasesActions = usePurchasesActions()
  const expensesActions = useExpensesActions()
  const ticketActions = useTicketActions()
  const { fetchFile } = useFileActions()

  const routePath = isPurchaseTicket ? RoutePath.PURCHASES : RoutePath.EXPENSES

  const populate = async () => {
    const { fetchExpense } = expensesActions()
    const { fetchPurchase, fetchPurchaseItemsByOrder } = purchasesActions()
    const { fetchTicket } = ticketActions()
    
    let stateParent: PurchaseOrder | Expense
    if (isPurchaseTicket) {
      stateParent = define(await fetchPurchase(parentId)) as PurchaseOrder
      const statePurchaseItems = define(await fetchPurchaseItemsByOrder(parentId)) as PurchaseItem[]
      const priceBreakdown = getPriceBreakdownFromItems(statePurchaseItems)
      setDefaultData({
        totalWithoutVAT: priceBreakdown.totalWithoutVAT,
        vatAmount: priceBreakdown.vatAmount
      })
    } else {
      stateParent = define(await fetchExpense(parentId)) as Expense
      setDefaultData({
        totalWithoutVAT: stateParent.amount,
        vatAmount: 0
      })
    }
    setParent(stateParent)
    
    if (ticketId) {
      const stateTicket = define(await fetchTicket(ticketId)) as Ticket
      if (stateTicket.payedAmount > 0) {
        navigate(getRoutePath(routePath, parentId))
      } else {
        setTicket(stateTicket)
        if (stateTicket.ticketUrl) {
          const file = await fetchFile(stateTicket.ticketUrl)
          setTicketFile(file)
        }
      }
    }
  }

  useEffect(() => {
    populate().then(() => setReady(true))
  }, [])

  const prefix = isPurchaseTicket ? 'Factura' : 'Factura / Comprobante'
  const ticketLabel = ticket ? `: ${descriptorHooks.ticket.code(ticket) }` : ''
  const title = `${ticketId ? 'Editar' : 'Subir'} ${prefix}${ticketLabel}`
  const detailLabel = parent ? (isPurchaseTicket ? descriptorHooks.purchase.code(parent) : (parent as Expense).name) : undefined
  const crumbs = useGetCrumbs(
    isPurchaseTicket ? 'Compras' : 'Gastos',
    routePath,
    detailLabel,
    parent?.id,
    title
  )

  return (
    <PanelLoader title={title} crumbs={crumbs} ready={ready}>
      {parent && (
        <TicketForm
          parentTable={parentTable}
          parent={parent}
          defaultData={defaultData}
          ticket={ticket}
          ticketFileParam={ticketFile}
        />
      )}
    </PanelLoader>
  )
}
