import { TableName } from '../../../tables'
import { usePurchasesActions, usePurchasesHooks } from '../../../modules/purchases'
import { useSalesActions, useSalesHooks } from '../../../modules/sales'
import { useManagerActions } from '../../../modules/manager'
import { useCashFundActions, CategorySaleYield, useAdministrationActions, useExpensesActions, useTicketActions, usePaymentTypeActions, useTaxLoaders } from '../../../modules/administration'
import { useProductsActions } from '../../../modules/products'
import { define } from '../../../utils/typeUtils'
import { O } from '../../ui'

type RemoveAction = (id: string) => Promise<void>
type RemoveValidationFn = (id: string) => Promise<boolean>

export const useDataTypeActions = () => {
    const salesHooks = useSalesHooks()
    const purchasesHooks = usePurchasesHooks()

    const taxLoaders = useTaxLoaders()

    const { removeCashFund } = useCashFundActions()
    const { removePaymentType } = usePaymentTypeActions()
    const administrationActions = useAdministrationActions()
    const ticketActions = useTicketActions()
    const expensesActions = useExpensesActions()
    const managerActions = useManagerActions()
    const purchasesActions = usePurchasesActions()
    const salesActions = useSalesActions()
    const productsActions = useProductsActions()

    const getRemoveAction = (
        tableName: TableName
    ): O<RemoveAction> => {
        const removeActions = new Map<TableName, RemoveAction>()
            .set(TableName.BRANCHES, managerActions().removeBranch)
            .set(TableName.BUDGETS, salesActions().removeBudget)
            .set(TableName.CASH_FUNDS, removeCashFund)
            .set(TableName.CATEGORIES, purchasesActions().removeCategory)
            .set(TableName.CHECKS, administrationActions().removeCheck)
            .set(TableName.CREDIT_CARDS, administrationActions().removeCreditCard)
            .set(TableName.CUSTOMER_TYPES, salesActions().removeCustomerType)
            .set(TableName.CUSTOMERS, salesActions().removeCustomer)
            .set(TableName.EMPLOYEES, administrationActions().removeEmployee)
            .set(TableName.EXPENSE_TYPES, expensesActions().removeExpenseType)
            .set(TableName.EXPENSES, expensesActions().removeExpense)
            .set(TableName.PAYMENT_TYPES, removePaymentType)
            .set(TableName.PRODUCTS, purchasesActions().removeProduct)
            .set(TableName.TAXES, administrationActions().removeTax)
            .set(TableName.SUPPLIERS, purchasesActions().removeSupplier)
        
        return removeActions.get(tableName)
    }

    const getRemoveValidation = (
        tableName: TableName
    ): O<RemoveValidationFn> => {
        if (tableName === TableName.CATEGORIES) {
            return async (categoryId: string) => {
                const categorySaleYields = (purchasesHooks.getSettings()?.categorySaleYields || []) as CategorySaleYield[]
                return !categorySaleYields.some(categorySaleYield => categorySaleYield.categoryId === categoryId) &&
                    await productsActions().countProducts('categoryId', categoryId) === 0
            }
        } else if (tableName === TableName.CASH_FUNDS) {
            return async (cashFundId: string) =>
                await administrationActions().countTransactions('cashFundId', cashFundId) === 0
        } else if (tableName === TableName.CUSTOMER_TYPES) {
            return async (customerTypeId: string) =>
                await salesActions().countCustomers('customerTypeId', customerTypeId) === 0
        } else if (tableName === TableName.CUSTOMERS) {
            return async (customerId: string) => {
                const { countBudgets, countSales, countHoards } = salesActions()
                return await countBudgets('customerId', customerId) === 0 &&
                    await countSales('customerId', customerId) === 0 &&
                    await countHoards('customerId', customerId) === 0
            }
        } else if (tableName === TableName.EMPLOYEES) {
            return async (employeeId: string) => {
                const { countBudgets, countSales, countHoards } = salesActions()
                return await expensesActions().countExpenses('employeeId', employeeId) === 0 &&
                    await countBudgets('sellerId', employeeId) === 0 &&
                    await countSales('sellerId', employeeId) === 0 &&
                    await countHoards('sellerId', employeeId) === 0
            }
        } else if (tableName === TableName.EXPENSE_TYPES) {
            return async (expenseTypeId: string) =>
                await expensesActions().countExpenses('expenseTypeId', expenseTypeId) === 0
        } else if (tableName === TableName.PAYMENT_TYPES) {
            return async (paymentTypeId: string) => {
                const { countSales, countHoards } = salesActions()
                return await countSales('paymentTypeId', paymentTypeId) === 0 &&
                    await countHoards('paymentTypeId', paymentTypeId) === 0
            }
        } else if (tableName === TableName.PRODUCTS) {
            return async (productId: string) => {
                const hoardIgnoredProductIds = (salesHooks.getSettings()?.hoardIgnoredProductIds || []) as string[]
                const { countBudgetItems, countSaleItems, countHoardItems } = salesActions()
                return !hoardIgnoredProductIds.some(hoardIgnoredProductId => hoardIgnoredProductId === productId) &&
                    await purchasesActions().countPurchaseItems('productId', productId) === 0 &&
                    await countBudgetItems('productId', productId) === 0 &&
                    await countSaleItems('productId', productId) === 0 &&
                    await countHoardItems('productId', productId) === 0
            }
        } else if (tableName === TableName.SUPPLIERS) {
            return async (supplierId: string) =>
                await expensesActions().countExpenses('supplierId', supplierId) === 0 &&
                await productsActions().countProducts('supplierId', supplierId) === 0 &&
                await purchasesActions().countPurchases('supplierId', supplierId) === 0
        } else if (tableName === TableName.TAXES) {
            return async (taxId: string) => {
                const { loadTax } = taxLoaders()
                const tax = define(await loadTax(taxId))
                return await productsActions().countProducts('vatTaxId', taxId) === 0 &&
                    await ticketActions().countTickets(tax.code) === 0
            }
        }
    }

    return {
        getRemoveAction,
        getRemoveValidation
    }
}
