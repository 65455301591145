import { Emitter, Event } from "../../../../../features/event-emitter"
import { Data } from "../../../../../features/ui"
import { Currency } from "../../../../../tables/types/types"
import { define } from "../../../../../utils/typeUtils"
import { PaymentStatus } from "../../../../operations"
import { useExpensesActions } from "../../../actions/expensesActions"
import { useAdministrationHooks, useAdministrationState } from "../../../hooks/administrationHooks"
import { Expense } from "../../../state/types"

export const useActions = () => {
    const { currentEmployee } = useAdministrationState()

    const { getSalaryExpenseTypeId } = useAdministrationHooks()

    const expensesActions = useExpensesActions()
    
    const submitSalaryExpense = async (
        expenseData: Data,
        createMode: boolean,
        code: number,
        branchId: string,
        ticketFile: File,
        detail?: string,
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const { companyId, id, name, amount, paymentStatus } = expenseData
        const dueDate = define(expenseData.paymentDate) as Date
        const expense: Expense = {
            companyId: define(companyId) as string,
            id: define(id) as string,
            code,
            name: define(name) as string,
            expenseTypeId: getSalaryExpenseTypeId(),
            date: new Date().toISOString(),
            branchId,
            amount: define(amount) as number,
            currency: Currency.ARS,
            detail,
            paymentStatus: paymentStatus as PaymentStatus || PaymentStatus.PENDING,
            employeeId: define(currentEmployee).id
        }
        
        await expensesActions().saveSalaryExpense(expense, ticketFile, dueDate)
        Emitter.emit(Event.DB_SUBMIT_DONE, createMode)
    }
    
    return {
        submitSalaryExpense
    }
}
