import { fileNameFromUrl, useFileActions } from '../../../../features/files'
import { FileTile, FileType, Modal, ModalId } from '../../../../features/ui'
import { DataType } from '../../../../tables'

type Props = {
    modalId: ModalId
    dataType?: DataType
    fileProp: keyof DataType
    title?: string
}

export const DownloadFileModal = (props: Props) => {
    const { modalId, dataType, fileProp, title } = props

    const fileUrl = dataType && dataType[fileProp]
    const fileName = fileNameFromUrl(fileUrl)

    const { downloadFile } = useFileActions()

    const onSubmit = () => {
        if (dataType && dataType[fileProp]) {
            downloadFile(dataType[fileProp])
        }
    }
    
    return (
        <Modal
            modalId={modalId}
            title={title || 'Descargar Comprobante'}
            submitLabel="Descargar"
            submitIcon="download"
            onSubmit={onSubmit}
        >
            <FileTile
                fileType={FileType.PDF}
                fileName={fileName}
            />
        </Modal>
    )
}
