import { Emitter, Event } from "../../../../features/event-emitter"
import { Data, O } from "../../../../features/ui"
import { Currency } from "../../../../tables/types/types"
import { define, isDefined } from "../../../../utils/typeUtils"
import { usePricesHooks } from "../../../operations"
import { useProductsActions } from "../../actions/productsActions"
import { Product } from "../../state/types"

export const useActions = () => {
    const pricesHooks = usePricesHooks()
    const productsActions = useProductsActions()

    const submitProduct = async (
        productData: Data,
        createMode: boolean,
        code: number,
        units: number,
        unitTypeId: string,
        measuresData: Data[],
        description?: string,
        imageFile?: File,
        productSaleYield?: number,
        basePrice?: number
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const { companyId, id, name, supplierId, supplierCode, categoryId, loted, imageUrl, vatTaxId } = productData
        const product: Product = {
            companyId: define(companyId) as string,
            id: define(id) as string,
            code,
            name: define(name) as string,
            supplierId: define(supplierId) as string,
            supplierCode: define(supplierCode) as string,
            categoryId: define(categoryId) as string,
            units,
            unitTypeId,
            loted: !!loted,
            description,
            imageUrl: imageUrl as O<string>,
            purchasable: true,
            salable: true,
            vatTaxId: define(vatTaxId) as string
        }

        const purchasePrice = define(productData.purchasePrice) as number
        const saleYield = isDefined(productSaleYield) ? productSaleYield : (
            isDefined(basePrice) ? pricesHooks.saleYield(purchasePrice, define(basePrice)) : undefined
        )
        const pricesData: Data = {
            purchasePrice: define(purchasePrice),
            saleYield,
            currency: Currency.ARS
        }
        
        await productsActions().saveProduct(product, pricesData, measuresData, imageFile)
        Emitter.emit(Event.DB_SUBMIT_DONE, createMode)
    }

    return {
        submitProduct
    }
}
