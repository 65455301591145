import styles from './budgetPDFPage.module.css'
import { Budget, BudgetItem, Customer, CustomerType } from "../../../state/types"
import { define } from '../../../../../utils/typeUtils'
import { useEffect, useMemo, useState } from 'react'
import { useSalesActions } from '../../../actions/salesActions'
import { useAdministrationState, useEmployeeLoaders } from '../../../../administration'
import { Product, useProductsActions } from '../../../../products'
import { useDataTypesHooks, useDescriptorHooks } from '../../../../../features/data-types'
import { useManagerState } from '../../../../manager'
import { PriceBreakdown, usePriceBreakdownHooks } from '../../../../operations'
import { FieldType, O, PDFHeader } from '../../../../../features/ui'
import { BudgetItemPDFRow } from './types'
import { TableName } from '../../../../../tables'
import { useFind } from '../../../../../state/reducers/hooks'

type Props = {
    pageIndex: number
    budget: Budget
    budgetItems: BudgetItem[]
    pagesAmount: number
}

export const BudgetPDFPage = (props: Props) => {
    const { pageIndex, budget, budgetItems, pagesAmount } = props
    const isLastPage = pageIndex === pagesAmount - 1
    
    const { branches } = useManagerState()
    const { paymentTypes } = useAdministrationState()

    const { formatValue } = useDataTypesHooks()
    const descriptorHooks = useDescriptorHooks()
    const { getPriceBreakdownFromItems } = usePriceBreakdownHooks()
    const find = useFind()

    const employeeLoaders = useEmployeeLoaders()

    const productsActions = useProductsActions()
    const salesActions = useSalesActions()

    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [products, setProducts] = useState<Product[]>([])
    const [customer, setCustomer] = useState<Customer>()

    const init = async () => {
        const productIds = budgetItems.map(budgetItem => budgetItem.productId)
        const stateProducts = await productsActions().fetchProductsByIds(productIds) as Product[]
        setProducts(stateProducts)
        const stateCustomer = define(await salesActions().fetchCustomer(budget.customerId)) as Customer
        setCustomer(stateCustomer)   
        setAsyncDataLoaded(true)
    }

    useEffect(() => {
        init()
    }, [budget, budgetItems])

    const seller = useMemo(() => employeeLoaders().findEmployee(budget.sellerId), [budget.sellerId])
    const customerType = find(TableName.CUSTOMER_TYPES, customer?.customerTypeId) as O<CustomerType>
    const priceBreakdown = getPriceBreakdownFromItems(budgetItems, customerType?.yield)
    const adjustedPriceBreakdown = getPriceBreakdownFromItems(budgetItems, customerType?.yield)

    const columns = [
        { name: 'product', label: 'Producto' },
        { name: 'content', label: 'Contenido' },
        { name: 'unitSubtotal', label: 'P. Unitario s/IVA' },
        { name: 'unitTotal', label: 'P. Unitario' },
        { name: 'quantity', label: 'Cantidad' },
        { name: 'total', label: 'P. Total' },
    ]

    const rows: BudgetItemPDFRow[] = useMemo(() => asyncDataLoaded && products.length > 0 ?
        budgetItems.map(budgetItem => {
            const product = define(products.find(product => product.id === budgetItem.productId)) as Product
            const itemPriceBreakdown = define(priceBreakdown.items.find(item => item.productId === product.id))
            return {
                product: product.name,
                content: descriptorHooks.product.content(product),
                unitSubtotal: formatValue(itemPriceBreakdown.unitSubtotal, FieldType.PRICE),
                unitTotal: formatValue(itemPriceBreakdown.unitTotal, FieldType.PRICE),
                quantity: descriptorHooks.product.unitsDetail(budgetItem.quantity, product),
                total: formatValue(itemPriceBreakdown.total, FieldType.PRICE)
            }
        }) : []
    , [budgetItems, products, priceBreakdown, asyncDataLoaded])

    const branchesInfo = branches.map(branch => {
        const mailInfo = branch.email ? ` - Mail: ${branch.email}` : ''
        return `Sucursal ${branch.name}: ${branch.address}${mailInfo}`
    })

    const paymentTypesInfo = paymentTypes
        .sort((paymentType1, paymentType2) => paymentType1.yield - paymentType2.yield)
        .map(paymentType => `${paymentType.name} (${formatValue(paymentType.yield, FieldType.PERCENTAGE)})`)
        .map(info => info.replace('Tarjeta de ', ''))
        .join(', ')

    return (
        <div className={styles.container}>
            <div className={styles.body}>                
                <PDFHeader noValidTicketLabel />

                {pageIndex === 0 && (<>
                    <div className={styles.row}>
                        <span className={styles.budgetTitle}>
                            Presupuesto #{budget.code}
                        </span>
                        <div className={styles.date}>
                            Fecha: {formatValue(new Date(budget.date), FieldType.DATE)}
                        </div>
                    </div>

                    <div className={`${styles.row} ${styles.alignStart}`}>
                        <div className={styles.personalInfo}>
                            <span>Cliente: {customer?.name}</span>
                            <span>Dirección: {customer?.address || '-'}</span>
                            <span>DNI/CUIT: {customer?.identifier}</span>
                            <span>Teléfono: {customer?.phone || '-'}</span>
                        </div>
                        <div className={styles.personalInfo}>
                            <span>Vendedor: {seller?.name}</span>
                            <span>Teléfono: {seller?.phone || '-'}</span>
                        </div>
                    </div>
                </>)}

                <div className={styles.tableRow}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                {columns.map((column, index) => (
                                    <th key={index}>
                                        {column.label}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                                {rows.map((row, index) => (
                                    <tr key={index}>
                                        {columns.map((column, index) => (
                                            <td key={index}>
                                                {row[column.name as keyof BudgetItemPDFRow]}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                <div className={styles.pagination}>
                    {pageIndex + 1} / {pagesAmount}
                </div>

                {isLastPage && (
                    <div className={`${styles.row} ${styles.priceBreakdown}`}>
                        <PriceBreakdown data={adjustedPriceBreakdown} />
                    </div>
                )}

                <div className={styles.footer}>
                    <div className={styles.separator} />
                    <div className={`${styles.row} ${styles.footerRow}`}>
                        {branchesInfo.map((branchInfo, index) => (
                            <span key={index}>{branchInfo}</span>
                        ))}
                        <span className={styles.marginTop}>{paymentTypesInfo}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
