import { useEffect, useState } from "react"
import { useDescriptorHooks } from "../../../../../features/data-types"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { PanelLoader, useGetCrumbs } from "../../../../../features/ui"
import { PurchaseItem, PurchaseOrder } from "../../../state/types"
import { define } from "../../../../../utils/typeUtils"
import { usePurchasesActions } from "../../../actions/purchasesActions"
import { PurchaseForm } from "./purchaseForm"

export const PurchaseFormLoader = () => {
    const { dataTypeId } = useRoutesHooks().parseUrl()
    const purchaseId = dataTypeId

    const [ready, setReady] = useState(false)
    const [purchase, setPurchase] = useState<PurchaseOrder>()
    const [purchaseItems, setPurchaseItems] = useState<PurchaseItem[]>([])

    const descriptorHooks = useDescriptorHooks()

    const purchasesActions = usePurchasesActions()

    const populate = async () => {
        const { fetchAllSuppliers, fetchPurchase, fetchPurchaseItemsByOrder } = purchasesActions()
        await fetchAllSuppliers()

        if (purchaseId) {
            const statePurchase = define(await fetchPurchase(purchaseId)) as PurchaseOrder
            setPurchase(statePurchase)
            const statePurchaseItems = await fetchPurchaseItemsByOrder(purchaseId) as PurchaseItem[]
            setPurchaseItems(statePurchaseItems)
        }
    }

    useEffect(() => {
        populate().then(() => setReady(true))
    }, [])

    const purchaseLabel = purchase ? `: ${descriptorHooks.purchase.code(purchase) }` : ''
    const title = `${purchaseId ? 'Editar' : 'Crear'} Compra${purchaseLabel}`
    const crumbs = useGetCrumbs(
        'Compras',
        RoutePath.PURCHASES,
        purchase ? descriptorHooks.purchase.code(purchase) : undefined,
        purchaseId,
        purchaseId ? 'Editar' : 'Crear'
    )

    return (
        <PanelLoader
            title={title}
            crumbs={crumbs}
            ready={ready}
            wide
        >
            <PurchaseForm
                purchase={purchase}
                purchaseItems={purchaseItems}
            />
        </PanelLoader>
      )
}
