import { Emitter, Event } from "../../../../../features/event-emitter"
import { Data } from "../../../../../features/ui"
import { usePaymentTypeActions } from "../../../actions/paymentTypeActions"
import { PaymentCategory, PaymentType } from "../../../state/types"

export const useActions = () => {
    const { savePaymentType } = usePaymentTypeActions()
    
    const submitPaymentType = async (
        paymentTypeData: Data,
        paymentTypeYield: number,
        createMode: boolean
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const { companyId, id, name, category } = paymentTypeData
        const paymentType: PaymentType = {
            companyId: companyId as string,
            id: id as string,
            name: name as string,
            category: category as PaymentCategory,
            yield: paymentTypeYield
        }

        await savePaymentType(paymentType)
        Emitter.emit(Event.DB_SUBMIT_DONE, createMode)
    }
    
    return {
        submitPaymentType
    }
}
