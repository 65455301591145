import { useEffect, useState } from "react"
import { PanelLoader } from "../../../../features/ui"
import { useManagerState } from "../../../manager"
import { define } from "../../../../utils/typeUtils"
import { PurchaseReceptionList } from "./purchaseReceptionList"
import { PurchaseOrder, usePurchaseLoaders } from "../../../purchases"

export const PurchaseReceptionListLoader = () => {
    const { currentBranch } = useManagerState()
    const branchId = define(currentBranch).id

    const [purchases, setPurchases] = useState<PurchaseOrder[]>([])
    const [ready, setReady] = useState(false)

    const purchaseLoaders = usePurchaseLoaders()

    const populate = async () => {
        const { loadBranchPurchases } = purchaseLoaders()
        const loadedPurchases = await loadBranchPurchases(branchId)
        setPurchases(loadedPurchases)
    }

    useEffect(() => {
        setReady(false)
        populate().then(() => setReady(true))
    }, [branchId])

    return (
        <PanelLoader
            title="Recepción de Compras"
            ready={ready}
            wide
        >
            <PurchaseReceptionList purchases={purchases} />
        </PanelLoader>
    )
}
