import { TableName } from '../../../tables'
import { define } from '../../../utils/typeUtils'

export const fileNameFromUrl = (fileUrl?: string) => fileUrl?.split('/').pop()

export const fileName = (
    table: TableName,
    identifier: string,
    table2?: TableName,
    identifier2?: string,
    customLabel?: string
) => {
    let tableLabel = ''
    let table2Label = ''
    if (table === TableName.BUDGETS) {
        tableLabel = 'Presupuesto'
    } else if (table === TableName.HOARD_ORDERS) {
        const prefix = customLabel || 'Orden'
        tableLabel = `${prefix}-Acopio`
    } else if (table === TableName.ORDER_DELIVERIES) {
        tableLabel = 'Remito'
        table2Label = table2 === TableName.PURCHASE_ORDERS ? 'Compra' : (table2 === TableName.SALE_ORDERS ? 'Venta' : 'Acopio')
    } else if (table === TableName.PAYMENT_ORDERS) {
        tableLabel = `${customLabel || 'Orden'}-Pago`
    } else if (table === TableName.PURCHASE_ORDERS) {
        tableLabel = 'Orden-Compra'
    } else if (table === TableName.SALE_ORDERS) {
        const prefix = customLabel || 'Orden'
        tableLabel = `${prefix}-Venta`
    } else if (table === TableName.TICKETS) {
        tableLabel = customLabel ? 'Recibo' : 'Factura'
        table2Label = table2 === TableName.PURCHASE_ORDERS ? 'Compra' : customLabel || 'Gasto'
    }

    const info2 = table2 ? `_${table2Label}-${identifier2}` : ''
    return `${tableLabel}-${identifier}${info2}.pdf`
}

export const fileSubPath = (
    tableName: TableName,
    id: string,
    fileName?: string
) => {
    if (tableName === TableName.COMPANIES) {
        return 'logo'
    }

    const partsMap = new Map<TableName, [string, string]>()
        .set(TableName.CATEGORIES, ['category', 'image'])
        .set(TableName.EXPENSES, ['expense', 'receipt'])
        .set(TableName.HOARD_ORDERS, ['hoardOrder', 'invoice'])
        .set(TableName.ORDER_DELIVERIES, ['orderDelivery', 'deliveryNote'])
        .set(TableName.PAYMENT_ORDERS, ['paymentOrder', 'receipt'])
        .set(TableName.PRODUCTS, ['product', 'image'])
        .set(TableName.SALE_ORDERS, ['saleOrder', 'invoice'])
        .set(TableName.TICKETS, ['ticket', 'ticket'])
    
    const parts = define(partsMap.get(tableName))
    return `${parts[0]}/${id}/${fileName || parts[1]}`
}
