import { useState } from "react"
import { PanelWrapper, FieldType, UIPermission, Action, Column, FieldValue, Data, SpinnerDiv, useUIActions, ModalId, StaticValue, Badge } from "../../../../features/ui"
import { DataType, TableName } from "../../../../tables"
import { useSystemStateHooks } from "../../../system"
import { define, toNumber } from "../../../../utils/typeUtils"
import { DataTypeList, Labels } from "../../../../features/data-types"
import { CloseOperationModal, DeliveryStatus, Operation, PaymentStatus } from "../../../operations"
import { OpenWizardModal, useWizardActions } from "../../../../features/wizard"
import { RoutePath, useRoutesHooks } from "../../../../features/routes"
import { useNavigate } from "react-router-dom"
import { useFind } from "../../../../state/reducers/hooks"
import { HoardFilters, HoardOrder, HoardType, hoardWizardKey } from "../../../sales"

export const HoardDeliveryList = () => {
    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [currentHoard, setCurrentHoard] = useState<HoardOrder>()
    const wizardKey = currentHoard ? hoardWizardKey(currentHoard) : undefined

    const { userHasPermission } = useSystemStateHooks()
    const hasDeliveryPermission = userHasPermission(UIPermission.SAVE_HOARD_DELIVERIES)
    const navigate = useNavigate()
    const { getRoutePath } = useRoutesHooks()
    const find = useFind()

    const { goToWizard } = useWizardActions()
    const { openModal } = useUIActions()

    const deliveryPath = (hoard?: HoardOrder) => hoard ? `${getRoutePath(RoutePath.HOARDS, hoard.id)}/${RoutePath.DELIVERY}` : '#'
    const findHoard = (code?: string) => define(find(TableName.HOARD_ORDERS, toNumber(code), 'code') as HoardOrder)

    const setRowsState = (hoards: Data[]) => {
        setRows(hoards)
        setAsyncDataLoaded(true)
    }

    const columns: Column[] = [
        {
            name: 'code',
            type: FieldType.NUMBER,
            label: '#', render: (value?: FieldValue) => `#${value}`
        },
        {
            name: 'type',
            type: FieldType.TEXT,
            label: 'Tipo',
            render: (value?: FieldValue) => Labels.hoardTypeLabel(define(value) as HoardType, true)
        },
        {
            name: 'dueDate',
            type: FieldType.DATE,
            label: 'Fecha Límite'
        },
        {
            name: 'value',
            type: FieldType.PRICE,
            label: 'Acopio Total',
            render: (value?: FieldValue) => value ?
                <StaticValue type={FieldType.PRICE} value={value} /> :
                <Badge label="Ver en detalle" />
        },
        {
            name: 'customerId',
            type: FieldType.TABLE,
            table: TableName.CUSTOMERS, label: 'Cliente'
        },
        {
            name: 'deliveryStatus',
            type: FieldType.TEXT,
            label: 'Estado de Entrega',
            render: (value?: FieldValue) => Labels.deliveryStatusLabel(define(value) as DeliveryStatus)
        }
    ]

    const onView = (code?: string) => {
        const hoard = findHoard(code)
        if (hoard) {
            navigate(getRoutePath(RoutePath.HOARDS, hoard.id))
        }
    }

    const onDeliver = (code?: string) => {
        const hoard = findHoard(code)
        setCurrentHoard(hoard)
        goToWizard(hoardWizardKey(hoard), deliveryPath(hoard))
    }

    const onClose = (code?: string) => {
        const hoard = findHoard(code)
        setCurrentHoard(hoard)
        openModal(ModalId.CLOSE_OPERATION)
    }

    const actions = (code?: string) => {
        const actionList: Action[] = [
            { icon: 'eye-fill', kind: 'tertiary', tooltip: 'Ver Detalle', onClick: onView }
        ]

        const hoard = findHoard(code)
        const isDelivered = hoard.deliveryStatus === DeliveryStatus.DELIVERED
        const isPayed = hoard.paymentStatus === PaymentStatus.PAYED

        if (hasDeliveryPermission && !isDelivered && isPayed) {
            const deliverAction = { icon: 'truck', tooltip: 'Registrar Entrega', onClick: onDeliver }
            const closeAction = { icon: 'bag-check-fill', tooltip: 'Cerrar', onClick: onClose }
            actionList.push(deliverAction, closeAction)
        }

        return actionList
    }

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRowsState(dataTypes as Data[])
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <PanelWrapper title="Entrega de Acopios" wide>
            <HoardFilters onSearchStart={onSearchStart} onSearchDone={onSearchDone}/>
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    rowId="code"
                    currentDataTypeParam={currentHoard}
                    actions={actions}
                    readOnly
                />
            )}
            <OpenWizardModal
                wizardKey={wizardKey}
                path={deliveryPath(currentHoard)}
            />
            <CloseOperationModal
                operationType={Operation.HOARD}
                operation={currentHoard}
            />
        </PanelWrapper>
    )
}
