import { ReducerState } from "../../../state/reducers/types"
import { addOrUpdate, remove } from "../../../state/reducers/utils"
import { ActionType, AdministrationAction } from './actionTypes'
import { CashFund, Check, CreditCard, Employee, Expense, ExpenseType, PaymentOrder, PaymentType, Settings, Tax, Ticket, Transaction } from "./types"

export type AdministrationState = {
    currentEmployee?: Employee
    settings: Settings[]
    taxes: Tax[]
    cashFunds: CashFund[]
    checks: Check[]
    creditCards: CreditCard[]
    transactions: Transaction[]
    paymentTypes: PaymentType[]
    employees: Employee[]
    expenseTypes: ExpenseType[]
    expenses: Expense[]
    paymentOrders: PaymentOrder[]
    tickets: Ticket[]
    selectedTickets: Ticket[]
}

const initialState: AdministrationState = {
    taxes: [],
    settings: [],
    cashFunds: [],
    checks: [],
    creditCards: [],
    transactions: [],
    paymentTypes: [],
    employees: [],
    expenseTypes: [],
    expenses: [],
    paymentOrders: [],
    tickets: [],
    selectedTickets: []
}

const reducer = (state = initialState, action: AdministrationAction) => {
    switch(action.type) {
        case ActionType.SET_CURRENT_EMPLOYEE:
            return { 
                ...state,
                currentEmployee: action.payload
            }
        case ActionType.UPDATE_TAXES:
            return { 
                ...state,
                taxes: addOrUpdate(state, action, 'taxes' as keyof ReducerState)
            }
        case ActionType.REMOVE_TAXES:
            return { 
                ...state,
                taxes: remove(state, action, 'taxes' as keyof ReducerState)
            }
        case ActionType.UPDATE_SETTINGS:
            return { 
                ...state,
                settings: addOrUpdate(state, action, 'settings' as keyof ReducerState)
            }
        case ActionType.UPDATE_PAYMENT_TYPES:
            return { 
                ...state,
                paymentTypes: addOrUpdate(state, action, 'paymentTypes' as keyof ReducerState)
            }
        case ActionType.REMOVE_PAYMENT_TYPES:
            return { 
                ...state,
                paymentTypes: remove(state, action, 'paymentTypes' as keyof ReducerState)
            }
        case ActionType.UPDATE_CASH_FUNDS:
            return { 
                ...state,
                cashFunds: addOrUpdate(state, action, 'cashFunds' as keyof ReducerState)
            }
        case ActionType.REMOVE_CASH_FUNDS:
            return { 
                ...state,
                cashFunds: remove(state, action, 'cashFunds' as keyof ReducerState)
            }
        case ActionType.UPDATE_CHECKS:
            return { 
                ...state,
                checks: addOrUpdate(state, action, 'checks' as keyof ReducerState)
            }
        case ActionType.REMOVE_CHECKS:
            return { 
                ...state,
                checks: remove(state, action, 'checks' as keyof ReducerState)
            }
        case ActionType.UPDATE_CREDIT_CARDS:
            return { 
                ...state,
                creditCards: addOrUpdate(state, action, 'creditCards' as keyof ReducerState)
            }
        case ActionType.REMOVE_CREDIT_CARDS:
            return { 
                ...state,
                creditCards: remove(state, action, 'creditCards' as keyof ReducerState)
            }
        case ActionType.UPDATE_TRANSACTIONS:
            return { 
                ...state,
                transactions: addOrUpdate(state, action, 'transactions' as keyof ReducerState)
            }
        case ActionType.UPDATE_EMPLOYEES:
            return {
                ...state,
                employees: addOrUpdate(state, action, 'employees' as keyof ReducerState)
            }
        case ActionType.REMOVE_EMPLOYEES:
            return { 
                ...state,
                employees: remove(state, action, 'employees' as keyof ReducerState)
            }
        case ActionType.UPDATE_EXPENSE_TYPES:
            return { 
                ...state,
                expenseTypes: addOrUpdate(state, action, 'expenseTypes' as keyof ReducerState)
            }
        case ActionType.REMOVE_EXPENSE_TYPES:
            return { 
                ...state,
                expenseTypes: remove(state, action, 'expenseTypes' as keyof ReducerState)
            }
        case ActionType.UPDATE_EXPENSES:
            return { 
                ...state,
                expenses: addOrUpdate(state, action, 'expenses' as keyof ReducerState)
            }
        case ActionType.REMOVE_EXPENSES:
            return { 
                ...state,
                expenses: remove(state, action, 'expenses' as keyof ReducerState)
            }
        case ActionType.UPDATE_PAYMENT_ORDERS:
            return { 
                ...state,
                paymentOrders: addOrUpdate(state, action, 'paymentOrders' as keyof ReducerState)
            }
        case ActionType.REMOVE_PAYMENT_ORDERS:
            return { 
                ...state,
                paymentOrders: remove(state, action, 'paymentOrders' as keyof ReducerState)
            }
        case ActionType.UPDATE_TICKETS:
            return { 
                ...state,
                tickets: addOrUpdate(state, action, 'tickets' as keyof ReducerState)
            }
        case ActionType.REMOVE_TICKETS:
            return { 
                ...state,
                tickets: remove(state, action, 'tickets' as keyof ReducerState)
            }
        case ActionType.SELECT_TICKETS:
            return {
                ...state,
                selectedTickets: action.payload
            }
        default:
            return state
    }
}

export default reducer
