import { useEffect, useState } from "react"
import { DataType } from '../../../../../tables'
import { PaymentOrder, Tax, Ticket } from '../../../state/types'
import { O, PagesPDFWrapper } from "../../../../../features/ui"
import { useTicketActions } from "../../../actions/ticketActions"
import { Supplier, usePurchasesActions } from "../../../../purchases"
import { useTicketHooks } from "../../../hooks/ticketHooks"
import { firstItem } from "../../../../../utils/listUtils"
import { define } from "../../../../../utils/typeUtils"
import { RetentionCertificatePDFPage } from "./retentionCertificatePDFPage"
import { useTaxLoaders } from "../../../loaders/taxLoaders"

type Props = {
    id: string
    paymentOrder: PaymentOrder
    previewMode?: boolean
}

export const RetentionCertificatePDF = (props: Props) => {
    const { id, paymentOrder, previewMode } = props

    const [tickets, setTickets] = useState<Ticket[]>([])
    const [supplier, setSupplier] = useState<Supplier>()

    const { getSupplierId } = useTicketHooks()

    const taxLoaders = useTaxLoaders()

    const ticketActions = useTicketActions()
    const purchasesActions = usePurchasesActions()

    const init = async () => {
        const { fetchSupplier } = purchasesActions()
        const ticketIds = paymentOrder.ticketItems.map(ticketItem => ticketItem.ticketId)
        const stateTickets = await ticketActions().fetchTickets(ticketIds) as Ticket[]
        setTickets(stateTickets)
        const supplierId = getSupplierId(define(firstItem(stateTickets)))
        if (supplierId) {
            const stateSupplier = await fetchSupplier(supplierId) as O<Supplier>
            setSupplier(stateSupplier)
        }
    }

    useEffect(() => {
        init()
    }, [paymentOrder])

    const renderPages = paymentOrder.taxItems.map(taxItem => {
        const retention = taxLoaders().findTaxByCode(taxItem.taxCode)
        return (_: number, dataType: DataType) => retention ? (
            <RetentionCertificatePDFPage
                key={taxItem.taxCode}
                paymentOrder={dataType as PaymentOrder}
                retention={retention}
                tickets={tickets}
                supplier={supplier}
            />
        ) : <></>
    })

    return (
        <PagesPDFWrapper
            id={id}
            dataType={paymentOrder}
            renderPages={renderPages}
            previewMode={previewMode}
        />
    )
}
