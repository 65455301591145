import styles from './companyPanel.module.css'
import { define } from '../../../../utils/typeUtils'
import { useSystemState, useSystemStateHooks } from '../../../system'
import { useActions } from './actions'
import { useManagerState } from '../../../manager'
import { Data, DetailFieldProps, FieldType, FieldValue, FormField, FormatType, ImageField, ProfilePanel, Spinner, UIPermission, Link, O } from '../../../../features/ui'
import { useEffect, useState } from 'react'
import { useFileActions } from '../../../../features/files'
import { useDataTypesHooks, validatePhone, validateTaxId } from '../../../../features/data-types'

export const CompanyPanel = () => {
  const { branches } = useManagerState()
  const { company } = useSystemState()
  const definedCompany = define(company)

  const [asyncDataLoaded, setAsyncDataLoaded] = useState(!definedCompany.logoUrl)
  const [logoFile, setLogoFile] = useState<File>()
  
  const { userHasPermission } = useSystemStateHooks()
  const readOnly = !userHasPermission(UIPermission.SAVE_COMPANY)
  const { formatCustomValue } = useDataTypesHooks()
  
  const { onBranchClick, updateCompany } = useActions()
  const { fetchFile } = useFileActions()

  useEffect(() => {
    if (definedCompany.logoUrl) {
      fetchFile(definedCompany.logoUrl, true).then(file => {
        setLogoFile(file)
        setAsyncDataLoaded(true)
      })
    } else {
      setAsyncDataLoaded(true)
    }
  }, [])

  const companyData = {
    ...definedCompany,
    imageUrl: definedCompany.logoUrl
  }

  const infoFields: DetailFieldProps[] = [
    {
      name: 'legalName',
      type: FieldType.TEXT,
      label: 'Razón Social'
    },
    {
      name: 'taxId',
      type: FieldType.TEXT,
      label: 'CUIT',
      render: (value?: FieldValue) => {
        const taxId = define(value) as number
        return <>{formatCustomValue(taxId, FormatType.CUIT_CUIL)}</>
      }
    },
    {
      name: 'websiteUrl',
      type: FieldType.TEXT,
      label: 'Página Web',
      render: (value?: FieldValue) => {
        const websiteUrl = define(value) as string
        return <Link
          text={websiteUrl}
          href={`https://${websiteUrl}`}
          kind="primary"
          className={styles.link}
        />
      }
    },
    {
      name: 'address',
      type: FieldType.TEXT,
      label: 'Dirección'
    },
    {
      name: 'email',
      type: FieldType.TEXT,
      label: 'Email'
    }
  ]
  if (definedCompany.phone) {
    const phoneField = {
      name: 'phone',
      type: FieldType.NUMBER,
      label: 'Teléfono',
      render: (value?: FieldValue) => {
        const phone = value as O<number>
        return <>{formatCustomValue(phone, FormatType.PHONE)}</>
      }
    }
    infoFields.push(phoneField)
  }
  if (definedCompany.grossIncomeCode) {
    const grossIncomeCodeField = {
      name: 'grossIncomeCode',
      type: FieldType.TEXT,
      label: 'N° de Inscripción a IIBB'
    }
    infoFields.push(grossIncomeCodeField)
  }
  const branchesField = {
    name: 'branches',
    type: FieldType.TEXT,
    label: 'Sucursales',
    render: () => <div className={styles.branchesContainer}>
      {branches.map(branch => <Link
        key={branch.name}
        text={branch.name}
        kind="primary"
        className={styles.link}
        onClick={onBranchClick(branch)}
      />)}
    </div>
  }
  infoFields.push(branchesField)

  const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <Spinner size='m'/>

  const fields: FormField[] = [
    {
      name: 'legalName',
      type: FieldType.TEXT,
      label: 'Razón Social'
    },
    {
      name: 'taxId',
      type: FieldType.NUMBER,
      label: 'CUIT',
      validate: validateTaxId
    },
    {
      name: 'logoUrl',
      type: FieldType.IMAGE,
      label: 'Logo',
      render: () => renderAsync(<ImageField
        id="logoUrl"
        value={logoFile}
        onChange={setLogoFile}
      />)
    },
    {
      name: 'websiteUrl',
      type: FieldType.TEXT,
      label: 'Página Web'
    },
    {
      name: 'address',
      type: FieldType.TEXT,
      label: 'Dirección'
    },
    {
      name: 'email',
      type: FieldType.TEXT,
      label: 'Email'
    },
    {
      name: 'phone',
      type: FieldType.NUMBER,
      label: 'Teléfono',
      validate: validatePhone
    },
    {
      name: 'grossIncomeCode',
      type: FieldType.TEXT,
      label: 'N° de Inscripción a IIBB'
    }
  ]

  const onSubmit = async (data: Data) => {
    return updateCompany(definedCompany, data, logoFile)
  }

  return <ProfilePanel
    infoFields={infoFields}
    fields={fields}
    data={companyData}
    subtitle={definedCompany.websiteUrl}
    infoTabLabel='Información de Compañía'
    readOnly={readOnly}
    onSubmit={onSubmit}
  />
}
