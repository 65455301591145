import { useSalesActions } from "../../../actions/salesActions"
import { SaleOrder } from "../../../state/types"
import { Data } from "../../../../../features/ui"
import { define } from "../../../../../utils/typeUtils"
import { Currency } from "../../../../../tables/types/types"
import { DeliveryStatus, PaymentStatus } from "../../../../operations"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { Emitter, Event } from "../../../../../features/event-emitter"
import { InvoiceStatus } from "../../../../administration"

export const useActions = () => {
    const { getRoutePath } = useRoutesHooks()
    
    const salesActions = useSalesActions()

    const submitSale = async (
        saleData: Data,
        code: number,
        branchId: string,
        sellerId: string,
        customerId: string,
        saleItemsData: Data[],
        createMode: boolean
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const { companyId, id, paymentTypeId, deliveryStatus, paymentStatus } = saleData
        const deliveryDate = define(saleData.deliveryDate) as Date
        const sale: SaleOrder = {
            companyId: define(companyId) as string,
            id: define(id) as string,
            code,
            creationDate: new Date().toISOString(),
            sellerId,
            customerId,
            currency: Currency.ARS,
            paymentTypeId: define(paymentTypeId) as string,
            deliveryDate: deliveryDate.toISOString(),
            branchId,
            deliveryStatus: deliveryStatus as DeliveryStatus || DeliveryStatus.PENDING,
            paymentStatus: paymentStatus as PaymentStatus || PaymentStatus.PENDING,
            invoiceStatus: InvoiceStatus.UNINVOICED
        }

        const savedSale = await salesActions().saveSaleWithItems(sale, saleItemsData, createMode)
        Emitter.emit(Event.DB_SUBMIT_DONE, createMode, false, getRoutePath(RoutePath.SALES, savedSale.id))
    }
    
    return {
        submitSale
    }
}
