import { CashFund, CashFundType } from "../../../state/types"
import { Data } from "../../../../../features/ui"
import { Currency } from "../../../../../tables/types/types"
import { useCashFundActions } from "../../../actions/cashFundActions"
import { Emitter, Event } from "../../../../../features/event-emitter"

export const useActions = () => {
    const { saveCashFund } = useCashFundActions()

    const submitCashFund = async (
        cashFundData: Data,
        createMode: boolean
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const { companyId, id, name, type } = cashFundData
        const cashFund: CashFund = {
            companyId: companyId as string,
            id: id as string,
            name: name as string,
            type: type as CashFundType,
            availableAmount: 0,
            reservedAmount: 0,
            currency: Currency.ARS
        }
        
        await saveCashFund(cashFund)
        Emitter.emit(Event.DB_SUBMIT_DONE, createMode)
    }
    
    return {
        submitCashFund
    }
}
