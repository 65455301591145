import { PurchaseItem, PurchaseOrder } from "../../purchases"
import { BudgetItem, HoardItem, HoardOrder, SaleItem, SaleOrder } from "../../sales"

export type OperationItem = Partial<PurchaseItem | BudgetItem | SaleItem | HoardItem>

export type DeliveredItem = {
    itemId: string
    delivered: number
}

export type OrderDelivery = {
    companyId: string
    id: string
    orderId: string
    date: string
    deliveredItems: DeliveredItem[]
    branchId: string
    deliveryNoteUrl?: string
}

export enum DeliveryStatus {
    PENDING = 'Pending',
    PARTIALLY_DELIVERED = 'Partially Delivered',
    DELIVERED = 'Delivered',
    CANCELLED = 'Canceleld'
}

export enum PaymentStatus {
    PENDING = 'Pending',
    PARTIALLY_PAYED = 'Partially Payed',
    PAYED = 'Delivered',
    CANCELLED = 'Canceleld'
}

export enum AdjustmentType {
    DISCOUNT = "Discount",
    SURCHARGE = 'Surcharge'
}

export enum TaxCategory {
    CONSUMER = 'Consumer',
    REGISTERED = 'Registered',
    REGISTERED_AGENT = 'Registered Agent',
    REGISTERED_FC = 'Registered FC',
    EXEMPT = 'Exempt',
    EXEMPT_FC = 'Exempt FC',
    SINGLE_TAXPAYER = 'Single Taxpayer',
    M_TICKET = 'M Ticket',
    NON_REGISTERED = 'Non Registered',
}

export enum Operation {
    PURCHASE,
    SALE,
    HOARD
}

export type OperationOrder = PurchaseOrder | SaleOrder | HoardOrder
