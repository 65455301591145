import { useEffect, useState } from 'react'
import { DataType } from '../../../../../tables'
import { FieldType, FieldValue, option, FilterField, Filters, O } from '../../../../../features/ui'
import { useManagerState } from '../../../../manager'
import { define } from '../../../../../utils/typeUtils'
import { HoardOrder, HoardType } from '../../../state/types'
import { useSalesActions } from '../../../actions/salesActions'
import { useSalesHooks, useSalesState } from '../../../hooks/salesHooks'
import { DeliveryStatus } from '../../../../operations'
import { Labels } from '../../../../../features/data-types'
import { SellerField } from '../../seller-field/sellerField'

type Props = {
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const HoardFilters = (props: Props) => {
    const { onSearchStart, onSearchDone } = props

    const salesState = useSalesState()
    const stateHoards = salesState.hoards
    const customers = salesState.customers
    const { currentBranch } = useManagerState()
    const currentBranchId = define(currentBranch).id

    const [hoards, setHoards] = useState<HoardOrder[]>()
    const [searchText, setSearchText] = useState<string>()
    const [type, setType] = useState<HoardType>()
    const [deliveryStatus, setDeliveryStatus] = useState<DeliveryStatus>()
    const [sellerId, setSellerId] = useState<string>()
    const [customerId, setCustomerId] = useState<string>()
    const [dateFrom, setDateFrom] = useState<Date>()
    const [dateTo, setDateTo] = useState<Date>()
    const [stateHoardsLength, setStateHoardsLength] = useState<number>(stateHoards.length)

    const { searchHoards } = useSalesHooks()

    const salesActions = useSalesActions()

    const fetchAndSearch = async () => {
        onSearchStart()
        const branchHoards = await salesActions().fetchAllHoards(currentBranchId) as HoardOrder[]
        setHoards(branchHoards)
        search(branchHoards, searchText, type, deliveryStatus, sellerId, customerId, dateFrom, dateTo)
    }

    useEffect(() => {
        if (stateHoards.length < stateHoardsLength) {
            fetchAndSearch()
        }
        setStateHoardsLength(stateHoards.length)
    }, [stateHoards])

    useEffect(() => {
        fetchAndSearch()
    }, [currentBranchId])
    
    const search = (
        baseHoardList: HoardOrder[] = [],
        searchTextParam?: string,
        typeParam?: HoardType,
        deliveryStatusParam?: string,
        sellerIdParam?: string,
        customerIdParam?: string,
        dateFromParam?: Date,
        dateToParam?: Date
    ) => {
        onSearchStart()
        let hoardList = baseHoardList.filter(hoard => hoard.branchId === currentBranchId)
        hoardList = hoardList.filter(hoard => !typeParam || hoard.type === typeParam)
        hoardList = hoardList.filter(hoard => !deliveryStatusParam || hoard.deliveryStatus === deliveryStatusParam)
        hoardList = hoardList.filter(hoard => !sellerIdParam || hoard.sellerId === sellerIdParam)
        hoardList = hoardList.filter(hoard => !customerIdParam || hoard.customerId === customerIdParam)
        hoardList = dateFromParam ? hoardList.filter(hoard => new Date(hoard.dueDate) >= dateFromParam) : hoardList
        hoardList = dateToParam ? hoardList.filter(hoard => new Date(hoard.dueDate) <= dateToParam) : hoardList
        hoardList = searchHoards(hoardList, searchTextParam)
        setTimeout(() => onSearchDone(hoardList), 100)
    }

    const handleSearch = (value?: FieldValue) => {
        const newSearchText = value as O<string>
        setSearchText(newSearchText)
        search(hoards, newSearchText, type, deliveryStatus, sellerId, customerId, dateFrom, dateTo)
    }

    const onChangeType = (value?: FieldValue) => {
        const newType = value as O<HoardType>
        if (newType !== type) {
            setType(newType)
            search(hoards, searchText, newType, deliveryStatus, sellerId, customerId, dateFrom, dateTo)
        }
    }

    const onChangeStatus = (value?: FieldValue) => {
        const newDeliveryStatus = value as O<DeliveryStatus>
        if (newDeliveryStatus !== deliveryStatus) {
            setDeliveryStatus(newDeliveryStatus)
            search(hoards, searchText, type, newDeliveryStatus, sellerId, customerId, dateFrom, dateTo)
        }
    }

    const onChangeSeller = (value?: FieldValue) => {
        const newSellerId = value as O<string>
        if (newSellerId !== sellerId) {
            setSellerId(newSellerId)
            search(hoards, searchText, type, deliveryStatus, newSellerId, customerId, dateFrom, dateTo)
        }
    }

    const onChangeCustomer = (value?: FieldValue) => {
        const newCustomerId = value as O<string>
        if (newCustomerId !== customerId) {
            setCustomerId(newCustomerId)
            search(hoards, searchText, type, deliveryStatus, sellerId, newCustomerId, dateFrom, dateTo)
        }
    }

    const onChangeDateFrom = (value?: FieldValue) => {
        const newDateFrom = value as O<Date>
        newDateFrom?.setHours(11, 59, 59)
        if (newDateFrom !== dateFrom) {
            setDateFrom(newDateFrom)
            search(hoards, searchText, type, deliveryStatus, sellerId, customerId, newDateFrom, dateTo)
        }
    }
    
    const onChangeDateTo = (value?: FieldValue) => {
        const newDateTo = value as O<Date>
        newDateTo?.setHours(11, 59, 59)
        if (newDateTo !== dateTo) {
            setDateTo(newDateTo)
            search(hoards, searchText, type, deliveryStatus, sellerId, customerId, dateFrom, newDateTo)
        }
    }

    const searchField: FilterField = {
        name: 'search',
        type: FieldType.TEXT,
        placeholder: 'Buscar por código, vendedor o cliente',
        icon: 'search',
        onChange: handleSearch
    }

    const filterFields: FilterField[] = [
        {
            name: 'type',
            type: FieldType.SELECT,
            options: Object.values(HoardType).map(type => option(type, Labels.hoardTypeLabel(type))),
            label: 'Tipo',
            space: 3,
            onChange: onChangeType
        },
        {
            name: 'deliveryStatus',
            type: FieldType.SELECT,
            options: Object.values(DeliveryStatus).map(status => option(status, Labels.deliveryStatusLabel(status))),
            label: 'Estado',
            space: 3,
            onChange: onChangeStatus
        },
        {
            ...SellerField({
                onChange: onChangeSeller
            }),
            space: 3
        } as FilterField,
        {
            name: 'customer',
            type: FieldType.SELECT,
            options: customers.map(customer => option(customer.id, customer.name)),
            label: 'Cliente',
            space: 3,
            onChange: onChangeCustomer
        },
        {
            name: 'dateFrom',
            type: FieldType.DATE,
            label: 'Vencimiento: Desde',
            space: 3,
            onChange: onChangeDateFrom
        },
        {
            name: 'dateTo',
            type: FieldType.DATE,
            label: 'Vencimiento: Hasta',
            space: 3,
            onChange: onChangeDateTo
        }
    ]

    return (
        <Filters
            searchField={searchField}
            fields={filterFields}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
