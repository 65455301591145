import { useState } from "react"
import { PanelWrapper, FieldType, UIPermission, Action, Column, FieldValue, SpinnerDiv, Data } from "../../../../features/ui"
import { useSystemStateHooks } from "../../../system"
import { useFind } from "../../../../state/reducers/hooks"
import { define, toNumber } from "../../../../utils/typeUtils"
import { DataTypeList, Labels } from "../../../../features/data-types"
import { DeliveryStatus, PaymentStatus } from "../../../operations"
import { OpenWizardModal, useWizardActions } from "../../../../features/wizard"
import { RoutePath, useRoutesHooks } from "../../../../features/routes"
import { useNavigate } from "react-router-dom"
import { SaleOrder, saleWizardKey, SaleFilters } from "../../../sales"
import { DataType, TableName } from "../../../../tables"

export const SaleDeliveryList = () => {
    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [currentSale, setCurrentSale] = useState<SaleOrder>()
    const wizardKey = currentSale ? saleWizardKey(currentSale) : undefined

    const { userHasPermission } = useSystemStateHooks()
    const hasDeliveryPermission = userHasPermission(UIPermission.SAVE_SALE_DELIVERIES)
    const navigate = useNavigate()
    const { getRoutePath } = useRoutesHooks()
    const find = useFind()

    const { goToWizard } = useWizardActions()

    const deliveryPath = (sale?: SaleOrder) => sale ? `${getRoutePath(RoutePath.SALES, sale.id)}/${RoutePath.DELIVERY}` : '#'
    const findSale = (code?: string) => define(find(TableName.SALE_ORDERS, toNumber(code), 'code') as SaleOrder)

    const setRowsState = (sales: SaleOrder[]) => {
        setRows(sales)
        setAsyncDataLoaded(true)
    }

    const columns: Column[] = [
        {
            name: 'code',
            type: FieldType.NUMBER,
            label: '#',
            render: (value?: FieldValue) => `#${value}`
        },
        {
            name: 'deliveryDate',
            type: FieldType.DATE,
            label: 'Fecha de Entrega'
        },
        {
            name: 'customerId',
            type: FieldType.TABLE,
            table: TableName.CUSTOMERS,
            label: 'Cliente'
        },
        {
            name: 'deliveryStatus',
            type: FieldType.TEXT,
            label: 'Estado de Entrega',
            render: (value?: FieldValue) => Labels.deliveryStatusLabel(define(value) as DeliveryStatus)
        }
    ]

    const onView = (code?: string) => {
        const sale = findSale(code)
        if (sale) {
            navigate(getRoutePath(RoutePath.SALES, sale.id))
        }
    }

    const onDeliver = (code?: string) => {
        const sale = findSale(code)
        setCurrentSale(sale)
        goToWizard(saleWizardKey(sale), deliveryPath(sale))
    }

    const actions = (code?: string) => {
        const actionList: Action[] = [
            { icon: 'eye-fill', kind: 'tertiary', tooltip: 'Ver Detalle', onClick: onView }
        ]

        const sale = findSale(code)
        const isDelivered = sale.deliveryStatus === DeliveryStatus.DELIVERED
        const isPayed = sale.paymentStatus === PaymentStatus.PAYED

        if (hasDeliveryPermission && !isDelivered && isPayed) {
            actionList.push({ icon: 'truck', tooltip: 'Registrar Entrega', onClick: onDeliver })
        }

        return actionList
    }

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRowsState(dataTypes as SaleOrder[])
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <PanelWrapper title="Entrega de Ventas" wide>
            <SaleFilters onSearchStart={onSearchStart} onSearchDone={onSearchDone}/>
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    rowId="code"
                    currentDataTypeParam={currentSale}
                    actions={actions}
                    readOnly
                />
            )}
            <OpenWizardModal
                wizardKey={wizardKey}
                path={deliveryPath(currentSale)}
            />
        </PanelWrapper>
    )
}
