import styles from './purchasePDFPage.module.css'
import { PurchaseItem, PurchaseOrder, Supplier } from "../../../state/types"
import { define } from '../../../../../utils/typeUtils'
import { useEffect, useMemo, useState } from 'react'
import { Product, useProductsActions } from '../../../../products'
import { useDataTypesHooks, useDescriptorHooks } from '../../../../../features/data-types'
import { FieldType, FormatType, O, PDFHeader } from '../../../../../features/ui'
import { PurchaseItemPDFRow } from './types'
import { usePurchasesActions } from '../../../actions/purchasesActions'
import { useEmployeeLoaders } from '../../../../administration'
import { usePurchasesHooks } from '../../../hooks/purchasesHooks'
import { useFind } from '../../../../../state/reducers/hooks'
import { TableName } from '../../../../../tables'
import { Branch } from '../../../../manager'
import { PriceBreakdown, usePriceBreakdownHooks } from '../../../../operations'
import { dedupe } from '../../../../../utils/listUtils'

type Props = {
    pageIndex: number
    purchase: PurchaseOrder
    purchaseItems: PurchaseItem[]
    pagesAmount: number
}

export const PurchasePDFPage = (props: Props) => {
    const { pageIndex, purchase, purchaseItems, pagesAmount } = props
    const isLastPage = pageIndex === pagesAmount - 1

    const [products, setProducts] = useState<Product[]>([])
    const [supplier, setSupplier] = useState<Supplier>()
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)

    const { getSettings } = usePurchasesHooks()
    const purchaseDocFooter = getSettings()?.purchaseDocFooter as O<string>
    const { formatCustomValue, formatValue } = useDataTypesHooks()
    const { getPriceBreakdownFromItems } = usePriceBreakdownHooks()
    const descriptorHooks = useDescriptorHooks()
    const find = useFind()

    const employeeLoaders = useEmployeeLoaders()

    const productsActions = useProductsActions()
    const purchasesActions = usePurchasesActions()

    const init = async () => {
        const productIds = dedupe(purchaseItems.map(purchaseItem => purchaseItem.productId))
        const stateProducts = await productsActions().fetchProductsByIds(productIds) as Product[]
        setProducts(stateProducts)
        const stateSupplier = define(await purchasesActions().fetchSupplier(purchase.supplierId)) as Supplier
        setSupplier(stateSupplier)
        setAsyncDataLoaded(true)
    }

    useEffect(() => {
        init()
    }, [purchase, purchaseItems])

    const employee = useMemo(() => employeeLoaders().findEmployee(purchase.employeeId), [purchase.employeeId])
    const priceBreakdown = getPriceBreakdownFromItems(purchaseItems)

    const branch = define(find(TableName.BRANCHES, purchase.deliveryBranchId)) as Branch
    const columns = [
        { name: 'supplierCode', label: 'Código' },
        { name: 'product', label: 'Producto' },
        { name: 'unitSubtotal', label: 'P. Unitario s/IVA' },
        { name: 'unitTotal', label: 'P. Unitario' },
        { name: 'quantity', label: 'Cantidad' },
        { name: 'total', label: 'P. Total' },
    ]

    const rows: PurchaseItemPDFRow[] = useMemo(() => asyncDataLoaded && products.length > 0 ?
        purchaseItems.map(purchaseItem => {
            const product = define(products.find(product => product.id === purchaseItem.productId)) as Product
            const itemPriceBreakdown = define(priceBreakdown.items.find(item => item.productId === product.id))
            return {
                supplierCode: product.supplierCode,
                product: product.name,
                unitSubtotal: formatValue(itemPriceBreakdown.unitSubtotal, FieldType.PRICE),
                unitTotal: formatValue(itemPriceBreakdown.unitTotal, FieldType.PRICE),
                quantity: descriptorHooks.product.unitsDetail(purchaseItem.quantity, product),
                total: formatValue(itemPriceBreakdown.total, FieldType.PRICE)
            }
        }) : []
    , [purchaseItems, products, priceBreakdown, asyncDataLoaded])

    return (
        <div className={styles.container}>
            <div className={styles.body}>                
                <PDFHeader />

                {pageIndex === 0 && (<>
                    <div className={styles.row}>
                        <span className={styles.title}>
                            Orden de Compra #{purchase.code}
                        </span>
                        <div className={styles.date}>
                            Fecha: {formatValue(new Date(purchase.creationDate), FieldType.DATE)}
                        </div>
                    </div>

                    <div className={`${styles.row} ${styles.alignStart}`}>
                        <div className={styles.infoSection}>
                            <span>Proveedor: {supplier?.name}</span>
                            <span>Dirección: {supplier?.address || '-'}</span>
                            <span>CUIT/CUIL: {formatCustomValue(supplier?.taxId, FormatType.CUIT_CUIL)}</span>
                        </div>
                        <div className={`${styles.infoSection} ${styles.rightSection}`}>
                            <span>Teléfono: {supplier?.phone ? formatCustomValue(supplier?.phone, FormatType.PHONE) : '-'}</span>
                            <span>Email: {supplier?.email || '-'}</span>
                        </div>
                    </div>

                    <div className={`${styles.row} ${styles.alignStart}`}>
                        <div className={styles.infoSection}>
                            <span>Entregar en: {branch.address || '-'}</span>
                            <span>Confeccionó: {employee?.name}</span>
                        </div>
                        <div className={`${styles.infoSection} ${styles.rightSection}`}>
                            <span>Fecha de Entrega: {formatValue(new Date(purchase.deliveryDate), FieldType.DATE)}</span>
                        </div>
                    </div>
                </>)}

                <div className={styles.tableRow}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                {columns.map((column, index) => (
                                    <th key={index}>
                                        {column.label}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                                {rows.map((row, index) => (
                                    <tr key={index}>
                                        {columns.map((column, index) => (
                                            <td key={index}>
                                                {row[column.name as keyof PurchaseItemPDFRow]}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                <div className={styles.pagination}>
                    {pageIndex + 1} / {pagesAmount}
                </div>

                {isLastPage && (
                    <div className={`${styles.row} ${styles.priceBreakdown}`}>
                        <PriceBreakdown data={priceBreakdown} />
                    </div>
                )}

                {purchaseDocFooter && (
                    <div className={styles.footer}>
                        <div className={styles.separator} />
                        <div className={styles.row}>
                            <span>{purchaseDocFooter}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
