import { awsS3Bucket, awsS3PublicBucket } from '../constants';
import { S3Api } from './s3'

export const useS3Actions = () => {
    return (s3Api: S3Api) => {
        const getFile = (
            s3FilePath: string,
            isImage = false
        ) => {
            const bucketName = isImage ? awsS3PublicBucket : awsS3Bucket
            const bucketNameIndex = s3FilePath.indexOf(bucketName)
            const filePath = s3FilePath.substring(bucketNameIndex + bucketName.length + 1)
            return s3Api.getFile(filePath, isImage)
        }
        
        const saveFile = (
            filePath: string,
            file: File,
            isImage = false
        ) => {
            return s3Api.saveFile(filePath, file, isImage)
        }

        const removeFile = (
            s3FilePath: string,
            isImage = false
        ) => {
            const bucketName = isImage ? awsS3PublicBucket : awsS3Bucket
            const bucketNameIndex = s3FilePath.indexOf(bucketName)
            const filePath = s3FilePath.substring(bucketNameIndex + bucketName.length + 1)
            return s3Api.removeFile(filePath, isImage)
        }

        return {
            getFile,
            saveFile,
            removeFile
        }
    }
}
