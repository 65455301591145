import styles from './filters.module.css'
import { DataType } from '../../../../tables'
import { FilterField } from '../types'
import { Field } from '../fields/field/field'
import { Button } from '../button/button'
import { useState } from 'react'

type Props = {
    searchField?: FilterField
    fields?: FilterField[]
    firstRowLength?: number
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const Filters = (props: Props) => {
    const { searchField, fields, firstRowLength = 4 } = props

    const [showFilters, setShowFilters] = useState<boolean>(!searchField)

    const onClickFilters = () => setShowFilters(prevValue => !prevValue)

    const showClassName = showFilters ? styles.show : ''

    const fieldRows = fields && fields.length > 4 ?
        [fields.slice(0, firstRowLength), fields.slice(firstRowLength, fields.length)] :
        [fields]

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                {searchField && (
                    <div className={`col-sm-${searchField.space || 5}`}>
                        <Field
                            field={searchField}
                            onChange={searchField.onChange}
                        />
                    </div>
                )}
                {fields && (
                    <Button
                        icon='filter'
                        label="Filtros"
                        kind={showFilters ? 'primary' : 'tertiary'}
                        small
                        onClick={onClickFilters}
                    />
                )}
            </div>
            {fields && fieldRows.map((fieldRow, index) => (
                <div key={index} className={`${styles.row} ${styles.filtersRow} ${showClassName}`}>
                    {fieldRow?.map(field => {
                        const { name, label, space = 4, onChange } = field
                        return (
                            <div key={`${name}-filter`} className={`col-sm-${space}`}>
                                {label && (
                                    <label className={styles.filterLabel}>{label}</label>
                                )}
                                <Field
                                    field={field}
                                    onChange={onChange}
                                    className={styles.filter}
                                />
                            </div>
                        )
                    })}
                </div>
            ))}
        </div>
    )
}
