import { useEffect, useState } from "react"
import { Data, Modal, ModalId, useUIState } from "../../../../../features/ui"
import { CashFund, Check, CreditCard, PaymentType } from "../../../state/types"
import { PaymentItemForm } from "./paymentItemForm"

type Props = {
    paymentItem?: Data
    pendingAmount: number
    cashFunds: CashFund[]
    creditCards: CreditCard[]
    checks: Check[]
    filterPaymentTypes?: (paymentType: PaymentType) => boolean
    onSubmit: (data: Data) => void
}

export const PaymentItemFormModal = (props: Props) => {
    const { paymentItem, pendingAmount, filterPaymentTypes, cashFunds, creditCards, checks, onSubmit } = props
    const [key, setKey] = useState(0)
    const { openedModal } = useUIState()

    useEffect(() => setKey(key + 1), [openedModal])
    
    return (
        <Modal
            modalId={ModalId.PAYMENT_ITEM_FORM}
            title="Agregar Medio de Pago"
            actions={[]}
            widthSize="m"
        >
            <PaymentItemForm
                key={key}
                paymentItem={paymentItem}
                pendingAmount={pendingAmount}
                cashFunds={cashFunds}
                creditCards={creditCards}
                checks={checks}
                filterPaymentTypes={filterPaymentTypes}
                onSubmit={onSubmit}
            />
        </Modal>
    )
}
