import { useEffect, useState } from "react"
import { useDescriptorHooks } from "../../../../../features/data-types"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { DetailFieldProps, DetailPanel, FieldType, useGetCrumbs } from "../../../../../features/ui"
import { TableName } from "../../../../../tables"
import { define } from "../../../../../utils/typeUtils"
import { Transaction } from "../../../state/types"
import { TransactionDestination } from "../transaction-destination/transactionDestination"
import { TransactionSource } from "../transaction-source/transactionSource"

export const TransactionDetail = () => {
  const { dataType } = useRoutesHooks().parseUrl()
  const transaction = define(dataType) as Transaction

  const [description, setDescription] = useState<string>()

  const descriptorHooks = useDescriptorHooks()

  const loadDescription = async () => descriptorHooks.transaction.info(transaction)

  useEffect(() => {
    loadDescription().then(setDescription)
  }, [transaction])
  
  const crumbs = useGetCrumbs('Transacciones', RoutePath.TRANSACTIONS, description)

  const fields: DetailFieldProps[] = [
    {
      name: 'date',
      type: FieldType.DATE,
      label: 'Fecha'
    },
    {
      name: 'sourceId',
      type: FieldType.TEXT,
      label: 'Origen',
      render: () => <TransactionSource transaction={transaction} />
    },
    {
      name: 'amount',
      type: FieldType.PRICE,
      label: 'Monto'
    },
    {
      name: 'destinationId',
      type: FieldType.TEXT,
      label: 'Destino',
      render: () => <TransactionDestination transaction={transaction} />
    }
  ]
  if (transaction.concept) {
    const conceptField = {
      name: 'concept',
      type: FieldType.TEXT,
      label: 'Concepto'
    }
    fields.push(conceptField)
  }

  return (<>
    <DetailPanel
      tableName={TableName.TRANSACTIONS}
      crumbs={crumbs}
      fields={fields}
      data={transaction}
      icon='arrow-left-right'
      readOnly
    />
  </>)
}
