import styles from './paymentOrderPDFPage.module.css'
import { useDataTypesHooks } from '../../../../../features/data-types'
import { FieldType, FormatType, PDFHeader } from '../../../../../features/ui'
import { Supplier } from '../../../../purchases'
import { useEffect, useMemo, useState } from 'react'
import { PaymentOrder, Tax, Ticket } from '../../../state/types'
import { PaymentItemData } from './types'
import { useTicketHooks } from '../../../hooks/ticketHooks'
import { usePaymentOrderHooks } from '../../../hooks/paymentOrderHooks'
import { usePaymentTypeLoaders } from '../../../loaders/paymentTypeLoaders'
import { define } from '../../../../../utils/typeUtils'
import { useTaxLoaders } from '../../../loaders/taxLoaders'

type Props = {
    paymentOrder: PaymentOrder
    tickets: Ticket[]
    supplier?: Supplier
}

export const PaymentOrderPDFPage1 = (props: Props) => {
    const { paymentOrder, tickets, supplier } = props

    const [paymentItemsData, setPaymentItemsData] = useState<PaymentItemData[]>([])

    const { formatCustomValue, formatValue } = useDataTypesHooks()
    const { getTicketPendingAmount } = useTicketHooks()
    const { describeSource } = usePaymentOrderHooks()

    const paymentTypeLoaders = usePaymentTypeLoaders()
    const taxLoaders = useTaxLoaders()

    const loadPaymentItemsData = async () => {
        const { findPaymentType } = paymentTypeLoaders()
        return Promise.all(
            paymentOrder.paymentItems.map(async paymentItem => {
                const paymentType = define(findPaymentType(paymentItem.paymentTypeId))
                const reference = await describeSource(paymentItem.sourceId)
                return { ...paymentItem, paymentType: paymentType.name, reference }
            })
        )
    }

    useEffect(() => {
        loadPaymentItemsData().then(newItemsData => setPaymentItemsData(newItemsData))
    }, [paymentOrder])

    const ticketItemsData = useMemo(() => paymentOrder.ticketItems.map(ticketItem => {
        const ticket = tickets.find(ticket => ticket.id === ticketItem.ticketId)
        return ticket && {
            ...ticketItem,
            code: ticket.code,
            dueDate: new Date(ticket.dueDate),
            total: ticket.total,
            pendingAmount: getTicketPendingAmount(ticket)
        }
    }), [paymentOrder, tickets])
    
    const taxItemsData = useMemo(() => paymentOrder.taxItems.map(taxItem => {
        const tax = taxLoaders().findTaxByCode(taxItem.taxCode)
        return tax && { ...taxItem, reference: tax.name }
    }), [paymentOrder])

    const paymentItemsAmount = useMemo(() => paymentOrder.paymentItems.reduce((sum, paymentItem) => sum + paymentItem.amount, 0), [paymentOrder])
    const taxItemsAmount = useMemo(() => paymentOrder.taxItems.reduce((sum, taxItem) => sum + taxItem.amount, 0), [paymentOrder])

    const renderTaxId = (taxId?: number) => formatCustomValue(taxId, FormatType.CUIT_CUIL)

    return (
        <div className={styles.container}>
            <div className={styles.body}>
                <PDFHeader />
                
                <div className={`${styles.row} ${styles.titleRow}`}>
                    <span className={styles.title}>
                        Orden de Pago #{paymentOrder.code}
                    </span>
                    <div className={styles.date}>
                        Fecha: {formatValue(new Date(paymentOrder.creationDate), FieldType.DATE)}
                    </div>
                </div>

                {supplier && (
                    <>
                        <div className={styles.row}>
                            <span className={styles.subtitle}>PROVEEDOR</span>
                        </div>

                        <div className={`${styles.row} ${styles.infoRow}`}>
                            <div className={styles.infoSection}>
                                <span>Razón Social:</span>
                                <span>Domicilio:</span>
                                <span>CUIT:</span>
                                <span>Teléfono:</span>
                            </div>
                            <div className={`${styles.infoSection} ${styles.rightSection}`}>
                                <span>{supplier?.name}</span>
                                <span>{supplier?.address || '-'}</span>
                                <span>{renderTaxId(supplier?.taxId) || '-'}</span>
                                <span>{formatCustomValue(supplier?.phone, FormatType.PHONE) || '-'}</span>
                            </div>
                        </div>
                    </>
                )}

                <div className={styles.row}>
                    <span className={styles.subtitle}>CANCELACIÓN DE DOCUMENTOS</span>
                </div>

                <div className={`${styles.row} ${styles.infoRow} ${styles.infoTable}`}>
                    <table>
                        <thead>
                            <tr>
                                <th>Factura</th>
                                <th>Fecha Vto.</th>
                                <th>Monto Factura</th>
                                <th>Imp. a Cancelar</th>
                                <th>Imp. Pendiente</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ticketItemsData.map((ticketItemData, index) => ticketItemData && (
                                <tr key={index}>
                                    <td>{ticketItemData.code}</td>
                                    <td>{formatValue(ticketItemData.dueDate, FieldType.DATE)}</td>
                                    <td>{formatValue(ticketItemData.total, FieldType.PRICE)}</td>
                                    <td>{formatValue(ticketItemData.payedAmount, FieldType.PRICE)}</td>
                                    <td>{formatValue(ticketItemData.pendingAmount, FieldType.PRICE)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className={styles.row}>
                    <span className={styles.subtitle}>MEDIOS DE PAGO</span>
                </div>

                <div className={`${styles.row} ${styles.infoRow} ${styles.infoTable}`}>
                    <table>
                        <thead>
                            <tr>
                                <th>Medio de Pago</th>
                                <th>Referencia</th>
                                <th>Importe</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paymentItemsData.map((paymentItemData, index) => paymentItemData && (
                                <tr key={index}>
                                    <td>{paymentItemData.paymentType}</td>
                                    <td>{paymentItemData.reference}</td>
                                    <td>{formatValue(paymentItemData.amount, FieldType.PRICE)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <span>Subtotal: {formatValue(paymentItemsAmount, FieldType.PRICE)}</span>
                </div>

                {paymentOrder.taxItems.length > 0 && (
                    <>
                        <div className={styles.row}>
                            <span className={styles.subtitle}>RETENCIONES</span>
                        </div>
                        <div className={`${styles.row} ${styles.infoRow} ${styles.infoTable}`}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Retención</th>
                                        <th>Importe</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {taxItemsData.map((taxItemData, index) => taxItemData && (
                                        <tr key={index}>
                                            <td>{taxItemData.taxCode}</td>
                                            <td>{taxItemData.reference}</td>
                                            <td>{formatValue(taxItemData.amount, FieldType.PRICE)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <span>Subtotal: {formatValue(taxItemsAmount, FieldType.PRICE)}</span>
                        </div>
                    </>
                )}

                <div className={styles.footer}>
                    <div className={styles.separator} />
                    <div className={`${styles.row} ${styles.footerRow}`}>
                        <div className={styles.signContainer}>
                            <div className={styles.placeholder} />
                            <span>Autorizó</span>
                        </div>
                        <div className={styles.signContainer}>
                            <div className={styles.placeholder} />
                            <span>Confeccionó</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
