import { Emitter, Event } from "../../../../../features/event-emitter"
import { TableName } from "../../../../../tables"
import { InvoiceStatus } from "../../../../administration"
import { HoardOrder, SaleOperation, SaleOrder, useSalesActions } from "../../../../sales"

export const useActions = () => {
    const salesActions = useSalesActions() 

    const submitInvoice = async (
        table: TableName,
        operation: SaleOperation,
        operationFile: File
    ) => {
        Emitter.emit(Event.DB_SUBMIT)
        
        const updatedOperation: SaleOperation = {
            ...operation,
            invoiceStatus: InvoiceStatus.INVOICED
        }

        if (table === TableName.SALE_ORDERS) {
            await salesActions().saveSaleWithFile(updatedOperation as SaleOrder, operationFile)
        } else {
            await salesActions().saveHoardWithFile(updatedOperation as HoardOrder, operationFile)
        }
        
        Emitter.emit(Event.DB_SUBMIT_DONE, false, false)
    }
    
    return {
        submitInvoice
    }
}
