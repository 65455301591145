import { useEffect, useState } from "react"
import { Action, PanelLoader } from "../../../../features/ui"
import { PendingPaymentList } from "./pendingPaymentList"
import { usePurchasesActions } from "../../../purchases"
import { RoutePath, useRoutesHooks } from "../../../../features/routes"
import { useNavigate } from "react-router-dom"
import { useExpensesActions } from "../../actions/expensesActions"
import { useTicketHooks } from "../../hooks/ticketHooks"
import { useTicketLoaders } from "../../loaders/ticketLoaders"
import { Ticket } from "../../state/types"

export const PendingPaymentListLoader = () => {
    const [tickets, setTickets] = useState<Ticket[]>([])
    const [ready, setReady] = useState(false)

    const navigate = useNavigate()
    const { getPurchaseIds, getExpenseIds } = useTicketHooks()
    const { getRoutePath } = useRoutesHooks()

    const ticketLoaders = useTicketLoaders()

    const purchasesActions = usePurchasesActions()
    const expensesActions = useExpensesActions()

    const populate = async () => {
        const { loadAllTickets } = ticketLoaders()
        const loadedTickets = await loadAllTickets()
        setTickets(loadedTickets)
        const { fetchPurchases, fetchAllSuppliers } = purchasesActions()
        const { fetchExpenses } = expensesActions()
        await fetchPurchases(getPurchaseIds(loadedTickets))
        await fetchExpenses(getExpenseIds(loadedTickets))
        await fetchAllSuppliers()
    }

    useEffect(() => {
        populate().then(() => setReady(true))
    }, [])

    const onGoToPaymentOrders = () => navigate(getRoutePath(RoutePath.PAYMENT_ORDERS))

    const headerActions: Action[] = [
        { label: 'Órdenes de Pago', kind: 'tertiary', onClick: onGoToPaymentOrders }
    ]

    return (
        <PanelLoader
            title="Pagos Pendientes"
            actions={headerActions}
            ready={ready}
            wide
        >
            <PendingPaymentList tickets={tickets} />
        </PanelLoader>
    )
}
