import { useEffect, useState } from "react"
import { Data, Modal, ModalId, useUIState } from "../../../../../features/ui"
import { TaxItemForm } from "./taxItemForm"
import { Tax } from "../../../state/types"

type Props = {
    taxItem?: Data
    total?: number
    vatAmount?: number
    title?: string
    editableAmount?: boolean
    taxes: Tax[]
    onSubmit: (data: Data) => void
}

export const TaxItemFormModal = (props: Props) => {
    const { taxItem, total, vatAmount, title, editableAmount, taxes, onSubmit } = props
    const [key, setKey] = useState(0)
    const { openedModal } = useUIState()

    useEffect(() => setKey(key + 1), [openedModal])
    
    return (
        <Modal
            modalId={ModalId.TAX_ITEM_FORM}
            title={title || 'Agregar Impuesto'}
            actions={[]}
        >
            <TaxItemForm
                key={key}
                taxItem={taxItem}
                total={total}
                vatAmount={vatAmount}
                editableAmount={editableAmount}
                taxes={taxes}
                onSubmit={onSubmit}
            />
        </Modal>
    )
}
