import { useState } from "react"
import { Column, FieldType, FieldValue, SpinnerDiv, UIPermission } from "../../../../../features/ui"
import { Data, DataTypeList, useDescriptorHooks } from "../../../../../features/data-types"
import { useSystemStateHooks } from "../../../../system"
import { DataType } from "../../../../../tables"
import { CreditCardFilters } from "./creditCardFilters"
import { CreditCard } from "../../../state/types"
import { define } from "../../../../../utils/typeUtils"

type Props = {
    creditCards: CreditCard[]
}

export const CreditCardList = (props: Props) => {
    const { creditCards } = props

    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)

    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_CREDIT_CARDS)
    const descriptorHooks = useDescriptorHooks()

    const columns: Column[] = [
        {
            name: 'type',
            type: FieldType.TEXT,
            label: 'Tipo'
        },
        {
            name: 'issuer',
            type: FieldType.TEXT,
            label: 'Emisor'
        },
        {
            name: 'cardholder',
            type: FieldType.TEXT,
            label: 'Titular'
        },
        {
            name: 'last4Numbers',
            type: FieldType.NUMBER,
            label: 'Número',
            render: (value?: FieldValue) => descriptorHooks.creditCard.number(define(value) as number)
        },
        {
            name: 'dueDate',
            type: FieldType.DATE,
            label: 'Fecha de Vto.'
        }
    ]

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRows(dataTypes as CreditCard[])
        setAsyncDataLoaded(true)
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <>
            <CreditCardFilters
                creditCards={creditCards}
                onSearchStart={onSearchStart}
                onSearchDone={onSearchDone}
            />
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    readOnly={readOnly}
                />
            )}
        </>
    )
}
