import { useNavigate } from "react-router-dom"
import { Modal, ModalId } from "../../../../../features/ui"
import { RoutePath } from "../../../../../features/routes"
import { Expense } from "../../../state/types"
import { useDescriptorHooks } from "../../../../../features/data-types"

type Props = {
    expense: Expense
    routePathPrefix?: string
}

export const UploadExpenseTicketModal = (props: Props) => {
    const { expense, routePathPrefix } = props

    const navigate = useNavigate()
    const descriptorHooks = useDescriptorHooks()

    const title = `Subir Factura?`
    
    const renderBody = () => <>
        Deseas subir la factura del Gasto {descriptorHooks.expense.code(expense)}?
    </>
    
    const onSubmit = () => {
        const prefix = routePathPrefix ? routePathPrefix + '/' : ''
        navigate(`${prefix}${RoutePath.TICKETS}/${RoutePath.CREATE}`)
    }

    return (
        <Modal
            modalId={ModalId.UPLOAD_EXPENSE_TICKET}
            title={title}
            submitLabel="Subir Factura"
            onSubmit={onSubmit}
        >
            <>{renderBody()}</>
        </Modal>
    )
}
