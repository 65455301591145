import styles from './retentionCertificatePDFPage.module.css'
import { useSystemState } from '../../../../system'
import { define } from '../../../../../utils/typeUtils'
import { useDataTypesHooks, useDescriptorHooks } from '../../../../../features/data-types'
import { FieldType, FormatType, PDFHeader } from '../../../../../features/ui'
import { Supplier } from '../../../../purchases'
import { PaymentOrder, Tax, Ticket, useTaxHooks, useTicketHooks } from '../../..'

type Props = {
    paymentOrder: PaymentOrder
    retention: Tax
    tickets: Ticket[]
    supplier?: Supplier
}

export const RetentionCertificatePDFPage = (props: Props) => {
    const { paymentOrder, retention, tickets, supplier } = props
    const { payedAmount } = paymentOrder

    const { company } = useSystemState()
    const definedCompany = define(company)
    
    const { calculateTaxBaseAmount, calculateTaxAmount } = useTaxHooks()
    const { getVatYield } = useTicketHooks()
    const { formatCustomValue, formatValue } = useDataTypesHooks()
    const descriptorHooks = useDescriptorHooks()

    const retentionDate = formatValue(new Date(paymentOrder.creationDate), FieldType.DATE)
    const payedVatAmount = payedAmount * getVatYield(tickets)
    const taxBaseAmount = calculateTaxBaseAmount(retention, payedAmount, payedVatAmount)
    const taxAmount = calculateTaxAmount(retention, payedAmount, payedVatAmount)
    
    const renderTaxId = (taxId?: number) => formatCustomValue(taxId, FormatType.CUIT_CUIL)

    return (
        <div className={styles.container}>
            <div className={styles.body}>
                <PDFHeader />
                
                <div className={`${styles.row} ${styles.titleRow}`}>
                    <span className={styles.title}>
                        Certificado de Retención <br />
                        Impuesto sobre los Ingresos Brutos <br />
                        {retention.name}
                    </span>
                    <div className={styles.subheaderRight}>
                        <div className={styles.date}>
                            Fecha: {retentionDate}
                        </div>
                        <div>
                            N° Comprobante: #{paymentOrder.code}
                        </div>
                    </div>
                </div>

                <div className={styles.row}>
                    <span className={styles.subtitle}>AGENTE DE RETENCIÓN</span>
                </div>

                <div className={`${styles.row} ${styles.infoRow}`}>
                    <div className={styles.infoSection}>
                        <span>Razón Social:</span>
                        <span>CUIT:</span>
                        <span>N° Inscripción IIBB:</span>
                    </div>
                    <div className={`${styles.infoSection} ${styles.rightSection}`}>
                        <span>{definedCompany.legalName}</span>
                        <span>{renderTaxId(definedCompany.taxId)}</span>
                        <span>{company?.grossIncomeCode || '-'}</span>
                    </div>
                </div>

                <div className={styles.row}>
                    <span className={styles.subtitle}>DATOS DEL CONTRIBUYENTE SUJETO A RETENCIÓN</span>
                </div>

                <div className={`${styles.row} ${styles.infoRow}`}>
                    <div className={styles.infoSection}>
                        <span>Razón Social:</span>
                        <span>Domicilio:</span>
                        <span>CUIT:</span>
                        <span>N° Inscripción IIBB:</span>
                    </div>
                    <div className={`${styles.infoSection} ${styles.rightSection}`}>
                        <span>{supplier?.name}</span>
                        <span>{supplier?.address || '-'}</span>
                        <span>{renderTaxId(supplier?.taxId) || '-'}</span>
                        <span>{supplier?.grossIncomeCode || '-'}</span>
                    </div>
                </div>

                <div className={styles.row}>
                    <span className={styles.subtitle}>DATOS DE LA OPERACIÓN SUJETA A RETENCIÓN</span>
                </div>

                <div className={`${styles.row} ${styles.infoRow}`}>
                    <div className={styles.infoSection}>
                        <span>Orden de Pago:</span>
                        <span>Fecha:</span>
                        <span>Base Imponible:</span>
                        <span>Porcentaje:</span>
                        <span className={styles.subtitle}>IMPORTE RETENIDO:</span>
                    </div>
                    <div className={`${styles.infoSection} ${styles.rightSection}`}>
                        <span>{descriptorHooks.paymentOrder.code(paymentOrder)}</span>
                        <span>{retentionDate}</span>
                        <span>{formatValue(taxBaseAmount, FieldType.PRICE)}</span>
                        <span>{formatValue(retention.yield, FieldType.PERCENTAGE)}</span>
                        <span className={styles.subtitle}>{formatValue(taxAmount, FieldType.PRICE)}</span>
                    </div>
                </div>                    

                <div className={styles.footer}>
                    <div className={styles.separator} />
                    <div className={`${styles.row} ${styles.footerRow}`}>
                        <div className={styles.signContainer}>
                            <div className={styles.placeholder} />
                            <span>Lugar y Fecha</span>
                        </div>
                        <div className={styles.signContainer}>
                            <div className={styles.placeholder} />
                            <span>{definedCompany.legalName}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
