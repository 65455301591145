import { useMemo } from "react"
import { DataType } from '../../../../tables'
import { PagesPDFWrapper } from './pagesPDFWrapper'
import { toList } from '../../../../utils/listUtils'

type Props = {
    id: string
    dataType: DataType
    items: DataType[]
    previewMode?: boolean
    renderPDFPage: (
        pageIndex: number,
        dataType: DataType,
        items: DataType[],
        pagesAmount: number
    ) => JSX.Element
}

export const ItemsPDFWrapper = (props: Props) => {
    const { id, dataType, items, previewMode, renderPDFPage } = props

    const getPagesItems = (dataTypeItems: DataType[]) => {
        const pages: DataType[][] = []
        const pushPages = (startIndex = 0) => {
            const isFirstPage = startIndex === 0
            const items = dataTypeItems.slice(startIndex)
            if (items.length > 0) {
                const maxPageSize = isFirstPage ? 10 : 13
                const pageSize = items.length < maxPageSize ? maxPageSize - 2 : (items.length === maxPageSize ? maxPageSize - 1 : maxPageSize)
                pages.push(items.slice(0, pageSize))
                pushPages(startIndex + pageSize)
            }
        }
        pushPages()
        
        return pages
    }

    const pagesItems: DataType[][] = useMemo(() => dataType ? getPagesItems(items) : [], [items])

    const renderPages = pagesItems.length > 0 ?
        pagesItems.map(pageItems =>
            (index: number, itemDataType: DataType) => renderPDFPage(index, itemDataType, pageItems, pagesItems.length)
        ) :
        toList((_: number, itemDataType: DataType) => renderPDFPage(0, itemDataType, [], 1))

    return <PagesPDFWrapper
        id={id}
        dataType={dataType}
        previewMode={previewMode}
        renderPages={renderPages}
    />
}
