import { SaleItem, SaleOrder } from "../../../state/types"
import { SalePDFPage } from './salePDFPage'
import { useEffect, useState } from "react"
import { ItemsPDFWrapper } from '../../../../../features/ui'
import { DataType } from '../../../../../tables'
import { useSaleLoaders } from "../../../loaders/saleLoaders"

type Props = {
    id: string
    sale: SaleOrder
    previewMode?: boolean
}

export const SalePDF = (props: Props) => {
    const { id, sale, previewMode } = props

    const [saleItems, setSaleItems] = useState<SaleItem[]>([])

    const saleLoaders = useSaleLoaders()

    useEffect(() => {
        const { loadSaleItems } = saleLoaders()
        loadSaleItems(sale.id).then(setSaleItems)
    }, [sale])

    const renderPDFPage = (
        pageIndex: number,
        dataType: DataType,
        items: DataType[],
        pagesAmount: number
    ) => (
        <SalePDFPage
            pageIndex={pageIndex}
            sale={dataType as SaleOrder}
            saleItems={items as SaleItem[]}
            pagesAmount={pagesAmount}
        />
    )

    return (
        <ItemsPDFWrapper
            id={id}
            dataType={sale}
            items={saleItems}
            previewMode={previewMode}
            renderPDFPage={renderPDFPage}
        />
    )
}
