import { useEffect, useMemo, useState } from "react"
import { FormField, Data, useUIStateActions, ModalId, useUIActions, FieldValue, FieldType, option, Badge, O } from "../../../../../features/ui"
import { Customer, HoardItem, HoardOrder, HoardType } from "../../../state/types"
import { PaymentType, PaymentTypeField, useAdministrationState, usePaymentTypeLoaders } from "../../../../administration"
import { TableName } from "../../../../../tables"
import { CustomerSearchField } from "../../../../../components/fields/search-field/customer-search-field/customerSearchField"
import { useActions } from "./actions"
import { define } from "../../../../../utils/typeUtils"
import { DataTypeForm, Labels } from "../../../../../features/data-types"
import { Lot, Product } from "../../../../products"
import { useGenerateNextValue } from "../../../../../tables/hooks"
import { Validatable } from "../../../../../features/ui"
import { Operation, OperationItemFormModal, OperationItemsTable, usePricesHooks, PriceBreakdown, usePriceBreakdownHooks } from '../../../../operations'
import { useFind } from "../../../../../state/reducers/hooks"
import { useSalesUIHooks } from "../../salesUiHooks"
import { Branch, useManagerState } from "../../../../manager"
import { useNavigate } from "react-router-dom"
import { useSalesHooks, useSalesState } from "../../../hooks/salesHooks"
import { SellerField } from "../../seller-field/sellerField"

type Props = {
    hoard?: HoardOrder
    hoardItems?: HoardItem[]
}

export const HoardForm = (props: Props) => {
    const { hoard, hoardItems = [] } = props
    const createMode = !hoard

    const { customers } = useSalesState()
    const { currentBranch } = useManagerState()
    const branchId = currentBranch?.id
    const { currentEmployee } = useAdministrationState()

    const defaultCustomer = customers.find(customerItem => customerItem.id === hoard?.customerId)
    const defaultHoardItemsData = hoardItems.map(hoardItem => ({
        productId: hoardItem.productId,
        lotId: hoardItem.lotId,
        price: hoardItem.price,
        quantity: hoardItem.quantity
    }))

    const [code, setCode] = useState(hoard?.code || 1)
    const [type, setType] = useState<O<HoardType>>(hoard?.type || HoardType.PRODUCT)
    const [sellerId, setSellerId] = useState<O<string>>(hoard?.sellerId || currentEmployee?.id)
    const [customer, setCustomer] = useState<O<Customer>>(defaultCustomer)
    const [hoardValue, setHoardValue] = useState<O<number>>(hoard?.value)
    const [hoardItemsData, setHoardItemsData] = useState<Data[]>(defaultHoardItemsData)
    const [currentHoardItem, setCurrentHoardItem] = useState<Data>()
    const [paymentType, setPaymentType] = useState<PaymentType>()
    const [itemsValidations, setItemsValidations] = useState<string[]>([])

    const { validateItemsData } = useSalesUIHooks()
    const { getSettings } = useSalesHooks()
    const hoardIgnoredProductIds = (getSettings()?.hoardIgnoredProductIds || []) as string[]
    const pricesHooks = usePricesHooks()
    const { getHoardPriceBreakdown } = usePriceBreakdownHooks()
    const generateNextValue = useGenerateNextValue()
    const navigate = useNavigate()
    const find = useFind()

    const paymentTypeLoaders = usePaymentTypeLoaders()

    const { submitHoard } = useActions()
    const { setDisableBranchSelector } = useUIStateActions()
    const { toggleModal } = useUIActions()

    const isProductHoard = type === HoardType.PRODUCT

    const init = async () => {
        setDisableBranchSelector(true)
        if (createMode) {
            setCode(await generateNextValue(TableName.HOARD_ORDERS, 'code'))
        }
    }

    useEffect(() => {
        init()
        return () => {
            setDisableBranchSelector(false)
        }
    }, [])

    useEffect(() => {
        setHoardValue(0)
    }, [type])

    const purchasePricesList = pricesHooks.product.purchasePrices()

    const hoardItemMatches = (data: Data, productId: string, lotId?: string) => data.productId === productId && data.lotId === lotId
    const filterProducts = (product: Product) => !hoardIgnoredProductIds.includes(product.id) &&
        (currentHoardItem?.productId === product.id || !hoardItemsData.map(hoardItemData => hoardItemData.productId).includes(product.id))
    const filterLots = (lot: Lot) => currentHoardItem?.lotId === lot.id ||
        !hoardItemsData.map(hoardItemData => hoardItemData.lotId).includes(lot.id)
    const priceBreakdown = useMemo(() =>
        getHoardPriceBreakdown(type, hoardValue, hoardItemsData, customer, paymentType)
    , [type, hoardValue, hoardItemsData, customer, paymentType])
    const defaultDueDate = new Date()
    defaultDueDate.setMonth(defaultDueDate.getMonth() + 3)

    const openFormModal = () => toggleModal(ModalId.OPERATION_ITEM_FORM)

    const onChangeType = (value?: FieldValue) => {
        const newType = value as O<HoardType>
        setType(newType)
        if (newType === HoardType.FINANCIAL) {
            setHoardItemsData([])
        }
    }

    const onChangeSeller = (value?: FieldValue) => setSellerId(value as O<string>)

    const onChangeCustomer = (value?: string) => {
        const newCustomer = find(TableName.CUSTOMERS, value) as O<Customer>
        setCustomer(newCustomer)
    }

    const onChangePaymentType = (value?: FieldValue) => {
        const { findPaymentType } = paymentTypeLoaders()
        setPaymentType(findPaymentType(value as O<string>))
    }

    const onCreateHoardItem = () => {
        setCurrentHoardItem(undefined)
        setItemsValidations([])
        openFormModal()
    }

    const onEditHoardItem = (productId: string, lotId?: string) => {
        setCurrentHoardItem(hoardItemsData.find(hoardItemData => hoardItemMatches(hoardItemData, productId, lotId)))
        setItemsValidations([])
        openFormModal()
    }

    const onRemoveHoardItem = (productId: string, lotId?: string) => {
        const newHoardItemsData = hoardItemsData.filter(hoardItemData => !hoardItemMatches(hoardItemData, productId, lotId))
        setHoardItemsData(newHoardItemsData)
        setItemsValidations([])
    }

    const onChangeValue = (value?: FieldValue) => {
        const newValue = value as O<number>
        setHoardValue(newValue)
    }

    const fields: FormField[] = [
        {
            name: 'branchId',
            type: FieldType.TABLE,
            table: TableName.BRANCHES,
            label: 'Sucursal de Entrega',
            render: () => {
                const branch = define(find(TableName.BRANCHES, branchId))as Branch
                return <Badge label={branch.name} />
            }
        },
        {
            name: 'type',
            type: FieldType.SELECT,
            label: 'Tipo',
            defaultValue: type,
            options: Object.values(HoardType).map(type => option(type, Labels.hoardTypeLabel(type))),
            disabled: !createMode,
            focus: true,
            onChange: onChangeType
        },
        SellerField({
            defaultValue: sellerId,
            onChange: onChangeSeller
        }),
        {
            name: 'customerId',
            type: FieldType.TABLE,
            table: TableName.CUSTOMERS,
            label: 'Cliente',
            render: () => (
                <CustomerSearchField
                    id='customerId'
                    defaultValue={customer?.id}
                    onChange={onChangeCustomer}
                />
            )
        },
        {
            name: 'dueDate',
            type: FieldType.DATE,
            label: 'Fecha Límite',
            defaultValue: defaultDueDate,
            min: new Date()
        },
        PaymentTypeField({
            onChange: onChangePaymentType
        }),
        {
            name: 'priceBreakdown',
            type: FieldType.PRICE,
            label: 'Precio',
            render: () => <PriceBreakdown data={priceBreakdown} />
        }
    ]
    if (isProductHoard) {
        const itemsField = {
            name: 'hoardItems',
            type: FieldType.TABLE,
            table: TableName.HOARD_ITEMS,
            render: () => (
                <Validatable validations={itemsValidations}>
                    <OperationItemsTable
                        rows={hoardItemsData}
                        operation={Operation.SALE}
                        readOnly={!createMode}
                        onCreate={onCreateHoardItem}
                        onEditRow={onEditHoardItem}
                        onRemoveRow={onRemoveHoardItem}
                    />
                </Validatable>
            )
        }
        fields.splice(3, 0, itemsField)
    } else {
        const valueField = {
            name: 'value',
            type: FieldType.PRICE,
            label: 'Monto',
            onChange: onChangeValue
        }
        fields.splice(4, 0, valueField)
    }

    const validate = async () => {
        if (createMode && isProductHoard) {
            const itemValidations = await validateItemsData(hoardItemsData, define(branchId))
            setItemsValidations(itemValidations)
            return itemValidations.length === 0
        }

        return true
    }

    const onSubmit = async (hoardData: Data) => {
        const valid = await validate()
        if (valid) {
            submitHoard(
                hoardData,
                code,
                define(branchId),
                define(sellerId),
                define(customer).id,
                hoardItemsData,
                purchasePricesList,
                createMode
            )
        }
    }

    const onCancel = () => navigate(-1)

    const onSaveHoardItem = (newData: Data) => {
        let newHoardItemsData = hoardItemsData
        if (currentHoardItem) {
            const { productId, lotId } = currentHoardItem
            newHoardItemsData = hoardItemsData.map(hoardItemData => hoardItemMatches(hoardItemData, define(productId) as string, lotId as O<string>) ? newData : hoardItemData)
        } else {
            newHoardItemsData = [newData, ...hoardItemsData]
        }
        setHoardItemsData(newHoardItemsData)
    }
    
    return (
        <>
            <DataTypeForm
               formId="hoard-form"
               fields={fields}
               createMode={createMode}
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
            <OperationItemFormModal
                operationItem={currentHoardItem}
                operation={Operation.SALE}
                withLots
                filterProducts={filterProducts}
                filterLots={filterLots}
                onSubmit={onSaveHoardItem}
            />
        </>
    )
}
