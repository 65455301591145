import { Emitter, Event } from "../../../../../features/event-emitter"
import { Data } from "../../../../../features/ui"
import { Currency, TableName } from "../../../../../tables/types/types"
import { define, round } from "../../../../../utils/typeUtils"
import { useTicketActions } from "../../../actions/ticketActions"
import { TaxItem, Ticket, TicketType } from "../../../state/types"

export const useActions = () => {
    const ticketActions = useTicketActions()

    const submitTicket = async (
        ticketData: Data,
        code: number,
        parentTable: TableName,
        parentId: string,
        subtotal: number,
        vatAmount: number,
        ticketFile: File,
        taxItemsData: Data[],
        createMode: boolean
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const { companyId, id, type, total } = ticketData
        const dueDate = define(ticketData.dueDate) as Date

        const taxItems: TaxItem[] = taxItemsData.map(taxItemData => ({
            taxCode: define(taxItemData.taxCode) as string,
            taxYield: define(taxItemData.taxYield) as number,
            amount: define(taxItemData.amount) as number
        }))
        const ticket: Ticket = {
            companyId: define(companyId) as string,
            id: define(id) as string,
            code,
            parentTable,
            parentId,
            type: define(type) as TicketType,
            creationDate: new Date().toISOString(),
            dueDate: dueDate.toISOString(),
            taxItems,
            subtotal: define(subtotal) as number,
            vatAmount: round(define(vatAmount) as number),
            total: define(total) as number,
            payedAmount: 0,
            currency: Currency.ARS
        }
        
        await ticketActions().saveTicketWithFile(ticket, ticketFile)
        Emitter.emit(Event.DB_SUBMIT_DONE, createMode)
    }
    
    return {
        submitTicket
    }
}
