import { DataType, TableName } from '../../../tables'
import { fileSubPath, useFileActions } from '../../files'
import { useDefaultModuleActions } from '../../../modules/common'
import { DynamoUserApi } from '../../../services'
import { define } from '../../../utils/typeUtils'
import { useSystemStateHooks } from '../../../modules/system'

export const useDataTypeFileActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId

    const defaultModuleActions = useDefaultModuleActions()
    const { saveFile, removeFile } = useFileActions()

    return (paramCompanyId?: string) => {
        const companyId = define(paramCompanyId || stateCompanyId)

        const saveWithFile = async (
            tableName: TableName,
            dataType: DataType,
            fileUrlProp: keyof DataType,
            file?: File,
            isImage = false
        ): Promise<DataType> => {
            const { fetchByParams, save } = defaultModuleActions(DynamoUserApi, companyId)

            const newDataType = {
                ...dataType
            }

            if (file) {
                const fileUrl = await saveFile(fileSubPath(tableName, dataType.id, file.name), file, isImage)
                newDataType[fileUrlProp] = fileUrl
            } else {
                const stateDT = await fetchByParams(tableName, { id: dataType.id })
                if (stateDT && stateDT[fileUrlProp]) {
                    await removeFile(stateDT[fileUrlProp], isImage)
                }
                delete (newDataType as Partial<DataType>)[fileUrlProp]
            }

            await save(tableName, newDataType)

            return newDataType
        }

        const saveWithImage = async (
            tableName: TableName,
            dataType: DataType,
            fileUrlProp: keyof DataType,
            file?: File
        ): Promise<DataType>  => {
            return saveWithFile(tableName, dataType, fileUrlProp, file, true)
        }

        const removeWithFile = async (
            tableName: TableName,
            id: string,
            fileUrlProp: keyof DataType,
            isImage = false
        ): Promise<void> => {
            const { fetchByParams, remove } = defaultModuleActions(DynamoUserApi, companyId)

            const stateDT = await fetchByParams(tableName, { id })
            if (stateDT && stateDT[fileUrlProp]) {
                await removeFile(stateDT[fileUrlProp], isImage)
            }

            await remove(tableName, id)
        }

        const removeWithImage = async (
            tableName: TableName,
            id: string,
            fileUrlProp: keyof DataType
        ) => {
            return removeWithFile(tableName, id, fileUrlProp, true)
        }

        return {
            saveWithFile,
            saveWithImage,
            removeWithFile,
            removeWithImage
        }
    }
}
