import { useState } from "react"
import { FieldType, ModalId, UIPermission, useUIActions, Action, Column, FieldValue, Data, SpinnerDiv } from "../../../../../features/ui"
import { DataType, TableName } from "../../../../../tables"
import { useSystemStateHooks } from "../../../../system"
import { Budget } from "../../../state/types"
import { DownloadBudgetPDFModal } from "../download-budget-pdf-modal/downloadBudgetPDFModal"
import { define, toNumber } from "../../../../../utils/typeUtils"
import { DataTypeList, useDescriptorHooks } from "../../../../../features/data-types"
import { BudgetFilters } from "./budgetFilters"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "../../../../../features/routes"
import { useEmployeeLoaders } from "../../../../administration"

type Props = {
    budgets: Budget[]
}

export const BudgetList = (props: Props) => {
    const { budgets } = props

    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [currentBudget, setCurrentBudget] = useState<Budget>()
    
    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_BUDGETS)
    const descriptorHooks = useDescriptorHooks()
    const navigate = useNavigate()

    const employeeLoaders = useEmployeeLoaders()

    const { toggleModal } = useUIActions()

    const setRowsState = (budgets: Budget[]) => {
        setRows(budgets)
        setAsyncDataLoaded(true)
    }

    const columns: Column[] = [
        { name: 'code', type: FieldType.NUMBER, label: '#', render: (value?: FieldValue) => `#${value}` },
        { name: 'date', type: FieldType.DATE, label: 'Fecha' },
        {
            name: 'sellerId',
            type: FieldType.TABLE,
            table: TableName.EMPLOYEES,
            label: 'Vendedor',
            render: (value?: FieldValue) => {
                const seller = employeeLoaders().findEmployee(define(value) as string)
                return descriptorHooks.employee.info(define(seller))
            }
        },
        { name: 'customerId', type: FieldType.TABLE, table: TableName.CUSTOMERS, label: 'Cliente' },
    ]

    const findBudget = (code?: string) => define(budgets.find(budget => budget.code === toNumber(code)))

    const onView = (code?: string) => {
        const budget = findBudget(code)
        navigate(budget.id)
    }

    const onDownloadPDF = (code?: string) => {
        setCurrentBudget(findBudget(code))
        toggleModal(ModalId.DOWNLOAD_BUDGET_PDF)
    }

    const onRefresh = (code?: string) => {
        const budget = findBudget(code)
        navigate(`${budget.id}/${RoutePath.UPDATE}`)
    }

    const onCreateSale = (code?: string) => {
        const budget = findBudget(code)
        navigate(`${budget.id}/${RoutePath.CREATE_SALE}`)
    }

    const onRemove = (code?: string) => {
        setCurrentBudget(findBudget(code))
        toggleModal(ModalId.REMOVE)
    }

    const actions = (): Action[] => {
        const actionList: Action[] = [
            { icon: 'eye-fill', kind: 'tertiary', tooltip: 'Ver Detalle', onClick: onView },
            { icon: 'download', kind: 'tertiary', tooltip: 'Descargar', onClick: onDownloadPDF }
        ]
        if (!readOnly) {
            actionList.push(
                { icon: 'arrow-repeat', tooltip: 'Actualizar', onClick: onRefresh },
                { icon: 'tags-fill', tooltip: 'Crear Venta', onClick: onCreateSale },
                { icon: 'trash-fill', kind: 'secondary', tooltip: 'Eliminar', onClick: onRemove }
            )
        }

        return actionList
    }

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRowsState(dataTypes as Budget[])
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <>
            <BudgetFilters
                budgets={budgets}
                onSearchStart={onSearchStart}
                onSearchDone={onSearchDone}
            />
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    rowId="code"
                    currentDataTypeParam={currentBudget}
                    actions={actions}
                    readOnly={readOnly}
                    showDetail={false}
                />
            )}
            <DownloadBudgetPDFModal budget={currentBudget} />
        </>
    )
}
