import { useState } from "react"
import { FieldType, UIPermission, Column, FieldValue, useUIActions, ModalId, Action, SpinnerDiv, Data, ButtonKind, Badge } from "../../../../../features/ui"
import { DataType } from "../../../../../tables"
import { useSystemStateHooks } from "../../../../system"
import { define, toNumber } from "../../../../../utils/typeUtils"
import { DataTypeList, Labels, useDataTypesHooks } from "../../../../../features/data-types"
import { Expense, Ticket } from "../../../state/types"
import { SalaryFilters } from "./salaryFilters"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "../../../../../features/routes"
import { PaymentStatus } from "../../../../operations"

type Props = {
    salaries: Expense[]
    tickets: Ticket[]
}

export const SalaryList = (props: Props) => {
    const { salaries, tickets } = props

    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [currentExpense, setCurrentExpense] = useState<Expense>()

    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_EXPENSES)
    const { formatValue } = useDataTypesHooks()
    const navigate = useNavigate()

    const { toggleModal } = useUIActions()

    const setRowsState = (expenses: Expense[]) => {
        const newRows = expenses.map(expense => {
            const ticket = tickets.find(ticket => ticket.parentId === expense.id)
            return {
                ...expense,
                ...(ticket && {
                    paymentDate: ticket.dueDate
                })
            }
        })
        setRows(newRows)
        setAsyncDataLoaded(true)
    }

    const columns: Column[] = [
        {
            name: 'code',
            type: FieldType.NUMBER,
            label: '#',
            render: (value?: FieldValue) => `#${value}`
        },
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre'
        },
        {
            name: 'paymentDate',
            type: FieldType.DATE,
            label: 'Fecha de Pago',
            render: (value?: FieldValue) => value ?
                formatValue(new Date(value as string), FieldType.DATE) :
                <Badge label="Sin recibo" />
        },
        {
            name: 'amount',
            type: FieldType.PRICE,
            label: 'Monto'
        },
        {
            name: 'paymentStatus',
            type: FieldType.TEXT,
            label: 'Estado de Pago',
            render: (value?: FieldValue) => Labels.paymentStatusLabel(define(value) as PaymentStatus)
        }
    ]

    const findSalary = (code?: string) => define(salaries.find(salary => salary.code === toNumber(code)))

    const onView = (code?: string) => {
        const salary = findSalary(code)
        navigate(salary.id)
    }

    const onEdit = (code?: string) => {
        const salary = findSalary(code)
        navigate(`${salary.id}/${RoutePath.EDIT}`)
    }

    const onRemove = (code?: string) => {
        setCurrentExpense(findSalary(code))
        toggleModal(ModalId.REMOVE)
    }

    const actions = (code?: string): Action[] => {
        const salary = findSalary(code)
        const isPaymentPending = salary.paymentStatus === PaymentStatus.PENDING
        const isEditable = isPaymentPending

        const actionList: Action[] = [
            { icon: 'eye-fill', kind: 'tertiary' as ButtonKind, tooltip: 'Ver Detalle', onClick: onView }
        ]
        if (!readOnly && isEditable) {
            const editAction = { icon: 'pencil-fill', tooltip: 'Editar', onClick: onEdit }
            const removeAction: Action = { icon: 'trash-fill', kind: 'secondary', tooltip: 'Eliminar', onClick: onRemove }
            actionList.push(editAction, removeAction)
        }

        return actionList
    }

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRowsState(dataTypes as Expense[])
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <>
            <SalaryFilters
                salaries={salaries}
                onSearchStart={onSearchStart}
                onSearchDone={onSearchDone}
            />
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    rowId="code"
                    currentDataTypeParam={currentExpense}
                    createLabel="Crear Nuevo"
                    actions={actions}
                    readOnly={readOnly}
                />
            )}
        </>
    )
}
