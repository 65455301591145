import styles from './downloadHoardPDFModal.module.css'
import { fileName, usePDFActions } from "../../../../../features/files"
import { Modal, ModalId } from "../../../../../features/ui"
import { HoardOrder } from "../../../state/types"
import { FileTile, FileType } from "../../../../../features/ui"
import { useEffect, useState } from "react"
import { TableName } from '../../../../../tables'
import { HoardPDF } from '../hoard-pdf/hoardPDF'

type Props = {
    hoard?: HoardOrder
}

export const DownloadHoardPDFModal = (props: Props) => {
    const { hoard } = props
    
    const [pdfName, setPdfName] = useState<string>()

    const { generatePDF } = usePDFActions()

    useEffect(() => {
        hoard && setPdfName(fileName(TableName.HOARD_ORDERS, hoard.code.toString()))
    }, [hoard])

    const pdfId = 'hoard-pdf'
    
    const onSubmit = () => {
        pdfName && generatePDF(pdfId, pdfName)
    }

    const renderHiddenPDF = () => hoard ? (
        <div className={styles.hidden}>
            <HoardPDF
                id={pdfId}
                hoard={hoard}
            />
        </div>
    ) : <></>
    
    return (
        <Modal
            modalId={ModalId.DOWNLOAD_HOARD_PDF}
            title="Descargar Acopio"
            submitLabel="Descargar"
            submitIcon="download"
            onSubmit={onSubmit}
        >
            <FileTile fileType={FileType.PDF} fileName={pdfName}/>
            {renderHiddenPDF()}
        </Modal>
    )
}
