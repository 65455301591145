import styles from './lotInfo.module.css'
import { Badge, FieldType } from "../../../../../features/ui"
import { useDataTypesHooks, Labels } from '../../../../../features/data-types'
import { Lot, LotAttribute } from '../../../state/types'

type Props = {
    lot: Lot
}

export const LotInfo = (props: Props) => {
    const { lot } = props
    const { attributes } = lot

    const { formatValue } = useDataTypesHooks()

    const attributesData: [string, string][] = []
    if (attributes) {
        Object.keys(attributes).map(key => {
            const attrKey = key as LotAttribute
            const value = attributes[attrKey] as string
            const isDate = attrKey === LotAttribute.DUE_DATE
            const attrValue = formatValue(isDate ? new Date(value) : value, isDate ? FieldType.DATE : FieldType.TEXT)
            attributesData.push([Labels.lotAttributeLabel(attrKey), attrValue])
        })
    }
    
    return (
        <div className={styles.lotContainer}>
            <Badge label={lot.code} className={styles.lotCode} />
            {attributesData.map(([label, value]) => (
                <span key={label}>
                    {label}: {value}
                </span>
            ))}
        </div>
    )
}
