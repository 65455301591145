import { useMemo } from "react"
import { useDescriptorHooks } from "../../../../../features/data-types"
import { Badge, Async, Link } from "../../../../../features/ui"
import { TableName } from "../../../../../tables"
import { useTransactionHooks } from "../../../hooks/transactionHooks"
import { Transaction, TransactionDestinationType } from "../../../state/types"
import { useNavigate } from "react-router-dom"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"

type Props = {
    transaction: Transaction
}

export const TransactionDestination = (props: Props) => {
    const { transaction } = props

    const descriptorHooks = useDescriptorHooks()
    const { getDestinationTable } = useTransactionHooks()
    const { getRoutePath } = useRoutesHooks()
    const navigate = useNavigate()

    const isPaymentOrder = useMemo(() => getDestinationTable(transaction.destinationType) === TableName.PAYMENT_ORDERS, [transaction.destinationType])
    
    const icon = useMemo(() => isPaymentOrder ? 'cash-coin' : 'inbox', [isPaymentOrder])

    const goToDestination = () => {
        const tablePath = isPaymentOrder ? RoutePath.PAYMENT_ORDERS : RoutePath.CASH_FUNDS
        const idPath = isPaymentOrder ? transaction.destinationId : undefined
        navigate(getRoutePath(tablePath, idPath))
    }

    return <Async
        loadFn={async () => {
            return transaction.destinationType === TransactionDestinationType.MANUAL_SUBSTRACTION ?
                <Badge label="Retiro Manual" /> :
                <Link
                    text={await descriptorHooks.transaction.destination(transaction)}
                    icon={icon}
                    kind="primary"
                    onClick={goToDestination}
                />
        }}
    />
}
