import styles from './salaryForm.module.css'
import { useEffect, useState } from "react"
import { PanelWrapper, FormField, Data, useUIStateActions, FieldType, FieldValue, Spinner, FileField, useGetCrumbs, Badge, TextArea, Validatable, O } from "../../../../../features/ui"
import { TableName } from "../../../../../tables"
import { useActions } from "./actions"
import { DataTypeForm } from "../../../../../features/data-types"
import { useGenerateNextValue2 } from "../../../../../tables/hooks"
import { Branch, useManagerState } from "../../../../manager"
import { define } from "../../../../../utils/typeUtils"
import { useFind } from "../../../../../state/reducers/hooks"
import { Expense, Ticket } from "../../../state/types"
import { useNavigate } from "react-router-dom"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { firstItem, toList } from "../../../../../utils/listUtils"
import { fileName, useFileActions } from '../../../../../features/files'
import { useTicketActions } from '../../../actions/ticketActions'

export const SalaryForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const expense = dataType as O<Expense>
    const createMode = !expense
    const { currentBranch } = useManagerState()
    const branchId = currentBranch?.id
    
    const [code, setCode] = useState(expense?.code || 1)
    const [ticketCode, setTicketCode] = useState<number>(1)
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [detail, setDetail] = useState<O<string>>(expense?.detail)
    const [ticketFile, setTicketFile] = useState<File>()
    const [ticketValidation, setTicketValidation] = useState<string>()
    
    const navigate = useNavigate()
    const generateNextValue = useGenerateNextValue2()
    const find = useFind()

    const { submitSalaryExpense } = useActions()
    const ticketActions = useTicketActions()
    const { setDisableBranchSelector } = useUIStateActions()
    const { fetchFile } = useFileActions()

    const init = async () => {
        setDisableBranchSelector(true)
        const newTicketCode = await generateNextValue(TableName.TICKETS, 'code')
        if (createMode) {
            setCode(await generateNextValue(TableName.EXPENSES, 'code'))
            setTicketCode(newTicketCode)
        } else {
            const ticket = firstItem(await ticketActions().fetchTicketsByParentId(expense.id)) as O<Ticket>
            setTicketCode(ticket?.code || newTicketCode)
            if (ticket) {
                const file = await fetchFile(define(ticket.ticketUrl))
                setTicketFile(file)
            }
        }
        setAsyncDataLoaded(true)
    }

    useEffect(() => {
        init()
        return () => {
            setDisableBranchSelector(false)
        }
    }, [])

    const expenseLabel = expense ? `: ${expense.name}` : ''
    const title = `${createMode ? 'Registrar' : 'Editar'} Sueldo Liquidado${expenseLabel}`

    const crumbs = useGetCrumbs('Sueldos Liquidados', RoutePath.SALARIES, expense?.name, expense?.id, createMode ? 'Registrar' : 'Editar')

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <Spinner size='m'/>

    const onDetailChange = (value?: FieldValue) => {
        setDetail(value as O<string>)
    }

    const onChangeTicket = (value?: FieldValue) => {
        setTicketValidation(undefined)
        setTicketFile(value as O<File>)
    }

    const fields: FormField[] = [
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre'
        },
        {
            name: 'branchId',
            type: FieldType.TABLE,
            table: TableName.BRANCHES,
            label: 'Sucursal',
            render: () => {
                const branch = define(find(TableName.BRANCHES, branchId))as Branch
                return <Badge label={branch.name} />
            }
        },
        {
            name: 'paymentDate',
            type: FieldType.DATE,
            label: 'Fecha de Pago',
            defaultValue: new Date()
        },
        {
            name: 'detail',
            type: FieldType.TEXT,
            label: 'Detalle',
            render: () => <TextArea
                id="detail"
                defaultValue={detail}
                optional
                onChange={onDetailChange}
            />
        },
        {
            name: 'amount',
            type: FieldType.PRICE,
            label: 'Monto'
        },
        {
            name: 'ticketUrl',
            type: FieldType.FILE,
            label: 'Recibo',
            hint: 'Formato: PDF',
            render: () => renderAsync(
                <Validatable validations={toList(ticketValidation)} left className={styles.ticketValidation}>
                    <FileField
                        id="ticketUrl"
                        value={ticketFile}
                        fileName={fileName(TableName.TICKETS, ticketCode.toString(), TableName.EXPENSES, code.toString(), 'Sueldo')}
                        onChange={onChangeTicket}
                    />
                </Validatable>
            )
        }
    ]
    
    const validate = (): boolean => {
        if (!ticketFile) {
            setTicketValidation('Debe subir el recibo')
            return false
        }
    
        return true
    }

    const onSubmit = (expenseData: Data) => {
        if (validate()) {
            submitSalaryExpense(
                expenseData,
                createMode,
                code,
                define(branchId),
                define(ticketFile),
                detail
            )
        }
    }

    const onCancel = () => navigate(-1)
    
    return (
        <PanelWrapper title={title} crumbs={crumbs}>
            <DataTypeForm
               formId="salary-expense-form"
               fields={fields}
               createMode={createMode}
               disableSubmit={!asyncDataLoaded}
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
        </PanelWrapper>
    )
}
