import { useEffect, useState } from "react"
import { Spinner } from "../spinner/spinner"

export type Props = {
    loadFn: () => Promise<JSX.Element>
}

export const Async = (props: Props) => {
    const { loadFn } = props
    const [value, setValue] = useState<JSX.Element>()
  
    const load = async () => {
        setValue(await loadFn())
    }

    useEffect(() => {
        load()
    }, [])
  
    return value || <Spinner centered={false} />
}
