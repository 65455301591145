import styles from './pagesPDFWrapper.module.css'
import { DataType } from '../../../../tables'
import { useState } from 'react'

type Props = {
    id: string
    dataType: DataType
    previewMode?: boolean
    renderPages: ((index: number, dataType: DataType) => JSX.Element)[]
}

export const PagesPDFWrapper = (props: Props) => {
    const { id, dataType, previewMode, renderPages } = props

    const [currentPage, setCurrentPage] = useState(0)

    const previousPage = () => setCurrentPage(page => page - 1)
    const nextPage = () => setCurrentPage(page => page + 1)

    return (
        <div className={styles.container}>
            <div className={`${styles.arrow} ${styles.prevArrow}`}>
                {currentPage > 0 && <i className="bi bi-arrow-left-circle" onClick={previousPage} />}
            </div>
            <div className={styles.pdfContainer}>
                <div id={id}>
                    {renderPages.map((renderPage, index) => (
                        <div
                            key={index}
                            className={`${styles.pageContainer} ${!previewMode || index === currentPage ? styles.currentPage : ''}`}
                        >
                            {renderPage(index, dataType)}
                        </div>
                    ))}
                </div>
            </div>
            <div className={`${styles.arrow} ${styles.nextArrow}`}>
                {currentPage < renderPages.length - 1 && <i className="bi bi-arrow-right-circle" onClick={nextPage} />}
            </div>
        </div>
    )
}
