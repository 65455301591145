import { TableName } from '../../tables'
import { Action, TableActions } from './types'

const readActions = [Action.GET_ALL, Action.GET_BY_PARAMS, Action.GET_BY_IDS]

export const readPermissions = (): TableActions[] => [
    { tableName: TableName.BRANCHES, actions: readActions },
    { tableName: TableName.BUDGETS, actions: readActions },
    { tableName: TableName.BUDGET_ITEMS, actions: readActions },
    { tableName: TableName.CASH_FUNDS, actions: readActions },
    { tableName: TableName.CATEGORIES, actions: readActions },
    { tableName: TableName.CHECKS, actions: readActions },
    { tableName: TableName.CREDIT_CARDS, actions: readActions },
    { tableName: TableName.CUSTOMER_TYPES, actions: readActions },
    { tableName: TableName.CUSTOMERS, actions: readActions },
    { tableName: TableName.EMPLOYEES, actions: readActions },
    { tableName: TableName.EXPENSE_TYPES, actions: readActions },
    { tableName: TableName.EXPENSES, actions: readActions },
    { tableName: TableName.HOARD_ITEMS, actions: readActions },
    { tableName: TableName.HOARD_ORDERS, actions: readActions },
    { tableName: TableName.LOTS, actions: readActions },
    { tableName: TableName.MEASURES, actions: readActions },
    { tableName: TableName.ORDER_DELIVERIES, actions: readActions },
    { tableName: TableName.PAYMENT_ORDERS, actions: readActions },
    { tableName: TableName.PAYMENT_TYPES, actions: readActions },
    { tableName: TableName.PRODUCTS, actions: readActions },
    { tableName: TableName.PRODUCT_PRICES, actions: readActions },
    { tableName: TableName.PURCHASE_ITEMS, actions: readActions },
    { tableName: TableName.PURCHASE_ORDERS, actions: readActions },
    { tableName: TableName.SALE_ITEMS, actions: readActions },
    { tableName: TableName.SALE_ORDERS, actions: readActions },
    { tableName: TableName.SETTINGS, actions: readActions },
    { tableName: TableName.STOCK, actions: readActions },
    { tableName: TableName.SUPPLIERS, actions: readActions },
    { tableName: TableName.TAXES, actions: readActions },
    { tableName: TableName.TICKETS, actions: readActions },
    { tableName: TableName.TRANSACTIONS, actions: readActions },
    { tableName: TableName.UNIT_TYPES, actions: readActions }
]
