import { useDefaultModuleActions } from "../../common"
import { useDataTypeFileActions } from "../../../features/data-types"
import { DynamoUserApi } from "../../../services"
import { DataType, TableName } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { Company, useSystemActions, useSystemStateHooks } from "../../system"
import { CreditCard, Employee, Settings, Tax } from "../state/types"
import { useTransactionActions } from "./transactionActions"
import { useCheckActions } from "./checkActions"

export const useAdministrationActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId

    const defaultModuleActions = useDefaultModuleActions()
    const systemActions = useSystemActions()
    const checkActions = useCheckActions()
    const transactionActions = useTransactionActions()
    const dataTypeFileActions = useDataTypeFileActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId
        const { save, remove } = defaultModuleActions(DynamoUserApi, define(companyId))
        const { fetchAllChecks, saveCheck, removeCheck } = checkActions(companyId)
        const { fetchTransactions, countTransactions, saveTransaction } = transactionActions(companyId)

        const saveSettings = (settings: Settings) => {
            return save(TableName.SETTINGS, settings)
        }

        const saveCompany = (company: Company) => {
            return systemActions().saveCompany(company)
        }
        
        const saveTax = (tax: Tax) => {
            return save(TableName.TAXES, tax)
        }

        const removeTax = (id: string) => {
            return remove(TableName.TAXES, id)
        }

        const saveCreditCard = (creditCard: CreditCard) => {
            return save(TableName.CREDIT_CARDS, creditCard)
        }

        const removeCreditCard = (id: string) => {
            return remove(TableName.CREDIT_CARDS, id)
        }

        const saveEmployee = (employee: Employee) => {
            return save(TableName.EMPLOYEES, employee)
        }

        const removeEmployee = (id: string) => {
            return remove(TableName.EMPLOYEES, id)
        }

        const removePaymentOrder = (id: string) => {
            const { removeWithFile } = dataTypeFileActions(define(companyId))
            return removeWithFile(TableName.PAYMENT_ORDERS, id, 'receiptUrl' as keyof DataType)
        }

        return {
            saveSettings,
            saveCompany,
            saveTax,
            removeTax,
            fetchAllChecks,
            saveCheck,
            removeCheck,
            saveCreditCard,
            removeCreditCard,
            fetchTransactions,
            countTransactions,
            saveTransaction,
            saveEmployee,
            removeEmployee,
            removePaymentOrder
        }
    }
}
