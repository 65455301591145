import { Emitter, Event } from "../../../../../features/event-emitter";
import { ModalId, useUIActions } from "../../../../../features/ui"
import { HoardOrder, SaleOperation, SaleOrder, useSalesActions } from "../../../../sales";
import { InvoiceStatus } from "../../../state/types";

export const useActions = () => {
    const salesActions = useSalesActions()
    const { openModal, closeModal } = useUIActions()

    const requestInvoice = async (
        isSale: boolean,
        operation: SaleOperation
    ) => {
        Emitter.emit(Event.DB_SUBMIT, 'Creando solicitud...')
        
        const { saveSale, saveHoard } = salesActions()
        const updatedDataType: SaleOperation = {
            ...operation,
            invoiceStatus: InvoiceStatus.REQUESTED
        }
        if (isSale)  {
            await saveSale(updatedDataType as SaleOrder)
        } else {
            await saveHoard(updatedDataType as HoardOrder)
        }

        setTimeout(() => {
            closeModal(ModalId.SPINNER)
            
            setTimeout(() => {
                openModal(ModalId.INVOICE_REQUESTED)
            }, 100)
        }, 3000)
    }

    return {
        requestInvoice
    }
}
