import styles from './downloadRetentionCertificatePDFModal.module.css'
import { useEffect, useState } from "react"
import { PaymentOrder } from '../../../state/types'
import { fileName, usePDFActions } from '../../../../../features/files'
import { TableName } from '../../../../../tables'
import { FileTile, FileType, Modal, ModalId } from '../../../../../features/ui'
import { RetentionCertificatePDF } from '../retention-certificate-pdf/retentionCertificatePDF'

type Props = {
    paymentOrder?: PaymentOrder
}

export const DownloadRetentionCertificatePDFModal = (props: Props) => {
    const { paymentOrder } = props
    
    const [pdfName, setPdfName] = useState<string>()

    const { generatePDF } = usePDFActions()
    
    useEffect(() => {
        paymentOrder && setPdfName(fileName(TableName.PAYMENT_ORDERS, paymentOrder.code.toString(), undefined, undefined, 'Certificado-Retencion'))
    }, [paymentOrder])

    const pdfId = 'retention-certificate-pdf'
    
    const onSubmit = () => {
        pdfName && generatePDF(pdfId, pdfName)
    }

    const renderHiddenPDF = () => paymentOrder ? (
        <div className={styles.hidden}>
            <RetentionCertificatePDF
                id={pdfId}
                paymentOrder={paymentOrder}
            />
        </div>
    ) : <></>
    
    return (
        <Modal
            modalId={ModalId.DOWNLOAD_RETENTION_CERTIFICATE_PDF}
            title="Descargar Certificado de Retención"
            submitLabel="Descargar"
            submitIcon="download"
            onSubmit={onSubmit}
        >
            <FileTile fileType={FileType.PDF} fileName={pdfName}/>
            {renderHiddenPDF()}
        </Modal>
    )
}
