import styles from './pdfHeader.module.css'
import { useSystemState } from '../../../../modules/system'
import { define } from '../../../../utils/typeUtils'
import { useDataTypesHooks } from '../../../data-types'
import { EmptyImgImg } from '../../constants'
import { FormatType } from '../types'

type Props = {
    noValidTicketLabel?: boolean
}

export const PDFHeader = (props: Props) => {
    const { noValidTicketLabel } = props

    const { company } = useSystemState()
    const definedCompany = define(company)

    const { formatCustomValue } = useDataTypesHooks()

    const renderTaxId = (taxId?: number) => formatCustomValue(taxId, FormatType.CUIT_CUIL)

    return (
        <>
            <div className={styles.row}>
                <div className={styles.left}>
                    {/* <img src={definedCompany.logoUrl || EmptyImgImg} className={styles.img} /> */}
                    <img src={EmptyImgImg} alt={''} className={styles.img} />
                    <span className={styles.title}>
                        {definedCompany.name}
                    </span>
                </div>
                {noValidTicketLabel ? (
                    <div className={styles.noValidTicket}>
                        <span className={styles.noValidTicketX}>X</span>
                        <div className={styles.noValidTicketText}>
                            Documento NO Válido como Factura
                        </div>
                    </div>
                ) : (
                    <div className={styles.right}>
                        <span>{definedCompany.legalName}</span>
                        <span>{definedCompany.address}</span>
                        <span>{renderTaxId(definedCompany.taxId)}</span>
                    </div>
                )}
            </div>

            <div className={styles.separator} />
        </>
    )
}
