import { useNavigate, useParams } from "react-router-dom"
import { Request, RequestStatus, UserRole, useSystemActions, useSystemStateActions } from "../../../../modules/system"
import { define } from "../../../../utils/typeUtils"
import { Emitter, Event, useInitEmitter } from "../../../event-emitter"
import { useServicesHooks } from "../../../../services"
import { getInitialRoute, roleName } from "../../../roles"
import { TenantKey, UniqueKey } from "../../../../tables/constants"
import { useManagerActions, useManagerStateActions } from "../../../../modules/manager"
import { useCategoryLoaders, useProductsActions } from "../../../../modules/products"
import { usePurchasesActions } from "../../../../modules/purchases"
import { useCustomerTypeLoaders, useSalesActions } from "../../../../modules/sales"
import { useStockActions, useUnitTypeLoaders } from "../../../../modules/stock"
import { Employee, useAdministrationStateActions, usePaymentTypeLoaders, useCashFundLoaders, useCreditCardLoaders, useEmployeeLoaders, useTaxLoaders, useSettingsLoaders, useExpenseTypeLoaders } from "../../../../modules/administration"
import { useRoutesHooks } from "../../hooks/routesHooks"
import { RoutePath } from "../../types"
import { firstItem } from "../../../../utils/listUtils"
import { useDataTypesHooks } from "../../../data-types"

export const useLoad = () => {
    const { companyCode } = useParams<{ companyCode: string }>()
    const { loadUser, parseUrl } = useRoutesHooks()
    const { pathParts } = parseUrl()
    
    const { initUserApis } = useServicesHooks()
    const { clearLoadedTables } = useDataTypesHooks()
    const navigate = useNavigate()

    const { setCurrentEmployee } = useAdministrationStateActions()
    const { setCurrentBranch } = useManagerStateActions()
    const { setUserRequest } = useSystemStateActions()

    const cashFundLoaders = useCashFundLoaders()
    const categoryLoaders = useCategoryLoaders()
    const creditCardLoaders = useCreditCardLoaders()
    const customerTypeLoaders = useCustomerTypeLoaders()
    const employeeLoaders = useEmployeeLoaders()
    const expenseTypeLoaders = useExpenseTypeLoaders()
    const paymentTypeLoaders = usePaymentTypeLoaders()
    const settingsLoaders = useSettingsLoaders()
    const taxLoaders = useTaxLoaders()
    const unitTypeLoaders = useUnitTypeLoaders()

    const managerActions = useManagerActions()
    const productsActions = useProductsActions()
    const purchasesActions = usePurchasesActions()
    const salesActions = useSalesActions()
    const stockActions = useStockActions()
    const systemActions = useSystemActions()

    useInitEmitter()

    return async () => {
        const { fetchAllCompanies, fetchCompanyByCode } = systemActions()

        const code = define(companyCode)
        const companies = await fetchAllCompanies()
        const company = companies.find(company => company.code === code)

        if (company) {
            clearLoadedTables()
            const user = await loadUser()

            if (user)  {
                await fetchCompanyByCode(code, true)
                const { fetchRequest, fetchAllRequests } = systemActions(company.id)
                const request = await fetchRequest(company.id, user.id) as Request | null
                request && setUserRequest(request)

                if (request && request.status === RequestStatus.APPROVED) {
                    const companyId = request.companyId
                    const userEmail = define(user.email)
                    await initUserApis(roleName(code, request.role), userEmail, TenantKey, UniqueKey)

                    const branches = await managerActions(companyId).fetchAllBranches()
                    
                    const { loadAllCashFunds } = cashFundLoaders(companyId)
                    const { loadAllCategories } = categoryLoaders(companyId)
                    const { loadAllCreditCards } = creditCardLoaders(companyId)
                    const { loadAllCustomerTypes } = customerTypeLoaders(companyId)
                    const { loadAllEmployees } = employeeLoaders(companyId)
                    const { loadAllExpenseTypes } = expenseTypeLoaders(companyId)   
                    const { loadAllPaymentTypes } = paymentTypeLoaders(companyId)
                    const { loadAllSettings } = settingsLoaders(companyId)
                    const { loadAllTaxes } = taxLoaders(companyId)
                    const { loadAllUnitTypes } = unitTypeLoaders()
                    
                    const { fetchAllProducts, fetchAllProductPrices, fetchAllLots, fetchAllMeasures } = productsActions(companyId)
                    const { fetchAllSuppliers } = purchasesActions(companyId)
                    const { fetchAllBudgets, fetchAllCustomers } = salesActions(companyId)
                    const { fetchAllStock } = stockActions(companyId)

                    const employees = await loadAllEmployees()
                    await loadAllCashFunds()
                    await loadAllCategories()
                    await loadAllCreditCards()
                    await loadAllCustomerTypes()
                    await loadAllExpenseTypes()
                    await loadAllPaymentTypes()
                    await loadAllSettings()
                    await loadAllTaxes()
                    await loadAllUnitTypes()

                    const employee = employees.find(employee => employee.email === userEmail)
                    if (employee) {
                        setCurrentEmployee(employee as Employee)
                        const employeeBranch = define(branches.find(branch => branch.id === employee.branchId))
                        setCurrentBranch(employeeBranch)
                    } else {
                        setCurrentBranch(define(firstItem(branches)))
                        Emitter.emit(Event.EMPLOYEE_MISSING, request)
                    }                    

                    await fetchAllBudgets()
                    await fetchAllCustomers()
                    await fetchAllProducts()
                    await fetchAllLots()
                    await fetchAllSuppliers()
                    await fetchAllStock()
                    await fetchAllProductPrices()
                    await fetchAllMeasures()
                    request.role === UserRole.MANAGER && await fetchAllRequests()

                    if (pathParts.length === 1) {
                        const initialRoute = getInitialRoute(request.role)
                        navigate(initialRoute)
                    }
                } else {
                    navigate(RoutePath.ACCESS)
                }
            }
        } else {
            navigate(RoutePath.ERROR)
        }   
    }
}
