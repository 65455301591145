import { useMemo } from "react"
import { Data } from "../../../../../features/data-types"
import { Table, Section, Column, FieldType, FieldValue } from "../../../../../features/ui"
import { TableName } from "../../../../../tables"
import { define } from "../../../../../utils/typeUtils"
import { useTaxLoaders } from "../../../loaders/taxLoaders"

type Props = {
    rows: Data[]
    readOnly?: boolean
    disableCreate?: boolean
    onCreate?: () => void
    onEditRow?: (taxCode: string) => void
    onRemoveRow?: (taxCode: string) => void
}

export const TaxItemsTable = (props: Props) => {
    const { rows, readOnly, disableCreate, onCreate, onEditRow, onRemoveRow } = props

    const taxLoaders = useTaxLoaders()
    
    const taxes = useMemo(() => taxLoaders().findAllTaxes(), [])

    const columns: Column[] = [
        {
            name: 'taxCode',
            type: FieldType.TABLE,
            table: TableName.TAXES,
            label: 'Impuesto',
            render: (value?: FieldValue) => {
                const tax = taxes.find(tax => tax.code === value)
                return define(tax).name
            }
        },
        {
            name: 'taxYield',
            type: FieldType.PERCENTAGE,
            label: '%'
        },
        {
            name: 'amount',
            type: FieldType.PRICE,
            label: 'Monto'
        }
    ]

    return (
        <Section
            id='taxItems'
            space={12}
        >
            <Table
                columns={columns}
                rows={rows}
                rowId='taxCode'
                readOnly={readOnly}
                createIcon="plus"
                disableCreate={disableCreate || rows.length >= taxes.length}
                small
                onCreate={onCreate}
                onEdit={onEditRow}
                onRemove={onRemoveRow}
            />
        </Section>
    )
}
