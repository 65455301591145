import { DataTypeForm, Labels, validateIdentifier, validatePhone } from "../../../../../features/data-types"
import { PanelWrapper, Data, FieldType, FormField, option, useGetCrumbs, O } from "../../../../../features/ui"
import { useActions } from "./actions"
import { Area, Employee } from "../../../state/types"
import { TableName } from "../../../../../tables"
import { useManagerState } from "../../../../manager"
import { useNavigate } from "react-router-dom"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"

export const EmployeeForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const employee = dataType as O<Employee>
    const createMode = !employee
    const { currentBranch } = useManagerState()

    const navigate = useNavigate()

    const { submitEmployee } = useActions()

    const employeeLabel = employee ? `: ${employee.name}` : ''
    const title = `${createMode ? 'Crear' : 'Editar'} Empleado${employeeLabel}`

    const crumbs = useGetCrumbs('Empleados', RoutePath.EMPLOYEES, employee?.name, employee?.id, createMode ? 'Crear' : 'Editar')

    const fields: FormField[] = [
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre'
        },
        {
            name: 'identifier',
            type: FieldType.NUMBER,
            label: 'DNI',
            allowDecimals: false,
            validate: validateIdentifier
        },
        {
            name: 'branchId',
            type: FieldType.TABLE,
            table: TableName.BRANCHES,
            label: 'Sucursal',
            defaultValue: employee?.branchId || currentBranch?.id
        }, 
        {
            name: 'area',
            type: FieldType.SELECT,
            label: 'Área',
            options: Object.values(Area).map(area => option(area, Labels.areaLabel(area)))
        },
        {
            name: 'email',
            type: FieldType.TEXT,
            label: 'Email'
        },
        {
            name: 'address',
            type: FieldType.TEXT,
            label: 'Domicilio',
            optional: true
        },
        {
            name: 'phone',
            type: FieldType.NUMBER,
            label: 'Teléfono',
            optional: true,
            validate: validatePhone
        }
    ]

    const onSubmit = (employeeData: Data) => {
        submitEmployee(employeeData, createMode)
    }

    const onCancel = () => navigate(-1)

    return (
        <PanelWrapper title={title} crumbs={crumbs}>
            <DataTypeForm
               formId="employee-form"
               fields={fields}
               createMode={createMode}
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
        </PanelWrapper>
    )
}
