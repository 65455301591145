import { useDefaultModuleActions } from "../../common"
import { Emitter, Event } from "../../../features/event-emitter"
import { DynamoUserApi } from "../../../services"
import { TextParams } from "../../../state/reducers/types"
import { TableName } from "../../../tables"
import { Currency } from "../../../tables/types/types"
import { define } from "../../../utils/typeUtils"
import { useSystemStateHooks } from "../../system"
import { Stock } from "../state/types"
import { newId } from "../../../features/data-types"
import { O } from "../../../features/ui"

export const useStockActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId
    const defaultModuleActions = useDefaultModuleActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId
        const tenantModuleActions = defaultModuleActions(DynamoUserApi, define(companyId))

        const fetchAllStock = async (limit?: number, startKey?: TextParams) => {
            return tenantModuleActions.fetchAll(TableName.STOCK, limit, startKey)
        }

        const fetchProductStock = (productId: string) => {
            return tenantModuleActions.fetchMultipleByParams(TableName.STOCK, { productId })
        }

        const fetchProductBranchStock = (productId: string, branchId: string) => {
            return tenantModuleActions.fetchMultipleByParams(TableName.STOCK, { productId, branchId })
        }

        const fetchStock = async (
            productId: string,
            branchId: string,
            lotId?: string
        ) => {
            let stock: O<Stock>
            if (lotId) {
                const params = { productId, branchId, lotId }
                stock = await tenantModuleActions.fetchByParams(TableName.STOCK, params) as O<Stock>
            } else {
                const stockList = await fetchProductBranchStock(productId, branchId) as Stock[]
                stock = stockList.find(stock => !stock.lotId)
            }

            return stock
        }

        const saveStock = (stock: Stock) => {
            return tenantModuleActions.save(TableName.STOCK, stock)
        }

        const modifyStock = async (
            productId: string,
            branchId: string,
            lotId: string,
            availableUnitsDiff = 0,
            reservedUnitsDiff = 0
        ) => {
            const stateStock = await fetchStock(productId, branchId, lotId) as O<Stock>

            let stock: Stock
            if (stateStock) {
                stock = {
                    ...stateStock,
                    availableUnits: stateStock.availableUnits + availableUnitsDiff,
                    reservedUnits: stateStock.reservedUnits + reservedUnitsDiff
                }
            } else {
                stock = {
                    companyId: define(companyId),
                    id: newId(),
                    productId,
                    lotId,
                    availableUnits: availableUnitsDiff,
                    reservedUnits: reservedUnitsDiff,
                    currency: Currency.ARS,
                    branchId
                }
            }
            
            const savedStock = await saveStock(stock) as Stock
            Emitter.emit(Event.UPDATE_STOCK, savedStock)

            return savedStock
        }

        const increaseStock = async (
            productId: string,
            branchId: string,
            quantity: number,
            lotId: string
        ) => {
            if (quantity > 0) {
                return modifyStock(productId, branchId, lotId, quantity, 0)
            }
        }

        const decreaseStock = async (
            productId: string, 
            branchId: string,
            quantity: number,
            lotId: string
        ) => {
            if (quantity > 0) {
                return modifyStock(productId, branchId, lotId, -quantity, 0)
            }
        }

        const reserveStock = async (
            productId: string,
            branchId: string,
            quantity: number,
            lotId: string
        ) => {
            if (quantity > 0) {
                return modifyStock(productId, branchId, lotId, -quantity, quantity)
            }
        }
        
        const enableStock = async (
            productId: string,
            branchId: string,
            quantity: number,
            lotId: string
        ) => {
            if (quantity > 0) {
                return modifyStock(productId, branchId, lotId, quantity, -quantity)
            }
        }

        const decreaseReservedStock = async (
            productId: string,
            branchId: string,
            quantity: number,
            lotId: string
        ) => {
            if (quantity > 0) {
                return modifyStock(productId, branchId, lotId, 0, -quantity)
            }
        }

        const removeStock = (id: string) => {
            return tenantModuleActions.remove(TableName.STOCK, id)
        }

        return {
            fetchAllStock,
            fetchProductStock,
            fetchProductBranchStock,
            saveStock,
            increaseStock,
            decreaseStock,
            reserveStock,
            enableStock,
            decreaseReservedStock,
            removeStock
        }
    }
}
