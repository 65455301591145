import { useSystemState } from "../../../modules/system"
import { S3SystemApi } from "../../../services/hooks/servicesHooks"
import { useS3Actions } from "../../../services/s3"
import { define } from "../../../utils/typeUtils"

export const useFileActions = () => {
    const { company } = useSystemState()

    const s3Actions = useS3Actions()

    const fetchFile = async (
        filePath: string,
        isImage = false
    ) => {
        const { getFile } = s3Actions(S3SystemApi)
        return getFile(filePath, isImage)
    }

    const downloadFile = async (filePath: string) => {
        const file = await fetchFile(filePath)

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(file)
        link.download = file.name
        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        setTimeout(() => window.URL.revokeObjectURL(link.href), 1000)
    }

    const saveFile = (
        fileSubPath: string,
        file: File,
        isImage = false
    ): Promise<string> => {
        const { saveFile } = s3Actions(S3SystemApi)
        const companyCode = define(company).code
        const filePath = `${companyCode}/${fileSubPath}`
        return saveFile(filePath, file, isImage)
    }

    const removeFile = (
        filePath: string,
        isImage = false
    ) => {
        const { removeFile } = s3Actions(S3SystemApi)
        return removeFile(filePath, isImage)
    }

    return {
        fetchFile,
        downloadFile,
        saveFile,
        removeFile
    }
}
