import { DataType } from "../../../tables"
import { normalize } from "../../../utils/typeUtils"

export const useFiltersHooks = () => {
    const search = (
        list: DataType[],
        valuesFn: (dataType: DataType) => string[],
        sortFn: (dataType_A: DataType, dataType_B: DataType) => number,
        text = '',
        showAll = false
    ): DataType[] => {
        const words = normalize(text).split(/\s+/).filter(word => word)
        const matchFn = (dataType: DataType, words: string[]) =>
            words.reduce((count, word) =>
                valuesFn(dataType).some(value => value.includes(word)) ? count + 1 : count, 0)
        
        return list
            .sort(sortFn)    
            .sort((dataTypeA, dataTypeB) => words.length > 0 ? matchFn(dataTypeB, words) - matchFn(dataTypeA, words) : 0)
            .filter(dataType => showAll || words.length === 0 || matchFn(dataType, words) > 0)
    }

    return {
        search
    }
}
