import { useEffect, useState } from "react"
import { SaleOperation } from "../../state/types"
import { Modal, ModalId, useUIState } from "../../../../features/ui"
import { ConfirmSalePaymentForm } from "./confirmSalePaymentForm"
import { TableName } from "../../../../tables"

type Props = {
    table: TableName
    operation?: SaleOperation
}

export const ConfirmSalePaymentFormModal = (props: Props) => {
    const { table, operation } = props
    const [key, setKey] = useState(0)
    const { openedModal } = useUIState()

    useEffect(() => setKey(key + 1), [openedModal])
    
    return (
        <Modal
            modalId={ModalId.CONFIRM_SALE_PAYMENT_FORM}
            title="Confirmar Pago"
            widthSize="m"
            actions={[]}
        >
            <ConfirmSalePaymentForm
                key={key}
                table={table}
                operation={operation}
            />
        </Modal>
    )
}
