import { useDefaultModuleActions } from "../../common"
import { DynamoUserApi } from "../../../services"
import { DataType, TableName } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { useSystemStateHooks } from "../../system"
import { OrderDelivery } from "../state/types"
import { useDataTypeFileActions } from "../../../features/data-types"

export const useOperationsActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId

    const defaultModuleActions = useDefaultModuleActions()
    const dataTypeFileActions = useDataTypeFileActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId
        const { fetchMultipleByParams, removeMultiple } = defaultModuleActions(DynamoUserApi, define(companyId))
    
        const fetchDeliveries = (orderId: string) => {
            return fetchMultipleByParams(TableName.ORDER_DELIVERIES, { orderId })
        }

        const saveDelivery = (
            delivery: OrderDelivery,
            deliveryNoteFile?: File
        ) => {
            const { saveWithFile } = dataTypeFileActions(define(companyId))
            return saveWithFile(TableName.ORDER_DELIVERIES, delivery, 'deliveryNoteUrl' as keyof DataType, deliveryNoteFile)
        }

        const removeDeliveries = (ids: string[]) => {
            return removeMultiple(TableName.ORDER_DELIVERIES, ids)
        }

        return {
            saveDelivery,
            fetchDeliveries,
            removeDeliveries
        }
    }
}
