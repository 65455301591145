import { useStateHooks } from '../../../state'
import { TableName } from '../../../tables'
import { useLoaders } from '../../common'
import { useSystemStateHooks } from '../../system'
import { ExpenseType } from '../state/types'

export const useExpenseTypeLoaders = () => {
    const systemHooks = useSystemStateHooks()
    const { getList } = useStateHooks()

    const loaders = useLoaders()

    return (companyId = systemHooks.companyId) => {
        const findAllExpenseTypes = () => getList(TableName.PAYMENT_TYPES) as ExpenseType[]

        const loadAllExpenseTypes = () => {
            const { loadAll } = loaders(companyId)
            return loadAll(TableName.EXPENSE_TYPES) as Promise<ExpenseType[]>
        }

        return {
            findAllExpenseTypes,
            loadAllExpenseTypes
        }
    }
}
