import styles from './pendingPaymentList.module.css'
import { Badge, BooleanField, Column, FieldType, FieldValue, O, SpinnerDiv, Table, UIPermission } from "../../../../features/ui"
import { useMemo, useState } from "react"
import { NO_SUPPLIER_LABEL, Supplier } from "../../../purchases"
import { PendingPaymentFilters } from "./pendingPaymentFilters"
import { Data, Labels, useDescriptorHooks } from "../../../../features/data-types"
import { DataType, TableName } from "../../../../tables"
import { define } from "../../../../utils/typeUtils"
import { useNavigate } from 'react-router-dom'
import { RoutePath, useRoutesHooks } from '../../../../features/routes'
import { useSystemStateHooks } from '../../../system'
import { useFind } from '../../../../state/reducers/hooks'
import { firstItem } from '../../../../utils/listUtils'
import { useAdministrationStateActions } from '../../hooks/administrationHooks'
import { SelectedTickets } from '../../state/constants'
import { Employee, Expense, Ticket, TicketType } from '../../state/types'
import { useTicketHooks } from '../../hooks/ticketHooks'

type Props = {
    tickets: Ticket[]
}

export const PendingPaymentList = (props: Props) => {
    const { tickets } = props

    const [rows, setRows] = useState<Data[]>([])
    const [selectedIds, setSelectedIds] = useState<string[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    
    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_PAYMENT_ORDERS)
    const { getTicketPendingAmount, isPurchase, findParent, isSalary } = useTicketHooks()
    const navigate = useNavigate()
    const { getRoutePath } = useRoutesHooks()
    const descriptorHooks = useDescriptorHooks()
    const find = useFind()

    const { selectTickets } = useAdministrationStateActions()

    const firstRow = (ids: string[]) => rows.find(row => row.id === firstItem(ids))
    const selectedSupplierId = useMemo(() => firstRow(selectedIds)?.supplierId, [selectedIds])
    const selectedIsSalary = useMemo(() => firstRow(selectedIds)?.type === TicketType.SALARY, [selectedIds])
    const selectedRefundEmployeeId = useMemo(() => firstRow(selectedIds)?.refundEmployeeId, [selectedIds])

    const columns: Column[] = [
        {
            name: 'ticket',
            type: FieldType.TEXT,
            label: 'Factura'
        },
        {
            name: 'operation',
            type: FieldType.TEXT,
            label: 'Operación'
        },
        {
            name: 'type',
            type: FieldType.TEXT,
            label: 'Tipo',
            render: (value?: FieldValue) => <Badge label={Labels.ticketTypeLabel(define(value) as TicketType)} />
        },
        {
            name: 'supplierId',
            type: FieldType.TABLE,
            table: TableName.SUPPLIERS,
            label: 'Proveedor',
            render: (value?: FieldValue) => {
                const supplier = find(TableName.SUPPLIERS, value) as O<Supplier>
                return <Badge label={supplier?.name || NO_SUPPLIER_LABEL} />
            }
        },
        {
            name: 'refundEmployeeId',
            type: FieldType.TABLE,
            table: TableName.EMPLOYEES,
            label: 'Empleado a Reintegrar',
            render: (value?: FieldValue) => {
                const employee = find(TableName.EMPLOYEES, value) as O<Employee>
                return employee?.name || <Badge label="No aplica" />
            }
        },
        {
            name: 'creationDate',
            type: FieldType.DATE,
            label: 'Fecha de Creación'
        },
        {
            name: 'dueDate',
            type: FieldType.DATE,
            label: 'Fecha de Vto.'
        },
        {
            name: 'pendingAmount',
            type: FieldType.PRICE,
            label: 'Monto Pendiente'
        }
    ]

    const setRowsState = (ticketList: Ticket[]) => {
        const newRows = ticketList.map(ticket => {
            const code = descriptorHooks.ticket.code(ticket)
            const table = ticket.parentTable as TableName
            const parent = define(findParent(table, ticket.parentId))
            const isPurchaseTicket = isPurchase(table)
            const isSalaryTicket = isSalary(table, parent.id)
            const expenseName = !isPurchaseTicket ? `: ${(parent as Expense).name}` : ''
            const operation = `${isPurchaseTicket ? 'Compra' : isSalaryTicket ? 'Sueldo' : 'Gasto'} ${descriptorHooks.dataType(table, parent)}${expenseName}`
            const refundEmployeeId = !isPurchaseTicket ? (parent as Expense).refundEmployeeId : undefined
            const pendingAmount = getTicketPendingAmount(ticket)
            
            return {
                id: ticket.id,
                ticket: code,
                operation,
                type: ticket.type,
                supplierId: parent.supplierId,
                refundEmployeeId: refundEmployeeId,
                creationDate: ticket.creationDate,
                dueDate: ticket.dueDate,
                pendingAmount
            }
        })
        setRows(newRows)
        setAsyncDataLoaded(true)
    }

    const onSearchStart = () => {
        setSelectedIds([])
        setAsyncDataLoaded(false)
    }

    const onSearchDone = (dataTypes: DataType[]) => {
        setRowsState(dataTypes as Ticket[])
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    const onToggleCheck = (id: string) => (value?: boolean) => {
        setSelectedIds(prevIds => value ? prevIds.concat(id) : prevIds.filter(prevId => prevId !== id))
    }

    const renderActions = (id: string) => {
        const noneSelected = selectedIds.length === 0
        const row = rows.find(row => row.id === id)
        const enabled = noneSelected || (
            row?.supplierId === selectedSupplierId &&
            selectedIsSalary == (row?.type === TicketType.SALARY) &&
            row?.refundEmployeeId === selectedRefundEmployeeId
        )

        return <div className={styles.checkContainer}>
            {!readOnly && enabled ?
                <BooleanField
                    id={`check-${row?.id}`}
                    onChange={onToggleCheck(id)}
                    className={styles.checkField}
                /> : <></>
            }
        </div>
    }

    const onCreate = () => {
        selectTickets(selectedIds.map(id => define(find(TableName.TICKETS, id)) as Ticket))
        localStorage.setItem(SelectedTickets, selectedIds.join(':'))
        navigate(getRoutePath(RoutePath.PAYMENT_ORDERS, RoutePath.CREATE))
    }

    return (
        <>
            <PendingPaymentFilters
                tickets={tickets}
                onSearchStart={onSearchStart}
                onSearchDone={onSearchDone}
            />
            {renderAsync(
                <Table
                    columns={columns}
                    rows={rows}
                    rowId="id"
                    createLabel="Crear Orden de Pago"
                    disableCreate={selectedIds.length === 0}
                    renderActions={renderActions}
                    readOnly={readOnly}
                    onCreate={onCreate}
                    createClassName={styles.createBtn}
                />
            )}
        </>
    )
}
