import { CashFund, Transaction, TransactionDestinationType, TransactionSourceType } from "../../../state/types"
import { useCashFundActions } from "../../../actions/cashFundActions"
import { Emitter, Event } from "../../../../../features/event-emitter"
import { Data } from "../../../../../features/ui"
import { define } from "../../../../../utils/typeUtils"
import { newId } from "../../../../../features/data-types"
import { Currency } from "../../../../../tables/types/types"
import { useSystemState } from "../../../../system"

export const useActions = () => {
    const { company } = useSystemState()
    const { manualAddToCashFund, manualSubstractFromCashFund } = useCashFundActions()

    const updateCashFundAmount = async (
        cashFund: CashFund,
        data: Data,
        concept: string,
        addMode: boolean
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const amount = define(data.amount) as number
        const transaction: Transaction = {
            companyId: define(company).id,
            id: newId(),
            date: new Date().toISOString(),
            sourceType: addMode ? TransactionSourceType.MANUAL_ADDITION : TransactionSourceType.CASH_FUND,
            sourceId: !addMode ? cashFund.id : undefined,
            amount: define(amount) as number,
            destinationType: addMode ? TransactionDestinationType.CASH_FUND : TransactionDestinationType.MANUAL_SUBSTRACTION,
            destinationId: addMode ? cashFund.id : undefined,
            currency: Currency.ARS,
            concept
        }
        if (addMode) {
            await manualAddToCashFund(transaction)
        } else {
            await manualSubstractFromCashFund(transaction)
        }
        Emitter.emit(Event.DB_SUBMIT_DONE, false)
    }
    
    return {
        updateCashFundAmount
    }
}
