import { useNavigate } from "react-router-dom"
import { newId, useDescriptorHooks } from "../../../../../features/data-types"
import { ModalId, O, showToast, useUIActions } from "../../../../../features/ui"
import { useWizardActions, useWizardHooks } from "../../../../../features/wizard"
import { useFindMultipleByParams } from "../../../../../state/reducers/hooks"
import { TableName } from "../../../../../tables"
import { isDefined } from "../../../../../utils/typeUtils"
import { DeliveredItem, OrderDelivery, useOperationsHooks, useOperationsStateActions } from "../../../../operations"
import { Lot, useProductsHooks } from "../../../../products"
import { useWarehouseActions } from "../../../../warehouse"
import { PurchaseOrder } from "../../../state/types"
import { PurchaseItemData } from "./types"
import { toLotAttributes } from "./utils"
import { Emitter, Event } from "../../../../../features/event-emitter"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"

export const useActions = () => {
    const { getBaseLot } = useProductsHooks()
    const { getPendingQuantity } = useOperationsHooks()
    const { getStepData } = useWizardHooks()
    const descriptorHooks = useDescriptorHooks()
    const navigate = useNavigate()
    const findMultipleByParams = useFindMultipleByParams()
    const { getRoutePath } = useRoutesHooks()

    const { setCurrentDelivery } = useOperationsStateActions()

    const { clearData } = useWizardActions()
    const warehouseActions = useWarehouseActions()
    const { closeModal, openModal } = useUIActions()

    const finishDelivery = async (
        wizardKey: string,
        purchase: PurchaseOrder,
        purchaseItemsData: PurchaseItemData[]
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const deliveries = findMultipleByParams(TableName.ORDER_DELIVERIES, { orderId: purchase.id }) as OrderDelivery[]
        const infoStepData = getStepData(wizardKey, 0)
        const companyId = purchase.companyId as string

        const date = new Date(infoStepData.date || purchase.deliveryDate)
        const branchId = infoStepData.deliveryBranchId || purchase.deliveryBranchId
        
        const lotData: Partial<Lot>[] = []
        const deliveredItems: DeliveredItem[] = purchaseItemsData.map(purchaseItemData => {
            const { stepIndex, purchaseItem } = purchaseItemData
            const itemStepData = getStepData(wizardKey, stepIndex)
            const deliveredQuantity = itemStepData.deliveredQuantity as O<number>
            const pendingQuantity = getPendingQuantity(purchaseItem, deliveries)
            const delivered = (isDefined(deliveredQuantity) ? deliveredQuantity : pendingQuantity) as number

            const newLot = itemStepData.choice === 'create-new'
            const existingLot = itemStepData.choice === 'select-existing'
            const noLot = itemStepData.choice === 'no-lot'
            const baseLotId = getBaseLot(purchaseItem.productId).id
            lotData.push({
                productId: purchaseItem.productId,
                id: existingLot ? itemStepData.lotId : (noLot ? baseLotId : undefined),
                code: newLot ? itemStepData.lotCode : undefined,
                attributes: itemStepData.lotData && toLotAttributes(itemStepData.lotData)
            })

            return {
                itemId: purchaseItem.id,
                delivered
            }
        })

        const delivery: OrderDelivery = {
            companyId,
            id: newId(),
            orderId: purchase.id,
            date: date.toISOString(),
            deliveredItems,
            branchId
        }

        const purchaseItems = purchaseItemsData.map(purchaseItemData => purchaseItemData.purchaseItem)
        await warehouseActions().savePurchaseDelivery(purchase, purchaseItems, delivery, lotData)

        closeModal(ModalId.SPINNER)
        showToast(`La recepción de la Compra ${descriptorHooks.purchase.code(purchase)} fue registrada.`)
        clearData(wizardKey)
        
        setCurrentDelivery(delivery)
        navigate(getRoutePath(RoutePath.PURCHASES, purchase.id))
        setTimeout(() => openModal(ModalId.UPLOAD_DELIVERY_NOTE), 500)
    }
    
    return {
        finishDelivery
    }
}
