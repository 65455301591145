import { useDefaultModuleActions } from "../../common"
import { DynamoUserApi } from "../../../services"
import { TextParams } from "../../../state/reducers/types"
import { TableName } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { useSystemStateHooks } from "../../system"
import { Check, CheckStatus } from "../state/types"

export const useCheckActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId

    const defaultModuleActions = useDefaultModuleActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId
        const { fetchAll, fetchByParams, save, remove } = defaultModuleActions(DynamoUserApi, define(companyId))

        const fetchAllChecks = async (limit?: number, startKey?: TextParams) => {
            return fetchAll(TableName.CHECKS, limit, startKey)
        }

        const fetchCheck = (id: string) => {
            return fetchByParams(TableName.CHECKS, { id })
        }

        const saveCheck = (check: Check) => {
            return save(TableName.CHECKS, check)
        }

        const modifyStatus = async (
            checkId: string,
            status: CheckStatus
        ) => {
            const stateCheck = define(await fetchCheck(checkId)) as Check
            const check: Check = {
                ...stateCheck,
                status
            }

            return await saveCheck(check)
        }

        const reserveCheck = (checkId: string) => {
            return modifyStatus(checkId, CheckStatus.RESERVED)
        }

        const endorseCheck = (checkId: string) => {
            return modifyStatus(checkId, CheckStatus.ENDORSED)
        }

        const removeCheck = (id: string) => {
            return remove(TableName.CHECKS, id)
        }

        return {
            fetchAllChecks,
            saveCheck,
            reserveCheck,
            endorseCheck,
            removeCheck
        }
    }
}
