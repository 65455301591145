import { Labels, useDataTypesHooks } from "../../../../../features/data-types"
import { FieldType, FormatType, Section } from "../../../../../features/ui"
import { InvoiceDataProp } from "../../../state/types"
import { TableName } from "../../../../../tables"
import { useInvoiceHooks } from "../../../hooks/invoiceHooks"
import { SaleOperation } from "../../../../sales"

type Props = {
    table: TableName
    operation?: SaleOperation
}

export const InvoiceDataDiv = (props: Props) => {
    const { table, operation } = props

    const { getInvoiceData } = useInvoiceHooks()
    const { formatValue, formatCustomValue } = useDataTypesHooks()

    const data = operation ? getInvoiceData(table, operation) : undefined
    
    if (!data) return <></>

    const labelFn = Labels.invoiceDataPropLabel
    
    const identifierFn = (identifier: number) => {
        const format = identifier.toString().length === 11 ? FormatType.CUIT_CUIL : FormatType.DNI
        return formatCustomValue(identifier, format)
    }

    const phoneFn = (phone?: number) => formatCustomValue(phone, FormatType.PHONE)

    const priceFn = (value: number) => formatValue(value, FieldType.PRICE)

    return <Section id="invoice-data">
        <ul>
            <li>
                Corralón:
                <ul>
                    <li>{labelFn(InvoiceDataProp.COMPANY_LEGAL_NAME)}: {data[InvoiceDataProp.COMPANY_LEGAL_NAME]}</li>
                    <li>{labelFn(InvoiceDataProp.COMPANY_TAX_ID)}: {identifierFn(data[InvoiceDataProp.COMPANY_TAX_ID])}</li>
                    <li>{labelFn(InvoiceDataProp.COMPANY_ADDRESS)}: {data[InvoiceDataProp.COMPANY_ADDRESS]}</li>
                </ul>
            </li>
            <li>
                Cliente:
                <ul>
                    <li>{labelFn(InvoiceDataProp.CUSTOMER_LEGAL_NAME)}: {data[InvoiceDataProp.CUSTOMER_LEGAL_NAME]}</li>
                    <li>{labelFn(InvoiceDataProp.CUSTOMER_IDENTIFIER)}: {identifierFn(data[InvoiceDataProp.CUSTOMER_IDENTIFIER])}</li>
                    <li>{labelFn(InvoiceDataProp.CUSTOMER_PHONE)}: {phoneFn(data[InvoiceDataProp.CUSTOMER_PHONE])}</li>
                </ul>
            </li>
            <li>{labelFn(InvoiceDataProp.SUBTOTAL)}: {priceFn(data[InvoiceDataProp.SUBTOTAL])}</li>
            <li>{labelFn(InvoiceDataProp.VAT_AMOUNT)}: {priceFn(data[InvoiceDataProp.VAT_AMOUNT])}</li>
            <li>{labelFn(InvoiceDataProp.TOTAL)}: {priceFn(data[InvoiceDataProp.TOTAL])}</li>
        </ul>
    </Section>
}
