import styles from './ticketForm.module.css'
import { useNavigate } from "react-router-dom"
import { DataTypeForm, Labels } from "../../../../../features/data-types"
import { Data, FieldType, FieldValue, FileField, FormField, ModalId, O, option, useEffectAfterMount, useUIActions, Validatable } from "../../../../../features/ui"
import { PurchaseOrder } from "../../../../purchases"
import { useActions } from "./actions"
import { define, isDefined } from "../../../../../utils/typeUtils"
import { useEffect, useMemo, useState } from "react"
import { toList } from '../../../../../utils/listUtils'
import { TableName } from '../../../../../tables'
import { Expense, TaxType, Ticket, TicketType } from '../../../state/types'
import { TaxItemsTable } from '../../tax/tax-items-table/taxItemsTable'
import { TaxItemFormModal } from '../../tax/tax-item-form-modal/taxItemFormModal'
import { fileName } from '../../../../../features/files'
import { useGenerateNextValue2 } from '../../../../../tables/hooks'
import { useTicketHooks } from '../../../hooks/ticketHooks'
import { useTaxLoaders } from '../../../loaders/taxLoaders'

type Props = {
  parentTable: TableName
  parent: PurchaseOrder | Expense
  defaultData: Data
  ticket?: Ticket
  ticketFileParam?: File
}

export const TicketForm = (props: Props) => {
  const { parentTable, parent, defaultData, ticket, ticketFileParam } = props
  const createMode = !ticket

  const defaultTotalWithoutVAT = define(defaultData.totalWithoutVAT) as number
  const defaultVatAmount = define(defaultData.vatAmount) as number
  const defaultTotal = defaultTotalWithoutVAT + defaultVatAmount
  
  const [code, setCode] = useState(ticket?.code || 1)
  const [type, setType] = useState<O<TicketType>>(ticket?.type || TicketType.TICKET_A)
  const [ticketFile, setTicketFile] = useState<O<File>>(ticketFileParam)
  const [ticketValidation, setTicketValidation] = useState<string>()
  const [subtotal, setSubtotal] = useState<O<number>>(isDefined(ticket?.subtotal) ? ticket?.subtotal : defaultTotalWithoutVAT)
  const [vatAmount, setVatAmount] = useState<O<number>>(isDefined(ticket?.subtotal) ? ticket?.vatAmount : defaultVatAmount)
  const [taxItemsData, setTaxItemsData] = useState<Data[]>(ticket?.taxItems || [])
  const [currentTaxItem, setCurrentTaxItem] = useState<Data>()
  const [total, setTotal] = useState<O<number>>(isDefined(ticket?.total) ? ticket?.total : defaultTotal)

  const { isPurchase } = useTicketHooks()
  const isPurchaseTicket = isPurchase(parentTable)
  const generateNextValue = useGenerateNextValue2()
  const navigate = useNavigate()

  const taxLoaders = useTaxLoaders()

  const { submitTicket } = useActions()
  const { toggleModal } = useUIActions()

  const init = async () => {
    if (createMode) {
      setCode(await generateNextValue(TableName.TICKETS, 'code'))
    }
  }

  useEffect(() => {
    init()  
  }, [])

  useEffectAfterMount(() => {
    subtotal && setTotal(subtotal + (vatAmount || 0) + taxItemsData.reduce((sum, taxItemData) => sum + (define(taxItemData.amount) as number), 0))
  }, [subtotal, vatAmount, taxItemsData])

  const perceptions = useMemo(() => {
    const allTaxes = taxLoaders().findAllTaxes()
    return allTaxes.filter(tax => tax.type === TaxType.PERCEPTION &&
      (currentTaxItem?.taxCode === tax.code || !taxItemsData.map(taxItemData => taxItemData.taxCode).includes(tax.code))
    )
  }, [taxItemsData, currentTaxItem])

  const nonTaxTypes = useMemo(() => [TicketType.TICKET_B, TicketType.TICKET_C, TicketType.RECEIPT_X], [])

  const taxItemMatches = (data: Data, taxCode: string) => data.taxCode === taxCode
  const openFormModal = () => toggleModal(ModalId.TAX_ITEM_FORM)

  const onChangeType = (value?: FieldValue) => {
    const newType = value as O<TicketType>
    setType(newType)
    if (newType && nonTaxTypes.includes(newType)) {
      setTaxItemsData([])
      setSubtotal(defaultTotal)
      setVatAmount(0)
      setTotal(defaultTotal)
    } else {
      setSubtotal(defaultTotalWithoutVAT)
      setVatAmount(defaultVatAmount)
      setTotal(defaultTotal)
    }
  }

  const onChangeTicket = (value?: FieldValue) => {
    setTicketValidation(undefined)
    setTicketFile(value as O<File>)
  }

  const onChangeSubtotal = (value?: FieldValue) => {
    setSubtotal(value as O<number>)
  }

  const onChangeVatAmount = (value?: FieldValue) => {
    setVatAmount(value as O<number>)
  }

  const onAddTaxItem = () => {
    setCurrentTaxItem(undefined)
    openFormModal()
  }

  const onEditTaxItem = (taxCode: string) => {
    setCurrentTaxItem(taxItemsData.find(taxItemData => taxItemMatches(taxItemData, taxCode)))
    openFormModal()
  }

  const onRemoveTaxItem = (taxCode: string) => {
    const newTaxItemsData = taxItemsData.filter(taxItemData => !taxItemMatches(taxItemData, taxCode))
    setTaxItemsData(newTaxItemsData)
  }

  const onChangeTotal = (value?: FieldValue) => {
    const newTotal = value as O<number>
    setTotal(newTotal)
    if (type && nonTaxTypes.includes(type)) {
      setSubtotal(newTotal)
    }
  }

  const fields: FormField[] = [
    {
      name: 'type',
      type: FieldType.SELECT,
      label: 'Tipo',
      defaultValue: type,
      options: Object.values(TicketType)
        .filter(type => type !== TicketType.SALARY)
        .map(type => option(type, Labels.ticketTypeLabel(type))),
      onChange: onChangeType
    },
    {
      name: 'ticketUrl',
      type: FieldType.FILE,
      label: isPurchaseTicket ? 'Factura' : 'Factura / Comprobante',
      hint: 'Formato: PDF',
      render: () => (
        <Validatable validations={toList(ticketValidation)} left className={styles.ticketValidation}>
          <FileField
            id="ticketUrl"
            value={ticketFile}
            fileName={fileName(TableName.TICKETS, code.toString(), parentTable, parent.code.toString())}
            onChange={onChangeTicket}
          />
        </Validatable>
      )
    },
    {
      name: 'dueDate',
      type: FieldType.DATE,
      label: 'Fecha de Vto.',
      min: new Date()
    },
    {
      name: 'total',
      type: FieldType.PRICE,
      label: 'Monto Final',
      value: total,
      onChange: onChangeTotal
    }
  ]
  if (!type || !nonTaxTypes.includes(type)) {
    const subtotalField = {
      name: 'subtotal',
      type: FieldType.PRICE,
      label: 'Subtotal Productos',
      value: subtotal,
      onChange: onChangeSubtotal
    }
    const vatAmountField = {
      name: 'vatAmount',
      type: FieldType.PRICE,
      label: 'IVA',
      value: vatAmount,
      onChange: onChangeVatAmount
    }
    const taxItemsField = {
      name: 'taxItems',
      type: FieldType.TABLE,
      label: 'Percepciones',
      render: () => (
        <TaxItemsTable
          rows={taxItemsData}
          disableCreate={!subtotal || perceptions.length === taxItemsData.length}
          onCreate={onAddTaxItem}
          onEditRow={onEditTaxItem}
          onRemoveRow={onRemoveTaxItem}
        />
      )
    }
    fields.splice(3, 0, subtotalField, vatAmountField, taxItemsField)
  }

  const validate = (): boolean => {
    if (!ticketFile) {
      setTicketValidation('Debe subir la factura')
      return false
    }

    return true
  }

  const onSubmit = (ticketData: Data) => {
    if (validate()) {
      submitTicket(
        ticketData,
        code,
        parentTable,
        parent.id,
        define(subtotal),
        define(vatAmount),
        define(ticketFile),
        taxItemsData,
        createMode
      )
    }
  }

  const onCancel = () => navigate(-1)

  const onSaveTaxItem = (newData: Data) => {
    let newTaxItemsData = taxItemsData
    if (currentTaxItem) {
      const { taxCode } = currentTaxItem
      newTaxItemsData = taxItemsData.map(taxItemData => taxItemMatches(taxItemData, define(taxCode) as string) ? newData : taxItemData)
    } else {
      newTaxItemsData = [newData, ...taxItemsData]
    }
    setTaxItemsData(newTaxItemsData)
  }

  return (
    <>
      <DataTypeForm
        formId="ticket-form"
        fields={fields}
        createMode={createMode}
        createLabel="Guardar"
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
      <TaxItemFormModal
        taxItem={currentTaxItem}
        total={subtotal}
        title="Agregar Percepción"
        editableAmount
        taxes={perceptions}
        onSubmit={onSaveTaxItem}
      />
    </>
  )
}
