import { useNavigate } from "react-router-dom"
import { Request, useSystemActions } from "../../../../modules/system"
import { define } from "../../../../utils/typeUtils"
import { useRoutesHooks } from "../../hooks/routesHooks"
import { RoutePath } from "../../types"
import { firstItem } from "../../../../utils/listUtils"

export const useLoad = () => {
    const { loadUser } = useRoutesHooks()
    const navigate = useNavigate()

    const systemActions = useSystemActions()

    return async () => {
        const { fetchAdmins, fetchRequests, fetchCompanyById } = systemActions()

        const user = await loadUser()

        if (user)  {
            const admins = await fetchAdmins(user.id)
            const adminIds = admins.map(admin => admin.id)
            if (!adminIds.includes(user.id)) {
                navigate(RoutePath.ADMIN)
            } else {
                const requests = await fetchRequests(user.id) as Request[]
                const request = firstItem(requests)
                if (request) {
                    const company = await fetchCompanyById(request.companyId)
                    navigate(`/${define(company).code}`)
                } else {
                    navigate(RoutePath.ERROR)
                }
            }
        }
    }
}
