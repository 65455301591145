import { TableName } from '../../../../../tables'
import { Data, UIPermission, DetailPanel, DetailFieldProps, FieldType, FieldValue, Spinner, Action, Tab, useGetCrumbs, PDFTabContent, ModalId, useUIActions } from '../../../../../features/ui'
import { define } from '../../../../../utils/typeUtils'
import { useEffect, useState } from 'react'
import { Operation, OperationItemsTable, OrderDelivery, DeliveryStatus, useOperationsActions, DeliveryList, PendingItemsList, PriceBreakdown, usePriceBreakdownHooks, PaymentStatus, DownloadDeliveryNoteModal, useOperationsStateActions } from '../../../../operations'
import { useSystemStateHooks } from '../../../../system'
import { PurchaseItem, PurchaseOrder } from '../../../state/types'
import { purchaseWizardKey } from '../../../utils/purchaseUtils'
import { usePurchasesActions } from '../../../actions/purchasesActions'
import { OpenWizardModal, useWizardActions } from '../../../../../features/wizard'
import { RoutePath, useRoutesHooks } from '../../../../../features/routes'
import { Labels, useDescriptorHooks } from '../../../../../features/data-types'
import { PurchasePDF } from '../purchase-pdf/purchasePDF'
import { DownloadPurchasePDFModal } from '../download-purchase-pdf-modal/downloadPurchasePDFModal'
import { useNavigate } from 'react-router-dom'
import { UploadAdditionalTicketModal, TicketList, Ticket, useTicketActions, DownloadTicketModal } from '../../../../administration'
import { UploadDeliveryNoteFormModal } from '../upload-delivery-note-modal/uploadDeliveryNoteFormModal'

export const PurchaseDetail = () => {
  const { dataType } = useRoutesHooks().parseUrl()
  const purchase = define(dataType) as PurchaseOrder
  const wizardKey = purchaseWizardKey(purchase)

  const [purchaseItems, setPurchaseItems] = useState<PurchaseItem[]>([])
  const [tickets, setTickets] = useState<Ticket[]>([])
  const [currentTicket, setCurrentTicket] = useState<Ticket>()
  const [deliveries, setDeliveries] = useState<OrderDelivery[]>([])
  const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
  
  const { userHasPermission } = useSystemStateHooks()
  const readOnly = !userHasPermission(UIPermission.SAVE_PURCHASES)
  const hasDeliveryPermission = userHasPermission(UIPermission.SAVE_PURCHASE_DELIVERIES)
  const descriptorHooks = useDescriptorHooks()
  const { getPriceBreakdownFromItems } = usePriceBreakdownHooks()
  const navigate = useNavigate()

  const { setCurrentDelivery } = useOperationsStateActions()

  const { goToWizard } = useWizardActions()
  const purchasesActions = usePurchasesActions()
  const ticketActions = useTicketActions()
  const operationsActions = useOperationsActions()
  const { toggleModal } = useUIActions()

  const init = async () => {
    const { fetchPurchaseItemsByOrder } = purchasesActions()
    const { fetchTicketsByParentId } = ticketActions()
    const statePurchaseItems = await fetchPurchaseItemsByOrder(purchase.id) as PurchaseItem[]
    setPurchaseItems(statePurchaseItems)
    const stateTickets = await fetchTicketsByParentId(purchase.id) as Ticket[]
    setTickets(stateTickets)
    const stateDeliveries = await operationsActions().fetchDeliveries(purchase.id) as OrderDelivery[]
    setDeliveries(stateDeliveries)
  }

  useEffect(() => {
    init().then(() => setAsyncDataLoaded(true))
  }, [])

  const priceBreakdown = getPriceBreakdownFromItems(purchaseItems)

  const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <Spinner size='m'/>

  const crumbs = useGetCrumbs('Compras', RoutePath.PURCHASES, descriptorHooks.purchase.code(purchase))

  const fields: DetailFieldProps[] = [
    { name: 'supplierId', type: FieldType.TABLE, table: TableName.SUPPLIERS, label: 'Proveedor' },
    { name: 'deliveryBranchId', type: FieldType.TABLE, table: TableName.BRANCHES, label: 'Sucursal de Recepción' },
    { name: 'deliveryDate', type: FieldType.DATE, label: 'Fecha de Entrega' },
    {
      name: 'purchaseItems',
      type: FieldType.TABLE,
      table: TableName.PURCHASE_ITEMS,
      render: () => renderAsync(
        <OperationItemsTable
          rows={purchaseItems as Data[]}
          operation={Operation.PURCHASE}
          readOnly
        />
      )
    },
    {
      name: 'priceBreakdown',
      type: FieldType.PRICE,
      label: 'Precio',
      render: () => renderAsync(
        <PriceBreakdown data={priceBreakdown} />
      )
    },
    {
      name: 'deliveryStatus',
      type: FieldType.TEXT,
      label: 'Estado de Entrega',
      render: (value?: FieldValue) => <>{Labels.deliveryStatusLabel(define(value) as DeliveryStatus)}</>
    },
    {
      name: 'paymentStatus',
      type: FieldType.TEXT,
      label: 'Estado de Pago',
      render: (value?: FieldValue) => <>{Labels.paymentStatusLabel(define(value) as PaymentStatus)}</>
    }
  ]
  if (purchase.deliveryStatus !== DeliveryStatus.DELIVERED && deliveries.length > 0) {
    const pendingItemsField = {
      name: 'pendingItems',
      type: FieldType.TABLE,
      table: TableName.PURCHASE_ITEMS,
      render: () => renderAsync(
        <PendingItemsList orderId={purchase.id} operation={Operation.PURCHASE} />
      )
    }
    fields.splice(4, 0, pendingItemsField)
  }

  const onDownloadPDF = () => toggleModal(ModalId.DOWNLOAD_PURCHASE_PDF)

  const onEdit = () => {
    navigate(RoutePath.EDIT)
  }

  const onUploadTicket = () => {
    if (tickets.length > 0) {
      toggleModal(ModalId.UPLOAD_ADDITIONAL_TICKET)
    } else {
      navigate(`${RoutePath.TICKETS}/${RoutePath.CREATE}`)
    }
  }

  const onDeliver = () => {
    goToWizard(purchaseWizardKey(purchase), RoutePath.DELIVERY)
  }

  const onArchive = () => {}

  const actions: Action[] = [
    { label: 'Descargar', kind: 'tertiary', icon: 'download', onClick: onDownloadPDF }
  ]
  const isDeliveryPending = purchase.deliveryStatus === DeliveryStatus.PENDING
  const isPaymentPending = purchase.paymentStatus === PaymentStatus.PENDING
  const isEditable = isDeliveryPending && isPaymentPending && tickets.length === 0
  const isDelivered = purchase.deliveryStatus === DeliveryStatus.DELIVERED
  const ticketsSubtotal = tickets.reduce((sum, ticket) => sum + ticket.subtotal, 0)
  const isInvoiceable = ticketsSubtotal < priceBreakdown.subtotal
  if (!readOnly) {
    if (isEditable) {
      actions.push({ label: 'Editar Compra', icon: 'pencil-fill', onClick: onEdit })
    }
    if (isInvoiceable) {
      actions.push({ label: 'Subir Factura', icon: 'upload', onClick: onUploadTicket })
    }
    if (isDelivered) {
      actions.push({ label: 'Archivar', icon: 'archive', kind: 'secondary', onClick: onArchive })
    }
  }
  if (hasDeliveryPermission && !isDelivered) {
    actions.push({ label: 'Registrar Recepción', icon: 'truck', onClick: onDeliver })
  }

  const renderPDFContent = (
    <PDFTabContent>
      <PurchasePDF
        id="purchase-pdf-preview"
        purchase={purchase}
        previewMode
      />
    </PDFTabContent>
  )

  const tabs: Tab[] = [
    { id: 'pdf', title: 'PDF', content: renderPDFContent }
  ]
  if (tickets.length > 0) {
    tabs.push({
      id: 'tickets',
      title: 'Facturas',
      content: <TicketList
        tickets={tickets}
        readOnly={readOnly}
        onChangeCurrentTicket={setCurrentTicket}
      />
    })
  }
  if (deliveries.length > 0) {
    tabs.push({
      id: 'deliveries',
      title: 'Entregas',
      content: <DeliveryList
        orderId={purchase.id}
        operation={Operation.PURCHASE}
        readOnly={!hasDeliveryPermission}
        onChangeCurrentDelivery={setCurrentDelivery}
      />
    })
  }

  return (<>
    <DetailPanel
      tableName={TableName.PURCHASE_ORDERS}
      crumbs={crumbs}
      fields={fields}
      data={purchase}
      icon='cart3'
      imgSpace={3}
      tabs={tabs}
      readOnly={readOnly}
      actions={actions}
    />
    <DownloadPurchasePDFModal purchase={purchase} />
    <DownloadTicketModal ticket={currentTicket} />
    <UploadAdditionalTicketModal />
    <UploadDeliveryNoteFormModal purchase={purchase} />
    <DownloadDeliveryNoteModal />
    <OpenWizardModal
      wizardKey={wizardKey}
      path={RoutePath.DELIVERY}
    />
  </>)
}
