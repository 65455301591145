import styles from './spinnerModal.module.css'
import { useUIState } from '../../hooks/uiHooks'
import { Modal } from '../modal/modal'
import { ModalId } from '../modal/types'
import { Spinner } from '../spinner/spinner'

export const SpinnerModal = () => {
    const { spinnerModalMessage } = useUIState()

    return (
        <Modal
            modalId={ModalId.SPINNER}
            title="No cierre la ventana"
            closeable={false}
            actions={[]}
        >
            <div className={styles.container}>
                <Spinner
                    label={spinnerModalMessage || "Guardando registro..."}
                    size="m"
                />
            </div>
        </Modal>
    )
}
