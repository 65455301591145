import { Data } from "../../../features/data-types"
import { O } from "../../../features/ui"
import { useStateHooks } from "../../../state/reducers/hooks"
import { TableName } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { PaymentType, Tax } from "../../administration"
import { Product } from "../../products"
import { Customer, CustomerType, HoardItem, HoardOrder, HoardType, SaleItem, SaleOperation } from "../../sales"
import { OperationItem } from "../state/types"
import { ItemPriceBreakdownType, PriceBreakdownType } from "./types"

export const usePriceBreakdownHooks = () => {
    const { find, findByParams } = useStateHooks()

    const getItemsPriceBreakdown = (
        items: OperationItem[],
        customerAdjustmentYield = 0,
        paymentTypeAdjustmentYield = 0
    ): ItemPriceBreakdownType[] => {
        return items.map(item => {
            const price = define(item.price)
            const quantity = define(item.quantity)
            const productId = define(item.productId)
            const product = define(find(TableName.PRODUCTS, productId)) as Product
            const vatTax = define(find(TableName.TAXES, product.vatTaxId)) as Tax
            
            const customerAdjustmentUnitAmount = price * customerAdjustmentYield / 100
            const customerUnitSubtotal = price + customerAdjustmentUnitAmount
            const paymentTypeAdjustmentUnitAmount = customerUnitSubtotal * paymentTypeAdjustmentYield / 100
            const unitSubtotal = customerUnitSubtotal + paymentTypeAdjustmentUnitAmount

            const unitVatAmount = unitSubtotal * vatTax.yield / 100
            const unitTotal = unitSubtotal + unitVatAmount
            
            const subtotal = price * quantity
            const customerAdjustmentAmount = customerAdjustmentUnitAmount * quantity
            const paymentTypeAdjustmentAmount = paymentTypeAdjustmentUnitAmount * quantity
            const totalWithoutVAT = unitSubtotal * quantity
            const vatAmount = unitVatAmount * quantity
            const total = unitTotal * quantity
            
            return {
                productId,
                unitSubtotal,
                unitVatAmount,
                unitTotal,
                subtotal,
                customerAdjustmentAmount,
                paymentTypeAdjustmentAmount,
                totalWithoutVAT,
                vatAmount,
                total
            }
        })
    }

    const getPriceBreakdown = (
        subtotal: number,
        customerAdjustmentYield = 0,
        paymentTypeAdjustmentYield = 0,
        vatAmount = 0
    ): PriceBreakdownType => {
        const customerAdjustmentAmount = subtotal * customerAdjustmentYield / 100
        const customerSubtotal = subtotal + customerAdjustmentAmount
        const paymentTypeAdjustmentAmount = customerSubtotal * paymentTypeAdjustmentYield / 100
        const totalWithoutVAT = customerSubtotal + paymentTypeAdjustmentAmount
        const total = totalWithoutVAT + vatAmount
        
        return {
            subtotal,
            customerAdjustmentAmount,
            paymentTypeAdjustmentAmount,
            totalWithoutVAT,
            vatAmount,
            total,
            items: []
        }
    }

    const getPriceBreakdownFromItems = (
        items: OperationItem[],
        customerAdjustmentYield = 0,
        paymentTypeAdjustmentYield = 0
    ): PriceBreakdownType => {
        const itemsPriceBreakdown = getItemsPriceBreakdown(items, customerAdjustmentYield, paymentTypeAdjustmentYield)
        
        const subtotal = itemsPriceBreakdown.reduce((sum, item) => sum + item.subtotal, 0)
        const customerAdjustmentAmount = itemsPriceBreakdown.reduce((sum, item) => sum + item.customerAdjustmentAmount, 0)
        const paymentTypeAdjustmentAmount = itemsPriceBreakdown.reduce((sum, item) => sum + item.paymentTypeAdjustmentAmount, 0)
        const totalWithoutVAT = itemsPriceBreakdown.reduce((sum, item) => sum + item.totalWithoutVAT, 0)
        const vatAmount = itemsPriceBreakdown.reduce((sum, item) => sum + item.vatAmount, 0)
        const total = itemsPriceBreakdown.reduce((sum, item) => sum + item.total, 0)
        
        return {
            subtotal,
            customerAdjustmentAmount,
            paymentTypeAdjustmentAmount,
            totalWithoutVAT,
            vatAmount,
            total,
            items: itemsPriceBreakdown
        }
    }

    const getOperationPriceBreakdown = (
        table: TableName,
        operation: SaleOperation,
        paymentTypeId?: string
    ): PriceBreakdownType => {
        const isSale = table === TableName.SALE_ORDERS
        const itemsTable = isSale ? TableName.SALE_ITEMS : TableName.HOARD_ITEMS
        const parentIdProp = isSale ? 'saleId' : 'hoardId'
        const customer = define(find(TableName.CUSTOMERS, operation.customerId)) as Customer
        const items = findByParams(itemsTable, { [parentIdProp]: operation.id }) as (SaleItem | HoardItem)[]
        const customerType = define(find(TableName.CUSTOMER_TYPES, customer.customerTypeId)) as CustomerType
        const paymentType = define(find(TableName.PAYMENT_TYPES, paymentTypeId || operation.paymentTypeId)) as PaymentType
        
        return isSale ?
            getPriceBreakdownFromItems(items, customerType.yield, paymentType.yield) :
            getHoardPriceBreakdown((operation as HoardOrder).type, (operation as HoardOrder).value, items, customer, paymentType)
    }

    const getHoardPriceBreakdown = (
        type = HoardType.FINANCIAL,
        value = 0,
        hoardItemsData: Data[] = [],
        customer?: Customer,
        paymentType?: PaymentType
    ): PriceBreakdownType => {
        const isFinancialHoard = type === HoardType.FINANCIAL
        const customerType = find(TableName.CUSTOMER_TYPES, customer?.customerTypeId) as O<CustomerType>

        return isFinancialHoard ?
            getPriceBreakdown(value, customerType?.yield, paymentType?.yield) :
            getPriceBreakdownFromItems(hoardItemsData, customerType?.yield, paymentType?.yield)
    }

    return {
        getItemsPriceBreakdown,
        getPriceBreakdown,
        getPriceBreakdownFromItems,
        getOperationPriceBreakdown,
        getHoardPriceBreakdown
    }
}
