import { DataTypeForm } from "../../../../features/data-types"
import { PanelWrapper, Data, FieldType, FormField, ImageField, Spinner, useGetCrumbs, O } from "../../../../features/ui"
import { useActions } from "./actions"
import { Category } from "../../state/types"
import { useEffect, useState } from "react"
import { useFileActions } from "../../../../features/files"
import { useNavigate } from "react-router-dom"
import { RoutePath, useRoutesHooks } from "../../../../features/routes"

export const CategoryForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const category = dataType as O<Category>
    const createMode = !category

    const [asyncDataLoaded, setAsyncDataLoaded] = useState(!category?.imageUrl)
    const [imageFile, setImageFile] = useState<File>()

    const navigate = useNavigate()

    const { submitCategory } = useActions()
    const { fetchFile } = useFileActions()

    useEffect(() => {
        if (!createMode && category && category?.imageUrl) {
            fetchFile(category.imageUrl, true).then(file => {
                setImageFile(file)
                setAsyncDataLoaded(true)
            })
        } else {
            setAsyncDataLoaded(true)
        }
    }, [])

    const categoryLabel = category ? `: ${category.name}` : ''
    const title = `${createMode ? 'Crear' : 'Editar'} Categoría${categoryLabel}`

    const crumbs = useGetCrumbs('Categorías', RoutePath.CATEGORIES, category?.name, undefined, createMode ? 'Crear' : 'Editar')

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <Spinner size='m'/>

    const fields: FormField[] = [
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre'
        },
        {
            name: 'imageUrl',
            type: FieldType.IMAGE,
            label: 'Imagen',
            render: () => renderAsync(<ImageField
                id="imageUrl"
                value={imageFile}
                onChange={setImageFile}
            />)
        }
    ]

    const onSubmit = (categoryData: Data) => {
        submitCategory(categoryData, createMode, imageFile)
    }

    const onCancel = () => navigate(-1)

    return (
        <PanelWrapper title={title} crumbs={crumbs}>
            <DataTypeForm
               formId="category-form"
               fields={fields}
               createMode={createMode}
               disableSubmit={!asyncDataLoaded}
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
        </PanelWrapper>
    )
}
