import { useEffect, useState } from 'react'
import { DataType } from '../../../../../tables'
import { FieldType, FieldValue, FilterField, Filters, O, option } from '../../../../../features/ui'
import { PaymentOrder, PaymentOrderStatus } from '../../../state/types'
import { Labels } from '../../../../../features/data-types'
import { usePaymentOrderHooks } from '../../../hooks/paymentOrderHooks'

type Props = {
    paymentOrders: PaymentOrder[]
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const PaymentOrderFilters = (props: Props) => {
    const { paymentOrders, onSearchStart, onSearchDone } = props

    const [searchText, setSearchText] = useState<string>()
    const [status, setStatus] = useState<PaymentOrderStatus>()
    const [creationDateFrom, setCreationPaymentDateFrom] = useState<Date>()
    const [creationDateTo, setCreationPaymentDateTo] = useState<Date>()

    const { searchPaymentOrders } = usePaymentOrderHooks()

    useEffect(() => search(searchText, status, creationDateFrom, creationDateTo), [paymentOrders])

    const search = (
        searchTextParam?: string,
        statusParam?: PaymentOrderStatus,
        creationDateFromParam?: Date,
        creationDateToParam?: Date
    ) => {
        onSearchStart()
        let paymentOrderList = paymentOrders.filter(paymentOrder => !statusParam || paymentOrder.status === statusParam)
        paymentOrderList = paymentOrderList.filter(paymentOrder => !creationDateFromParam || new Date(paymentOrder.creationDate) >= creationDateFromParam)
        paymentOrderList = paymentOrderList.filter(paymentOrder => !creationDateToParam || new Date(paymentOrder.creationDate) <= creationDateToParam)
        paymentOrderList = searchPaymentOrders(paymentOrderList, searchTextParam)
        setTimeout(() => onSearchDone(paymentOrderList), 100)
    }

    const handleSearch = (value?: FieldValue) => {
        const newSearchText = value as O<string>
        setSearchText(newSearchText)
        search(newSearchText, status, creationDateFrom, creationDateTo)
    }

    const onChangeStatus = (value?: FieldValue) => {
        const newStatus = value as O<PaymentOrderStatus>
        if (newStatus !== status) {
            setStatus(newStatus)
            search(searchText, newStatus, creationDateFrom, creationDateTo)
        }
    }

    const onChangeCreationDateFrom = (value?: FieldValue) => {
        const newCreationDateFrom = (value as O<Date>)
        newCreationDateFrom?.setHours(11, 59, 59)
        if (newCreationDateFrom !== creationDateFrom) {
            setCreationPaymentDateFrom(newCreationDateFrom)
            search(searchText, status, newCreationDateFrom, creationDateTo)
        }
    }
    
    const onChangeCreationDateTo = (value?: FieldValue) => {
        const newCreationDateTo = value as O<Date>
        newCreationDateTo?.setHours(11, 59, 59)
        if (newCreationDateTo !== creationDateTo) {
            setCreationPaymentDateTo(newCreationDateTo)
            search(searchText, status, creationDateFrom, newCreationDateTo)
        }
    }

    const searchField: FilterField = {
        name: 'search',
        type: FieldType.TEXT,
        placeholder: 'Buscar por código',
        icon: 'search',
        onChange: handleSearch
    }

    const filterFields: FilterField[] = [
        {
            name: 'status',
            type: FieldType.SELECT,
            options: Object.values(PaymentOrderStatus).map(status => option(status, Labels.paymentOrderStatusLabel(status))),
            label: 'Estado',
            space: 3,
            onChange: onChangeStatus
        },
        {
            name: 'creationDateFrom',
            type: FieldType.DATE,
            label: 'Fecha: Desde',
            space: 3,
            onChange: onChangeCreationDateFrom
        },
        {
            name: 'creationDateTo',
            type: FieldType.DATE,
            label: 'Fecha: Hasta',
            space: 3,
            onChange: onChangeCreationDateTo
        }
    ]

    return (
        <Filters
            searchField={searchField}
            fields={filterFields}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
