import { useEffect, useState } from 'react'
import { DataType } from '../../../../../tables'
import { Expense } from '../../../state/types'
import { useAdministrationHooks, useAdministrationState } from '../../../hooks/administrationHooks'
import { FieldType, FieldValue, FilterField, Filters, O, option } from '../../../../../features/ui'
import { usePurchasesState } from '../../../../purchases'
import { useManagerState } from '../../../../manager'
import { define } from '../../../../../utils/typeUtils'
import { Labels } from '../../../../../features/data-types'
import { PaymentStatus } from '../../../../operations'
import { useExpenseHooks } from '../../../hooks/expenseHooks'

type Props = {
    expenses: Expense[]
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const ExpenseFilters = (props: Props) => {
    const { expenses, onSearchStart, onSearchDone } = props

    const administrationState = useAdministrationState()
    const expenseTypes = administrationState.expenseTypes

    const [searchText, setSearchText] = useState<string>()
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>()
    const [expenseTypeId, setExpenseTypeId] = useState<string>()
    const [supplierId, setSupplierId] = useState<string>()
    const [dateFrom, setDateFrom] = useState<Date>()
    const [dateTo, setDateTo] = useState<Date>()

    const { suppliers } = usePurchasesState()
    const { currentBranch } = useManagerState()
    const currentBranchId = define(currentBranch).id

    const { getSalaryExpenseTypeId } = useAdministrationHooks()
    const { searchExpenses } = useExpenseHooks()

    const isNonSalaryExpense = (expense: Expense) => expense.expenseTypeId !== getSalaryExpenseTypeId()

    useEffect(() => {
        search(searchText, paymentStatus, expenseTypeId, supplierId, dateFrom, dateTo)
    }, [expenses])

    const search = (
        searchTextParam?: string,
        paymentStatusParam?: string,
        expenseTypeIdParam?: string,
        supplierIdParam?: string,
        dateFromParam?: Date,
        dateToParam?: Date
    ) => {
        onSearchStart()
        let expenseList = expenses.filter(expense => expense.branchId === currentBranchId).filter(isNonSalaryExpense)
        expenseList = expenseList.filter(expense => !paymentStatusParam || expense.paymentStatus === paymentStatusParam)
        expenseList = expenseList.filter(expense => !expenseTypeIdParam || expense.expenseTypeId === expenseTypeIdParam)
        expenseList = expenseList.filter(expense => !supplierIdParam || expense.supplierId === supplierIdParam)
        expenseList = dateFromParam ? expenseList.filter(expense => new Date(expense.date) >= dateFromParam) : expenseList
        expenseList = dateToParam ? expenseList.filter(expense => new Date(expense.date) <= dateToParam) : expenseList
        expenseList = searchExpenses(expenseList, searchTextParam)
        setTimeout(() => onSearchDone(expenseList), 100)
    }

    const handleSearch = (value?: FieldValue) => {
        const newSearchText = value as O<string>
        setSearchText(newSearchText)
        search(newSearchText, paymentStatus, expenseTypeId, supplierId, dateFrom, dateTo)
    }

    const onChangePaymentStatus = (value?: FieldValue) => {
        const newPaymentStatus = value as O<PaymentStatus>
        if (newPaymentStatus !== paymentStatus) {
            setPaymentStatus(newPaymentStatus)
            search(searchText, newPaymentStatus, expenseTypeId, supplierId, dateFrom, dateTo)
        }
    }

    const onChangeExpenseType = (value?: FieldValue) => {
        const newExpenseTypeId = value as O<string>
        if (newExpenseTypeId !== expenseTypeId) {
            setExpenseTypeId(newExpenseTypeId)
            search(searchText, paymentStatus, newExpenseTypeId, supplierId, dateFrom, dateTo)
        }
    }

    const onChangeSupplier = (value?: FieldValue) => {
        const newSupplierId = value as O<string>
        if (newSupplierId !== supplierId) {
            setSupplierId(newSupplierId)
            search(searchText, paymentStatus, expenseTypeId, newSupplierId, dateFrom, dateTo)
        }
    }

    const onChangeDateFrom = (value?: FieldValue) => {
        const newDateFrom = value as O<Date>
        newDateFrom?.setHours(11, 59, 59)
        if (newDateFrom !== dateFrom) {
            setDateFrom(newDateFrom)
            search(searchText, paymentStatus, expenseTypeId, supplierId, newDateFrom, dateTo)
        }
    }
    
    const onChangeDateTo = (value?: FieldValue) => {
        const newDateTo = value as O<Date>
        newDateTo?.setHours(11, 59, 59)
        if (newDateTo !== dateTo) {
            setDateTo(newDateTo)
            search(searchText, paymentStatus, expenseTypeId, supplierId, dateFrom, newDateTo)
        }
    }

    const searchField: FilterField = {
        name: 'search',
        type: FieldType.TEXT,
        placeholder: 'Buscar por código o nombre',
        icon: 'search',
        onChange: handleSearch
    }

    const supplierOptions = suppliers
        .filter(supplier => !supplier.productSupplier)
        .map(supplier => option(supplier.id, supplier.name))

    const filterFields: FilterField[] = [
        {
            name: 'paymentStatus',
            type: FieldType.SELECT,
            options: Object.values(PaymentStatus).map(status => option(status, Labels.paymentStatusLabel(status))),
            label: 'Estado',
            space: 3,
            onChange: onChangePaymentStatus
        },
        {
            name: 'expenseType',
            type: FieldType.SELECT,
            options: expenseTypes.map(type => option(type.id, type.name)),
            label: 'Tipo',
            space: 3,
            onChange: onChangeExpenseType
        },
        {
            name: 'supplier',
            type: FieldType.SELECT,
            options: supplierOptions,
            label: 'Proveedor',
            space: 3,
            onChange: onChangeSupplier
        },
        {
            name: 'dateFrom',
            type: FieldType.DATE,
            label: 'Fecha: Desde',
            space: 2,
            onChange: onChangeDateFrom
        },
        {
            name: 'dateTo',
            type: FieldType.DATE,
            label: 'Fecha: Hasta',
            space: 2,
            onChange: onChangeDateTo
        }
    ]

    return (
        <Filters
            searchField={searchField}
            fields={filterFields}
            firstRowLength={3}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
