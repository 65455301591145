import { ModalId, useUIActions } from "../../../../../features/ui"
import { useSalesActions } from "../../../actions/salesActions"
import { Budget } from "../../../state/types"
import { Data } from "../../../../../features/ui"
import { define } from "../../../../../utils/typeUtils"
import { Currency } from "../../../../../tables/types/types"
import { useNavigate } from "react-router-dom"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { Emitter, Event } from "../../../../../features/event-emitter"

export const useActions = () => {
    const navigate = useNavigate()
    const { getRoutePath } = useRoutesHooks()

    const salesActions = useSalesActions()
    const { toggleModal } = useUIActions()
    
    const submitBudget = async (
        budgetData: Data,
        code: number,
        sellerId: string,
        customerId: string,
        budgetItemsData: Data[],
        createMode: boolean
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const date = define(budgetData.date) as Date
        const budget: Budget = {
            companyId: budgetData.companyId as string,
            id: budgetData.id as string,
            code,
            date: date.toISOString(),
            sellerId,
            customerId,
            currency: Currency.ARS
        }
        
        const savedBudget = await salesActions().saveBudgetWithItems(budget, budgetItemsData)

        Emitter.emit(Event.DB_SUBMIT_DONE, createMode, false)
        navigate(getRoutePath(RoutePath.BUDGETS, savedBudget.id))
        setTimeout(() => toggleModal(ModalId.DOWNLOAD_BUDGET_PDF), 500)
    }
    
    return {
        submitBudget
    }
}
