import { Form, Data, FormField, ModalId, FieldType, FieldValue, useUIActions, option, O } from "../../../../../features/ui"
import { CashFund, Check, CreditCard, PaymentCategory, PaymentType } from '../../../state/types'
import { TableName } from "../../../../../tables"
import { useState } from "react"
import { useAdministrationState } from "../../../hooks/administrationHooks"
import { define, isDefined } from "../../../../../utils/typeUtils"
import { useDescriptorHooks } from "../../../../../features/data-types"
import { firstItem } from "../../../../../utils/listUtils"

export type Props = {
    paymentItem?: Data
    pendingAmount: number
    cashFunds: CashFund[]
    creditCards: CreditCard[]
    checks: Check[]
    filterPaymentTypes?: (paymentType: PaymentType) => boolean
    onSubmit: (data: Data) => void
}

export const PaymentItemForm = (props: Props) => {
    const { paymentItem, pendingAmount, filterPaymentTypes, cashFunds, creditCards,  checks, onSubmit } = props
    const createMode = !paymentItem

    const { paymentTypes } = useAdministrationState()
    const paymentTypeList = filterPaymentTypes ? paymentTypes.filter(filterPaymentTypes) : paymentTypes

    const findPaymentType = (id?: string) => paymentTypeList.find(paymentType => paymentType.id === id)
    const findCashFund = (id?: string) => cashFunds.find(cashFund => cashFund.id === id)
    const findCheck = (id?: string) => checks.find(check => check.id === id)
    const minimum = (n1: number, n2?: number) => isDefined(n2) ? Math.min(n1, define(n2)) : n1

    const defaultPaymentTypeId = (paymentItem?.paymentTypeId || firstItem(paymentTypeList)?.id) as O<string>
    const deafultPaymentType = findPaymentType(defaultPaymentTypeId)
    const defaultAmount = (paymentItem?.amount || pendingAmount) as O<number>
    const defaultCashFund = deafultPaymentType?.category === PaymentCategory.CASH_FUND ? findCashFund(paymentItem?.sourceId as O<string>) : undefined
    const defaultCheck = deafultPaymentType?.category === PaymentCategory.CHECK ? findCheck(paymentItem?.sourceId as O<string>) : undefined
    const [paymentCategory, setPaymentCategory] = useState<O<PaymentCategory>>(deafultPaymentType?.category)
    const [amount, setAmount] = useState<O<number>>(defaultAmount)
    const [maxAmount, setMaxAmount] = useState<O<number>>(defaultCashFund?.availableAmount || defaultCheck?.amount)

    const descriptorHooks = useDescriptorHooks()

    const { toggleModal } = useUIActions()

    const paymentTypeOptions = paymentTypeList.map(paymentType => option(paymentType.id, paymentType.name))
    const cashFundOptions = cashFunds.map(cashFund => option(cashFund.id, cashFund.name))
    const checkOptions = checks.map(check => option(check.id, descriptorHooks.check.info(check)))
    const creditCardOptions = creditCards.map(creditCard => option(creditCard.id, descriptorHooks.creditCard.info(creditCard)))

    const onChangePaymentTypeId = (value?: FieldValue) => {
        const paymentType = findPaymentType(value as O<string>)
        setPaymentCategory(paymentType?.category)
    }

    const onChangeAmount = (value?: FieldValue) => {
        setAmount(value as O<number>)
    }

    const onChangeCashFund = (value?: FieldValue) => {
        const cashFund = findCashFund(value as O<string>)
        setAmount(minimum(pendingAmount, cashFund?.availableAmount))
        setMaxAmount(cashFund?.availableAmount)
    }

    const onChangeCheck = (value?: FieldValue) => {
        const check = findCheck(value as O<string>)
        const newMaxAmount = minimum(pendingAmount, check?.amount)
        setAmount(newMaxAmount)
        setMaxAmount(check?.amount)
    }

    const onChangeCreditCard = () => {
        setAmount(pendingAmount)
        setMaxAmount(undefined)
    }

    const fields: FormField[] = [
        {
            name: 'paymentTypeId',
            type: FieldType.TABLE,
            table: TableName.PAYMENT_TYPES,
            label: 'Medio de Pago',
            defaultValue: defaultPaymentTypeId,
            options: paymentTypeOptions,
            onChange: onChangePaymentTypeId
        },
        {
            name: 'amount',
            type: FieldType.PRICE,
            label: 'Monto',
            value: amount,
            min: 0.01,
            max: maxAmount || undefined,
            disabled: paymentCategory === PaymentCategory.CHECK,
            onChange: onChangeAmount
        }
    ]
    if (paymentCategory === PaymentCategory.CASH_FUND) {
        const sourceField = {
            name: 'cashFundId',
            type: FieldType.TABLE,
            table: TableName.CASH_FUNDS,
            label: 'Caja',
            defaultValue: paymentItem?.sourceId,
            options: cashFundOptions,
            onChange: onChangeCashFund
        }
        fields.splice(1, 0, sourceField)
    } else if (paymentCategory === PaymentCategory.CHECK) {
        const sourceField = {
            name: 'checkId',
            type: FieldType.TABLE,
            table: TableName.CHECKS,
            label: 'Cheque',
            defaultValue: paymentItem?.sourceId,
            options: checkOptions,
            onChange: onChangeCheck
        }
        fields.splice(1, 0, sourceField)
    } else if (paymentCategory === PaymentCategory.CREDIT_CARD) {
        const sourceField = {
            name: 'creditCardId',
            type: FieldType.TABLE,
            table: TableName.CREDIT_CARDS,
            label: 'Tarjeta',
            defaultValue: paymentItem?.sourceId,
            options: creditCardOptions,
            onChange: onChangeCreditCard
        }
        fields.splice(1, 0, sourceField)
    }
    
    const handleSubmit = (data: Data) => {
        const sourceId = paymentCategory === PaymentCategory.CASH_FUND ? data.cashFundId : (paymentCategory === PaymentCategory.CHECK ? data.checkId : data.creditCardId)
        onSubmit({
            paymentTypeId: data.paymentTypeId,
            paymentCategory,
            sourceId,
            amount
        })
        toggleModal(ModalId.PAYMENT_ITEM_FORM)
    }

    const handleCancel = () => toggleModal(ModalId.PAYMENT_ITEM_FORM)

    return (
        <Form
            formId="payment-item-form"
            fields={fields}
            createMode={createMode}
            createLabel="Agregar"
            onSubmit={handleSubmit}
            onCancel={handleCancel}
        />
    )
}
