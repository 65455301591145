import { useStateHooks } from '../../../state'
import { TableName } from '../../../tables'
import { useLoaders } from '../../common'
import { UnitType } from '../state/types'

export const useUnitTypeLoaders = () => {
    const { getList } = useStateHooks()

    const loaders = useLoaders()

    return () => {
        const findAllUnitTypes = () => getList(TableName.UNIT_TYPES) as UnitType[]

        const loadAllUnitTypes = () => {
            const { loadAll } = loaders()
            return loadAll(TableName.UNIT_TYPES) as Promise<UnitType[]>
        }

        return {
            findAllUnitTypes,
            loadAllUnitTypes
        }
    }
}
