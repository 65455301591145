import { DataTypeList, Labels } from "../../../../../features/data-types"
import { Action, Column, Data, FieldType, FieldValue, ModalId, SpinnerDiv, UIPermission, useUIActions, Badge, O } from "../../../../../features/ui"
import { useSystemStateHooks } from "../../../../system"
import { define } from "../../../../../utils/typeUtils"
import { CashFund, CashFundType } from "../../../state/types"
import { DataType, TableName } from "../../../../../tables"
import { CashFundFilters } from "./cashFundFilters"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "../../../../../features/routes"
import { useStateHooks } from "../../../../../state"

type Props = {
    cashFunds: CashFund[]
}

export const CashFundList = (props: Props) => {
    const { cashFunds } = props

    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [currentCashFund, setCurrentCashFund] = useState<CashFund>()

    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_CASH_FUNDS)
    const { find } = useStateHooks()
    const navigate = useNavigate()

    const { toggleModal } = useUIActions()

    const columns: Column[] = [
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre'
        },
        {
            name: 'type',
            type: FieldType.TEXT,
            label: 'Tipo',
            render: (value?: FieldValue) => <Badge label={Labels.cashFundTypeLabel(define(value) as CashFundType)} />
        },
        {
            name: 'availableAmount',
            type: FieldType.PRICE,
            label: 'Disponible'
        },
        {
            name: 'reservedAmount',
            type: FieldType.PRICE,
            label: 'Reservado'
        }
    ]

    const onAddAmount = (id?: string) => id && navigate(`${id}/${RoutePath.ADD}`)
    
    const onSubstractAmount = (id?: string) => id && navigate(`${id}/${RoutePath.SUBSTRACT}`)

    const onTransfer = (id?: string) => id && navigate(`${id}/${RoutePath.TRANSFER}`)

    const onEdit = (id?: string) => id && navigate(`${id}/${RoutePath.EDIT}`)

    const onRemove = (id?: string) => {
        setCurrentCashFund(find(TableName.CASH_FUNDS, id) as O<CashFund>)
        toggleModal(ModalId.REMOVE)
    }

    const actions = (): Action[] => {
        const actionList: Action[] = []
        if (!readOnly) {
            actionList.push(
                { icon: 'plus-circle-fill', tooltip: 'Agregar Dinero', onClick: onAddAmount },
                { icon: 'dash-circle-fill', kind: 'secondary', tooltip: 'Retirar Dinero', onClick: onSubstractAmount },
                { icon: 'arrow-left-right', tooltip: 'Transferir Dinero', onClick: onTransfer },
                { icon: 'pencil-fill', tooltip: 'Editar', onClick: onEdit },
                { icon: 'trash-fill', kind: 'secondary', tooltip: 'Eliminar', onClick: onRemove }
            )
        }

        return actionList
    }

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRows(dataTypes as CashFund[])
        setAsyncDataLoaded(true)
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <>
            <CashFundFilters
                cashFunds={cashFunds}
                onSearchStart={onSearchStart}
                onSearchDone={onSearchDone}
            />
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    currentDataTypeParam={currentCashFund}
                    actions={actions}
                    readOnly={readOnly}
                />
            )}
        </>
    )
}
