import { useState } from "react"
import { EditableFormField, EditablePanel, FieldType, FieldValue, O, TabsPanel, UIPermission } from "../../../../../features/ui"
import { useSystemStateHooks } from "../../../../system"
import { usePurchasesHooks } from "../../../hooks/purchasesHooks"
import { useActions } from "./actions"
import { define } from "../../../../../utils/typeUtils"

export const PurchasesSettingsPanel = () => {
    const { getSettings } = usePurchasesHooks()
    const settings = getSettings()
    const purchaseDocFooter = settings?.purchaseDocFooter as O<string>
    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_PURCHASES_SETTINGS)

    const [docFooterState, setDocFooterState] = useState<string>(purchaseDocFooter || '')

    const { updatePurchaseDocFooter } = useActions()

    const onSaveDocFooter = (value?: FieldValue) => {
        const newDocFooter = define(value) as string
        if (newDocFooter !== docFooterState) {
            setDocFooterState(newDocFooter)
            updatePurchaseDocFooter(newDocFooter)
        }
    }

    const fields: EditableFormField[] = [
        {
            name: 'purchaseDocFooter',
            type: FieldType.LONG_TEXT,
            defaultValue: docFooterState,
            label: 'Pie de Página',
            hint: 'El texto se mostrará en el pie de página del documento de orden de compra',
            onSave: onSaveDocFooter,
        }
    ]

    const tabs = [
        {
            id: 'purchase',
            title: 'Orden de Compra',
            content: <EditablePanel
                fields={fields}
                readOnly={readOnly}
            />
        }
    ]

    return (
        <TabsPanel
            title="Configuración"
            tabs={tabs}
            wide={false}
        />
    )
}
