import { useEffect, useState } from "react"
import { O, PanelLoader, useGetCrumbs } from "../../../../../features/ui"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { CashFund } from "../../../state/types"
import { CashFundForm } from "./cashFundForm"
import { useCashFundLoaders } from "../../../loaders/cashFundLoaders"

export const CashFundFormLoader = () => {  
  const { dataType } = useRoutesHooks().parseUrl()
  const cashFund = dataType as O<CashFund>
  const createMode = !cashFund

  const [ready, setReady] = useState(false)

  const cashFundLoaders = useCashFundLoaders()

  const cashFundLabel = cashFund ? `: ${cashFund.name}` : ''
  const title = `${createMode ? 'Crear' : 'Editar'} Caja${cashFundLabel}`

  const populate = async () => {
    const { loadCashFund } = cashFundLoaders()
    cashFund && await loadCashFund(cashFund.id, true)
  }

  useEffect(() => {
    populate().then(() => setReady(true))
  }, [])

  const crumbs = useGetCrumbs('Cajas', RoutePath.CASH_FUNDS, cashFund?.name, undefined, createMode ? 'Crear' : 'Editar')

  return (
    <PanelLoader title={title} crumbs={crumbs} ready={ready}>
      <CashFundForm />
    </PanelLoader>
  )
}
