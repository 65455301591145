import { useState } from "react"
import { PanelWrapper, FieldType, UIPermission, Action, Column, FieldValue, SpinnerDiv, Data, ModalId, useUIActions, ButtonKind } from "../../../../../features/ui"
import { DataType, TableName } from "../../../../../tables"
import { useSystemStateHooks } from "../../../../system"
import { SaleOrder } from "../../../state/types"
import { useFind } from "../../../../../state/reducers/hooks"
import { define, toNumber } from "../../../../../utils/typeUtils"
import { DataTypeList, Labels } from "../../../../../features/data-types"
import { DeliveryStatus, PaymentStatus } from "../../../../operations"
import { SaleFilters } from "./saleFilters"
import { RoutePath } from "../../../../../features/routes"
import { useNavigate } from "react-router-dom"
import { InvoiceStatus } from "../../../../administration"
import { DownloadSaleFilesModal } from "../../download-sale-files-modal/downloadSaleFilesModal"

export const SaleList = () => {
    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [currentSale, setCurrentSale] = useState<SaleOrder>()

    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_SALES)
    const hasInvoicePermission = userHasPermission(UIPermission.INVOICE_SALES)
    const navigate = useNavigate()
    const find = useFind()

    const { openModal } = useUIActions()

    const findSale = (code?: string) => define(find(TableName.SALE_ORDERS, toNumber(code), 'code') as SaleOrder)

    const setRowsState = (sales: SaleOrder[]) => {
        setRows(sales)
        setAsyncDataLoaded(true)
    }

    const columns: Column[] = [
        { name: 'code', type: FieldType.NUMBER, label: '#', render: (value?: FieldValue) => `#${value}` },
        { name: 'creationDate', type: FieldType.DATE, label: 'Fecha de Creación' },
        { name: 'deliveryDate', type: FieldType.DATE, label: 'Fecha de Entrega' },
        { name: 'sellerId', type: FieldType.TABLE, table: TableName.EMPLOYEES, label: 'Vendedor' },
        { name: 'customerId', type: FieldType.TABLE, table: TableName.CUSTOMERS, label: 'Cliente' },
        {
            name: 'deliveryStatus',
            type: FieldType.TEXT,
            label: 'Estado de Entrega',
            render: (value?: FieldValue) => Labels.deliveryStatusLabel(define(value) as DeliveryStatus)
        },
        {
            name: 'paymentStatus',
            type: FieldType.TEXT,
            label: 'Estado de Pago',
            render: (value?: FieldValue) => Labels.paymentStatusLabel(define(value) as PaymentStatus)
        },
        {
            name: 'invoiceStatus',
            type: FieldType.TEXT,
            label: 'Facturación',
            render: (value?: FieldValue) => Labels.invoiceStatusLabel(define(value) as InvoiceStatus)
        }
    ]

    const onView = (code?: string) => {
        const sale = findSale(code)
        if (sale) {
            navigate(sale.id)
        }
    }

    const onDownloadFiles = (code?: string) => {
        setCurrentSale(findSale(code))
        openModal(ModalId.DOWNLOAD_FILES)
    }

    const onEdit = (code?: string) => {
        const sale = findSale(code)
        navigate(`${sale.id}/${RoutePath.EDIT}`)
    }

    const onArchive = (code?: string) => {
        findSale(code)
    }

    const onConfirmPayment = (code?: string) => {
        const sale = findSale(code)
        if (sale) {
            navigate(sale.id)
            setTimeout(() => openModal(ModalId.CONFIRM_SALE_PAYMENT_FORM), 500)
        }
    }

    const onInvoice = (code?: string) => {
        const sale = findSale(code)
        if (sale) {
            navigate(sale.id)
            setTimeout(() => openModal(ModalId.INVOICE), 500)
        }
    }

    const actions = (code?: string) => {
        const actionList: Action[] = [
            { icon: 'eye-fill', kind: 'tertiary', tooltip: 'Ver Detalle', onClick: onView },
            { icon: 'download', kind: 'tertiary', tooltip: 'Descargar Documentos', narrowTooltip: true, onClick: onDownloadFiles }
        ]

        const sale = findSale(code)
        const isInvoiced = sale.invoiceStatus === InvoiceStatus.INVOICED
        const isInvoiceable = sale.invoiceStatus === InvoiceStatus.UNINVOICED
        const isEditable = sale.deliveryStatus === DeliveryStatus.PENDING && sale.paymentStatus === PaymentStatus.PENDING && isInvoiceable
        const isDelivered = sale.deliveryStatus === DeliveryStatus.DELIVERED
        const isPayed = sale.paymentStatus === PaymentStatus.PAYED

        if (!readOnly) {
            if (isEditable) {
                actionList.push({ icon: 'pencil-fill', tooltip: 'Editar', onClick: onEdit })
            } else if (isDelivered && isPayed && isInvoiced) {
                actionList.push({ icon: 'archive', kind: 'secondary' as ButtonKind, tooltip: 'Archivar', onClick: onArchive })
            }
            if (!isPayed) {
                actionList.push({ icon: 'check-lg', tooltip: 'Confirmar Pago', onClick: onConfirmPayment })
            }
        }
        if (hasInvoicePermission && isInvoiceable) {
            actionList.push({ icon: 'file-earmark-check', tooltip: 'Facturar', kind: 'quinary', onClick: onInvoice })
        }

        return actionList
    }

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRowsState(dataTypes as SaleOrder[])
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <PanelWrapper title="Órdenes de Venta" wide>
            <SaleFilters onSearchStart={onSearchStart} onSearchDone={onSearchDone}/>
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    rowId="code"
                    currentDataTypeParam={currentSale}
                    actions={actions}
                    readOnly={readOnly}
                />
            )}
            <DownloadSaleFilesModal
                table={TableName.SALE_ORDERS}
                operation={currentSale}
            />
        </PanelWrapper>
    )
}
