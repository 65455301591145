import { useDefaultModuleActions } from "../../common"
import { useChildrenActions, SaveChildrenFn, newId, RemoveChildrenFn, useDataTypeFileActions } from "../../../features/data-types"
import { Data, O } from "../../../features/ui"
import { DynamoUserApi } from "../../../services"
import { DataType, TableName } from "../../../tables"
import { Currency } from "../../../tables/types/types"
import { define } from "../../../utils/typeUtils"
import { OrderDelivery, DeliveryStatus, useOperationsActions, useOperationsHooks, PaymentStatus } from "../../operations"
import { useStockActions } from "../../stock"
import { useSystemStateHooks } from "../../system"
import { SaleOrder, SaleItem } from "../state/types"
import { useBudgetActions } from "./budgetActions"
import { useCustomerActions } from "./customerActions"
import { useHoardActions } from "./hoard-actions/hoardActions"
import { Settings, SettingsDataType, SettingsModule, useAdministrationActions, useSettingsLoaders } from "../../administration"
import { useCashFundActions } from "../../administration"

export const useSalesActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId
    const { getPendingQuantity } = useOperationsHooks()

    const settingsLoaders = useSettingsLoaders()

    const defaultModuleActions = useDefaultModuleActions()
    const administrationActions = useAdministrationActions()
    const budgetActions = useBudgetActions()
    const hoardActions = useHoardActions()
    const operationsActions = useOperationsActions()
    const customerActions = useCustomerActions()
    const stockActions = useStockActions()
    const { addToCashFund } = useCashFundActions()
    const { submitChildren } = useChildrenActions()
    const dataTypeFileActions = useDataTypeFileActions()

    return (paramCompanyId?: string) => {
        const companyId = define(paramCompanyId || stateCompanyId)
        const { fetchByParams, fetchMultipleByParams, fetchByIds, count, save, saveMultiple, remove, removeMultiple } = defaultModuleActions(DynamoUserApi, companyId)
        const { fetchAllBudgets, countBudgets, saveBudgetWithItems, removeBudget, fetchBudget, fetchBudgetItemsByOrder, countBudgetItems } = budgetActions(companyId)
        const { fetchAllHoards, fetchHoard, countHoards, saveHoard, saveHoardWithItems, saveHoardWithFile, removeHoard, fetchHoardItemsByOrder, countHoardItems, saveHoardDelivery, confirmHoardPayment, closeHoard } = hoardActions(companyId)
        const { saveCustomerType, removeCustomerType, fetchAllCustomers, fetchCustomer, countCustomers, saveCustomer, saveCustomers, removeCustomer } = customerActions(companyId)
        const { fetchDeliveries, saveDelivery, removeDeliveries } = operationsActions(companyId)
        const { saveSettings } = administrationActions(companyId)

        const saveSalesSetting = async (propName: string, value: SettingsDataType) => {
            const { findModuleSettings } = settingsLoaders(companyId)
            const settings = findModuleSettings(SettingsModule.SALES)
            const updatedSettings: Settings = settings ? {
                ...settings,
                [propName]: value
            } : {
                companyId: define(companyId),
                id: newId(),
                module: SettingsModule.SALES,
                [propName]: value
            }
            
            return await saveSettings(updatedSettings) as Settings
        }

        const fetchAllSales = (branchId: string) => {
            return fetchMultipleByParams(TableName.SALE_ORDERS, { branchId })
        }

        const fetchSale = (id: string) => {
            return fetchByParams(TableName.SALE_ORDERS, { id })
        }

        const fetchSales = (ids: string[]) => {
            return fetchByIds(TableName.SALE_ORDERS, ids)
        }

        const countSales = (propName: string, propValue: string) => {
            return count(TableName.SALE_ORDERS, { [propName]: propValue })
        }

        const saveSale = (sale: SaleOrder) => {
            return save(TableName.SALE_ORDERS, sale)
        }

        const saveSaleWithItems = async (
            sale: SaleOrder,
            saleItemsData: Data[],
            modifyStock = true
        ) => {
            const savedSale = await saveSale(sale)

            const saleItems = saleItemsData.map(saleItemData => ({
                ...saleItemData,
                companyId: define(companyId),
                id: newId(),
                saleId: sale.id,
                currency: Currency.ARS
            })) as SaleItem[]
            
            const stateSaleItems = await fetchSaleItemsByOrder(sale.id) as SaleItem[]
            const saveItems: SaveChildrenFn = (children: DataType[]) => saveSaleItems(children as SaleItem[], sale.branchId, modifyStock)
            await submitChildren(stateSaleItems, saleItems, saveItems, removeSaleItems as RemoveChildrenFn)
            return savedSale
        }

        const saveSaleWithFile = async (
            sale: SaleOrder,
            file: File,
            fileProp = 'invoiceUrl'
        ) => {
            const { saveWithFile } = dataTypeFileActions(define(companyId))
            return saveWithFile(TableName.SALE_ORDERS, sale, fileProp as keyof DataType, file)
        }

        const removeSale = async (id: string) => {
            const stateSale = define(await fetchSale(id)) as SaleOrder
            
            if (stateSale.deliveryStatus !== DeliveryStatus.DELIVERED) {
                await remove(TableName.SALE_ORDERS, id)
                
                const saleItems = await fetchSaleItemsByOrder(id) as SaleItem[]
                await removeSaleItems(saleItems)

                const deliveries = await fetchDeliveries(id) as OrderDelivery[]
                const deliveryIds = deliveries.map(delivery => delivery.id)
                
                return removeDeliveries(deliveryIds)
            }
        }

        const fetchSaleItemsByOrder = (saleId: string) => {
            return fetchMultipleByParams(TableName.SALE_ITEMS, { saleId })
        }

        const countSaleItems = (propName: string, propValue: string) => {
            return count(TableName.SALE_ITEMS, { [propName]: propValue })
        }

        const saveSaleItems = async (
            saleItems: SaleItem[],
            branchId: string,
            modifyStock: boolean
        ) => {
            if (modifyStock) {
                const { reserveStock } = stockActions()
                saleItems.forEach(async saleItem => {
                    const { productId, lotId, quantity } = saleItem
                    await reserveStock(productId, branchId, quantity, lotId)  
                })
            }

            const savedSaleItems = await saveMultiple(TableName.SALE_ITEMS, saleItems) as SaleItem[]
            return savedSaleItems
        }

        const removeSaleItems = (saleItems: SaleItem[]) => {
            return removeMultiple(TableName.SALE_ITEMS, saleItems.map(saleItem => saleItem.id))
        }

        const saveSaleDelivery = async (
            sale: SaleOrder,
            saleItems: SaleItem[],
            delivery: OrderDelivery
        ) => {
            const { decreaseReservedStock } = stockActions()
            
            const deliveries = await fetchDeliveries(sale.id) as OrderDelivery[]
            const savedDelivery = await saveDelivery(delivery) as OrderDelivery

            let pendingItemsCount = 0
            savedDelivery.deliveredItems.forEach(deliveredItem => {
                const deliveredSaleItem = saleItems.find(saleItem => saleItem.id === deliveredItem.itemId)
                const pendingQuantity = getPendingQuantity(define(deliveredSaleItem) as SaleItem, deliveries)
                deliveredItem.delivered < pendingQuantity && pendingItemsCount++
            })
            const updatedSale: SaleOrder = {
                ...sale,
                branchId: savedDelivery.branchId,
                deliveryStatus: pendingItemsCount === 0 ? DeliveryStatus.DELIVERED : DeliveryStatus.PARTIALLY_DELIVERED
            }

            const savedSale = await saveSale(updatedSale) as SaleOrder

            savedDelivery.deliveredItems.forEach(async deliveredItem => {
                const saleItem = define(saleItems.find(saleItem => saleItem.id === deliveredItem.itemId))
                await decreaseReservedStock(saleItem.productId, savedDelivery.branchId, deliveredItem.delivered, saleItem.lotId)
            })

            return savedSale
        }

        const confirmSalePayment = async (
            sale: SaleOrder,
            receiptFile: File,
            paymentTypeId: string,
            cashFundId: string,
            totalAmount: number
        ) => {
            const payedSale: SaleOrder = {
                ...sale,
                paymentTypeId,
                paymentStatus: PaymentStatus.PAYED
            }
            const savedSale = await saveSaleWithFile(payedSale, receiptFile, 'receiptUrl')
            await addToCashFund(cashFundId, totalAmount)

            return savedSale
        }

        return {
            saveSalesSetting,
            fetchAllSales,
            fetchSale,
            fetchSales,
            countSales,
            saveSale,
            saveSaleWithItems,
            saveSaleWithFile,
            removeSale,
            fetchSaleItemsByOrder,
            countSaleItems,
            saveSaleDelivery,
            confirmSalePayment,
            fetchAllBudgets,
            countBudgets,
            fetchBudget,
            fetchBudgetItemsByOrder,
            saveBudgetWithItems,
            removeBudget,
            countBudgetItems,
            fetchAllHoards,
            fetchHoard,
            countHoards,
            saveHoard,
            saveHoardWithItems,
            saveHoardWithFile,
            removeHoard,
            fetchHoardItemsByOrder,
            countHoardItems,
            saveHoardDelivery,
            confirmHoardPayment,
            closeHoard,
            saveCustomerType,
            removeCustomerType,
            fetchAllCustomers,
            fetchCustomer,
            countCustomers,
            saveCustomer,
            saveCustomers,
            removeCustomer
        }
    }
}
