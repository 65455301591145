import { useEffect, useState } from "react"
import { useDescriptorHooks } from "../../../../../features/data-types"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { PanelLoader, useGetCrumbs } from "../../../../../features/ui"
import { define } from "../../../../../utils/typeUtils"
import { HoardItem, HoardOrder } from "../../../state/types"
import { useSalesActions } from "../../../actions/salesActions"
import { HoardForm } from "./hoardForm"

export const HoardFormLoader = () => {
    const { dataTypeId } = useRoutesHooks().parseUrl()

    const [ready, setReady] = useState(false)
    const [hoard, setHoard] = useState<HoardOrder>()
    const [hoardItems, setHoardItems] = useState<HoardItem[]>()

    const descriptorHooks = useDescriptorHooks()

    const salesActions = useSalesActions()

    const populate = async () => {
        const { fetchAllCustomers, fetchHoard, fetchHoardItemsByOrder } = salesActions()
        await fetchAllCustomers()

        if (dataTypeId)  {
            const stateHoard = define(await fetchHoard(dataTypeId)) as HoardOrder
            setHoard(stateHoard)
            const stateHoardItems = await fetchHoardItemsByOrder(dataTypeId) as HoardItem[]
            setHoardItems(stateHoardItems)
        }
    }

    useEffect(() => {
        populate().then(() => setReady(true))
    }, [])

    const titleLabel = hoard ? `: ${descriptorHooks.hoard.code(hoard) }` : ''
    const title = `${hoard ? 'Editar' : 'Crear'} Acopio${titleLabel}`
    const crumbs = useGetCrumbs(
        'Acopios',
        RoutePath.HOARDS,
        hoard ? descriptorHooks.hoard.code(hoard) : undefined,
        dataTypeId,
        hoard ? 'Editar' : 'Crear'
    )

    return (
        <PanelLoader
            title={title}
            crumbs={crumbs}
            ready={ready}
            wide
        >
            <HoardForm
                hoard={hoard}
                hoardItems={hoardItems}
            />
        </PanelLoader>
    )
}
