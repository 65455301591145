import styles from './downloadPaymentOrderFilesModal.module.css'
import { useMemo } from 'react'
import { DownloadFilesModal, fileName, fileNameFromUrl, useFileActions, usePDFActions } from '../../../../../features/files'
import { TableName } from '../../../../../tables'
import { define } from '../../../../../utils/typeUtils'
import { PaymentOrder, PaymentOrderStatus } from '../../../state/types'
import { PaymentOrderPDF } from '../payment-order-pdf/paymentOrderPDF'
import { RetentionCertificatePDF } from '../retention-certificate-pdf/retentionCertificatePDF'

type Props = {
    paymentOrder?: PaymentOrder
}

export const DownloadPaymentOrderFilesModal = (props: Props) => {
    const { paymentOrder } = props

    const { generatePDF } = usePDFActions()
    const { downloadFile } = useFileActions()

    const pdfId = 'payment-order-pdf'
    const retentionPdfId = 'retention-certificate-pdf'
    const paymentOrderFileName = useMemo(() => paymentOrder && fileName(TableName.PAYMENT_ORDERS, paymentOrder.code.toString()), [paymentOrder])
    const retentionCertificateFileName = useMemo(() =>
        paymentOrder && fileName(TableName.PAYMENT_ORDERS, paymentOrder.code.toString(), undefined, undefined, 'Certificado-Retencion'),
    [paymentOrder])

    const filesData = []
    if (paymentOrderFileName) {
        filesData.push({
            id: 'operation',
            label: 'Orden de Pago',
            fileName: paymentOrderFileName,
            onDownload: () => generatePDF(pdfId, paymentOrderFileName)
        })
    }
    if (paymentOrder?.receiptUrl) {
        filesData.push({
            id: 'receipt',
            label: 'Comprobante de Pago',
            fileName: define(fileNameFromUrl(paymentOrder.receiptUrl)),
            onDownload: () => downloadFile(define(paymentOrder.receiptUrl))
        })
    }
    if (retentionCertificateFileName && paymentOrder?.status === PaymentOrderStatus.PAYED && paymentOrder.taxItems.length > 0) {
        filesData.push({
            id: 'retention-certificate',
            label: 'Certificado de Retención',
            fileName: retentionCertificateFileName,
            onDownload: () => generatePDF(retentionPdfId, retentionCertificateFileName)
        })
    }

    const renderHiddenPDF = () => paymentOrder ? (
        <div className={styles.hidden}>
            <PaymentOrderPDF
                id={pdfId}
                paymentOrder={paymentOrder}
            />
        </div>
    ) : <></>

    const renderHiddenRetentionCertificatePDF = () => paymentOrder ? (
        <div className={styles.hidden}>
            <RetentionCertificatePDF
                id={retentionPdfId}
                paymentOrder={paymentOrder}
            />
        </div>
    ) : <></>

    return (
        <DownloadFilesModal
            operationId={paymentOrder?.id}
            filesData={filesData}
        >
            {renderHiddenPDF()}
            {renderHiddenRetentionCertificatePDF()}
        </DownloadFilesModal>
    )
}
