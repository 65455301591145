import { useEffect, useState } from "react"
import { PanelLoader } from "../../../../../features/ui"
import { Tax } from "../../../state/types"
import { ListLoaderWrapper } from "../../../../../features/data-types"
import { TableName } from "../../../../../tables"
import { useTaxLoaders } from "../../../loaders/taxLoaders"
import { TaxList } from "./taxList"

const Loader = () => {
    const [taxes, setTaxes] = useState<Tax[]>([])
    const [ready, setReady] = useState(false)

    const taxLoaders = useTaxLoaders()

    const populate = async () => {
        const { loadAllTaxes } = taxLoaders()
        setTaxes(await loadAllTaxes())
    }

    useEffect(() => {
        populate().then(() => setReady(true))
    }, [])

    return (
        <PanelLoader
            title="Impuestos"
            ready={ready}
            wide
        >
            <TaxList taxes={taxes} />
        </PanelLoader>
    )
}

export const TaxListLoader = () => (
    <ListLoaderWrapper table={TableName.TAXES}>
        <Loader />
    </ListLoaderWrapper>
)
