import { useEffect, useState } from "react"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { PanelLoader, useGetCrumbs } from "../../../../../features/ui"
import { PaymentOrderForm } from "./paymentOrderForm"
import { usePurchasesActions } from "../../../../purchases"
import { useNavigate } from "react-router-dom"
import { useAdministrationStateActions } from "../../../hooks/administrationHooks"
import { SelectedTickets } from "../../../state/constants"
import { Check, Ticket } from "../../../state/types"
import { useTicketActions } from "../../../actions/ticketActions"
import { useExpensesActions } from "../../../actions/expensesActions"
import { useTicketHooks } from "../../../hooks/ticketHooks"
import { useCheckLoaders } from "../../../loaders/checkLoaders"

export const PaymentOrderFormLoader = () => {
  const { dataTypeId } = useRoutesHooks().parseUrl()
  const paymentOrderId = dataTypeId

  const [checks, setChecks] = useState<Check[]>([])
  const [ready, setReady] = useState(false)

  const { selectTickets } = useAdministrationStateActions()

  const navigate = useNavigate()
  const { getPurchaseIds, getExpenseIds } = useTicketHooks()
  const { getRoutePath } = useRoutesHooks()

  const checkLoaders = useCheckLoaders()

  const ticketActions = useTicketActions()
  const expensesActions = useExpensesActions()
  const purchasesActions = usePurchasesActions()

  const populate = async () => {
    const { loadAllChecks } = checkLoaders()
    setChecks(await loadAllChecks())

    const { fetchTickets } = ticketActions()
    const { fetchExpenses } = expensesActions()
    const { fetchPurchases } = purchasesActions()
    
    const ticketIds = localStorage.getItem(SelectedTickets)?.split(':') || []
    if (ticketIds.length > 0) {
      const tickets = await fetchTickets(ticketIds) as Ticket[]
      selectTickets(tickets)
      await fetchPurchases(getPurchaseIds(tickets))
      await fetchExpenses(getExpenseIds(tickets))
    } else {
      navigate(getRoutePath(RoutePath.PENDING_PAYMENTS))
    }
  }

  useEffect(() => {
    populate().then(() => setReady(true))
  }, [])

  const title = `${paymentOrderId ? 'Editar' : 'Crear'} Orden de Pago`
  const crumbs = useGetCrumbs(
    'Pagos Pendientes',
    RoutePath.PENDING_PAYMENTS,
    undefined,
    undefined,
    'Crear'
  )

  return (
    <PanelLoader
      title={title}
      crumbs={crumbs}
      ready={ready}
    >
      <PaymentOrderForm checks={checks} />
    </PanelLoader>
  )
}
