export enum ModalId {
    DOWNLOAD_BUDGET_PDF = 'download-budget-pdf-modal',
    DOWNLOAD_DELIVERY_NOTE = 'download-delivery-note-modal',
    DOWNLOAD_FILES = 'download-files-modal',
    DOWNLOAD_HOARD_PDF = 'download-hoard-pdf-modal',
    DOWNLOAD_INVOICE = 'download-invoice-modal',
    DOWNLOAD_PAYMENT_ORDER_PDF = 'download-payment-order-pdf-modal',
    DOWNLOAD_PURCHASE_PDF = 'download-purchase-pdf-modal',
    DOWNLOAD_RECEIPT = 'download-receipt-modal',
    DOWNLOAD_RETENTION_CERTIFICATE_PDF = 'download-retention-certificate-pdf-modal',
    DOWNLOAD_SALE_PDF = 'download-sale-pdf-modal',
    DOWNLOAD_TICKET = 'download-ticket-modal',
    CATEGORY_SALE_YIELD_FORM = 'category-sale-yield-form-modal',
    CLOSE_OPERATION = 'close-operation-modal',
    CONFIRM_PAYMENT_FORM = 'confirm-payment-form-modal',
    CONFIRM_SALE_PAYMENT_FORM = 'confirm-sale-payment-form-modal',
    FINISH_WIZARD = 'finish-wizard-modal',
    INVOICE = 'invoice-modal',
    INVOICE_REQUESTED = 'invoice_requested-modal',
    LOT_DATA_FORM = 'lot-data-form-modal',
    MEASURE_FORM = 'measure-form-modal',
    MESSAGE = 'message-modal',
    NON_HOARDABLE_PRODUCT_FORM = 'non-hoardable-product-form-modal',
    OPEN_WIZARD = 'open-wizard-modal',
    OPERATION_ITEM_FORM = 'operation-item-form-modal',
    PARTIAL_PAYMENT_MODAL = 'partial-payment-modal',
    PAYMENT_ITEM_FORM = 'payment-item-form-modal',
    QUICK_FORM = 'quick-form-modal',
    REMOVE = 'remove-modal',
    REMOVE_CATEGORY_SALE_YIELD = 'remove-category-sale-yield-modal',
    RESOLVE_REQUEST = 'resolve-request-modal',
    SPINNER = 'spinner-modal',
    TAX_ITEM_FORM = 'tax-item-form-modal',
    UNIGNORE_PRODUCT = 'unignore-product-modal',
    UPLOAD_ADDITIONAL_TICKET = 'upload-additional-ticket-modal',
    UPLOAD_DELIVERY_NOTE = 'upload-delivery-note-modal',
    UPLOAD_EXPENSE_TICKET = 'upload-expense-ticket-modal',
    UPLOAD_INVOICE = 'upload-invoice-modal',
    VALIDATION = 'validation-modal'
}
