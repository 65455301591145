import { O } from '../../../features/ui'
import { useStateHooks } from '../../../state'
import { TableName } from '../../../tables'
import { useLoaders } from '../../common'
import { useSystemStateHooks } from '../../system'
import { Branch } from '../state/types'

export const useBranchLoaders = () => {
    const systemHooks = useSystemStateHooks()
    const { getList, find } = useStateHooks()

    const loaders = useLoaders()

    return (companyId = systemHooks.companyId) => {
        const findAllBranches = () => getList(TableName.BRANCHES) as Branch[]

        const findBranch = (id?: string) => find(TableName.BRANCHES, id) as O<Branch>

        const loadAllBranches = (dbRequest = false) => {
            const { loadAll } = loaders(companyId)
            return loadAll(TableName.BRANCHES, dbRequest) as Promise<Branch[]>
        }

        return {
            findAllBranches,
            findBranch,
            loadAllBranches,
        }
    }
}
