import { useEffect, useState } from "react"
import { DataType } from "../../../../../tables"
import { LAST_14_DAYS, LAST_30_DAYS, LAST_7_DAYS, MANUAL, periodOptions, TODAY } from "./utils"
import { FieldType, FieldValue, FilterField, Filters, O, Option, option } from "../../../../../features/ui"
import { useActions } from "./actions"
import { Transaction } from "../../../state/types"
import { useDescriptorHooks } from "../../../../../features/data-types"
import { define } from "../../../../../utils/typeUtils"

type Props = {
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const TransactionFilters = (props: Props) => {
    const { onSearchStart, onSearchDone } = props

    const buildDateFrom = (daysBack: number) => {
        const newDateFrom = new Date()
        newDateFrom.setDate(newDateFrom.getDate() - daysBack)
        newDateFrom.setHours(0, 0, 0, 0)
        return newDateFrom
    }

    const buildDateTo = () => {
        const newDateTo = new Date()
        newDateTo.setHours(23, 59, 59, 999)
        return newDateTo
    }

    const [transactions, setTransactions] = useState<Transaction[]>([])
    const [period, setPeriod] = useState<string>(LAST_7_DAYS)
    const [dateFrom, setDateFrom] = useState<Date>(buildDateFrom(7))
    const [dateTo, setDateTo] = useState<Date>(buildDateTo())
    const [sourceOptions, setSourceOptions] = useState<Option[]>([])
    const [sourceId, setSourceId] = useState<string>()
    const [destinationOptions, setDestinationOptions] = useState<Option[]>([])
    const [destinationId, setDestinationId] = useState<string>()
    const [amountFrom, setAmountFrom] = useState<number>()
    const [amountTo, setAmountTo] = useState<number>()

    const descriptorHooks = useDescriptorHooks()

    const { fetchTransactions } = useActions()

    useEffect(() => {
        search(dateFrom, dateTo)
    }, [])

    const loadSourceOptions = async () => {
        const transactionList = Array.from(new Map(transactions
            .filter(transaction => !!transaction.sourceId)
            .map(transaction => [transaction.sourceId, transaction])
        ).values())
        return Promise.all(transactionList.map(async transaction =>
            option(define(transaction.sourceId), await descriptorHooks.transaction.source(transaction))
        ))
    }

    const loadDestinationOptions = async () => {
        const transactionList = Array.from(new Map(transactions
            .filter(transaction => !!transaction.destinationId)
            .map(transaction => [transaction.destinationId, transaction])
        ).values())
        return Promise.all(transactionList.map(async transaction =>
            option(define(transaction.destinationId), await descriptorHooks.transaction.destination(transaction))
        ))
    }

    useEffect(() => {
        loadSourceOptions().then(newOptions => setSourceOptions(newOptions))
        loadDestinationOptions().then(newOptions => setDestinationOptions(newOptions))
    }, [transactions])

    const search = async (
        dateFromParam: Date,
        dateToParam: Date,
        sourceIdParam?: string,
        destinationIdParam?: string,
        amountFromParam?: number,
        amountToParam?: number
    ) => {
        onSearchStart()
        let transactionList = await fetchTransactions(dateFromParam, dateToParam) as Transaction[]
        setTransactions(transactionList)
        transactionList = sourceIdParam ? transactionList.filter(transaction => transaction.sourceId === sourceIdParam) : transactionList
        transactionList = destinationIdParam ? transactionList.filter(transaction => transaction.destinationId === destinationIdParam) : transactionList
        transactionList = amountFromParam ? transactionList.filter(transaction => transaction.amount >= amountFromParam) : transactionList
        transactionList = amountToParam ? transactionList.filter(transaction => transaction.amount <= amountToParam) : transactionList
        setTimeout(() => onSearchDone(transactionList), 100)
    }

    const onChangePeriod = (value?: FieldValue) => {
        const newPeriod = value as O<string>
        if (newPeriod) {
            setPeriod(newPeriod)
            if (newPeriod !== MANUAL) {
                let newDateFrom = dateFrom
                if (newPeriod === TODAY) {
                    newDateFrom = buildDateFrom(0)
                } else if (value === LAST_7_DAYS) {
                    newDateFrom = buildDateFrom(7)
                } else if (value === LAST_14_DAYS) {
                    newDateFrom = buildDateFrom(14)
                } else if (value === LAST_30_DAYS) {
                    newDateFrom = buildDateFrom(30)
                }
                const newDateTo = buildDateTo()
                setDateFrom(newDateFrom)
                setDateTo(newDateTo)
                search(newDateFrom, newDateTo, sourceId, destinationId, amountFrom, amountTo)
            }
        }
    }

    const onChangeDateFrom = (value?: FieldValue) => {
        const newDateFrom = value as O<Date>
        newDateFrom?.setHours(11, 59, 59)
        if (newDateFrom) {
            setPeriod(MANUAL)
            setDateFrom(newDateFrom)
            search(newDateFrom, dateTo, sourceId, destinationId, amountFrom, amountTo)
        }
    }
    
    const onChangeDateTo = (value?: FieldValue) => {
        const newDateTo = value as O<Date>
        newDateTo?.setHours(11, 59, 59)
        if (newDateTo) {
            setPeriod(MANUAL)
            setDateTo(newDateTo)
            search(dateFrom, newDateTo, sourceId, destinationId, amountFrom, amountTo)
        }
    }

    const onChangeSource = (value?: FieldValue) => {
        const newSourceId = value as O<string>
        if (newSourceId !== sourceId) {
            setSourceId(newSourceId)
            search(dateFrom, dateTo, newSourceId, destinationId, amountFrom, amountTo)
        }
    }

    const onChangeDestination = (value?: FieldValue) => {
        const newDestinationId = value as O<string>
        if (newDestinationId !== destinationId) {
            setDestinationId(newDestinationId)
            search(dateFrom, dateTo, sourceId, newDestinationId, amountFrom, amountTo)
        }
    }

    const onChangeAmountFrom = (value?: FieldValue) => {
        const newAmountFrom = (value || 0) as number
        setAmountFrom(newAmountFrom)
        search(dateFrom, dateTo, sourceId, destinationId, newAmountFrom, amountTo)
    }

    const onChangeAmountTo = (value?: FieldValue) => {
        const newAmountTo = value as O<number>
        setAmountTo(newAmountTo)
        search(dateFrom, dateTo, sourceId, destinationId, amountFrom, newAmountTo)
    }

    const filterFields: FilterField[] = [
        {
            name: 'period',
            type: FieldType.SELECT,
            value: period,
            options: periodOptions(),
            label: 'Período',
            space: 2,
            onChange: onChangePeriod
        },
        {
            name: 'dateFrom',
            type: FieldType.DATE,
            value: dateFrom,
            label: 'Fecha: Desde',
            max: dateTo,
            space: 2,
            onChange: onChangeDateFrom
        },
        {
            name: 'dateTo',
            type: FieldType.DATE,
            value: dateTo,
            label: 'Fecha: Hasta',
            min: dateFrom,
            max: new Date(),
            space: 2,
            onChange: onChangeDateTo
        },
        {
            name: 'source',
            type: FieldType.SELECT,
            options: sourceOptions,
            label: 'Origen',
            space: 3,
            onChange: onChangeSource
        },
        {
            name: 'destination',
            type: FieldType.SELECT,
            options: destinationOptions,
            label: 'Destino',
            space: 3,
            onChange: onChangeDestination
        },
        {
            name: 'amountFrom',
            type: FieldType.NUMBER,
            label: 'Monto: Desde',
            max: amountTo,
            space: 2,
            onChange: onChangeAmountFrom
        },
        {
            name: 'amountTo',
            type: FieldType.NUMBER,
            label: 'Monto: Hasta',
            min: amountFrom,
            space: 2,
            onChange: onChangeAmountTo
        }
    ]

    return (
        <Filters
            fields={filterFields}
            firstRowLength={3}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
