import { O } from '../../../features/ui'
import { TableName } from '../../../tables'
import { useLoaders } from '../../common'
import { useSystemStateHooks } from '../../system'
import { PaymentOrder } from '../state/types'

export const usePaymentOrderLoaders = () => {
    const systemHooks = useSystemStateHooks()
    const loaders = useLoaders()

    return (companyId = systemHooks.companyId) => {
        const loadAllPaymentOrders = () => {
            const { loadAll } = loaders(companyId)
            return loadAll(TableName.PAYMENT_ORDERS) as Promise<PaymentOrder[]>
        }

        const loadPaymentOrder = (
            id: string,
            dbRequest = false
        ) => {
            const { load } = loaders(companyId)
            return load(TableName.PAYMENT_ORDERS, id, dbRequest) as Promise<O<PaymentOrder>>
        }
        
        return {
            loadAllPaymentOrders,
            loadPaymentOrder
        }
    }
}
