import { Emitter, Event } from "../../../../../features/event-emitter"
import { Data, ModalId, useUIActions } from "../../../../../features/ui"
import { useGenerateNextValue } from "../../../../../tables/hooks"
import { Currency, TableName } from "../../../../../tables/types/types"
import { define, round } from "../../../../../utils/typeUtils"
import { usePaymentOrderActions } from "../../../actions/paymentOrderActions"
import { useAdministrationStateActions } from "../../../hooks/administrationHooks"
import { useTicketHooks } from "../../../hooks/ticketHooks"
import { SelectedTickets } from "../../../state/constants"
import { PaymentCategory, PaymentItem, PaymentOrder, PaymentOrderStatus, PaymentTicketItem, TaxItem, Ticket } from "../../../state/types"

export const useActions = () => {
    const { getTicketPendingAmount } = useTicketHooks()
    const generateNextValue = useGenerateNextValue()

    const paymentOrderActions = usePaymentOrderActions()
    const { selectTickets } = useAdministrationStateActions()
    const { openModal } = useUIActions()

    const addPaymentTicketItem = (
        payedAmount: number,
        pendingTickets: Ticket[],
        items: PaymentTicketItem[] = []
    ): PaymentTicketItem[] => {
        if (payedAmount <= 0 || pendingTickets.length === 0) {
            return items
        }

        const sortedTickets = pendingTickets.sort((ticket_A, ticket_B) => ticket_A.dueDate.localeCompare(ticket_B.dueDate))
        const ticket = define(sortedTickets.shift())
        const ticketPendingAmount = getTicketPendingAmount(ticket)
        if (payedAmount <= ticketPendingAmount) {
            items.push({
                ticketId: ticket.id,
                payedAmount,
            })
            return items
        } else {
            items.push({
                ticketId: ticket.id,
                payedAmount: ticketPendingAmount,
            })
            return addPaymentTicketItem(round(payedAmount - ticketPendingAmount), sortedTickets, items)
        }
    }

    const submitPaymentOrderOrOpenModal = (
        partialPayment: boolean,
        paymentOrderData: Data,
        ticketItems: PaymentTicketItem[],
        taxItemsData: Data[],
        paymentItemsData: Data[]
    ) => {
        if (partialPayment) {
            openModal(ModalId.PARTIAL_PAYMENT_MODAL)
        } else {
            submitPaymentOrder(paymentOrderData, ticketItems, taxItemsData, paymentItemsData)
        }
    }
    
    const submitPaymentOrder = async (
        paymentOrderData: Data,
        ticketItems: PaymentTicketItem[],
        taxItemsData: Data[],
        paymentItemsData: Data[]
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const { companyId, id, payedAmount } = paymentOrderData
        const code = await generateNextValue(TableName.PAYMENT_ORDERS, 'code')

        const taxItems: TaxItem[] = taxItemsData.map(taxItemData => ({
            taxCode: define(taxItemData.taxCode) as string,
            taxYield: define(taxItemData.taxYield) as number,
            amount: define(taxItemData.amount) as number
        }))
        const paymentItems: PaymentItem[] = paymentItemsData.map(paymentItemData => ({
            paymentTypeId: define(paymentItemData.paymentTypeId) as string,
            paymentCategory: define(paymentItemData.paymentCategory) as PaymentCategory,
            sourceId: define(paymentItemData.sourceId) as string,
            amount: define(paymentItemData.amount) as number
        }))
        const paymentOrder: PaymentOrder = {
            companyId: define(companyId) as string,
            id: define(id) as string,
            code,
            ticketItems,
            payedAmount: define(payedAmount) as number,
            taxItems,
            paymentItems,
            status: PaymentOrderStatus.EMITTED,
            creationDate: new Date().toISOString(),
            currency: Currency.ARS
        }
        
        const { createPaymentOrder } = paymentOrderActions()
        await createPaymentOrder(paymentOrder)
        Emitter.emit(Event.DB_SUBMIT_DONE, true)
        setTimeout(cleanSelectedTickets, 1000)
    }

    const cleanSelectedTickets = () => {
        selectTickets([])
        localStorage.removeItem(SelectedTickets)
    }
    
    return {
        addPaymentTicketItem,
        submitPaymentOrderOrOpenModal,
        submitPaymentOrder,
        cleanSelectedTickets
    }
}
