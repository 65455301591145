import styles from './measureForm.module.css'
import { Form, Data, FormField, ModalId, useUIActions, FieldType, option, FieldValue, SelectField, NumberField, O } from "../../../../features/ui"
import { firstItem } from "../../../../utils/listUtils"
import { MeasureType } from '../../state/types'
import { useStockState } from "../../../stock"
import { useState } from "react"
import { getUnitTypeCategory } from "../../utils/measuresUtils"
import { Labels } from '../../../../features/data-types'

type Props = {
    measureData?: Data
    filterMeasureTypes: (measureType: MeasureType) => boolean
    onSubmit: (data: Data) => void
}

export const MeasureForm = (props: Props) => {
    const { measureData, filterMeasureTypes, onSubmit } = props
    const createMode = !measureData
    
    const { unitTypes } = useStockState()

    const measureTypes = Object.values(MeasureType).filter(filterMeasureTypes)
    const defaultType = firstItem(measureTypes)
    const [type, setType] = useState<O<MeasureType>>((measureData?.type as O<MeasureType>) || defaultType)
    const [units, setUnits] = useState<O<number>>((measureData?.units as O<number>) || 1)
    const categoryUnitTypes = (measureType?: MeasureType) => unitTypes.filter(unitType => measureType && unitType.category === getUnitTypeCategory(measureType))
    const defaultUnitType = (measureType?: MeasureType) => firstItem(categoryUnitTypes(measureType))?.id
    const [unitTypeId, setUnitTypeId] = useState<O<string>>((measureData?.unitTypeId as O<string>) || defaultUnitType(defaultType))

    const { closeModal } = useUIActions()
    
    const typeOptions = measureTypes.map(type => option(type, Labels.measureTypeLabel(type)))
    const unitTypeOptions = categoryUnitTypes(type).map(unitType => option(unitType.id, unitType.name))

    const onChangeType = (value?: FieldValue) => {
        const newType = value as O<MeasureType>
        setType(newType)
        setUnitTypeId(firstItem(categoryUnitTypes(newType))?.id)
    }

    const onChangeUnitType = (value?: FieldValue) => {
        setUnitTypeId(value as O<string>)
    }

    const fields: FormField[] = [
        {
            name: 'type',
            type: FieldType.SELECT,
            defaultValue: type,
            options: typeOptions,
            label: 'Tipo',
            onChange: onChangeType
        },
        {
            name: 'units',
            type: FieldType.NUMBER,
            label: 'Valor',
            render: () => {
                return <div className={styles.unitsContainer}>
                    <NumberField
                        id="units"
                        defaultValue={units}
                        min={0.01}
                        className={styles.units}
                        onChange={setUnits}
                    />
                    <SelectField
                        id="unitTypeId"
                        value={unitTypeId}
                        options={unitTypeOptions}
                        sorted
                        onChange={onChangeUnitType}
                    />
                </div>
            }
        }
    ]

    const handleSubmit = () => {
        onSubmit({ type, units, unitTypeId })
        closeModal(ModalId.MEASURE_FORM)
    }

    const handleCancel = () => closeModal(ModalId.MEASURE_FORM)

    return (
        <Form
            formId="measure-form"
            fields={fields}
            createMode={createMode}
            createLabel="Agregar"
            onSubmit={handleSubmit}
            onCancel={handleCancel}
        />
    )
}
