import { useEffect, useState } from "react"
import { PanelLoader } from "../../../../../features/ui"
import { PurchaseList } from "./purchaseList"
import { PurchaseItem, PurchaseOrder } from "../../../state/types"
import { usePurchaseLoaders } from "../../../loaders/purchaseLoaders"
import { useManagerState } from "../../../../manager"
import { define } from "../../../../../utils/typeUtils"
import { Ticket, useTicketLoaders } from "../../../../administration"

export const PurchaseListLoader = () => {
    const { currentBranch } = useManagerState()
    const branchId = define(currentBranch).id

    const [purchases, setPurchases] = useState<PurchaseOrder[]>([])
    const [purchaseItems, setPurchaseItems] = useState<PurchaseItem[]>([])
    const [tickets, setTickets] = useState<Ticket[]>([])
    const [ready, setReady] = useState(false)

    const purchaseLoaders = usePurchaseLoaders()
    const ticketLoaders = useTicketLoaders()

    const populate = async () => {
        const { loadBranchPurchases, loadPurchaseItemsByPurchases } = purchaseLoaders()
        const loadedPurchases = await loadBranchPurchases(branchId)
        setPurchases(loadedPurchases)
        const purchaseIds = loadedPurchases.map(purchase => purchase.id)
        setPurchaseItems(await loadPurchaseItemsByPurchases(purchaseIds))
        const { loadTicketsByParents } = ticketLoaders()
        setTickets(await loadTicketsByParents(purchaseIds))
    }

    useEffect(() => {
        setReady(false)
        populate().then(() => setReady(true))
    }, [branchId])

    return (
        <PanelLoader
            title="Órdenes de Compra"
            ready={ready}
            wide
        >
            <PurchaseList
                purchases={purchases}
                purchaseItems={purchaseItems}
                tickets={tickets}
            />
        </PanelLoader>
    )
}
