import { RemoveChildrenFn, SaveChildrenFn, newId, useChildrenActions } from "../../../../features/data-types"
import { Data } from "../../../../features/ui"
import { DataType } from "../../../../tables"
import { Currency } from "../../../../tables/types/types"
import { define } from "../../../../utils/typeUtils"
import { OrderDelivery, DeliveryStatus, useOperationsActions, useOperationsHooks } from "../../../operations"
import { useStockActions } from "../../../stock"
import { useSystemStateHooks } from "../../../system"
import { HoardOrder, HoardItem } from "../../state/types"
import { useCommonActions } from "./commonActions"

export const useProductHoardActions = () => {
    const { getPendingQuantity } = useOperationsHooks()
    const stateCompanyId = useSystemStateHooks().companyId
    const commonActions = useCommonActions()
    const operationsActions = useOperationsActions()
    const stockActions = useStockActions()
    const { submitChildren } = useChildrenActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId

        const saveProductHoard = async (
            hoard: HoardOrder,
            hoardItemsData: Data[],
            modifyStock = true
        ) => {
            const { fetchHoardItemsByOrder, saveHoard, removeHoardItems } = commonActions(define(companyId))
            const savedHoard = await saveHoard(hoard) as HoardOrder

            const hoardItems = hoardItemsData.map(hoardItemData => ({
                ...hoardItemData,
                companyId: define(companyId),
                id: newId(),
                hoardId: hoard.id,
                currency: Currency.ARS
            })) as HoardItem[]
            
            const stateHoardItems = await fetchHoardItemsByOrder(hoard.id) as HoardItem[]
            const saveItems: SaveChildrenFn = (children: DataType[]) => saveProductHoardItems(children as HoardItem[], hoard.branchId, modifyStock)
            await submitChildren(stateHoardItems, hoardItems, saveItems, removeHoardItems as RemoveChildrenFn)
            return savedHoard
        }

        const saveProductHoardItems = async (
            hoardItems: HoardItem[],
            branchId: string,
            modifyStock: boolean
        ) => {
            const { saveHoardItems } = commonActions(define(companyId))

            if (modifyStock) {
                const { reserveStock } = stockActions()
                hoardItems.forEach(async hoardItem => {
                    const { productId, lotId, quantity } = hoardItem
                    await reserveStock(productId, branchId, quantity, lotId)  
                })
            }

            const savedHoardItems = await saveHoardItems(hoardItems) as HoardItem[]
            return savedHoardItems
        }

        const saveProductHoardDelivery = async (
            hoard: HoardOrder,
            hoardItems: HoardItem[],
            delivery: OrderDelivery
        ) => {
            const { saveHoard } = commonActions(define(companyId))
            const { fetchDeliveries, saveDelivery } = operationsActions()
            const { decreaseReservedStock } = stockActions()
            
            const deliveries = await fetchDeliveries(hoard.id) as OrderDelivery[]
            const savedDelivery = await saveDelivery(delivery) as OrderDelivery 

            let pendingItemsCount = 0
            savedDelivery.deliveredItems.forEach(deliveredItem => {
                const deliveredHoardItem = hoardItems.find(hoardItem => hoardItem.id === deliveredItem.itemId)
                const pendingQuantity = getPendingQuantity(define(deliveredHoardItem) as HoardItem, deliveries)
                deliveredItem.delivered < pendingQuantity && pendingItemsCount++
            })
            const updatedHoard: HoardOrder = {
                ...hoard,
                branchId: savedDelivery.branchId,
                deliveryStatus: pendingItemsCount === 0 ? DeliveryStatus.DELIVERED : DeliveryStatus.PARTIALLY_DELIVERED
            }

            const savedHoard = await saveHoard(updatedHoard) as HoardOrder

            savedDelivery.deliveredItems.forEach(async deliveredItem => {
                const hoardItem = define(hoardItems.find(hoardItem => hoardItem.id === deliveredItem.itemId))
                await decreaseReservedStock(hoardItem.productId, savedDelivery.branchId, deliveredItem.delivered, hoardItem.lotId)
            })

            return savedHoard
        }

        return {
            saveProductHoard,
            saveProductHoardDelivery
        }
    }
}
