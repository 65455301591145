import { useNavigate } from "react-router-dom"
import { useLoaders } from "../../../../modules/common"
import { useRoutesHooks } from "../../hooks/routesHooks"
import { getRouteFromTableName } from "../../utils/routesUtils"

export const useLoad = () => {
    const { parseUrl, getRoutePath } = useRoutesHooks()
    const { tableName, dataTypeId } = parseUrl()

    const navigate = useNavigate()

    const loaders = useLoaders()
    
    return async () => {
        if (tableName && dataTypeId) {
            const { load } = loaders()
            const loadedDataType = await load(tableName, dataTypeId)
            if (!loadedDataType) {
                const tableRoutePath = getRouteFromTableName(tableName)
                tableRoutePath && navigate(getRoutePath(tableRoutePath))
            }
        }
    }
}
