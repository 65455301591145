import { O } from '../../../features/ui'
import { useStateHooks } from '../../../state'
import { TableName } from '../../../tables'
import { useLoaders } from '../../common'
import { useSystemStateHooks } from '../../system'
import { PaymentType } from '../state/types'

export const usePaymentTypeLoaders = () => {
    const systemHooks = useSystemStateHooks()
    const { getList, find } = useStateHooks()
    
    const loaders = useLoaders()

    return (companyId = systemHooks.companyId) => {
        const findAllPaymentTypes = () => getList(TableName.PAYMENT_TYPES) as PaymentType[]

        const findPaymentType = (id?: string) => find(TableName.PAYMENT_TYPES, id) as O<PaymentType>

        const loadAllPaymentTypes = () => {
            const { loadAll } = loaders(companyId)
            return loadAll(TableName.PAYMENT_TYPES) as Promise<PaymentType[]>
        }

        return {
            findAllPaymentTypes,
            findPaymentType,
            loadAllPaymentTypes
        }
    }
}
