import { option } from "../../../../../features/ui";

export const TODAY = 'today'
export const LAST_7_DAYS = '7-days'
export const LAST_14_DAYS = '14-days'
export const LAST_30_DAYS = '30-days'
export const MANUAL = 'manual'

export const periodOptions = () => [
    option(TODAY, 'Hoy'),
    option(LAST_7_DAYS, 'Últimos 7 días'),
    option(LAST_14_DAYS, 'Últimos 14 días'),
    option(LAST_30_DAYS, 'Últimos 30 días'),
    option(MANUAL, 'Manual')
]
