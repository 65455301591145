import { Labels } from "../../../../../features/data-types"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { DetailFieldProps, DetailPanel, FieldType, FieldValue, UIPermission, useGetCrumbs } from "../../../../../features/ui"
import { TableName } from "../../../../../tables"
import { define, isDefined } from "../../../../../utils/typeUtils"
import { useSystemStateHooks } from "../../../../system"
import { Period, Tax, TaxBase, TaxType } from "../../../state/types"

export const TaxDetail = () => {
  const { dataType } = useRoutesHooks().parseUrl()
  const tax = define(dataType) as Tax

  const { userHasPermission } = useSystemStateHooks()
  const readOnly = !userHasPermission(UIPermission.SAVE_TAXES)

  const crumbs = useGetCrumbs('Impuestos', RoutePath.TAXES, tax.name)

  const fields: DetailFieldProps[] = [
    { name: 'name', type: FieldType.TEXT, label: 'Nombre' },
    {
      name: 'type',
      type: FieldType.TEXT,
      label: 'Tipo',
      render: (value?: FieldValue) => <>{Labels.taxTypeLabel(define(value) as TaxType)}</>
    },
    {
      name: 'code',
      type: FieldType.TEXT,
      label: 'Código'
    },
    {
      name: 'legalCode',
      type: FieldType.TEXT,
      label: 'Código ARCA'
    },
    {
      name: 'taxBase',
      type: FieldType.TEXT,
      label: 'Base Imponible',
      render: (value?: FieldValue) => <>{Labels.taxBaseLabel(define(value) as TaxBase)}</>
    },
    {
      name: 'nonTaxableMin',
      type: FieldType.PRICE,
      label: 'Mínimo No Imponible'
    },
    {
      name: 'yield',
      type: FieldType.PERCENTAGE,
      label: 'Alícuota'
    }
  ]
  if (tax.nonTaxableMinPeriod) {
    const nonTaxableMinPeriodField = {
      name: 'nonTaxableMinPeriod',
      type: FieldType.TEXT,
      label: 'Período Mínimo No Imponible',
      render: (value?: FieldValue) => <>{Labels.periodLabel(define(value) as Period)}</>
    }
    fields.splice(5, 0, nonTaxableMinPeriodField)
  }
  if (isDefined(tax.unregisteredYield)) {
    const unregisteredYieldField = {
      name: 'unregisteredYield',
      type: FieldType.PERCENTAGE,
      label: 'Alícuota No Registrada'
    }
    fields.splice(6 + (tax.nonTaxableMinPeriod ? 1 : 0), 0, unregisteredYieldField)
  }

  return (<>
    <DetailPanel
      tableName={TableName.TAXES}
      crumbs={crumbs}
      fields={fields}
      data={tax}
      icon='coin'
      readOnly={readOnly}
    />
  </>)
}
