import { useEffect, useState } from "react"
import { useLoad } from "./load"
import { Outlet } from "react-router-dom"
import { ElementLoader } from "../element-loader/elementLoader"

export const IdElement = () => {
    const [ready, setReady] = useState(false)

    const load = useLoad()

    useEffect(() => {
        load().then(() => setReady(true))
    }, [])

    return (
        <ElementLoader ready={ready}>
            <Outlet />
        </ElementLoader>
    )
}
