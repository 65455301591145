import { useState } from "react"
import { Column, FieldType, FieldValue, SpinnerDiv, UIPermission } from "../../../../../features/ui"
import { Data, DataTypeList, Labels } from "../../../../../features/data-types"
import { define } from "../../../../../utils/typeUtils"
import { Tax, TaxType } from "../../../state/types"
import { useSystemStateHooks } from "../../../../system"
import { DataType } from "../../../../../tables"
import { TaxFilters } from "./taxFilters"

type Props = {
    taxes: Tax[]
}

export const TaxList = (props: Props) => {
    const { taxes } = props

    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)

    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_TAXES)

    const columns: Column[] = [
        {
            name: 'legalCode',
            type: FieldType.TEXT,
            label: 'Código ARCA'
        },
        { name: 'name', type: FieldType.TEXT, label: 'Nombre' },
        {
            name: 'type',
            type: FieldType.TEXT,
            label: 'Tipo',
            render: (value?: FieldValue) => Labels.taxTypeLabel(define(value) as TaxType)
        },
        { name: 'yield', type: FieldType.PERCENTAGE, label: '%' },
        { name: 'nonTaxableMin', type: FieldType.PRICE, label: 'Min. No Imponible' }
    ]

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRows(dataTypes as Tax[])
        setAsyncDataLoaded(true)
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <>
            <TaxFilters
                taxes={taxes}
                onSearchStart={onSearchStart}
                onSearchDone={onSearchDone}
            />
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    readOnly={readOnly}
                />
            )}
        </>
    )
}
