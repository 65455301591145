import App from '../../app';
import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { AdminElement } from './components/admin-element/adminElement';
import { IndexElement } from './components/index-element/indexElement';
import { CompanyElement } from './components/company-element/companyElement';
import { NotFoundElement } from './components/not-found-element/notFoundElement';
import { AccessRequestElement } from './components/access-request-element/accessRequestElement';
import { StockDetail, StockForm, StockList } from '../../modules/stock';
import { CategoryForm, CategoryList, ImportPricesPanel, ProductDetailLoader, ProductForm, ProductListLoader, ProductYieldPanel } from '../../modules/products';
import { RoutePath } from './types';
import { StockAction } from '../ui';
import { IdElement } from './components/id-element/idElement';
import { TaxListLoader, TaxDetailLoader, CashFundFormLoader, CheckForm, CheckListLoader, CompanyPanel, EmployeeForm, EmployeeListLoader, TicketFormLoader, ExpenseDetailLoader, ExpenseForm, ExpenseListLoader, ExpenseSupplierForm, ExpenseSupplierList, ExpenseTypeForm, ExpenseTypeList, PaymentTypeForm, PaymentTypeList, SalaryDetailLoader, SalaryForm, SalaryListLoader, TransferCashFundFormLoader, CheckDetailLoader, CreditCardForm, CreditCardDetailLoader, TaxForm, EmployeeDetailLoader, PendingPaymentListLoader, PaymentOrderFormLoader, TransactionList, PaymentOrderListLoader, TransactionDetail, PaymentOrderDetail, CashFundListLoader, EditCashFundAmountFormLoader, CreditCardListLoader } from '../../modules/administration';
import { BranchDetail, BranchList, RequestList } from '../../modules/manager';
import { PurchaseDeliveryForm, PurchaseDetail, PurchaseFormLoader, PurchaseListLoader, PurchasesSettingsPanel, SupplierForm, SupplierList } from '../../modules/purchases';
import { BudgetDetailLoader, BudgetForm, BudgetListLoader, CustomerForm, CustomerList, CustomerTypeForm, CustomerTypeList, HoardDeliveryForm, HoardDetail, HoardFormLoader, HoardList, RefreshBudgetForm, SaleDeliveryForm, SaleDetail, SaleFormLoader, SaleList, SalesSettingsPanel } from '../../modules/sales';
import { UserPanel } from '../../modules/system';
import { HoardDeliveryList, PurchaseReceptionListLoader, SaleDeliveryList } from '../../modules/warehouse';
import { AdminElement_OLD } from './components/admin-element/adminElement_OLD';
import { InvoiceList } from '../../modules/construfy-admin';

export const Router = () => createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<App />}>
            <Route path="" element={<IndexElement />} />
            
            <Route path={RoutePath.ADMIN} element={<AdminElement />}>
                <Route path={RoutePath.INVOICES} element={<InvoiceList />} />
            </Route>
            <Route path={"/admin_old"} element={<AdminElement_OLD />} />
            
            <Route path="/:companyCode" element={<CompanyElement />}>
                <Route path={RoutePath.ACCESS} element={<AccessRequestElement />} />

                <Route path={RoutePath.STOCK}>
                    <Route path="" element={<StockList />} />
                    
                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<StockDetail />} />
                        <Route path={RoutePath.ADD} element={<StockForm />} />
                        <Route path={RoutePath.SUBSTRACT} element={<StockForm stockAction={StockAction.SUBSTRACT} />} />
                    </Route>
                </Route>
               
                <Route path={RoutePath.PRODUCTS}>
                    <Route path="" element={<ProductListLoader />} />

                    <Route path={RoutePath.PRICES} element={<ImportPricesPanel />} />

                    <Route path={RoutePath.CREATE} element={<ProductForm />} />
                    
                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<ProductDetailLoader />} />
                        <Route path={RoutePath.EDIT} element={<ProductForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.COMPANY} element={<CompanyPanel />} />

                <Route path={RoutePath.BRANCHES}>
                    <Route path="" element={<BranchList />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<BranchDetail />} />
                    </Route>
                </Route>

                <Route path={RoutePath.REQUESTS} element={<RequestList />} />

                <Route path={RoutePath.PENDING_PAYMENTS}>
                    <Route path="" element={<PendingPaymentListLoader />} />
                </Route>

                <Route path={RoutePath.PAYMENT_ORDERS}>
                    <Route path="" element={<PaymentOrderListLoader />} />

                    <Route path={RoutePath.CREATE} element={<PaymentOrderFormLoader />} />
                    
                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<PaymentOrderDetail />} />
                    </Route>
                </Route>

                <Route path={RoutePath.EMPLOYEES}>
                    <Route path="" element={<EmployeeListLoader />} />

                    <Route path={RoutePath.CREATE} element={<EmployeeForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<EmployeeDetailLoader />} />
                        <Route path={RoutePath.EDIT} element={<EmployeeForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.SALARIES}>
                    <Route path="" element={<SalaryListLoader />} />

                    <Route path={RoutePath.CREATE} element={<SalaryForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<SalaryDetailLoader />} />
                        <Route path={RoutePath.EDIT} element={<SalaryForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.CASH_FUNDS}>
                    <Route path="" element={<CashFundListLoader />} />
                    
                    <Route path={RoutePath.CREATE} element={<CashFundFormLoader />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path={RoutePath.EDIT} element={<CashFundFormLoader />} />
                        <Route path={RoutePath.TRANSFER} element={<TransferCashFundFormLoader />} />
                        <Route path={RoutePath.ADD} element={<EditCashFundAmountFormLoader />} />
                        <Route path={RoutePath.SUBSTRACT} element={<EditCashFundAmountFormLoader />} />
                    </Route>
                </Route>

                <Route path={RoutePath.CHECKS}>
                    <Route path="" element={<CheckListLoader />} />

                    <Route path={RoutePath.CREATE} element={<CheckForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<CheckDetailLoader />} />
                        <Route path={RoutePath.EDIT} element={<CheckForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.CREDIT_CARDS}>
                    <Route path="" element={<CreditCardListLoader />} />

                    <Route path={RoutePath.CREATE} element={<CreditCardForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<CreditCardDetailLoader />} />
                        <Route path={RoutePath.EDIT} element={<CreditCardForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.TRANSACTIONS}>
                    <Route path="" element={<TransactionList />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<TransactionDetail />} />
                    </Route>
                </Route>

                <Route path={RoutePath.EXPENSES}>
                    <Route path="" element={<ExpenseListLoader />} />
                    
                    <Route path={RoutePath.CREATE} element={<ExpenseForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<ExpenseDetailLoader />} />
                        <Route path={RoutePath.EDIT} element={<ExpenseForm />} />

                        <Route path={RoutePath.TICKETS}>
                            <Route path={RoutePath.CREATE} element={<TicketFormLoader />} />
                    
                            <Route path=":id" element={<IdElement />}>
                                <Route path={RoutePath.EDIT} element={<TicketFormLoader />} />
                            </Route>
                        </Route>
                    </Route>
                </Route>

                <Route path={RoutePath.EXPENSE_TYPES}>
                    <Route path="" element={<ExpenseTypeList />} />
                    
                    <Route path={RoutePath.CREATE} element={<ExpenseTypeForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path={RoutePath.EDIT} element={<ExpenseTypeForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.EXPENSE_SUPPLIERS}>
                    <Route path="" element={<ExpenseSupplierList />} />
                    
                    <Route path={RoutePath.CREATE} element={<ExpenseSupplierForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path={RoutePath.EDIT} element={<ExpenseSupplierForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.PAYMENT_TYPES}>
                    <Route path="" element={<PaymentTypeList />} />
                    
                    <Route path={RoutePath.CREATE} element={<PaymentTypeForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path={RoutePath.EDIT} element={<PaymentTypeForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.TAXES}>
                    <Route path="" element={<TaxListLoader />} />

                    <Route path={RoutePath.CREATE} element={<TaxForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<TaxDetailLoader />} />
                        <Route path={RoutePath.EDIT} element={<TaxForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.PURCHASES}>
                    <Route path="" element={<PurchaseListLoader />} />
                    
                    <Route path={RoutePath.CREATE} element={<PurchaseFormLoader />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<PurchaseDetail />} />
                        <Route path={RoutePath.EDIT} element={<PurchaseFormLoader />} />
                        <Route path={RoutePath.DELIVERY} element={<PurchaseDeliveryForm />} />
                        
                        <Route path={RoutePath.TICKETS}>
                            <Route path={RoutePath.CREATE} element={<TicketFormLoader />} />
                    
                            <Route path=":id" element={<IdElement />}>
                                <Route path={RoutePath.EDIT} element={<TicketFormLoader />} />
                            </Route>
                        </Route>
                    </Route>

                    <Route path={RoutePath.SETTINGS} element={<PurchasesSettingsPanel />} />
                </Route>

                <Route path={RoutePath.SUPPLIERS}>
                    <Route path="" element={<SupplierList />} />
                    
                    <Route path={RoutePath.CREATE} element={<SupplierForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path={RoutePath.EDIT} element={<SupplierForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.BUDGETS}>
                    <Route path="" element={<BudgetListLoader />} />
                    
                    <Route path={RoutePath.CREATE} element={<BudgetForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<BudgetDetailLoader />} />
                        <Route path={RoutePath.UPDATE} element={<RefreshBudgetForm />} />
                        <Route path={RoutePath.CREATE_SALE} element={<SaleFormLoader />} />
                    </Route>
                </Route>

                <Route path={RoutePath.SALES}>
                    <Route path="" element={<SaleList />} />
                    
                    <Route path={RoutePath.CREATE} element={<SaleFormLoader />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<SaleDetail />} />
                        <Route path={RoutePath.EDIT} element={<SaleFormLoader />} />
                        <Route path={RoutePath.DELIVERY} element={<SaleDeliveryForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.HOARDS}>
                    <Route path="" element={<HoardList />} />
                    
                    <Route path={RoutePath.SETTINGS} element={<SalesSettingsPanel />} />

                    <Route path={RoutePath.CREATE} element={<HoardFormLoader />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path="" element={<HoardDetail />} />
                        <Route path={RoutePath.EDIT} element={<HoardFormLoader />} />
                        <Route path={RoutePath.DELIVERY} element={<HoardDeliveryForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.CATEGORIES}>
                    <Route path="" element={<CategoryList />} />
                    
                    <Route path={RoutePath.CREATE} element={<CategoryForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path={RoutePath.EDIT} element={<CategoryForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.PRODUCT_YIELD} element={<ProductYieldPanel />} />

                <Route path={RoutePath.CUSTOMERS}>
                    <Route path="" element={<CustomerList />} />
                    
                    <Route path={RoutePath.CREATE} element={<CustomerForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path={RoutePath.EDIT} element={<CustomerForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.CUSTOMER_TYPES}>
                    <Route path="" element={<CustomerTypeList />} />
                    
                    <Route path={RoutePath.CREATE} element={<CustomerTypeForm />} />

                    <Route path=":id" element={<IdElement />}>
                        <Route path={RoutePath.EDIT} element={<CustomerTypeForm />} />
                    </Route>
                </Route>

                <Route path={RoutePath.PURCHASES_RECEPTION}>
                    <Route path="" element={<PurchaseReceptionListLoader />} />
                </Route>

                <Route path={RoutePath.SALES_DELIVERY}>
                    <Route path="" element={<SaleDeliveryList />} />
                </Route>

                <Route path={RoutePath.HOARDS_DELIVERY}>
                    <Route path="" element={<HoardDeliveryList />} />
                </Route>

                <Route path={RoutePath.USER} element={<UserPanel />} />
            </Route>
            
            <Route path={RoutePath.ERROR} element={<NotFoundElement />} />

            <Route path="*" element={<Navigate to={RoutePath.ERROR} />} />
        </Route>
    )
)
