import * as actionCreators from '../state/actionCreators'
import { define, normalize } from "../../../utils/typeUtils"
import { useFind, useFindByParams } from "../../../state/reducers/hooks"
import { DataType, TableName } from "../../../tables"
import { Lot, Product, ProductPrices } from "../state/types"
import { ProductsState } from "../state/reducer"
import { useSelector } from "react-redux"
import { State } from "../../../state"
import { bindActionCreators } from "redux"
import { useDispatch } from "react-redux"
import { usePricesHooks } from "../../operations"
import { CustomerType, useSalesState } from "../../sales"
import { useAdministrationState } from "../../administration"
import { PriceItem } from "./types"
import { Supplier } from "../../purchases"
import { useFiltersHooks } from "../../../features/ui"
import { NO_LOT } from '../utils/lotUtils'

export const useProductsState = (): ProductsState => useSelector((state: State) => state.productsReducer)

export const useProductsStateActions = () => bindActionCreators(actionCreators, useDispatch())

export const useProductsHooks = () => {
    const { paymentTypes } = useAdministrationState()
    const { customerTypes } = useSalesState()

    const pricesHooks = usePricesHooks()
    const { search } = useFiltersHooks()
    const find = useFind()
    const findByParams = useFindByParams()

    const getPurchasePrice = (productId: string) => (define(find(TableName.PRODUCT_PRICES, productId, 'productId')) as ProductPrices).purchasePrice

    const getBaseLot = (productId: string): Lot => {
        const params = { productId, code: NO_LOT }
        return define(findByParams(TableName.LOTS, params)) as Lot
    }

    const getPriceList = (productId: string): PriceItem[] => {
        const list: PriceItem[] = []
        paymentTypes.forEach(paymentType => {
            customerTypes.forEach(customerType => {
                const price = pricesHooks.product.adjustedPrice(productId, paymentType, customerType)
                list.push({
                    customerTypeId: customerType.id,
                    paymentTypeId: paymentType.id,
                    price
                })
            })
        })

        const findCustomerType = (id: string) => define(find(TableName.CUSTOMER_TYPES, id)) as CustomerType

        const salePriceList = list.sort((a, b) => {
            const customerTypeA = findCustomerType(a.customerTypeId)
            const customerTypeB = findCustomerType(b.customerTypeId)
            if (customerTypeA.name < customerTypeB.name) return -1
            if (customerTypeA.name > customerTypeB.name) return 1
            return a.price - b.price
        })
        return salePriceList
    }

    const searchProducts = (
        products: Product[],
        text = '',
        showAll = false
    ): Product[] => {
        const valuesFn = (productDT: DataType) => {
            const { code, supplierCode, name, supplierId } = productDT as Product
            const supplier = define(find(TableName.SUPPLIERS, supplierId)) as Supplier
            return [
                code.toString(),
                supplierCode ? normalize(supplierCode) : '',
                normalize(name),
                normalize(supplier.name)
            ]
        }
        const sortFn = (productDT_A: DataType, productDT_B: DataType) => 
            (productDT_A as Product).name.localeCompare((productDT_B as Product).name)

        return search(products, valuesFn, sortFn, text, showAll) as Product[]
    }

    return {
        getPurchasePrice,
        getBaseLot,
        getPriceList,
        searchProducts
    }
}
