import { DataType, TableName } from '../../../tables'
import { ListResponse } from '../types'
import { RangeParams, TextParams } from '../../../state/reducers/types'
import { useDefaultDbActions } from './defaultDbActions'
import { useFindByParams, useFindByIds, useFindReducerRemover, useFindReducerUpdater } from '../../../state/reducers/hooks'
import { DynamoApi } from '../../../services/dynamo'
import { firstItem } from '../../../utils/listUtils'
import { O } from '../../../features/ui'

export const useDefaultModuleActions = () => {
    const defaultDbActions = useDefaultDbActions()
    const findReducerUpdater = useFindReducerUpdater()
    const findReducerRemover = useFindReducerRemover()
    const findByParams = useFindByParams()
    const findByIds = useFindByIds()

    const getUpdater = (tableName: TableName) => findReducerUpdater(tableName) as any
    const getRemover = (tableName: TableName) => findReducerRemover(tableName) as any

    return (dynamoApi: DynamoApi, companyId?: string) => {
        const fetchAll = async (
            tableName: TableName,
            limit?: number,
            startKey?: TextParams
        ) => {
            return defaultDbActions(dynamoApi, companyId).getAll(tableName, limit, startKey, undefined)
                .then(response => {
                    response.dataTypes.length > 0 && getUpdater(tableName)(response.dataTypes)
                    return response
                })
        }

        const fetchByParams = async (
            tableName: TableName,
            paramsFilter: TextParams
        ): Promise<O<DataType>> => {
            const item = findByParams(tableName, paramsFilter)
            if (item) {
                return new Promise(resolve => resolve(item))
            } else {
                return defaultDbActions(dynamoApi, companyId).getByParams(tableName, paramsFilter)
                    .then(dataTypes => {
                        const item = firstItem(dataTypes)
                        item && getUpdater(tableName)(dataTypes)
                        return item || undefined
                    })
            }
        }

        const fetchMultipleByParams = async (
            tableName: TableName,
            paramsFilter: TextParams
        ): Promise<DataType[]> => {
            return defaultDbActions(dynamoApi, companyId).getByParams(tableName, paramsFilter)
                .then(dataTypes => {
                    dataTypes.length > 0 && getUpdater(tableName)(dataTypes)
                    return dataTypes
                })
        }

        const fetchByIds = async (
            tableName: TableName,
            ids: string[]
        ): Promise<DataType[]> => {
            if (ids.length > 0) {
                const list = findByIds(tableName, ids)
                if (list.length === ids.length) {
                    return list
                } else {
                    return defaultDbActions(dynamoApi, companyId).getByIds(tableName, ids)
                        .then(dataTypes => {
                            dataTypes.length > 0 && getUpdater(tableName)(dataTypes)
                            return dataTypes
                        })
                }
            } else {
                return []
            }
        }

        const fetchFiltered = async (
            tableName: TableName,
            propFilters?: TextParams,
            rangeFilters?: RangeParams
        ): Promise<ListResponse> => {
            return defaultDbActions(dynamoApi, companyId).getFiltered(tableName, propFilters, rangeFilters)
                .then(response => {
                    response.dataTypes.length > 0 && getUpdater(tableName)(response.dataTypes)
                    return response
                })
        }

        const count = async (
            tableName: TableName,
            paramsFilter: TextParams
        ): Promise<number> => {
            return defaultDbActions(dynamoApi, companyId).count(tableName, paramsFilter)
        }
        
        const save = async (
            tableName: TableName,
            dataType: DataType
        ): Promise<DataType> => {
            const dataTypes = [dataType]
            return defaultDbActions(dynamoApi, companyId).save(tableName, dataTypes)
                .then(_ => {
                    getUpdater(tableName)(dataTypes)
                    return dataType
                })
        }

        const saveMultiple = async (
            tableName: TableName,
            dataTypes: DataType[]
        ): Promise<DataType[]> => {
            if (dataTypes.length > 0) {
                return defaultDbActions(dynamoApi, companyId).save(tableName, dataTypes)
                    .then(_ => {
                        getUpdater(tableName)(dataTypes)
                        return dataTypes
                    })
            } else {
                return new Promise(resolve => resolve([]))
            }
        }

        const remove = async (
            tableName: TableName,
            id: string
        ): Promise<void> => {
            const ids = [id]
            return defaultDbActions(dynamoApi, companyId).remove(tableName, ids)
                .then(() => getRemover(tableName)(ids))
        }

        const removeMultiple = async (
            tableName: TableName,
            ids: string[]
        ): Promise<void> => {
            if (ids.length > 0) {
                return defaultDbActions(dynamoApi, companyId).remove(tableName, ids)
                    .then(_ => getRemover(tableName)(ids))    
            } else {
                return new Promise(resolve => resolve())
            }
        }

        return {
            fetchAll,
            fetchByParams,
            fetchMultipleByParams,
            fetchByIds,
            fetchFiltered,
            count,
            save,
            saveMultiple,
            remove,
            removeMultiple
        }
    }
}
