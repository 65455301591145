import styles from './hoardPDFPage.module.css'
import { Customer, HoardItem, HoardOrder, HoardType } from "../../../state/types"
import { define } from '../../../../../utils/typeUtils'
import { useEffect, useMemo, useState } from 'react'
import { Employee, useEmployeeLoaders, usePaymentTypeLoaders } from '../../../../administration'
import { useProductLoaders } from '../../../../products'
import { useDataTypesHooks, useDescriptorHooks } from '../../../../../features/data-types'
import { useBranchLoaders } from '../../../../manager'
import { PriceBreakdown, usePriceBreakdownHooks } from '../../../../operations'
import { FieldType, PDFHeader } from '../../../../../features/ui'
import { HoardItemPDFRow } from './types'
import { TableName } from '../../../../../tables'
import { useCustomerLoaders } from '../../../loaders/customerLoaders'
import { useCustomerTypeLoaders } from '../../../loaders/customerTypeLoaders'

type Props = {
    pageIndex: number
    hoard: HoardOrder
    hoardItems: HoardItem[]
    pagesAmount: number
}

export const HoardPDFPage = (props: Props) => {
    const { pageIndex, hoard, hoardItems, pagesAmount } = props
    const isLastPage = pageIndex === pagesAmount - 1
    
    const [customer, setCustomer] = useState<Customer>()
    const [seller, setSeller] = useState<Employee>()
    const [rows, setRows] = useState<HoardItemPDFRow[]>([])

    const { formatValue } = useDataTypesHooks()
    const descriptorHooks = useDescriptorHooks()
    const { getPriceBreakdownFromItems, getOperationPriceBreakdown } = usePriceBreakdownHooks()

    const branchLoaders = useBranchLoaders()
    const productLoaders = useProductLoaders()
    const { loadCustomer } = useCustomerLoaders()
    const customerTypeLoaders = useCustomerTypeLoaders()
    const employeeLoaders = useEmployeeLoaders()
    const paymentTypeLoaders = usePaymentTypeLoaders()

    const branch = useMemo(() => define(branchLoaders().findBranch(hoard.branchId)), [hoard])
    const customerType = useMemo(() => customerTypeLoaders().findCustomerType(customer?.customerTypeId), [customer])
    const paymentType = useMemo(() => define(paymentTypeLoaders().findPaymentType(hoard.paymentTypeId)), [hoard])
    const isProductHoard = hoard.type === HoardType.PRODUCT
    const priceBreakdown = useMemo(() => getPriceBreakdownFromItems(hoardItems, customerType?.yield), [hoardItems, customerType])
    const adjustedPriceBreakdown = getOperationPriceBreakdown(TableName.HOARD_ORDERS, hoard)
    
    const loadRows = async () => {
        const productIds = hoardItems.map(hoardItem => hoardItem.productId)
        const { loadProductsByIds } = productLoaders()
        const products = await loadProductsByIds(productIds)
        return hoardItems.map(hoardItem => {
            const product = define(products.find(product => product.id === hoardItem.productId))
            const itemPriceBreakdown = define(priceBreakdown.items.find(item => item.productId === product.id))
            return {
                product: product.name,
                content: descriptorHooks.product.content(product),
                unitSubtotal: formatValue(itemPriceBreakdown.unitSubtotal, FieldType.PRICE),
                unitTotal: formatValue(itemPriceBreakdown.unitTotal, FieldType.PRICE),
                quantity: descriptorHooks.product.unitsDetail(hoardItem.quantity, product),
                total: formatValue(itemPriceBreakdown.total, FieldType.PRICE)
            }
        })
    }

    useEffect(() => {
        employeeLoaders().loadEmployee(hoard.sellerId).then(setSeller)
        loadCustomer(hoard.customerId).then(setCustomer)
        loadRows().then(newRows => setRows(newRows))
    }, [hoard, hoardItems, priceBreakdown])

    const columns = [
        { name: 'product', label: 'Producto' },
        { name: 'content', label: 'Contenido' },
        { name: 'unitSubtotal', label: 'P. Unitario s/IVA' },
        { name: 'unitTotal', label: 'P. Unitario' },
        { name: 'quantity', label: 'Cantidad' },
        { name: 'total', label: 'P. Total' },
    ]

    return (
        <div className={styles.container}>
            <div className={styles.body}>
                <PDFHeader noValidTicketLabel />

                {pageIndex === 0 && (<>
                    <div className={styles.row}>
                        <span className={styles.hoardTitle}>
                            Acopio #{hoard.code}
                        </span>
                        <div className={styles.date}>
                            Fecha: {formatValue(new Date(hoard.creationDate), FieldType.DATE)}
                        </div>
                    </div>

                    <div className={`${styles.row} ${styles.alignStart}`}>
                        <div className={styles.infoSection}>
                            <span>Cliente: {customer?.name}</span>
                            <span>Dirección: {customer?.address || '-'}</span>
                            <span>DNI/CUIT: {customer?.identifier}</span>
                            <span>Teléfono: {customer?.phone || '-'}</span>
                        </div>
                        <div className={`${styles.infoSection} ${styles.rightSection}`}>
                            <span>Vendedor: {seller?.name}</span>
                            <span>Teléfono: {seller?.phone || '-'}</span>
                        </div>
                    </div>

                    <div className={`${styles.row} ${styles.alignStart}`}>
                        <div className={styles.infoSection}>
                            <span>Sucursal: {branch.name}</span>
                            <span>Dirección: {branch.address}</span>
                        </div>
                        <div className={`${styles.infoSection} ${styles.rightSection}`}>
                            <span>Fecha de Vto.: {formatValue(new Date(hoard.dueDate), FieldType.DATE)}</span>
                        </div>
                    </div>

                    <div className={`${styles.row} ${styles.alignStart}`}>
                        <div className={styles.infoSection}>
                            <span>Medio de Pago: {paymentType.name} ({formatValue(paymentType.yield, FieldType.PERCENTAGE)})</span>
                        </div>
                    </div>
                </>)}

                {rows.length > 0 && (
                    <>
                        <div className={styles.tableRow}>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        {columns.map((column, index) => (
                                            <th key={index}>
                                                {column.label}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                        {rows.map((row, index) => (
                                            <tr key={index}>
                                                {columns.map((column, index) => (
                                                    <td key={index}>
                                                        {row[column.name as keyof HoardItemPDFRow]}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                        <div className={styles.pagination}>
                            {pageIndex + 1} / {pagesAmount}
                        </div>
                    </>
                )}

                {!isProductHoard && (
                    <div className={styles.separator} />
                )}
                
                {isLastPage && (
                    <div className={`${styles.row} ${styles.priceBreakdown}`}>
                        <PriceBreakdown data={adjustedPriceBreakdown} />
                    </div>
                )}

                <div className={styles.footer}>
                    <div className={styles.separator} />
                    <div className={`${styles.row} ${styles.footerRow}`}>
                        <span>
                            Sucursal {branch.name}: {branch.address}{branch.email ? ` - Mail: ${branch.email}` : ''}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
