import { useEffect, useState } from "react"
import { Action, PanelLoader } from "../../../../../features/ui"
import { PaymentOrderList } from "./paymentOrderList"
import { useNavigate } from "react-router-dom"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { PaymentOrder } from "../../../state/types"
import { usePaymentOrderLoaders } from "../../../loaders/paymentOrderLoaders"

export const PaymentOrderListLoader = () => {
    const [paymentOrders, setPaymentOrders] = useState<PaymentOrder[]>([])
    const [ready, setReady] = useState(false)

    const navigate = useNavigate()
    const { getRoutePath } = useRoutesHooks()

    const paymentOrderLoaders = usePaymentOrderLoaders()

    const populate = async () => {
        const { loadAllPaymentOrders } = paymentOrderLoaders()
        const loadedPaymentOrders = await loadAllPaymentOrders()
        setPaymentOrders(loadedPaymentOrders)
    }

    useEffect(() => {
        populate().then(() => setReady(true))
    }, [])

    const onGoToPendingPayments = () => {
        navigate(getRoutePath(RoutePath.PENDING_PAYMENTS))
    }

    const headerActions: Action[] = [
        { label: 'Pagos Pendientes', kind: 'tertiary', onClick: onGoToPendingPayments }
    ]

    return (
        <PanelLoader
            title="Órdenes de Pago"
            actions={headerActions}
            ready={ready}
        >
            <PaymentOrderList paymentOrders={paymentOrders} />
        </PanelLoader>
    )
}
