import { TableName } from "../../../../../tables"
import { useDescriptorHooks } from "../../../../../features/data-types"
import { FieldType, FormField } from "../../../../../features/ui"
import { WizardStep } from "../../../../../features/wizard"
import { PurchaseOrder } from "../../../state/types"

type Props = {
    wizardKey: string
    stepIndex: number
    purchase: PurchaseOrder
}

export const PurchaseInfoStep = (props: Props) => {
    const { wizardKey, stepIndex, purchase } = props
    const descriptorHooks = useDescriptorHooks()

    const fields: FormField[] = [
        {
            name: 'code',
            type: FieldType.NUMBER,
            label: 'Compra',
            render: () => <>{descriptorHooks.purchase.code(purchase)}</>
        },
        {
            name: 'date',
            type: FieldType.DATE,
            defaultValue: new Date(purchase.deliveryDate),
            label: 'Fecha de Recepción',
            focus: true
        },
        {
            name: 'deliveryBranchId',
            type: FieldType.TABLE,
            table: TableName.BRANCHES,
            defaultValue: purchase.deliveryBranchId,
            label: 'Sucursal de Recepción'
        }
    ]

    return <WizardStep
        wizardKey={wizardKey}
        stepIndex={stepIndex}
        fields={fields}
    />
}
