import { useState } from "react"
import { CustomerType } from "../../../state/types"
import { AdjustmentType } from "../../../../operations"
import { useActions } from "./actions"
import { PanelWrapper, Data, FieldType, FieldValue, FormField, option, useGetCrumbs, O } from "../../../../../features/ui"
import { DataTypeForm, Labels } from "../../../../../features/data-types"
import { useNavigate } from "react-router-dom"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"

export const CustomerTypeForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const customerType = dataType as O<CustomerType>
    const createMode = !customerType

    const defaultType = customerType && customerType?.yield < 0 ? AdjustmentType.DISCOUNT : AdjustmentType.SURCHARGE
    const [adjustmentType, setAdjustmentType] = useState<O<AdjustmentType>>(defaultType)
    const [customerTypeYield, setCustomerTypeYield] = useState<number>(customerType?.yield || 0)

    const navigate = useNavigate()

    const { submitCustomerType } = useActions()

    const customerTypeLabel = customerType ? `: ${customerType.name}` : ''
    const title = `${createMode ? 'Crear' : 'Editar'} Tipo de Cliente${customerTypeLabel}`

    const crumbs = useGetCrumbs('Tipos de Cliente', RoutePath.CUSTOMER_TYPES, customerType?.name, undefined, createMode ? 'Crear' : 'Editar')

    const typeOptions = Object.values(AdjustmentType).map(value => option(value, Labels.adjustmentTypeLabel(value)))

    const onAdjustmentTypeChange = (value?: FieldValue) => {
        const newType = value as O<AdjustmentType>
        setAdjustmentType(newType)
        const yieldAbsoluteValue = Math.abs(customerTypeYield)
        setCustomerTypeYield(newType === AdjustmentType.DISCOUNT ? -yieldAbsoluteValue : yieldAbsoluteValue)
    }

    const onYieldChange = (value?: FieldValue) => {
        const newYield = (value || 0) as number
        setCustomerTypeYield(adjustmentType === AdjustmentType.DISCOUNT && newYield !== 0 ? -newYield : newYield)
    }
    
    const fields: FormField[] = [
        { name: 'name', type: FieldType.TEXT, label: 'Nombre' },
        {
            name: 'adjustmentType',
            type: FieldType.SELECT,
            defaultValue: adjustmentType,
            options: typeOptions,
            label: 'Tipo de Ajuste',
            onChange: onAdjustmentTypeChange
        },
        {
            name: 'newYield',
            type: FieldType.PERCENTAGE,
            defaultValue: Math.abs(customerTypeYield),
            label: 'Porcentaje de Ajuste',
            onChange: onYieldChange
        }
    ]

    const onSubmit = (customerTypeData: Data) => submitCustomerType(customerTypeData, customerTypeYield, createMode)

    const onCancel = () => navigate(-1)

    return (
        <PanelWrapper title={title} crumbs={crumbs}>
            <DataTypeForm
               formId="customer-type-form"
               fields={fields}
               createMode={createMode}
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
        </PanelWrapper>
    )
}
