import styles from './operationItemForm.module.css'
import { useEffect, useState } from "react"
import { FieldValue, Form, Data, FormField, ModalId, useUIActions, FieldType, Option, option, useUIState, O } from "../../../../features/ui"
import { Product, useProductsState, Lot, ProductSelectField } from "../../../products"
import { TableName } from "../../../../tables"
import { useFind } from "../../../../state/reducers/hooks"
import { define } from "../../../../utils/typeUtils"
import { useDescriptorHooks } from '../../../../features/data-types'
import { Operation } from '../../state/types'
import { usePricesHooks } from '../../hooks/pricesHooks'
import { PricesListItem } from './types'
import { useManagerState } from '../../../manager'
import { firstItem } from '../../../../utils/listUtils'

type Props = {
    operationItem?: Data
    operation: Operation
    branchId?: string
    withLots?: boolean
    pricesList?: PricesListItem[]
    filterProducts?: (product: Product) => boolean
    filterLots?: (lot: Lot) => boolean
    onSubmit: (data: Data) => void
}

export const OperationItemForm = (props: Props) => {
    const { operationItem, operation, branchId, withLots = false, pricesList, filterProducts, filterLots, onSubmit } = props
    const createMode = !operationItem
    const isSale = operation === Operation.SALE

    const { currentBranch } = useManagerState()
    const definedBranchId = define(branchId || currentBranch?.id)
    const { products, lots } = useProductsState()
    const { openedModal } = useUIState()

    const defaultProductId = operationItem?.productId as O<string>
    const [productId, setProductId] = useState<O<string>>(defaultProductId)
    const defaultLotId = operationItem?.lotId as O<string>
    const [lotId, setLotId] = useState<O<string>>(defaultLotId)
    const [price, setPrice] = useState<O<number>>(operationItem?.price as O<number>)
    const [focusProduct, setFocusProduct] = useState(false)
    const currentProduct = products.find(product => product.id === productId) as O<Product>

    const pricesHooks = usePricesHooks()
    const descriptorHooks = useDescriptorHooks()
    const find = useFind()

    const { toggleModal } = useUIActions()

    useEffect(() => {
        setTimeout(() => setFocusProduct(openedModal === ModalId.OPERATION_ITEM_FORM), 500)
    }, [openedModal])

    const getProductLots = (id?: string) => lots.filter(lot => lot.productId === id)
    const getLotList = (productLots: Lot[]) => filterLots ? productLots.filter(filterLots) : productLots    

    const lotList = withLots ? getLotList(getProductLots(productId)) : []
    const productList = products.filter(product => {
        const productLots = getProductLots(product.id)
        return product.loted && withLots ? getLotList(productLots).length > 0 : (!filterProducts || filterProducts(product))
    })
    
    const lotOptions = lotList.map(lot => option(lot.id, lot.code))

    const onProductChange = (value?: FieldValue) => {
        const newProductId = value as O<string>
        setProductId(newProductId)
        setLotId(undefined)
        if (newProductId) {
            if (isSale) {
                const basePrice = pricesHooks.product.basePrice(newProductId)
                const newPrice = pricesList ? (pricesList.find(item => item.productId === newProductId)?.price || basePrice) : basePrice
                setPrice(newPrice)
            } else {
                setPrice(pricesHooks.product.purchasePrice(newProductId))
            }
        }
    }

    const onLotChange = (value?: FieldValue) => {
        setLotId(value as O<string>)
    }

    const renderLotOption = (option: Option, onClick?: (e: React.MouseEvent<HTMLElement>)=> void) => {
        const lot = define(find(TableName.LOTS, option.value)) as Lot
        const product = define(find(TableName.PRODUCTS, lot.productId)) as Product
        const stockInfo = descriptorHooks.product.lot.availableStock(product, definedBranchId, lot)
        const stockClassName = `${styles.productStock} ${stockInfo.startsWith('0') ? styles.noStock : ''}`
        return (
            <div className={styles.productOption} onClick={onClick}>
                {lot.code}
                <span className={stockClassName}>
                    {stockInfo}
                </span>
            </div>
        )
    }

    const fields: FormField[] = [
        {
            name: 'productId',
            type: FieldType.TABLE,
            table: TableName.PRODUCTS,
            label: 'Producto',
            render: () => <ProductSelectField
                productList={productList}
                branchId={definedBranchId}
                defaultValue={defaultProductId}
                focus={focusProduct}
                onChange={onProductChange}
            />
        },
        {
            name: 'price',
            type: FieldType.PRICE,
            label: `Precio de ${isSale ? 'Lista' : 'Compra'}`,
            value: price,
            disabled: true
        },
        {
            name: 'quantity',
            type: FieldType.NUMBER,
            label: 'Cantidad',
            defaultValue: operationItem?.quantity || 1,
            min: 1,
            allowDecimals: false
        }
    ]
    if (withLots && currentProduct?.loted) {
        const lotField = {
            key: `lot-id-${productId}`,
            name: 'lotId',
            type: FieldType.SELECT,
            label: 'Partida',
            defaultValue: defaultLotId,
            options: lotOptions,
            placeholder: 'Seleccionar partida',
            renderOption: renderLotOption,
            onChange: onLotChange
        }
        fields.splice(1, 0, lotField)
    }

    const handleSubmit = (data: Data) => {
        const dataLotId = withLots ? (currentProduct?.loted ? lotId : define(firstItem(lotList)).id) : undefined
        onSubmit({
            ...data,
            productId,
            lotId: dataLotId
        })
        toggleModal(ModalId.OPERATION_ITEM_FORM)
    }

    const handleCancel = () => toggleModal(ModalId.OPERATION_ITEM_FORM)

    return (
        <Form
            formId="operation-item-form"
            fields={fields}
            createMode={createMode}
            createLabel="Agregar"
            onSubmit={handleSubmit}
            onCancel={handleCancel}
        />
    )
}
