import { useDescriptorHooks } from "../../../features/data-types"
import { useFiltersHooks } from "../../../features/ui"
import { DataType } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { useCashFundLoaders } from "../loaders/cashFundLoaders"
import { useCheckLoaders } from "../loaders/checkLoaders"
import { useCreditCardLoaders } from "../loaders/creditCardLoaders"
import { PaymentOrder } from "../state/types"

export const usePaymentOrderHooks = () => {
    const cashFundLoaders = useCashFundLoaders()
    const checkLoaders = useCheckLoaders()
    const creditCardLoaders = useCreditCardLoaders()

    const descriptorHooks = useDescriptorHooks()
    const { search } = useFiltersHooks()

    const describeSource = async (sourceId: string): Promise<string> => {
        const { findCashFund } = cashFundLoaders()
        const cashFund = findCashFund(sourceId)
        if (cashFund) {
            return `Caja: ${cashFund.name}`
        }
        const { loadCheck } = checkLoaders()
        const check = await loadCheck(sourceId)
        if (check) {
            return `Cheque: ${descriptorHooks.check.info(check)}`
        }
        const { findCreditCard } = creditCardLoaders()
        const creditCard = findCreditCard(sourceId)
        return descriptorHooks.creditCard.info(define(creditCard))
    }

    const searchPaymentOrders = (
        paymentOrderList: PaymentOrder[],
        text = '',
        showAll = false
    ): PaymentOrder[] => {
        const valuesFn = (paymentOrderDT: DataType) => {
            const { code } = paymentOrderDT as PaymentOrder
            return [code.toString()]
        }
        const sortFn = (paymentOrderDT_A: DataType, paymentOrderDT_B: DataType) => 
            (paymentOrderDT_B as PaymentOrder).creationDate.localeCompare((paymentOrderDT_A as PaymentOrder).creationDate)

        return search(paymentOrderList, valuesFn, sortFn, text, showAll) as PaymentOrder[]
    }

    return {
        describeSource,
        searchPaymentOrders
    }
}
