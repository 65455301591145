import styles from './ticketList.module.css'
import { Button, FieldType, FileTile, FileType, ModalId, Section, useUIActions } from '../../../../../features/ui'
import { useMemo } from 'react'
import { fileNameFromUrl } from '../../../../../features/files'
import { useDataTypesHooks } from '../../../../../features/data-types'
import { useNavigate } from 'react-router-dom'
import { RoutePath } from '../../../../../features/routes'
import { Ticket } from '../../../state/types'

type Props = {
  tickets: Ticket[]
  ticketLabel?: string
  readOnly?: boolean
  onChangeCurrentTicket?: (ticket: Ticket) => void
}

export const TicketList = (props: Props) => {
  const { tickets, ticketLabel = 'Factura', readOnly, onChangeCurrentTicket } = props

  const { formatValue } = useDataTypesHooks()
  const navigate = useNavigate()

  const { toggleModal } = useUIActions()

  const dataList = useMemo(() => tickets
    .sort((ticket_A, ticket_B) => ticket_A.dueDate.localeCompare(ticket_B.dueDate))
    .map((ticket, index) => ({ index, ticket }))
  , [tickets])
  
  const onDownload = (ticket: Ticket) => () => {
    onChangeCurrentTicket && onChangeCurrentTicket(ticket)
    toggleModal(ModalId.DOWNLOAD_TICKET)
  }

  const onEdit = (ticketId: string) => () => {
    navigate(`${RoutePath.TICKETS}/${ticketId}/${RoutePath.EDIT}`)
  }

  return (
    <>
      {dataList
        .sort((data_A, data_B) => data_A.index > data_B.index ? 1 : -1)
        .map((data) => (
          <Section
            key={data.index}
            id={`ticket-${data.index}`}
            title={`${ticketLabel} #${data.ticket.code} - Fecha Vto.: ${formatValue(new Date(data.ticket.dueDate), FieldType.DATE)} - ${formatValue(data.ticket.total, FieldType.PRICE)}`}
            collapsable
            defaultExpanded
            className={styles.ticketSection}
          >
            <div className={styles.container}>
              <FileTile
                fileType={FileType.PDF}
                fileName={fileNameFromUrl(data.ticket.ticketUrl)}
              />
              <div>
                <Button
                  icon='eye-fill'
                  kind='tertiary'
                  tooltip={`Ver ${ticketLabel}`}
                  small
                  rightMargin
                  onClick={onDownload(data.ticket)}
                />
                <Button
                  icon='download'
                  kind='tertiary'
                  tooltip='Descargar'
                  small
                  rightMargin
                  onClick={onDownload(data.ticket)}
                />
                {!readOnly && data.ticket.payedAmount === 0 && (
                  <Button
                    icon='pencil-fill'
                    tooltip='Editar'
                    small
                    onClick={onEdit(data.ticket.id)}
                  />
                )}
              </div>
            </div>
          </Section>
        )
      )}
    </>
  )
}
