import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import * as actionCreators from "../state/actionCreators"
import { AdministrationState } from "../state/reducer"
import { useSelector } from "react-redux"
import { State } from "../../../state"
import { define, normalize } from "../../../utils/typeUtils"
import { CashFund, CategorySaleYield, Check, CheckStatus, Employee, PaymentType, Settings, SettingsModule } from "../state/types"
import { useFind } from "../../../state/reducers/hooks"
import { Labels, Standards } from "../../../features/data-types"
import { DataType, TableName } from "../../../tables"
import { usePurchasesHooks } from "../../purchases"
import { useFiltersHooks } from "../../../features/ui"

export const useAdministrationState = (): AdministrationState => useSelector((state: State) => state.administrationReducer)

export const useAdministrationStateActions = () => bindActionCreators(actionCreators, useDispatch())

export const useAdministrationHooks = () => {
    const { settings, expenses } = useAdministrationState()
    const { search } = useFiltersHooks()
    const { getSettings } = usePurchasesHooks()
    const find = useFind()

    const applyPaymentTypeAdjustment = (
        price: number,
        paymentType: PaymentType
    ) => {
        return price * (1 + (paymentType.yield / 100))
    }

    const getSalesSettings = (): Settings => define(settings.find(setting => setting.module === SettingsModule.SALES))

    const getParentSaleYield = (categoryId?: string) => {
        const settings = getSettings()
        const generalSaleYield = define(settings?.generalSaleYield) as number
        const categorySaleYields = (settings?.categorySaleYields || []) as CategorySaleYield[]
        const categorySaleYield = categorySaleYields.find(categorySaleYield => categorySaleYield.categoryId === categoryId)?.saleYield
        return categorySaleYield || generalSaleYield
    }

    const getBranchExpenses = (branchId: string) => {
        return expenses.filter(expense => expense.branchId === branchId)
    }

    const getSalaryExpenseTypeId = () => {
        return define(find(TableName.EXPENSE_TYPES, Standards.SalaryExpenseTypeName, 'name')?.id)
    }

    const searchEmployees = (
        employees: Employee[],
        text = '',
        showAll = false
    ): Employee[] => {
        const valuesFn = (employeeDT: DataType) => {
            const { name, identifier, email } = employeeDT as Employee
            return [
                normalize(name),
                identifier ? identifier.toString() : '',
                normalize(email)
            ]
        }
        const sortFn = (employeeDT_A: DataType, employeeDT_B: DataType) => 
            (employeeDT_A as Employee).name.localeCompare((employeeDT_B as Employee).name)

        return search(employees, valuesFn, sortFn, text, showAll) as Employee[]
    }

    const searchCashFunds = (
        cashFunds: CashFund[],
        text = '',
        showAll = false
    ): CashFund[] => {
        const valuesFn = (cashFundDT: DataType) => {
            const { name, type } = cashFundDT as CashFund
            return [
                normalize(name),
                normalize(Labels.cashFundTypeLabel(type))
            ]
        }
        const sortFn = (cashFundDT_A: DataType, cashFundDT_B: DataType) => 
            (cashFundDT_A as CashFund).name.localeCompare((cashFundDT_B as CashFund).name)

        return search(cashFunds, valuesFn, sortFn, text, showAll) as CashFund[]
    }

    const searchChecks = (
        checks: Check[],
        text = '',
        showAll = false
    ): Check[] => {
        const valuesFn = (checkDT: DataType) => {
            const { name, taxId } = checkDT as Check
            return [
                normalize(name),
                taxId.toString()
            ]
        }
        const sortFn = (checkDT_A: DataType, checkDT_B: DataType) => {
            const statusPriorities = new Map<CheckStatus, number>()
                .set(CheckStatus.ENABLED, 1)
                .set(CheckStatus.RESERVED, 2)
                .set(CheckStatus.ENDORSED, 3)
            const check_A = checkDT_A as Check
            const check_B = checkDT_B as Check
            const statusOrder = (statusPriorities.get(check_A.status) || 0) - (statusPriorities.get(check_B.status) || 0)
            return statusOrder !== 0 ? statusOrder : check_A.paymentDate.localeCompare(check_B.paymentDate)
        }

        return search(checks, valuesFn, sortFn, text, showAll) as Check[]
    }

    const searchPaymentTypes = (
        paymentTypes: PaymentType[],
        text = '',
        showAll = false
    ): PaymentType[] => {
        const valuesFn = (paymentTypeDT: DataType) => {
            const { name } = paymentTypeDT as PaymentType
            return [normalize(name)]
        }
        const sortFn = (paymentTypeDT_A: DataType, paymentTypeDT_B: DataType) => 
            (paymentTypeDT_A as PaymentType).name.localeCompare((paymentTypeDT_B as PaymentType).name)

        return search(paymentTypes, valuesFn, sortFn, text, showAll) as PaymentType[]
    }

    return {
        applyPaymentTypeAdjustment,
        getSalesSettings,
        getParentSaleYield,
        getBranchExpenses,
        getSalaryExpenseTypeId,
        searchEmployees,
        searchCashFunds,
        searchChecks,
        searchPaymentTypes
    }
}
