import { DownloadFileModal, ModalId } from "../../../../../features/ui"
import { DataType } from "../../../../../tables"
import { SaleOperation } from "../../../../sales"

type Props = {
    operation?: SaleOperation
}

export const DownloadInvoiceModal = (props: Props) => {
    const { operation } = props

    return (
        <DownloadFileModal
            modalId={ModalId.DOWNLOAD_INVOICE}
            dataType={operation}
            fileProp={'invoiceUrl' as keyof DataType}
            title="Descargar Factura"
        />
    )
}
