import { O } from '../../../features/ui'
import { TableName } from '../../../tables'
import { useLoaders } from '../../common'
import { Customer } from '../state/types'

export const useCustomerLoaders = () => {
    const loaders = useLoaders()

    const loadAllCustomers = () => {
        const { loadAll } = loaders()
        return loadAll(TableName.CUSTOMERS) as Promise<Customer[]>
    }

    const loadCustomer = (id: string) => {
        const { load } = loaders()
        return load(TableName.CUSTOMERS, id) as Promise<O<Customer>>
    }

    return {
        loadAllCustomers,
        loadCustomer
    }
}
