import { useMemo } from "react"
import { Data, useDataTypesHooks, useDescriptorHooks } from "../../../../../features/data-types"
import { FieldType, Modal, ModalId } from "../../../../../features/ui"
import { useFind } from "../../../../../state/reducers/hooks"
import { TableName } from "../../../../../tables"
import { define } from "../../../../../utils/typeUtils"
import { PaymentTicketItem, Ticket } from "../../../state/types"
import { useAdministrationState } from "../../../hooks/administrationHooks"
import { useTicketHooks } from "../../../hooks/ticketHooks"

type Props = {
    data?: Data
    table: TableName
    paymentTicketItems: PaymentTicketItem[]
    onSubmit: (data: Data) => void
}

export const PartialPaymentModal = (props: Props) => {
    const { data, table, paymentTicketItems, onSubmit } = props

    const { selectedTickets } = useAdministrationState()

    const { getTicketPendingAmount, findParent, isPurchase } = useTicketHooks()
    const { formatValue } = useDataTypesHooks()
    const descriptorHooks = useDescriptorHooks()
    const find = useFind()

    const title = `Pago Parcial de Facturas`

    const pendingTickets = useMemo(() =>
        selectedTickets.filter(ticket => !paymentTicketItems.map(item => item.ticketId).includes(ticket.id)),
    [selectedTickets, paymentTicketItems])
    
    const renderBody = () => <>
        Se está efectuando un pago parcial del total de las facturas:<br />
        <ul>
            {paymentTicketItems.map((item, index) => {
                const ticket = define(find(TableName.TICKETS, item.ticketId)) as Ticket
                const parent = define(findParent(table, ticket.parentId))
                const parentLabel = isPurchase(table) ? 'Compra' : 'Gasto'
                const paymentLabel = item.payedAmount === getTicketPendingAmount(ticket) ? 'Pago completo' : 'Pago parcial'
                
                return <li key={index}>
                    {parentLabel} {descriptorHooks.dataType(table, parent)} -
                    Vto. {formatValue(new Date(ticket.dueDate), FieldType.DATE)}:
                    <b> {formatValue(item.payedAmount, FieldType.PRICE)} - {paymentLabel}</b>
                </li>
            })}

            {pendingTickets.map((ticket, index) => {
                const parent = define(findParent(table, ticket.parentId))
                const parentLabel = isPurchase(table) ? 'Compra' : 'Gasto'
                const paymentLabel = 'Pago pendiente'
                
                return <li key={index}>
                    {parentLabel} {descriptorHooks.dataType(table, parent)} -
                    Vto. {formatValue(new Date(ticket.dueDate), FieldType.DATE)}:
                    <b> {formatValue(0, FieldType.PRICE)} - {paymentLabel}</b>
                </li>
            })}
        </ul>
        Deseas confirmar el pago pacial?
    </>

    const handleSubmit = () => {
        data && onSubmit(data)
    }

    return (
        <Modal
            modalId={ModalId.PARTIAL_PAYMENT_MODAL}
            title={title}
            submitLabel="Confirmar"
            widthSize="m"
            onSubmit={handleSubmit}
        >
            {renderBody()}
        </Modal>
    )
}
