import { useDefaultModuleActions } from "../../../common"
import { DynamoUserApi } from "../../../../services"
import { DataType, TableName } from "../../../../tables"
import { HoardOrder, HoardItem } from "../../state/types"
import { useDataTypeFileActions } from "../../../../features/data-types"
import { define } from "../../../../utils/typeUtils"
import { PaymentStatus } from "../../../operations"
import { useCashFundActions } from "../../../administration"

export const useCommonActions = () => {
    const defaultModuleActions = useDefaultModuleActions()
    const dataTypeFileActions = useDataTypeFileActions()
    const { addToCashFund } = useCashFundActions()

    return (companyId: string) => {
        const { fetchByParams, fetchMultipleByParams, fetchByIds, count, save, saveMultiple, remove, removeMultiple } = defaultModuleActions(DynamoUserApi, companyId)

        const fetchAllHoards = (branchId: string) => {
            return fetchMultipleByParams(TableName.HOARD_ORDERS, { branchId })
        }

        const fetchHoard = (id: string) => {
            return fetchByParams(TableName.HOARD_ORDERS, { id })
        }

        const countHoards = (propName: string, propValue: string) => {
            return count(TableName.HOARD_ORDERS, { [propName]: propValue })
        }

        const saveHoard = (hoard: HoardOrder) => {
            return save(TableName.HOARD_ORDERS, hoard)
        }

        const saveHoardWithFile = async (
            hoard: HoardOrder,
            file: File,
            fileProp = 'invoiceUrl'
        ) => {
            const { saveWithFile } = dataTypeFileActions(define(companyId))
            return saveWithFile(TableName.HOARD_ORDERS, hoard, fileProp as keyof DataType, file)
        }

        const removeHoard = (id: string) => {
            return remove(TableName.HOARD_ORDERS, id)
        }

        const fetchHoardItemsByOrder = (hoardId: string) => {
            return fetchMultipleByParams(TableName.HOARD_ITEMS, { hoardId })
        }

        const fetchHoardItemsByIds = (ids: string[]) => {
            return fetchByIds(TableName.HOARD_ITEMS, ids)
        }

        const countHoardItems = (propName: string, propValue: string) => {
            return count(TableName.HOARD_ITEMS, { [propName]: propValue })
        }

        const saveHoardItems = (hoardItems: HoardItem[]) => {
            return saveMultiple(TableName.HOARD_ITEMS, hoardItems)
        }

        const removeHoardItems = (hoardItems: HoardItem[]) => {
            return removeMultiple(TableName.HOARD_ITEMS, hoardItems.map(hoardItem => hoardItem.id))
        }

        const confirmHoardPayment = async (
            hoard: HoardOrder,
            receiptFile: File,
            paymentTypeId: string,
            cashFundId: string,
            totalAmount: number
        ) => {
            const payedHoard: HoardOrder = {
                ...hoard,
                paymentTypeId,
                paymentStatus: PaymentStatus.PAYED
            }
            const savedHoard = await saveHoardWithFile(payedHoard, receiptFile, 'receiptUrl')
            await addToCashFund(cashFundId, totalAmount)

            return savedHoard
        }

        return {
            fetchAllHoards,
            fetchHoard,
            countHoards,
            saveHoard,
            saveHoardWithFile,
            confirmHoardPayment,
            removeHoard,
            fetchHoardItemsByOrder,
            fetchHoardItemsByIds,
            countHoardItems,
            saveHoardItems,
            removeHoardItems
        }
    }
}
