import { useSaveActions } from "../../common"
import { TableName } from "../../../tables"
import { Transaction } from "../state/types"

export const useTransactionActions = () => {
    const saveActions = useSaveActions()

    const saveTransaction = async (transaction: Transaction) => {
        const { save } = saveActions()
        const dataType = await save(TableName.TRANSACTIONS, transaction)
        return dataType as Transaction
    }

    return {
        saveTransaction
    }
}
