import { normalize } from "../../../utils/typeUtils"
import { Expense } from "../state/types"
import { useFind } from "../../../state/reducers/hooks"
import { DataType, TableName } from "../../../tables"
import { Supplier } from "../../purchases"
import { O, useFiltersHooks } from "../../../features/ui"

export const useExpenseHooks = () => {
    const { search } = useFiltersHooks()
    const find = useFind()

    const searchExpenses = (
        expenseList: Expense[],
        text = '',
        showAll = false
    ): Expense[] => {
        const valuesFn = (expenseDT: DataType) => {
            const { code, name, supplierId } = expenseDT as Expense
            const supplier = find(TableName.SUPPLIERS, supplierId) as O<Supplier>
            return [
                code.toString(),
                normalize(name),
                supplier ? normalize(supplier.name) : ''
            ]
        }
        const sortFn = (expenseDT_A: DataType, expenseDT_B: DataType) => 
            (expenseDT_B as Expense).date.localeCompare((expenseDT_A as Expense).date)

        return search(expenseList, valuesFn, sortFn, text, showAll) as Expense[]
    }

    return {
        searchExpenses
    }
}
