import { Check, CreditCard, PaymentOrder, Transaction, useTransactionHooks, TransactionDestinationType, TransactionSourceType, Expense, Ticket, Employee } from "../../../modules/administration"
import { Lot, Product } from "../../../modules/products"
import { PurchaseOrder } from "../../../modules/purchases"
import { Budget, HoardOrder, SaleOrder } from "../../../modules/sales"
import { Stock, UnitType } from "../../../modules/stock"
import { useFind, useFindByParams, useFindMultipleByParams } from "../../../state/reducers/hooks"
import { DataType, NamedDataType, TableName } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { FieldType, O } from "../../ui"
import { useDataTypesHooks } from "./dataTypesHooks"

export const useDescriptorHooks = () => {
    const { formatValue } = useDataTypesHooks()
    const { getSourceTable, getSource, getDestinationTable, getDestination } = useTransactionHooks()
    const find = useFind()
    const findByParams = useFindByParams()
    const findMultipleByParams = useFindMultipleByParams()

    const dataType = (tableName: TableName, dataType: DataType) => {
        const identifiers = new Map<TableName, (dataType: DataType) => string>()
            .set(TableName.BUDGETS, (dataType: DataType) => budget.code(dataType as Budget))
            .set(TableName.CHECKS, (dataType: DataType) => check.info(dataType as Check))
            .set(TableName.CREDIT_CARDS, (dataType: DataType) => creditCard.info(dataType as CreditCard))
            .set(TableName.EXPENSES, (dataType: DataType) => expense.code(dataType as Expense))
            .set(TableName.HOARD_ORDERS, (dataType: DataType) => purchase.code(dataType as PurchaseOrder))
            .set(TableName.PAYMENT_ORDERS, (dataType: DataType) => paymentOrder.code(dataType as PaymentOrder))
            .set(TableName.PURCHASE_ORDERS, (dataType: DataType) => hoard.code(dataType as HoardOrder))
            .set(TableName.SALE_ORDERS, (dataType: DataType) => sale.code(dataType as SaleOrder))
        return identifiers.get(tableName)?.(dataType) || (dataType as NamedDataType).name || dataType.id
    }

    const dataType2 = (tableName: TableName, dataType: DataType) => {
        const identifiers = new Map<TableName, (dataType: DataType) => Promise<string>>()
            .set(TableName.BUDGETS, async (dataType: DataType) => budget.code(dataType as Budget))
            .set(TableName.CHECKS, async (dataType: DataType) => check.info(dataType as Check))
            .set(TableName.CREDIT_CARDS, async (dataType: DataType) => creditCard.info(dataType as CreditCard))
            .set(TableName.EMPLOYEES, async (dataType: DataType) => employee.info(dataType as Employee))
            .set(TableName.EXPENSES, async (dataType: DataType) => expense.code(dataType as Expense))
            .set(TableName.HOARD_ORDERS, async (dataType: DataType) => purchase.code(dataType as PurchaseOrder))
            .set(TableName.PAYMENT_ORDERS, async (dataType: DataType) => paymentOrder.code(dataType as PaymentOrder))
            .set(TableName.PURCHASE_ORDERS, async (dataType: DataType) => hoard.code(dataType as HoardOrder))
            .set(TableName.SALE_ORDERS, async (dataType: DataType) => sale.code(dataType as SaleOrder))
            .set(TableName.TRANSACTIONS, (dataType: DataType) => transaction.info(dataType as Transaction))
        return identifiers.get(tableName)?.(dataType) || (dataType as NamedDataType).name || dataType.id
    }

    const content = (units: number, unitTypeId: string): string => {
        const unitType = define(find(TableName.UNIT_TYPES, unitTypeId)) as UnitType
        return `${formatValue(units, FieldType.NUMBER)} ${unitType.abbreviation}`
    }

    const code = (dataType: PurchaseOrder | Expense | Budget | SaleOrder | HoardOrder | PaymentOrder | Product | Ticket) => `#${dataType.code}`

    const productContent = (product: Product): string => {
        return content(product.units, product.unitTypeId)
    }

    const unitsDetail = (
        units: number,
        product: Product
    ) => {
        const totalUnits = units * product.units
        const contentInfo = totalUnits && product.units !== 1 ? ` (${content(totalUnits, product.unitTypeId)})` : ''
        return `${formatValue(units, FieldType.NUMBER)}${contentInfo}`
    }

    const productAvailableStock = (
        product: Product,
        branchId: string
    ): string => {
        const stockList = findMultipleByParams(TableName.STOCK, { productId: product.id, branchId }) as Stock[]
        const units = stockList.reduce((sum, stock) => sum + stock.availableUnits, 0)
        return unitsDetail(units, product)
    }

    const productReservedStock = (
        product: Product,
        branchId: string
    ): string => {
        const stockList = findMultipleByParams(TableName.STOCK, { productId: product.id, branchId }) as Stock[]
        const units = stockList.reduce((sum, stock) => sum + stock.reservedUnits, 0)
        return unitsDetail(units, product)
    }

    const productLotAvailableStock = (
        product: Product,
        branchId: string,
        lot: Lot
    ): string => {
        const params = { productId: product.id, branchId, lotId: lot.id }
        const stock = findByParams(TableName.STOCK, params) as O<Stock>
        const units = stock?.availableUnits || 0
        return unitsDetail(units, product)
    }

    const productLotReservedStock = (
        product: Product,
        branchId: string,
        lot: Lot
    ): string => {
        const params = { productId: product.id, branchId, lotId: lot.id }
        const stock = findByParams(TableName.STOCK, params) as O<Stock>
        const units = stock?.reservedUnits || 0
        return unitsDetail(units, product)
    }

    const lot = {
        availableStock: productLotAvailableStock,
        reservedStock: productLotReservedStock,
    }

    const product = {
        code,
        content: productContent,
        unitsDetail,
        availableStock: productAvailableStock,
        reservedStock: productReservedStock,
        lot
    }

    const purchase = {
        code
    }

    const expense = {
        code
    }

    const budget = {
        code
    }

    const sale = {
        code
    }

    const hoard = {
        code
    }

    const paymentOrder = {
        code
    }

    const ticket = {
        code
    }

    const checkInfo = (check: Check) => `${check.name} (${formatValue(check.amount, FieldType.PRICE)})`

    const check = {
        info: checkInfo
    }

    const creditCardNumber = (last4Numbers: number) => `*-${last4Numbers}`

    const creditCardInfo = (creditCard: CreditCard) => `${creditCard.type.toUpperCase()} ${creditCardNumber(creditCard.last4Numbers)}`

    const creditCard = {
        number: creditCardNumber,
        info: creditCardInfo
    }

    const transactionSource = async (transaction: Transaction) => {
        if (transaction.sourceType === TransactionSourceType.MANUAL_ADDITION) {
            return 'Adición Manual'
        } else {
            const tableName = define(getSourceTable(transaction.sourceType))
            const source = await getSource(transaction.sourceType, transaction.sourceId)
            const prefix = transaction.sourceType === TransactionSourceType.CHECK ? 'Cheque: ' : ''
            return prefix + dataType(tableName, define(source))
        }
    }

    const transactionDestination = async (transaction: Transaction) => {
        if (transaction.destinationType === TransactionDestinationType.MANUAL_SUBSTRACTION) {
            return 'Retiro Manual'
        } else {
            const table = define(getDestinationTable(transaction.destinationType))
            const destination = await getDestination(transaction.destinationType, transaction.destinationId)
            const prefix = transaction.destinationType === TransactionDestinationType.PAYMENT_ORDER ? 'Orden de Pago ' : ''
            return prefix + dataType(table, define(destination))
        }
    }

    const transactionInfo = async (transaction: Transaction) =>
        `${await transactionDestination(transaction)} (${formatValue(transaction.amount, FieldType.PRICE)})`

    const transaction = {
        source: transactionSource,
        destination: transactionDestination,
        info: transactionInfo
    }

    const employeeInfo = (employee: Employee) => employee.name + (employee.identifier ? ` (${employee.identifier})` : '')

    const employee = {
        info: employeeInfo
    }

    return {
        dataType,
        dataType2,
        content,
        product,
        purchase,
        expense,
        budget,
        sale,
        hoard,
        paymentOrder,
        ticket,
        check,
        creditCard,
        transaction,
        employee
    }
}
