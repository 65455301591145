import { TableName } from '../../../tables'
import { useLoaders } from '../../common'
import { Budget } from '..'
import { useSystemStateHooks } from '../../system'

export const useBudgetLoaders = () => {
    const systemHooks = useSystemStateHooks()
    
    const loaders = useLoaders()

    return (companyId = systemHooks.companyId) => {
        const loadAllBudgets = () => {
            const { loadAll } = loaders(companyId)
            return loadAll(TableName.BUDGETS) as Promise<Budget[]>
        }

        return {
            loadAllBudgets
        }
    }
}
