import { normalize } from "../../../utils/typeUtils"
import { CreditCard } from "../state/types"
import { DataType } from "../../../tables"
import { useFiltersHooks } from "../../../features/ui"

export const useCreditCardHooks = () => {
    const { search } = useFiltersHooks()

    const searchCreditCards = (
        creditCards: CreditCard[],
        text = '',
        showAll = false
    ): CreditCard[] => {
        const valuesFn = (creditCardDT: DataType) => {
            const { type, issuer, cardholder, last4Numbers } = creditCardDT as CreditCard
            return [
                normalize(type),
                normalize(issuer),
                normalize(cardholder),
                last4Numbers.toString()
            ]
        }
        const sortFn = (creditCardDT_A: DataType, creditCardDT_B: DataType) => 
            (creditCardDT_A as CreditCard).dueDate.localeCompare((creditCardDT_B as CreditCard).dueDate)

        return search(creditCards, valuesFn, sortFn, text, showAll) as CreditCard[]
    }

    return {
        searchCreditCards
    }
}
