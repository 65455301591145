import { useNavigate } from "react-router-dom"
import { DataTypeForm, Labels } from "../../../../../features/data-types"
import { Data, FieldType, FormField, O, option } from "../../../../../features/ui"
import { CashFund, CashFundType } from "../../../state/types"
import { useActions } from "./actions"
import { useRoutesHooks } from "../../../../../features/routes"

export const CashFundForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const cashFund = dataType as O<CashFund>
    const createMode = !cashFund

    const navigate = useNavigate()

    const { submitCashFund } = useActions()

    const fields: FormField[] = [
        { name: 'name', type: FieldType.TEXT, label: 'Nombre' },
        {
            name: 'type',
            type: FieldType.SELECT,
            label: 'Tipo de Caja',
            options: Object.values(CashFundType).map(type => option(type, Labels.cashFundTypeLabel(type)))
        }
    ]

    const onSubmit = (cashFundData: Data) => submitCashFund(cashFundData, createMode)

    const onCancel = () => navigate(-1)

    return (
        <DataTypeForm
            formId="cash-fund-form"
            fields={fields}
            createMode={createMode}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    )
}
