import { useEffect, useState } from "react"
import { PanelLoader, useGetCrumbs } from "../../../../../features/ui"
import { TransferCashFundForm } from "./transferCashFundForm"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { define } from "../../../../../utils/typeUtils"
import { CashFund } from "../../../state/types"
import { useCashFundLoaders } from "../../../loaders/cashFundLoaders"

export const TransferCashFundFormLoader = () => {  
  const { dataType } = useRoutesHooks().parseUrl()
  const cashFund = define(dataType) as CashFund

  const [ready, setReady] = useState(false)

  const cashFundLoaders = useCashFundLoaders()

  const populate = async () => {
    const { loadCashFund } = cashFundLoaders()
    await loadCashFund(cashFund.id, true)
  }

  useEffect(() => {
    populate().then(() => setReady(true))
  }, [])

  const crumbs = useGetCrumbs('Cajas', RoutePath.CASH_FUNDS, cashFund.name, undefined, 'Transferencia')

  return (
    <PanelLoader title="Transferencia de Caja" crumbs={crumbs} ready={ready}>
      <TransferCashFundForm />
    </PanelLoader>
  )
}
