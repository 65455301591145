import { TableName } from '../../../../../tables'
import { Data, UIPermission, DetailPanel, DetailFieldProps, FieldType, FieldValue, Action, Spinner, Tab, useGetCrumbs, SpinnerDiv, PDFTabContent, useUIActions, ModalId, O } from '../../../../../features/ui'
import { Customer, CustomerType, SaleItem, SaleOrder } from '../../../state/types'
import { define } from '../../../../../utils/typeUtils'
import { useSalesActions } from '../../../actions/salesActions'
import { useEffect, useState } from 'react'
import { Operation, OperationItemsTable, DeliveryStatus, DeliveryList, OrderDelivery, useOperationsActions, PendingItemsList, PriceBreakdown, usePriceBreakdownHooks, PaymentStatus } from '../../../../operations'
import { useSystemStateHooks } from '../../../../system'
import { InvoiceStatus, PaymentType, InvoiceModal, InvoiceRequestedModal, DownloadInvoiceModal, useEmployeeLoaders } from '../../../../administration'
import { saleWizardKey } from '../../../utils/salesUtils'
import { OpenWizardModal, useWizardActions } from '../../../../../features/wizard'
import { RoutePath, useRoutesHooks } from '../../../../../features/routes'
import { useFind } from '../../../../../state/reducers/hooks'
import { useDescriptorHooks, Labels } from '../../../../../features/data-types'
import { SalePDF } from '../sale-pdf/salePDF'
import { DownloadSalePDFModal } from '../download-sale-pdf-modal/downloadSalePDFModal'
import { useNavigate } from 'react-router-dom'
import { ConfirmSalePaymentFormModal } from '../../confirm-sale-payment-modal/confirmSalePaymentFormModal'
import { DownloadReceiptModal } from '../../../../common'

export const SaleDetail = () => {
  const { dataType } = useRoutesHooks().parseUrl()
  const sale = define(dataType) as SaleOrder
  const wizardKey = saleWizardKey(sale)

  const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
  const [saleItems, setSaleItems] = useState<SaleItem[]>([])
  const [customer, setCustomer] = useState<Customer>()
  const [deliveries, setDeliveries] = useState<OrderDelivery[]>([])
  
  const { userHasPermission } = useSystemStateHooks()
  const { getPriceBreakdownFromItems } = usePriceBreakdownHooks()
  const descriptorHooks = useDescriptorHooks()
  const navigate = useNavigate()
  const find = useFind()

  const employeeLoaders = useEmployeeLoaders()

  const { goToWizard } = useWizardActions()
  const salesActions = useSalesActions()
  const operationsActions = useOperationsActions()
  const { openModal } = useUIActions()

  const readOnly = !userHasPermission(UIPermission.SAVE_SALES)
  const hasDeliveryPermission = userHasPermission(UIPermission.SAVE_SALE_DELIVERIES)
  const hasInvoicePermission = userHasPermission(UIPermission.INVOICE_SALES)

  const init = async () => {
    const stateSaleItems = await salesActions().fetchSaleItemsByOrder(sale.id) as SaleItem[]
    setSaleItems(stateSaleItems)
    const stateDeliveries = await operationsActions().fetchDeliveries(sale.id) as OrderDelivery[]
    setDeliveries(stateDeliveries)
    const stateCustomer = (await salesActions().fetchCustomer(sale.customerId)) as Customer
    setCustomer(stateCustomer)
    setAsyncDataLoaded(true)
  }

  useEffect(() => {
    init()
  }, [])

  const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <Spinner size='m'/>

  const crumbs = useGetCrumbs('Ventas', RoutePath.SALES, descriptorHooks.sale.code(sale))

  if (!sale) return <SpinnerDiv />

  const customerType = find(TableName.CUSTOMER_TYPES, customer?.customerTypeId) as O<CustomerType>
  const paymentType = find(TableName.PAYMENT_TYPES, sale.paymentTypeId) as O<PaymentType>
  const priceBreakdown = getPriceBreakdownFromItems(saleItems, customerType?.yield, paymentType?.yield)

  const fields: DetailFieldProps[] = [
    { name: 'branchId', type: FieldType.TABLE, table: TableName.BRANCHES, label: 'Sucursal' },
    { name: 'deliveryDate', type: FieldType.DATE, label: 'Fecha de Entrega' },
    {
      name: 'sellerId',
      type: FieldType.TABLE,
      table: TableName.EMPLOYEES,
      label: 'Vendedor',
      render: (value?: FieldValue) => {
        const seller = employeeLoaders().findEmployee(define(value) as string)
        return <>{descriptorHooks.employee.info(define(seller))}</>
      }
    },
    { name: 'customerId', type: FieldType.TABLE, table: TableName.CUSTOMERS, label: 'Cliente' },
    {
      name: 'saleItems',
      type: FieldType.TABLE,
      table: TableName.SALE_ITEMS,
      render: () => renderAsync(
        <OperationItemsTable
            rows={saleItems as Data[]}
            operation={Operation.SALE}
            readOnly
        />
      )
    },
    { name: 'paymentTypeId', type: FieldType.TABLE, table: TableName.PAYMENT_TYPES, label: 'Medio de Pago' },
    {
      name: 'priceBreakdown',
      type: FieldType.PRICE,
      label: 'Precio',
      render: () => renderAsync(
        <PriceBreakdown data={priceBreakdown} />
      )
    },
    {
      name: 'deliveryStatus',
      type: FieldType.TEXT,
      label: 'Estado de Entrega',
      render: (value?: FieldValue) => <>{Labels.deliveryStatusLabel(define(value) as DeliveryStatus)}</>
    },
    {
      name: 'paymentStatus',
      type: FieldType.TEXT,
      label: 'Estado de Pago',
      render: (value?: FieldValue) => <>{Labels.paymentStatusLabel(define(value) as PaymentStatus)}</>
    },
    {
      name: 'invoiceStatus',
      type: FieldType.TEXT,
      label: 'Facturación',
      render: (value?: FieldValue) => <>{Labels.invoiceStatusLabel(define(value) as InvoiceStatus)}</>
    }
  ]
  if (sale.deliveryStatus !== DeliveryStatus.DELIVERED && deliveries.length > 0) {
    const pendingItemsField = {
      name: 'pendingItems',
      type: FieldType.TABLE,
      table: TableName.SALE_ITEMS,
      render: () => renderAsync(
        <PendingItemsList orderId={sale.id} operation={Operation.SALE} />
      )
    }
    fields.splice(5, 0, pendingItemsField)
  }

  const onDownloadInvoice = () => openModal(ModalId.DOWNLOAD_INVOICE)

  const onDownloadPDF = () => openModal(ModalId.DOWNLOAD_SALE_PDF)

  const onEdit = () => navigate(RoutePath.EDIT)

  const onArchive = () => {}

  const onDeliver = () => goToWizard(saleWizardKey(sale), RoutePath.DELIVERY)

  const onDownloadReceipt = () => openModal(ModalId.DOWNLOAD_RECEIPT)

  const onConfirmPayment = () => openModal(ModalId.CONFIRM_SALE_PAYMENT_FORM)

  const onInvoice = () => openModal(ModalId.INVOICE)

  const actions: Action[] = []
  const isInvoiced = sale.invoiceStatus === InvoiceStatus.INVOICED
  const isInvoiceable = sale.invoiceStatus === InvoiceStatus.UNINVOICED
  const isEditable = sale.deliveryStatus === DeliveryStatus.PENDING && sale.paymentStatus === PaymentStatus.PENDING && isInvoiceable
  const isDelivered = sale.deliveryStatus === DeliveryStatus.DELIVERED
  const isPayed = sale.paymentStatus === PaymentStatus.PAYED
  
  if (isPayed) {
    actions.push({ label: 'Comp. de Pago', icon: 'download', kind: 'tertiary', onClick: onDownloadReceipt })
  }
  if (isInvoiced) {
    actions.push({ label: 'Factura', kind: 'senary', icon: 'download', onClick: onDownloadInvoice })
  } else {
    actions.unshift({ label: 'Orden de Venta', icon: 'download', kind: 'tertiary', onClick: onDownloadPDF })
  }
  if (!readOnly) {
    if (isEditable) {
      actions.push({ label: 'Editar Venta', icon: 'pencil-fill', onClick: onEdit })
    } else if (isDelivered && isPayed && isInvoiced) {
      actions.push({ label: 'Archivar', icon: 'archive', kind: 'secondary', onClick: onArchive })
    }
    if (!isPayed) {
      actions.push({ label: 'Confirmar Pago', icon: 'check-lg', onClick: onConfirmPayment })
    }
  }
  if (hasDeliveryPermission && !isDelivered && isPayed) {
    actions.push({ label: 'Registrar Entrega', icon: 'truck', onClick: onDeliver })
  }
  if (hasInvoicePermission && isInvoiceable) {
    actions.push({ label: 'Facturar', icon: 'file-earmark-check', kind: 'quinary', onClick: onInvoice })
  }

  const renderPDFContent = (
    <PDFTabContent>
      <SalePDF
        id="sale-pdf-preview"
        sale={sale}
        previewMode
      />
    </PDFTabContent>
  )

  const tabs: Tab[] = [
    { id: 'pdf', title: 'PDF', content: renderPDFContent }
  ]
  if (deliveries.length > 0) {
    tabs.push({
      id: 'deliveries',
      title: 'Entregas',
      content: <DeliveryList
        orderId={sale.id}
        operation={Operation.SALE}
        readOnly
      />
    })
  }

  return (<>
    <DetailPanel
      tableName={TableName.SALE_ORDERS}
      crumbs={crumbs}
      fields={fields}
      data={sale}
      icon='tags'
      imgSpace={3}
      tabs={tabs}
      readOnly={readOnly}
      actions={actions}
    />
    <DownloadInvoiceModal operation={sale} />
    <DownloadSalePDFModal sale={sale} />
    <DownloadReceiptModal operation={sale} />
    <ConfirmSalePaymentFormModal
      table={TableName.SALE_ORDERS}
      operation={sale}
    />
    <OpenWizardModal
      wizardKey={wizardKey}
      path={RoutePath.DELIVERY}
    />
    <InvoiceModal
      table={TableName.SALE_ORDERS}
      operation={sale}
    />
    <InvoiceRequestedModal />
  </>)
}
