import { useSystemActions, useSystemStateActions } from "../../../../modules/system"
import { useServicesHooks } from "../../../../services"
import { ConstrufyAdminRoleName } from "../../../../modules/construfy-admin"
import { define } from "../../../../utils/typeUtils"
import { TenantKey, UniqueKey } from "../../../../tables/constants"
import { useNavigate } from "react-router-dom"
import { useInitEmitter } from "../../../event-emitter"
import { useRoutesHooks } from "../../hooks/routesHooks"
import { RoutePath } from "../../types"
import { firstItem } from "../../../../utils/listUtils"

export const useLoad = () => {
    const { loadUser } = useRoutesHooks()
    const { initUserApis } = useServicesHooks()
    const navigate = useNavigate()

    const { setConstrufyAdminId, setCompany } = useSystemStateActions()
    const systemActions = useSystemActions()

    useInitEmitter()

    return async () => {
        const user = await loadUser()

        if (user)  {
            const { fetchAdmins, fetchAllCompanies } = systemActions()

            document.body.style.backgroundColor = '#364f4b'
            const footer = document.querySelector('footer')
            if (footer) {
                footer.style.borderTopColor = '#6d9791'
            }
            const copyright = document.querySelector('.copyright') as HTMLElement
            if (copyright) {
                copyright.style.color = 'white'
            }
            
            const admins = await fetchAdmins(user.id)
            const adminIds = admins.map(admin => admin.id)
            if (!adminIds.includes(user.id)) {
                navigate(RoutePath.ERROR)
            } else {
                const companies = await fetchAllCompanies()
                setCompany(define(firstItem(companies)))
                
                initUserApis(ConstrufyAdminRoleName, define(user.email), TenantKey, UniqueKey)
                // setConstrufyAdminId(admin.id) // borrar reducer
            }
        }
    }
}
