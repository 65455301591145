import { DynamoUserApi } from "../../../services"
import { TableName } from "../../../tables"
import { dedupe } from "../../../utils/listUtils"
import { Employee, PaymentType, Tax, useAdministrationStateActions } from "../../administration"
import { useDefaultModuleActions } from "../../common"
import { Branch, useManagerStateActions } from "../../manager"
import { Product, useProductsStateActions } from "../../products"
import { Customer, CustomerType, HoardItem, HoardOrder, SaleItem, SaleOrder, useSalesStateActions } from "../../sales"
import { useSystemStateHooks } from "../../system"

export const useConstrufyAdminFetchActions = () => {
    const { updateSales, updateSaleItems, updateHoards, updateHoardItems, updateCustomers, updateCustomerTypes } = useSalesStateActions()
    const { updateProducts } = useProductsStateActions()
    const { updateEmployees, updateTaxes, updatePaymentTypes } = useAdministrationStateActions()
    const { updateBranches } = useManagerStateActions()
    const { companyId } = useSystemStateHooks()
    const defaultModuleActions = useDefaultModuleActions()

    const fetchAllBranches = async () => {
        updateBranches([])
        const { fetchAll } = defaultModuleActions(DynamoUserApi, companyId)
        const branches = (await fetchAll(TableName.BRANCHES)).dataTypes as Branch[]
        return branches
    }

    const fetchCustomers = async (ids: string[]) => {
        updateCustomers([])
        const { fetchByIds } = defaultModuleActions(DynamoUserApi, companyId)
        const customers = (await fetchByIds(TableName.CUSTOMERS, dedupe(ids))) as Customer[]
        return customers
    }
    
    const fetchCustomerTypes = async (ids: string[]) => {
        updateCustomerTypes([])
        const { fetchByIds } = defaultModuleActions(DynamoUserApi, companyId)
        const customerTypes = (await fetchByIds(TableName.CUSTOMER_TYPES, dedupe(ids))) as CustomerType[]
        return customerTypes
    }

    const fetchEmployees = async (ids: string[]) => {
        updateEmployees([])
        const { fetchByIds } = defaultModuleActions(DynamoUserApi, companyId)
        const employees = (await fetchByIds(TableName.EMPLOYEES, dedupe(ids))) as Employee[]
        return employees
    }

    const fetchAllHoards = async () => {
        updateHoards([])
        const { fetchAll } = defaultModuleActions(DynamoUserApi, companyId)
        const hoards = (await fetchAll(TableName.HOARD_ORDERS)).dataTypes as HoardOrder[]
        return hoards
    }

    const fetchAllHoardItems = async () => {
        updateHoardItems([])
        const { fetchAll } = defaultModuleActions(DynamoUserApi, companyId)
        const hoardItems = (await fetchAll(TableName.HOARD_ITEMS)).dataTypes as HoardItem[]
        return hoardItems
    }

    const fetchPaymentTypes = async (ids: string[]) => {
        updatePaymentTypes([])
        const { fetchByIds } = defaultModuleActions(DynamoUserApi, companyId)
        const paymentTypes = (await fetchByIds(TableName.PAYMENT_TYPES, dedupe(ids))) as PaymentType[]
        return paymentTypes
    }

    const fetchAllProducts = async () => {
        updateProducts([])
        const { fetchAll } = defaultModuleActions(DynamoUserApi, companyId)
        const products = (await fetchAll(TableName.PRODUCTS)).dataTypes as Product[]
        return products
    }

    const fetchAllSales = async () => {
        updateSales([])
        const { fetchAll } = defaultModuleActions(DynamoUserApi, companyId)
        const sales = (await fetchAll(TableName.SALE_ORDERS)).dataTypes as SaleOrder[]
        return sales
    }

    const fetchAllSaleItems = async () => {
        updateSaleItems([])
        const { fetchAll } = defaultModuleActions(DynamoUserApi, companyId)
        const saleItems = (await fetchAll(TableName.SALE_ITEMS)).dataTypes as SaleItem[]
        return saleItems
    }

    const fetchAllTaxes = async () => {
        updateTaxes([])
        const { fetchAll } = defaultModuleActions(DynamoUserApi, companyId)
        const taxes = (await fetchAll(TableName.TAXES)).dataTypes as Tax[]
        return taxes
    }

    return {
        fetchAllBranches,
        fetchCustomers,
        fetchCustomerTypes,
        fetchEmployees,
        fetchAllHoards,
        fetchAllHoardItems,
        fetchPaymentTypes,
        fetchAllProducts,
        fetchAllSales,
        fetchAllSaleItems,
        fetchAllTaxes
    }
}
