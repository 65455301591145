import { O } from "../../../features/ui";
import { UnitTypeCategory } from "../../stock";
import { MeasureType } from "../state/types";

export const getUnitTypeCategory = (measureType: MeasureType): O<UnitTypeCategory> => {
    const categories = new Map<MeasureType, UnitTypeCategory>()
        .set(MeasureType.AREA, UnitTypeCategory.AREA)
        .set(MeasureType.DEPTH, UnitTypeCategory.LENGTH)
        .set(MeasureType.DIAMETER, UnitTypeCategory.LENGTH)
        .set(MeasureType.HEIGHT, UnitTypeCategory.LENGTH)
        .set(MeasureType.THICKNESS, UnitTypeCategory.LENGTH)
        .set(MeasureType.VOLUME, UnitTypeCategory.VOLUME)
        .set(MeasureType.WEIGHT, UnitTypeCategory.WEIGHT)
        .set(MeasureType.WIDTH, UnitTypeCategory.LENGTH)

    return categories.get(measureType)
}
