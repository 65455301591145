import styles from './downloadBudgetPDFModal.module.css'
import { fileName, usePDFActions } from "../../../../../features/files"
import { Modal, ModalId } from "../../../../../features/ui"
import { Budget } from "../../../state/types"
import { BudgetPDF } from "../budget-pdf/budgetPDF"
import { FileTile, FileType } from "../../../../../features/ui"
import { useEffect, useState } from "react"
import { TableName } from '../../../../../tables'

type Props = {
    budget?: Budget
}

export const DownloadBudgetPDFModal = (props: Props) => {
    const { budget } = props
    
    const [pdfName, setPdfName] = useState<string>()

    const { generatePDF } = usePDFActions()

    useEffect(() => {
        budget && setPdfName(fileName(TableName.BUDGETS, budget.code.toString()))
    }, [budget])

    const pdfId = 'budget-pdf'
    
    const onSubmit = () => {
        pdfName && generatePDF(pdfId, pdfName)
    }

    const renderHiddenPDF = () => budget ? (
        <div className={styles.hidden}>
            <BudgetPDF
                id={pdfId}
                budget={budget}
            />
        </div>
    ) : <></>
    
    return (
        <Modal
            modalId={ModalId.DOWNLOAD_BUDGET_PDF}
            title="Descargar Presupuesto"
            submitLabel="Descargar"
            submitIcon="download"
            onSubmit={onSubmit}
        >
            <FileTile fileType={FileType.PDF} fileName={pdfName}/>
            {renderHiddenPDF()}
        </Modal>
    )
}
