import { useDefaultModuleActions } from "../../common"
import { Standards, newId } from "../../../features/data-types"
import { DynamoUserApi } from "../../../services"
import { TableName } from "../../../tables"
import { define } from "../../../utils/typeUtils"
import { useSystemStateHooks } from "../../system"
import { Expense, ExpenseType, Ticket, TicketType } from "../state/types"
import { useTicketActions } from "./ticketActions"
import { Currency } from "../../../tables/types/types"
import { firstItem } from "../../../utils/listUtils"
import { useGenerateNextValue2 } from "../../../tables/hooks"
import { useExpenseTypeLoaders } from "../loaders/expenseTypeLoaders"

export const useExpensesActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId
    const generateNextValue = useGenerateNextValue2()

    const expenseTypeLoaders = useExpenseTypeLoaders()

    const defaultModuleActions = useDefaultModuleActions()
    const ticketActions = useTicketActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId
        const { fetchByParams, fetchMultipleByParams, fetchByIds, count, save, remove } = defaultModuleActions(DynamoUserApi, define(companyId))
        const { fetchTicketsByParentId, saveTicketWithFile, removeTicket } = ticketActions(companyId)

        const saveExpenseType = (expenseType: ExpenseType) => {
            return save(TableName.EXPENSE_TYPES, expenseType)
        }

        const removeExpenseType = async (id: string) => {
            const { loadAllExpenseTypes } = expenseTypeLoaders(companyId)
            const expenseTypes = await loadAllExpenseTypes()
            const expenseType = expenseTypes.find(expenseType => expenseType.id === id)
            const isSalary = expenseType?.name === Standards.SalaryExpenseTypeName
            if (expenseTypes.length > 1 && !isSalary) {
                return remove(TableName.EXPENSE_TYPES, id)
            }
        }

        const fetchAllExpenses = (branchId: string) => {
            return fetchMultipleByParams(TableName.EXPENSES, { branchId })
        }

        const fetchExpenses = (ids: string[]) => {
            return fetchByIds(TableName.EXPENSES, ids)
        }

        const countExpenses = (propName: string, propValue: string) => {
            return count(TableName.EXPENSES, { [propName]: propValue })
        }

        const fetchExpense = (id: string) => {
            return fetchByParams(TableName.EXPENSES, { id })
        }

        const saveExpense = (expense: Expense) => {
            return save(TableName.EXPENSES, expense)
        }

        const saveSalaryExpense = async (
            expense: Expense,
            ticketFile: File,
            dueDate: Date
        ) => {
            const savedSalaryExpense = await save(TableName.EXPENSES, expense) as Expense

            const stateTickets = await fetchTicketsByParentId(savedSalaryExpense.id) as Ticket[]
            const stateTicket = firstItem(stateTickets)

            const code = stateTicket?.code || await generateNextValue(TableName.TICKETS, 'code')
            const ticket: Ticket = {
                companyId: define(companyId) as string,
                id: stateTicket?.id || newId(),
                code,
                parentTable: TableName.EXPENSES,
                parentId: savedSalaryExpense.id,
                type: TicketType.SALARY,
                creationDate: new Date().toISOString(),
                dueDate: dueDate.toISOString(),
                taxItems: [],
                subtotal: savedSalaryExpense.amount,
                vatAmount: 0,
                total: savedSalaryExpense.amount,
                payedAmount: 0,
                currency: Currency.ARS
            }
            await saveTicketWithFile(ticket, ticketFile)

            return savedSalaryExpense
        }

        const removeExpense = async (id: string) => {
            await remove(TableName.EXPENSES, id)

            const tickets = await fetchTicketsByParentId(id)
            tickets.forEach(async ticket => {
                await removeTicket(ticket.id)
            })
        }

        return {
            saveExpenseType,
            removeExpenseType,
            fetchAllExpenses,
            fetchExpenses,
            countExpenses,
            fetchExpense,
            saveExpense,
            saveSalaryExpense,
            removeExpense
        }
    }
}
