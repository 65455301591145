import { useEffect, useState } from "react"
import { ItemsPDFWrapper } from '../../../../../features/ui'
import { DataType } from '../../../../../tables'
import { PurchaseItem, PurchaseOrder } from '../../../state/types'
import { usePurchasesActions } from '../../../actions/purchasesActions'
import { PurchasePDFPage } from "./purchasePDFPage"

type Props = {
    id: string
    purchase: PurchaseOrder
    previewMode?: boolean
}

export const PurchasePDF = (props: Props) => {
    const { id, purchase, previewMode } = props

    const [purchaseItems, setPurchaseItems] = useState<PurchaseItem[]>([])

    const purchasesActions = usePurchasesActions()

    const init = async () => {
        const statePurchaseItems = await purchasesActions().fetchPurchaseItemsByOrder(purchase.id) as PurchaseItem[]
        setPurchaseItems(statePurchaseItems)
    }

    useEffect(() => {
        init()
    }, [])

    const renderPDFPage = (
        pageIndex: number,
        dataType: DataType,
        items: DataType[],
        pagesAmount: number
    ) => (
        <PurchasePDFPage
            pageIndex={pageIndex}
            purchase={dataType as PurchaseOrder}
            purchaseItems={items as PurchaseItem[]}
            pagesAmount={pagesAmount}
        />
    )

    return (
        <ItemsPDFWrapper
            id={id}
            dataType={purchase}
            items={purchaseItems}
            previewMode={previewMode}
            renderPDFPage={renderPDFPage}
        />
    )
}
