import { useEffect, useState } from "react"
import { PanelLoader } from "../../../../../features/ui"
import { CashFundList } from "./cashFundList"
import { CashFund } from "../../../state/types"
import { useCashFundLoaders } from "../../../loaders/cashFundLoaders"
import { ListLoaderWrapper } from "../../../../../features/data-types"
import { TableName } from "../../../../../tables"

const Loader = () => {
  const [cashFunds, setCashFunds] = useState<CashFund[]>([])
  const [ready, setReady] = useState(false)

  const cashFundLoaders = useCashFundLoaders()

  const populate = async () => {
    const { loadAllCashFunds } = cashFundLoaders()
    setCashFunds(await loadAllCashFunds())
  }

  useEffect(() => {
    populate().then(() => setReady(true))
  }, [])

  return (
    <PanelLoader
      title="Cajas"
      ready={ready}
    >
      <CashFundList cashFunds={cashFunds}/>
    </PanelLoader>
  )
}

export const CashFundListLoader = () => (
  <ListLoaderWrapper table={TableName.CASH_FUNDS}>
    <Loader />
  </ListLoaderWrapper>
)
