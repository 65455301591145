import { Data, O } from "../../../../../features/ui"
import { useAdministrationActions } from "../../../actions/administrationActions"
import { define } from "../../../../../utils/typeUtils"
import { Period, Tax, TaxBase, TaxType } from "../../../state/types"
import { Emitter, Event } from "../../../../../features/event-emitter"

export const useActions = () => {
    const administrationActions = useAdministrationActions()

    const submitTax = async (
        taxData: Data,
        createMode: boolean
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const { companyId, id, code, legalCode, name, type, taxBase, nonTaxableMin, nonTaxableMinPeriod, unregisteredYield } = taxData
        const tax: Tax = {
            companyId: companyId as string,
            id: id as string,
            code: define(code) as string,
            legalCode: define(legalCode) as string,
            name: define(name) as string,
            type: define(type) as TaxType,
            taxBase: define(taxBase) as TaxBase,
            nonTaxableMin: define(nonTaxableMin) as number,
            nonTaxableMinPeriod: nonTaxableMinPeriod as O<Period>,
            yield: define(taxData.yield) as number,
            unregisteredYield: unregisteredYield as O<number>
        }
        
        await administrationActions().saveTax(tax)
        Emitter.emit(Event.DB_SUBMIT_DONE, createMode)
    }
    
    return {
        submitTax
    }
}
