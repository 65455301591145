import { Emitter, Event } from "../../../../../features/event-emitter"
import { Data, O } from "../../../../../features/ui"
import { define } from "../../../../../utils/typeUtils"
import { IdentifierType } from "../../../../system"
import { useSalesActions } from "../../../actions/salesActions"
import { Customer, CustomerCategory } from "../../../state/types"

export const useActions = () => {
    const salesActions = useSalesActions()

    const submitCustomer = async (
        customerData: Data,
        createMode: boolean,
        identifierType: IdentifierType,
        identifier: number
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const { companyId, id, name, category, customerTypeId, email, address, phone } = customerData
        const customer: Customer = {
            companyId: define(companyId) as string,
            id: define(id) as string,
            name: define(name) as string,
            category: define(category) as CustomerCategory,
            identifierType,
            identifier,
            customerTypeId: define(customerTypeId) as string,
            email: email as O<string>,
            address: address as O<string>,
            phone: phone as O<number>
        }
        
        await salesActions().saveCustomer(customer)
        Emitter.emit(Event.DB_SUBMIT_DONE, createMode)
    }
    
    return {
        submitCustomer
    }
}
