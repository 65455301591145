import { Modal } from "../modal/modal"
import { ModalId } from "../modal/types"

type Props = {
    title: string
    body: string
    modalId?: ModalId
}

export const MessageModal = (props: Props) => {
    const { title, body, modalId } = props

    return (
        <Modal
            modalId={modalId || ModalId.MESSAGE}
            title={title}
            cancelLabel="Cerrar"
        >
            <>{body}</>
        </Modal>
    )
}
