import styles from './adminElement.module.css'
import { useEffect, useState } from "react"
import { useConstrufyAdminActions } from "../../../../modules/construfy-admin"
import { Company, RequestStatus, useSystemActions } from "../../../../modules/system"
import { UploadButton } from "../../../../components/upload-button-old/uploadButton_OLD"
import { useLoad } from "./load"
import { Button, FieldValue, FileTile, FileType, O, SelectField, option } from "../../../ui"
import { define } from '../../../../utils/typeUtils'
import { TableName } from '../../../../tables'
import { ElementLoader } from "../element-loader/elementLoader"

export const AdminElement_OLD = () => {
  const {
    createTables,
    createUnitTypes,
    createDefaultData,
    createCompany,
    createCompanyPolicies,
    saveConstrufyAdminPolicy,
    saveSystemPolicy,
    saveCompanyPolicies,
    resolveRequest,
    clearTable,
    migrate,
    removeUser,
    importSuppliers,
    importCustomers,
    importProducts
  } = useConstrufyAdminActions()
  
  const [companies, setCompanies] = useState<Company[]>([])
  const [companyId, setCompanyId] = useState<string>()
  const [table, setTable] = useState<TableName>()
  const [ready, setReady] = useState(false)

  const load = useLoad()
  const systemActions = useSystemActions()

  useEffect(() => {
    load().then(() => {
      systemActions().fetchAllCompanies().then(stateCompanies => {
        setCompanies(stateCompanies)
        setReady(true)
      })
    })
  }, [])

  const [file, setFile] = useState<File>()
  
  const tables = [
    TableName.CUSTOMERS,
    TableName.LOTS,
    TableName.PRODUCTS,
    TableName.PRODUCT_PRICES,
    TableName.STOCK,
    TableName.SUPPLIERS,
  ]

  const onChangeCompany = (value?: FieldValue) => {
    setCompanyId(value as O<string>)
  }

  const onChangeTable = (value?: FieldValue) => {
    setTable(value as O<TableName>)
  }

  const onSelectFile = (newFile?: File) => setFile(newFile)
  
  const onImport = () => {
    if (file) {
      const definedCompanyId = define(companyId)
      if (table === TableName.SUPPLIERS) {
        importSuppliers(file, definedCompanyId)
      } else if (table === TableName.CUSTOMERS) {
        importCustomers(file, definedCompanyId)
      } else if (table === TableName.PRODUCTS) {
        importProducts(file, definedCompanyId)
      } 
    }
  }

  const onClear = () => {
    table && clearTable(define(companyId), table)
  }

  return (
    <ElementLoader ready={ready}>
      <>
        <button onClick={createTables}>
          Create tables
        </button>
        <button onClick={() => createCompany('cisilotto', 'Cisilotto', 'Cisilotto Hnos. SA', 20000000000, 'cisilottohnos.com.ar', 'mholub@cisilotto.com.ar', 'Colectora Oeste, KM 41, La Reja')}>
          Create company
        </button>
        <button onClick={createUnitTypes}>
          Create unit types
        </button>
        <button onClick={() => createDefaultData(companyId)}>
          Create default data
        </button>
        <button onClick={() => createCompanyPolicies(define(companyId), 'cisilotto')}>
          Create company policies
        </button>
        <button onClick={saveConstrufyAdminPolicy}>
          Save CONSTRUFY ADMIN policy
        </button>
        <button onClick={saveSystemPolicy}>
          Save SYSTEM policy
        </button>
        <button onClick={saveCompanyPolicies}>
          Save company policies
        </button>
        <button onClick={() => resolveRequest('cisilotto', 'lvidalclaypole@gmail.com', RequestStatus.APPROVED)}>
          Approve company manager
        </button>
        <button onClick={migrate}>
          Migrate
        </button>
        <button onClick={() => removeUser('distinctplaces@gmail.com')}>
          Remove user
        </button>
        
        <div className={styles.importContainer}>
          <SelectField
            id="companyId"
            options={companies.map(company => option(company.id, company.name))}
            onChange={onChangeCompany}
          />
          <SelectField
            id="table"
            options={tables.map(tableName => option(tableName, tableName))}
            onChange={onChangeTable}
          />
          <FileTile fileType={FileType.CSV} fileName={file?.name} />
          <UploadButton
              id='import-csv'
              label='Importar Archivo .csv'
              fileTypes='.csv'
              kind='tertiary'
              onSelectFile={onSelectFile}
          />
          <Button
            label="Importar"
            disabled={!companyId}
            onClick={onImport}
          />
          <Button
            label="Borrar Todo"
            disabled={!companyId}
            onClick={onClear}
          />
        </div>
      </>
    </ElementLoader>
  )
}
