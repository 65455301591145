import { useState } from "react"
import { DataTypeForm, Labels } from "../../../../../features/data-types"
import { PanelWrapper, Data, FieldType, FieldValue, FormField, option, useGetCrumbs, O } from "../../../../../features/ui"
import { PaymentCategory, PaymentType } from "../../../state/types"
import { useActions } from "./actions"
import { AdjustmentType } from "../../../../operations"
import { useNavigate } from "react-router-dom"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"

export const PaymentTypeForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const paymentType = dataType as O<PaymentType>
    const createMode = !paymentType

    const defaultType = paymentType && paymentType?.yield < 0 ? AdjustmentType.DISCOUNT : AdjustmentType.SURCHARGE
    const [adjustmentType, setAdjustmentType] = useState<O<AdjustmentType>>(defaultType)
    const [paymentTypeYield, setPaymentTypeYield] = useState<number>(paymentType?.yield || 0)

    const navigate = useNavigate()

    const { submitPaymentType } = useActions()

    const paymentTypeLabel = paymentType ? `: ${paymentType.name}` : ''
    const title = `${createMode ? 'Crear' : 'Editar'} Medio de Pago${paymentTypeLabel}`

    const crumbs = useGetCrumbs('Medios de Pago', RoutePath.PAYMENT_TYPES, paymentType?.name, undefined, createMode ? 'Crear' : 'Editar')

    const typeOptions = Object.values(AdjustmentType).map(value => option(value, Labels.adjustmentTypeLabel(value)))

    const onAdjustmentTypeChange = (value?: FieldValue) => {
        const newType = value as O<AdjustmentType>
        setAdjustmentType(newType)
        const yieldAbsoluteValue = Math.abs(paymentTypeYield)
        setPaymentTypeYield(newType === AdjustmentType.DISCOUNT ? -yieldAbsoluteValue : yieldAbsoluteValue)
    }

    const onYieldChange = (value?: FieldValue) => {
        const newYield = (value || 0) as number
        setPaymentTypeYield(adjustmentType === AdjustmentType.DISCOUNT && newYield !== 0 ? -newYield : newYield)
    }
    
    const fields: FormField[] = [
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre'
        },
        {
            name: 'category',
            type: FieldType.SELECT,
            label: 'Tipo',
            options: Object.values(PaymentCategory).map(category => option(category, Labels.paymentCategoryLabel(category)))
        },
        {
            name: 'adjustmentType',
            type: FieldType.SELECT,
            defaultValue: adjustmentType,
            options: typeOptions,
            label: 'Tipo de Ajuste',
            onChange: onAdjustmentTypeChange
        },
        {
            name: 'newYield',
            type: FieldType.PERCENTAGE,
            defaultValue: Math.abs(paymentTypeYield),
            label: 'Porcentaje de Ajuste',
            onChange: onYieldChange
        }
    ]

    const onSubmit = (paymentTypeData: Data) => submitPaymentType(paymentTypeData, paymentTypeYield, createMode)

    const onCancel = () => navigate(-1)

    return (
        <PanelWrapper title={title} crumbs={crumbs}>
            <DataTypeForm
               formId="payment-type-form"
               fields={fields}
               createMode={createMode}
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
        </PanelWrapper>
    )
}
