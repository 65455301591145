import styles from './uploadDeliveryNoteForm.module.css'
import { Form, FormField, ModalId, useUIActions, FieldType, FileField, Validatable, Spinner } from "../../../../../features/ui"
import { define } from "../../../../../utils/typeUtils"
import { useState } from "react"
import { toList } from "../../../../../utils/listUtils"
import { useActions } from "./actions"
import { useDataTypesHooks } from "../../../../../features/data-types"
import { useOperationsState } from '../../../../operations'
import { useFind } from '../../../../../state/reducers/hooks'
import { TableName } from '../../../../../tables'
import { Branch } from '../../../../manager'
import { fileName } from '../../../../../features/files'
import { PurchaseOrder } from '../../../state/types'

type Props = {
    purchase: PurchaseOrder
}

export const UploadDeliveryNoteForm = (props: Props) => {
    const { purchase } = props
    
    const { currentDelivery } = useOperationsState()

    const [deliveryNoteFile, setDeliveryNoteFile] = useState<File>()
    const [deliveryNoteValidation, setDeliveryNoteValidation] = useState<string>()

    const { formatValue } = useDataTypesHooks()
    const find = useFind()

    const { submitDeliveryNote } = useActions()
    const { toggleModal } = useUIActions()

    if (!currentDelivery) return <Spinner />

    const deliveryDate = formatValue(new Date(currentDelivery.date), FieldType.DATE).split('/').join('-')

    const onChangeFile = (value?: File) => {
        setDeliveryNoteValidation(undefined)
        setDeliveryNoteFile(value)
    }

    const fields: FormField[] = [
        {
            name: 'delivery',
            type: FieldType.TEXT,
            label: 'Entrega',
            render: () => {
                const date = formatValue(new Date(currentDelivery.date), FieldType.DATE)
                const branch = define(find(TableName.BRANCHES, currentDelivery.branchId)) as Branch
                return <>{date} - {branch.name}</>
            }
        },
        {
            name: 'deliveryNoteUrl',
            type: FieldType.FILE,
            label: 'Remito',
            hint: 'Formato: PDF',
            render: () => (
                <Validatable validations={toList(deliveryNoteValidation)} left className={styles.deliveryNoteValidation}>
                    <FileField
                        id="deliveryNoteUrl"
                        fileName={fileName(TableName.ORDER_DELIVERIES, deliveryDate, TableName.PURCHASE_ORDERS, purchase.code.toString())}
                        onChange={onChangeFile}
                    />
                </Validatable>
            )
        }
    ]
    
    const validate = (): boolean => {
        if (!deliveryNoteFile) {
            setDeliveryNoteValidation('Debe subir el remito')
            return false
        }
    
        return true
    }

    const onSubmit = () => {
        if (validate()) {
            submitDeliveryNote(currentDelivery, define(deliveryNoteFile))
        }
    }

    const handleCancel = () => toggleModal(ModalId.UPLOAD_DELIVERY_NOTE)

    return (
        <Form
            formId="upload-delivery-note-form"
            fields={fields}
            createMode={false}
            updateLabel="Confirmar"
            onSubmit={onSubmit}
            onCancel={handleCancel}
        />
    )
}
