import { useMemo } from "react"
import { FieldType, FieldValue, FormField, option, Option } from "../../../../features/ui"
import { TableName } from "../../../../tables"
import { Area, Employee, useEmployeeLoaders } from "../../../administration"
import { define } from "../../../../utils/typeUtils"
import { useDescriptorHooks } from "../../../../features/data-types"

type Props = {
    defaultValue?: string,
    onChange?: (value?: FieldValue) => void
}

export const SellerField = (props: Props): FormField => {
    const { defaultValue, onChange } = props
    
    const descriptorHooks = useDescriptorHooks()

    const employeeLoaders = useEmployeeLoaders()

    const employees = useMemo(() => employeeLoaders().findAreasEmployees([Area.MANAGMENT, Area.SALES]), [])

    const label = descriptorHooks.employee.info
    
    const options = employees.map(employee => option(employee.id, label(employee)))
    
    const renderOption = (option: Option, onClick?: (e: React.MouseEvent<HTMLElement>) => void) => {
        const seller = define(employees.find(employee => employee.id === option.value))
        return (
            <div onClick={onClick}>
                <span>{label(seller)}</span>
            </div>
        )
    }

    return {
        name: 'sellerId',
        type: FieldType.TABLE,
        table: TableName.EMPLOYEES,
        label: 'Vendedor',
        defaultValue,
        options,
        placeholder: 'Buscar por nombre o DNI',
        renderOption: renderOption,
        onChange
    }
}
