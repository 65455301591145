import { useEffect, useState } from 'react'
import { FileTile, FileType, Modal, ModalId, option, RadioField, useUIState } from '../../../ui'
import { firstItem } from '../../../../utils/listUtils'

type FileData = {
    id: string
    label: string
    fileName: string,
    onDownload?: () => void
}

type Props = {
    operationId?: string
    filesData: FileData[]
    children: JSX.Element | JSX.Element[]
}

export const DownloadFilesModal = (props: Props) => {
    const { operationId, filesData, children } = props

    const { openedModal } = useUIState()

    const [fileData, setFileData] = useState<FileData>()
    const [key, setKey] = useState(0)

    useEffect(() => setFileData(firstItem(filesData)), [operationId])

    useEffect(() => setKey(key + 1), [openedModal])

    const options = filesData.map(fileData => option(fileData.id, fileData.label))

    const onChangeFileOption = (value?: string) => {
        setFileData(filesData.find(fileData => fileData.id === value))
    }

    const onSubmit = () => {
        fileData?.onDownload && fileData.onDownload()
    }

    return (
        <Modal
            modalId={ModalId.DOWNLOAD_FILES}
            title="Descargar Documentos"
            submitLabel="Descargar"
            submitIcon="download"
            onSubmit={onSubmit}
        >
            <>
                <p>Seleccionar el documento a descargar: </p>
                <RadioField
                    key={key}
                    id="file-option"
                    defaultValue={fileData?.id}
                    options={options}
                    onChange={onChangeFileOption}
                />
                <br />
                <FileTile
                    fileType={FileType.PDF}
                    fileName={fileData?.fileName}
                />
                {children}
            </>
        </Modal>
    )
}
