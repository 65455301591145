import { useEffect, useState } from "react"
import { Modal, ModalId, useUIState } from "../../../../../features/ui"
import { UploadInvoiceForm } from "./uploadInvoiceForm"
import { TableName } from "../../../../../tables"
import { SaleOperation } from "../../../../sales"

type Props = {
    table: TableName
    operation?: SaleOperation
}

export const UploadInvoiceFormModal = (props: Props) => {
    const { table, operation } = props
    const [key, setKey] = useState(0)
    const { openedModal } = useUIState()

    useEffect(() => setKey(key + 1), [openedModal])
    
    return (
        <Modal
            modalId={ModalId.UPLOAD_INVOICE}
            title="Subir Factura ARCA"
            widthSize="m"
            actions={[]}
        >
            <UploadInvoiceForm
                key={key}
                table={table}
                operation={operation}
            />
        </Modal>
    )
}
