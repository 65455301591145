import { TableName } from "aws-sdk/clients/dynamodb"
import { TextOrNumber } from "../../../features/ui"
import { Currency } from "../../../tables/types/types"
import { PaymentStatus } from "../../operations"
import { IdentifierType } from "../../system"

export type SettingsDataType = TextOrNumber | object

export enum SettingsModule {
    PURCHASES = 'Purchases',
    SALES = 'Sales'
}

export type Settings = {
    companyId: string
    id: string
    module: SettingsModule
    [prop: string]: SettingsDataType
}

export type CategorySaleYield = {
    categoryId: string
    saleYield: number
}

export enum TaxType {
    SALE_TAX = 'SaleTax',
    VAT = 'VAT',
    PERCEPTION = 'Perception',
    RETENTION = 'Retention'
}

export enum TaxBase {
    PRICE_WITHOUT_VAT = 'Price without VAT',
    VAT = 'VAT',
    PRICE_WITH_VAT = 'Price with VAT'
}

export enum Period {
    DAILY = 'Daily',
    WEEKLY = 'Weekly',
    MONTHLY = 'Monthly',
    ANNUAL = 'Annual',
    PER_OPERATION = 'PerOperation'
}

export type Tax = {
    companyId: string
    id: string
    code: string
    legalCode: string
    name: string
    type: TaxType
    taxBase: TaxBase
    nonTaxableMin: number
    nonTaxableMinPeriod?: Period
    yield: number
    unregisteredYield?: number
}

export type TaxItem = {
    taxCode: string
    taxYield: number
    amount: number
}

export enum CashFundType {
    CASH = 'Cash',
    BANCK_ACCOUNT = 'Bank Account'
}

export type CashFund = {
    companyId: string
    id: string
    name: string
    type: CashFundType
    availableAmount: number
    reservedAmount: number
    currency: Currency
}

export enum CheckType {
    PHYSICAL = 'Physical',
    E_CHEQ = 'E-Cheq'
}

export enum CheckStatus {
    ENABLED = 'Enabled',
    RESERVED = 'Reserved',
    ENDORSED = 'Endorsed'
}

export type Check = {
    companyId: string
    id: string
    type: CheckType
    name: string
    amount: number
    issueDate: string
    paymentDate: string
    taxId: number
    endorsable: boolean
    status: CheckStatus
}

export type CreditCard = {
    companyId: string
    id: string
    type: string
    issuer: string
    cardholder: string
    last4Numbers: number
    dueDate: string
}

export enum TransactionSourceType {
    MANUAL_ADDITION = 'ManualAddition',
    CASH_FUND = 'CashFund',
    CHECK = 'Check',
    CREDIT_CARD = 'CreditCard'
}

export enum TransactionDestinationType {
    MANUAL_SUBSTRACTION = 'ManualSubstraction',
    CASH_FUND = 'CashFund',
    PAYMENT_ORDER = 'PaymentOrder'
}

export type Transaction = {
    companyId: string
    id: string
    date: string
    sourceType: TransactionSourceType
    sourceId?: string
    amount: number
    destinationType: TransactionDestinationType
    destinationId?: string
    currency: Currency
    concept?: string
}

export enum PaymentCategory {
    CASH_FUND = 'CashFund',
    CHECK = 'Check',
    CREDIT_CARD = 'CreditCard'
}

export type PaymentType = {
    companyId: string
    id: string
    name: string
    category: PaymentCategory
    yield: number
}

export enum Area {
    MANAGMENT = 'Managment',
    ADMINISTRATION = 'Administration',
    PURCHASES = 'Purchases',
    SALES = 'Sales',
    WAREHOUSE = 'Warehouse',
    HR = 'Human Resources'
}

export type Employee = {
    companyId: string
    id: string
    name: string
    identifier?: number
    area: Area
    email: string
    phone?: number
    address?: string
    branchId: string
}

export type Expense = {
    companyId: string
    id: string
    code: number
    name: string
    expenseTypeId: string
    date: string
    branchId: string
    amount: number
    currency: Currency
    detail?: string
    employeeId: string
    supplierId?: string
    refundEmployeeId?: string
    paymentStatus: PaymentStatus
}

export type ExpenseType = {
    companyId: string
    id: string
    name: string
}

export enum TicketType {
    TICKET_A = "TicketA",
    TICKET_B = "TicketB",
    TICKET_C = "TicketC",
    RECEIPT_X = "ReceiptX",
    CREDIT_NOTE = 'CreditNote',
    SALARY = 'Salary'
}

export type Ticket = {
    companyId: string
    id: string
    code: number
    parentTable: TableName
    parentId: string
    type: TicketType
    creationDate: string
    dueDate: string
    taxItems: TaxItem[]
    subtotal: number
    vatAmount: number
    total: number
    payedAmount: number
    currency: Currency
    ticketUrl?: string
}

export enum PaymentOrderStatus {
    EMITTED = 'Emitted',
    PAYED = 'Delivered'
}

export type PaymentItem = {
    paymentTypeId: string
    paymentCategory: PaymentCategory
    sourceId: string
    amount: number
}

export type PaymentTicketItem = {
    ticketId: string
    payedAmount: number
}

export type PaymentOrder = {
    companyId: string
    id: string
    code: number
    ticketItems: PaymentTicketItem[]
    paymentItems: PaymentItem[]
    taxItems: TaxItem[]
    payedAmount: number
    status: PaymentOrderStatus
    creationDate: string
    currency: Currency
    receiptUrl?: string
}

export enum InvoiceStatus {
    UNINVOICED = 'Uninvoiced',
    REQUESTED = 'Requested',
    INVOICED = 'Invoiced'
}

export enum InvoiceDataProp {
    COMPANY_LEGAL_NAME = 'companyLegalName',
    COMPANY_TAX_ID = 'companyTaxId',
    COMPANY_ADDRESS = 'companyAddress',
    CUSTOMER_LEGAL_NAME = 'customerLegalName',
    CUSTOMER_IDENTIFIER_TYPE = 'customerIdentifierType',
    CUSTOMER_IDENTIFIER = 'customerIdentifier',
    CUSTOMER_PHONE = 'customerPhone',
    SUBTOTAL = 'subtotal',
    VAT_AMOUNT = 'vatAmount',
    TOTAL = 'total'
}

export type InvoiceData = {
    companyLegalName: string,
    companyTaxId: number,
    companyAddress: string,
    customerLegalName: string,
    customerIdentifierType: IdentifierType
    customerIdentifier: number
    customerPhone?: number
    subtotal: number,
    vatAmount: number,
    total: number
}
