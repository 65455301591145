import styles from './fileField.module.css'
import { useEffect, useState } from 'react'
import { FileTile } from '../../file-tile/fileTile'
import { FileType } from '../../file-tile/types'
import { UploadButton } from '../../upload-button/uploadButton'

type Props = {
    id: string
    value?: File
    fileName?: string
    onChange: (value?: File) => void
}

export const FileField = (props: Props) => {
    const { id, value, fileName, onChange } = props

    const [file, setFile] = useState<File>()
    
    useEffect(() => {
        setFile(value)
    }, [value])

    const onChangeFile = (uploadedFile?: File) => {
        const newFile = uploadedFile ? new File([uploadedFile], fileName || uploadedFile.name, { type: uploadedFile.type }) : undefined
        setFile(newFile)
        onChange(newFile)
    }

    const onClickRemove = () => {
        setFile(undefined)
        onChange(undefined)
    }

    return (
        <div className={styles.container}>
            <FileTile fileType={FileType.PDF} fileName={file?.name} />
            <div className="pt-2">
                <UploadButton
                    id={id}
                    fileTypes={[FileType.PDF]}
                    onChange={onChangeFile}
                    onRemove={onClickRemove}
                />
            </div>
        </div>
    )
}
