import styles from './sidebar.module.css'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MenuItem } from '../../state/types'

type Props = {
    menu: MenuItem[]
}

export const Sidebar = (props: Props) => {
    const { menu } = props

    const [isHovered, setIsHovered] = useState<boolean>(false)
    const [openedItemLevel1, setOpenedItemLevel1] = useState<string>()
    const [openedItemLevel2, setOpenedItemLevel2] = useState<string>()
    const [openedItemLevel3, setOpenedItemLevel3] = useState<string>()
    const [activeItem, setActiveItem] = useState<string>()

    const navigate = useNavigate()
    
    const openedItem = (level: number) => level === 1 ? openedItemLevel1 : (level === 2 ? openedItemLevel2 : openedItemLevel3)
    const setOpenedItemFn = (level: number) => level === 1 ? setOpenedItemLevel1 : (level === 2 ? setOpenedItemLevel2 : setOpenedItemLevel3)

    const hoverClassName = isHovered ? styles.hover : ''

    const hover = () => setIsHovered(true)
    const unhover = () => setIsHovered(false)

    const onClickItem = (item: MenuItem, level: number) => () => {
        if (item.path) {
            navigate(item.path)
            setActiveItem(item.id)
        } else if (item.children) {
            setOpenedItemFn(level)(prev => prev === item.id ? undefined : item.id)
        }
    }

    const renderItem = (item: MenuItem, level: number) => {
        const opened = openedItem(level) === item.id
        const openedClassName = opened ? styles.opened : ''
        const activeClassName = !item.children && activeItem === item.id ? styles.active : ''
        const visibleClassName = isHovered ? styles.visible : ''

        return (
            <div
                key={item.id}
                id={item.id}
                className={`${styles.item} ${openedClassName}`}
            >
                <div className={`${styles.titleContainer} ${openedClassName} ${activeClassName}`} onClick={onClickItem(item, level)}>
                    <span>
                        <i className={`bi bi-${item.icon || 'record-fill'} ${styles.icon} ${item.icon ? styles.bigIcon : styles.smallIcon}`} />
                        <span className={`${styles.title} ${visibleClassName}`}>{item.label}</span>
                    </span>
                    {item.children &&
                        <i className={`bi bi-chevron-down ${styles.arrow} ${openedClassName}`} />
                    }
                </div>
                {isHovered && (
                    <div className={`${styles.content} ${openedClassName}`}>
                        {item.children?.map((item: MenuItem) => renderItem(item, level + 1))}
                    </div>
                )}
            </div>
        )   
    }

    return (
        <aside
            onMouseEnter={hover}
            onMouseLeave={unhover}
            className={`${styles.container} ${hoverClassName}`}
        >
            <div className={styles.toggle}>
                <i className="bi bi-list toggle-sidebar-btn" />
            </div>
            {menu.map((item: MenuItem) => renderItem(item, 1))}
        </aside>
    )
}
