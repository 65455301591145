import { useStateHooks } from '../../../state'
import { TableName } from '../../../tables'
import { useLoaders } from '../../common'
import { useSystemStateHooks } from '../../system'
import { Category } from '../state/types'

export const useCategoryLoaders = () => {
    const systemHooks = useSystemStateHooks()
    const { getList } = useStateHooks()

    const loaders = useLoaders()

    return (companyId = systemHooks.companyId) => {
        const findAllCategories = () => getList(TableName.CATEGORIES) as Category[]

        const loadAllCategories = () => {
            const { loadAll } = loaders(companyId)
            return loadAll(TableName.CATEGORIES) as Promise<Category[]>
        }

        return {
            findAllCategories,
            loadAllCategories
        }
    }
}
