import { DownloadFileModal, ModalId } from "../../../../features/ui"
import { DataType } from "../../../../tables"
import { useOperationsState } from "../../../operations"

export const DownloadDeliveryNoteModal = () => {
    const { currentDelivery } = useOperationsState()

    return (
        <DownloadFileModal
            modalId={ModalId.DOWNLOAD_DELIVERY_NOTE}
            dataType={currentDelivery}
            fileProp={'deliveryNoteUrl' as keyof DataType}
            title="Descargar Remito"
        />
    )
}
