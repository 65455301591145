import styles from './deliveryList.module.css'
import { TableName } from "../../../../tables"
import { useDataTypesHooks } from "../../../../features/data-types"
import { Button, Column, Data, FieldType, FieldValue, ModalId, O, Section, Table, useUIActions } from "../../../../features/ui"
import { useFind, useFindMultipleByParams } from "../../../../state/reducers/hooks"
import { define } from "../../../../utils/typeUtils"
import { Product, ProductName } from "../../../products"
import { Operation, OrderDelivery } from '../../state/types'

type Props = {
  orderId: string
  operation: Operation
  readOnly?: boolean
  onChangeCurrentDelivery?: (delivery: OrderDelivery) => void
}

export const DeliveryList = (props: Props) => {
  const { orderId, operation, readOnly, onChangeCurrentDelivery } = props

  const { formatValue } = useDataTypesHooks()
  const find = useFind()
  const findMultipleByParams = useFindMultipleByParams()

  const { toggleModal } = useUIActions()

  const deliveries = findMultipleByParams(TableName.ORDER_DELIVERIES, { orderId }) as OrderDelivery[]

  const tableName = () => {
    switch (operation) {
      case Operation.PURCHASE:
        return TableName.PURCHASE_ITEMS
      case Operation.HOARD:
        return TableName.HOARD_ITEMS
      case Operation.SALE:
      default:
        return TableName.SALE_ITEMS
    }
  }

  const columns: Column[] = [
    {
      name: 'productId',
      type: FieldType.TEXT,
      label: 'Producto',
      render: (value?: FieldValue) => {
        const product = define(find(TableName.PRODUCTS, value as O<string>)) as Product
        return <ProductName product={product} showSupplierCode />
      }
    },
    {
      name: 'quantity',
      type: FieldType.TEXT,
      label: 'Cantidad Entregada'
    }
  ]

  const onDownload = (delivery: OrderDelivery) => () => {
    onChangeCurrentDelivery && onChangeCurrentDelivery(delivery)
    toggleModal(ModalId.DOWNLOAD_DELIVERY_NOTE)
  }
  
  const onUpload = (delivery: OrderDelivery) => () => {
    onChangeCurrentDelivery && onChangeCurrentDelivery(delivery)
    toggleModal(ModalId.UPLOAD_DELIVERY_NOTE)
  }

  return (<>
    {deliveries
      .sort((delivery_A, delivery_B) => delivery_B.date.localeCompare(delivery_A.date))
      .map((delivery, index) => {
        const date = formatValue(new Date(delivery.date), FieldType.DATE)
        const branchName = formatValue(delivery.branchId, FieldType.TABLE, TableName.BRANCHES)

        const items = delivery.deliveredItems.map(deliveredItem => {
          const item = define(find(tableName(), deliveredItem.itemId)) as Data
          return {
            productId: item.productId,
            quantity: deliveredItem.delivered
          }
        })

        return (
          <Section
            key={index}
            id="delivery"
            title={`${date} - ${branchName}`}
            collapsable
            className={styles.deliverySection}
          >
            <div className={styles.container}>
              <Table
                columns={columns}
                rows={items}
                rowId='productId'
                readOnly
                small
              />
              {delivery.deliveryNoteUrl ? (
                <Button
                  icon='download'
                  tooltip='Descargar Remito'
                  kind="tertiary"
                  small
                  rightMargin
                  onClick={onDownload(delivery)}
                />
              ) : !readOnly && (
                <Button
                  icon='upload'
                  tooltip='Subir Remito'
                  small
                  rightMargin
                  onClick={onUpload(delivery)}
                /> 
              )}
            </div>
          </Section>
        )
      }
    )}
  </>)
}
