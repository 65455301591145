import { FieldValue, O } from "../features/ui"

export const toString = <T>(value: T): string => value as string

export const toNumber = (value?: string): O<number> =>
    value !== '' && value !== undefined ? parseFloat(value) : undefined

export const round = (value: number, decimals = 2) => +value.toFixed(decimals)

export const toBoolean = (value?: string): boolean => value === 'true'

export const toUpperCase = <T>(value: T): string => toString(value).toUpperCase()

export const capitalize = (str: string): string =>
    str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')

export const pluralize = (str: string): string => {
    const language = 'SPANISH'
    return language === 'SPANISH' ? pluralizeSpanish(str) : pluralizeEnglish(str)
}

export const pluralizeEnglish = (str: string): string => {
    const pluralizeCh = str.endsWith('ch') ? str + 'es' : str + 's'
    const pluralizeY = str.endsWith('y') ? str.slice(0, -1) + 'ies' : pluralizeCh
    const pluralizeS = str.endsWith('s') ? str : pluralizeY
    return pluralizeS
}

export const pluralizeSpanish = (str: string): string => {
    const words = str.split(' ')
    return words.length > 1 ? words.map(pluralizeSpanishWord).join(' ') : pluralizeSpanishWord(str)
}

export const pluralizeSpanishWord = (str: string): string => 
    str.endsWith('d') || str.endsWith('n') || str.endsWith('r') || str.endsWith('l') ? str + 'es' : str + 's'

export const replaceAll = (str: string, from: string, to: string): string => str.split(from).join(to)

export const replaceAllMultiple = (str: string, ...replaceArgs: [from: string, to: string][]): string => {
    let result = str
    replaceArgs.forEach(([from, to]) => {
        result = replaceAll(result, from, to)
    })
    return result
}

export const normalize = (str: string): string => removeAccentMarks(str.toLowerCase())

export const equalsNormalized = (str_1?: string, str_2?: string): boolean => !!str_1 && !!str_2 && normalize(str_1) === normalize(str_2)

export const removeSpaces = (str: string): string => str.split(' ').join('')

export const removeAccentMarks = (str: string): string =>
    replaceAllMultiple(str, ['á', 'a'], ['é', 'e'], ['í', 'i'], ['ó', 'o'], ['ú', 'u'])

export const getUrlPath = (pathname: string): string => {
    return pathname.replace('/', '')
}

export const define = <T>(value: T) => {
    if (value === undefined || value === null) {
        throw new Error('Value is undefined.')
    }
    return value
}

export const isDefined = (value?: FieldValue): boolean => value !== undefined

export const toDate = (dateStr?: string): O<Date> => dateStr ? new Date(dateStr) : undefined
