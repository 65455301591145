import { useNavigate } from "react-router-dom"
import { Data, DataTypeForm } from "../../../../../features/data-types"
import { FieldType, FieldValue, FormField, ModalId, O, StaticValue, useEffectAfterMount, useUIActions, Validatable } from "../../../../../features/ui"
import { useActions } from "./actions"
import { define } from '../../../../../utils/typeUtils'
import { useMemo, useState } from 'react'
import { TaxItemsTable } from '../../tax/tax-items-table/taxItemsTable'
import { Check, CheckStatus, PaymentCategory, PaymentTicketItem, TaxType } from '../../../state/types'
import { TaxItemFormModal } from '../../tax/tax-item-form-modal/taxItemFormModal'
import { useAdministrationState } from '../../../hooks/administrationHooks'
import { firstItem, toList } from '../../../../../utils/listUtils'
import { PaymentItemFormModal } from '../payment-item-form-modal/paymentItemFormModal'
import { PartialPaymentModal } from '../partial-payment-modal/partialPaymentModal'
import { usePricesHooks } from '../../../../operations'
import { PaymentOrderTicketsInfo } from '../payment-order-tickets-info/paymentOrderTicketsInfo'
import { TableName } from "../../../../../tables"
import { useTicketHooks } from "../../../hooks/ticketHooks"
import { PaymentItemsTable } from "../payment-items-table/paymentItemsTable"
import { useCashFundLoaders } from "../../../loaders/cashFundLoaders"
import { useCreditCardLoaders } from "../../../loaders/creditCardLoaders"
import { usePaymentTypeLoaders } from "../../../loaders/paymentTypeLoaders"
import { useTaxLoaders } from "../../../loaders/taxLoaders"

type Props = {
  checks: Check[]
}

export const PaymentOrderForm = (props: Props) => {
  const { checks } = props

  const { selectedTickets } = useAdministrationState()
  const { getVatYield, getTicketPendingAmount } = useTicketHooks()

  const vatYield = useMemo(() => getVatYield(selectedTickets), [])
  const pendingAmountsSum = useMemo(() => selectedTickets.reduce((sum, ticket) => sum + getTicketPendingAmount(ticket), 0), [])
  const firstTicket = useMemo(() => define(firstItem(selectedTickets)), [])

  const [payedAmount, setPayedAmount] = useState<O<number>>(pendingAmountsSum)
  const [taxItemsData, setTaxItemsData] = useState<Data[]>([])
  const [currentTaxItem, setCurrentTaxItem] = useState<Data>()
  const [paymentItemsData, setPaymentItemsData] = useState<Data[]>([])
  const [currentPaymentItem, setCurrentPaymentItem] = useState<Data>()
  const [pendingAmount, setPendingAmount] = useState<number>(pendingAmountsSum)
  const [paymentItemsValidation, setPaymentItemsValidation] = useState<string>()
  const [pendingAmountValidation, setPendingAmountValidation] = useState<string>()
  const [modalData, setModalData] = useState<O<Data>>()
  
  const pricesHooks = usePricesHooks()
  const { isSalary } = useTicketHooks()
  const navigate = useNavigate()

  const cashFundLoaders = useCashFundLoaders()
  const creditCardLoaders = useCreditCardLoaders()
  const paymentTypeLoaders = usePaymentTypeLoaders()
  const taxLoaders = useTaxLoaders()

  const { addPaymentTicketItem, submitPaymentOrderOrOpenModal, submitPaymentOrder, cleanSelectedTickets } = useActions()
  const { toggleModal } = useUIActions()

  const taxItemsAmount = useMemo(() => {
    setPendingAmountValidation(undefined)
    return taxItemsData.reduce((sum, taxItemData) => sum + (define(taxItemData.amount) as number), 0)
  }, [taxItemsData])
  const paymentItemsAmount = useMemo(() => {
    setPendingAmountValidation(undefined)
    return paymentItemsData.reduce((sum, paymentItemData) => sum + (define(paymentItemData.amount) as number), 0)
  }, [paymentItemsData])

  useEffectAfterMount(() => {
    if (payedAmount) {
      setTaxItemsData(taxItemsData.map(taxItemData => ({
        ...taxItemData,
        amount: pricesHooks.adjustment.calculate(payedAmount, define(taxItemData.taxYield) as number)
      })))
    }
  }, [payedAmount])

  useEffectAfterMount(() => {
    setPendingAmountValidation(undefined)
    payedAmount && setPendingAmount(payedAmount - taxItemsAmount - paymentItemsAmount)
  }, [payedAmount, taxItemsAmount, paymentItemsAmount])

  const paymentTypes = useMemo(() => paymentTypeLoaders().findAllPaymentTypes(), [])
  const paymentTicketItems: PaymentTicketItem[] = useMemo(() => addPaymentTicketItem(payedAmount || 0, [...selectedTickets]), [payedAmount])
  const payedVatAmount = useMemo(() => (payedAmount || 0) * vatYield, [payedAmount])

  const taxItemMatches = (data: Data, taxCode: string) => data.taxCode === taxCode
  const paymentItemMatches = (data: Data, paymentTypeId: string, sourceId: string) =>
    data.paymentTypeId === paymentTypeId && data.sourceId === sourceId
  const filterSources = (paymentCategory: PaymentCategory, source: Data) =>
    (currentPaymentItem?.paymentCategory === paymentCategory && currentPaymentItem?.sourceId === source.id) ||
    !paymentItemsData
      .filter(paymentItemData => paymentItemData.paymentCategory === paymentCategory)
      .map(paymentItemData => paymentItemData.sourceId)
      .includes(source.id)

  const retentions = useMemo(() => {
    const allTaxes = taxLoaders().findAllTaxes()
    return allTaxes.filter(tax => tax.type === TaxType.RETENTION &&
      (currentTaxItem?.taxCode === tax.code || !taxItemsData.map(taxItemData => taxItemData.taxCode).includes(tax.code))
    )
  }, [taxItemsData, currentTaxItem])

  const cashFunds = useMemo(() => {
    const allCashFunds = cashFundLoaders().findAllCashFunds()
    return allCashFunds.filter(cashFund => filterSources(PaymentCategory.CASH_FUND, cashFund))
  }, [paymentItemsData, currentPaymentItem])

  const creditCards = useMemo(() => {
    const allCreditCards = creditCardLoaders().findAllCreditCards()
    return allCreditCards.filter(creditCard => filterSources(PaymentCategory.CREDIT_CARD, creditCard))
  }, [paymentItemsData, currentPaymentItem])

  const checkList = useMemo(() =>
    checks
      .filter(check => check.endorsable).filter(check => check.status === CheckStatus.ENABLED)
      .filter(check => filterSources(PaymentCategory.CHECK, check))
  , [checks, paymentItemsData, currentPaymentItem])

  const onChangePayedAmount = (value?: FieldValue) => {
    setPayedAmount(value as O<number>)
  }

  const onAddTaxItem = () => {
    setCurrentTaxItem(undefined)
    toggleModal(ModalId.TAX_ITEM_FORM)
  }

  const onEditTaxItem = (taxCode: string) => {
    setCurrentTaxItem(taxItemsData.find(taxItemData => taxItemMatches(taxItemData, taxCode)))
    toggleModal(ModalId.TAX_ITEM_FORM)
  }

  const onRemoveTaxItem = (taxCode: string) => {
    const newTaxItemsData = taxItemsData.filter(taxItemData => !taxItemMatches(taxItemData, taxCode))
    setTaxItemsData(newTaxItemsData)
  }

  const onAddPaymentItem = () => {
    setCurrentPaymentItem(undefined)
    setPendingAmountValidation(undefined)
    setPaymentItemsValidation(undefined)
    toggleModal(ModalId.PAYMENT_ITEM_FORM)
  }

  const onEditPaymentItem = (paymentTypeId: string, sourceId: string) => {
    setCurrentPaymentItem(paymentItemsData.find(paymentItemData => paymentItemMatches(paymentItemData, paymentTypeId, sourceId)))
    setPendingAmountValidation(undefined)
    toggleModal(ModalId.PAYMENT_ITEM_FORM)
  }

  const onRemovePaymentItem = (paymentTypeId: string, sourceId: string) => {
    const newPaymentItemsData = paymentItemsData.filter(paymentItemData => !paymentItemMatches(paymentItemData, paymentTypeId, sourceId))
    setPaymentItemsData(newPaymentItemsData)
  }

  const fields: FormField[] = [
    {
      name: 'tickets',
      type: FieldType.TEXT,
      label: 'Facturas',
      render: () => <PaymentOrderTicketsInfo
        tickets={selectedTickets}
        amounts={new Map(Array.from(selectedTickets, ticket => [ticket.id, getTicketPendingAmount(ticket)]))}
        showLinks={false}
      />
    },
    {
      name: 'selectedAmount',
      type: FieldType.PRICE,
      label: 'Importe Seleccionado',
      render: () => <StaticValue type={FieldType.PRICE} value={pendingAmountsSum} />
    },
    {
      name: 'payedAmount',
      type: FieldType.PRICE,
      label: 'Importe a Pagar',
      value: payedAmount,
      max: pendingAmountsSum,
      onChange: onChangePayedAmount
    },
    {
      name: 'paymentItems',
      type: FieldType.TABLE,
      label: 'Medios de Pago',
      render: () => (
        <Validatable validations={toList(paymentItemsValidation)}>
          <PaymentItemsTable
            rows={paymentItemsData}
            disableCreate={!payedAmount || paymentTypes.length === paymentItemsData.length}
            onCreate={onAddPaymentItem}
            onEditRow={onEditPaymentItem}
            onRemoveRow={onRemovePaymentItem}
          />
        </Validatable>
      )
    },
    {
      name: 'pendingAmount',
      type: FieldType.PRICE,
      label: 'Saldo Pendiente',
      render: () => (
        <Validatable validations={toList(pendingAmountValidation)} left>
          <StaticValue type={FieldType.PRICE} value={pendingAmount} />
        </Validatable>
      )
    }
  ]
  if (!isSalary(firstTicket.parentTable, firstTicket.parentId)) {
    const taxItemsField = {
      name: 'taxItems',
      type: FieldType.TABLE,
      label: 'Retenciones',
      render: () => (
        <TaxItemsTable
          rows={taxItemsData}
          disableCreate={!payedAmount || retentions.length === taxItemsData.length}
          onCreate={onAddTaxItem}
          onEditRow={onEditTaxItem}
          onRemoveRow={onRemoveTaxItem}
        />
      )
    }
    fields.splice(3, 0, taxItemsField)
  }

  const validate = (): boolean => {
    let isValid = true
    if (paymentItemsData.length === 0) {
      setPaymentItemsValidation('Debe agregar al menos 1 medio de pago')
      isValid = false
    } else if (pendingAmount !== 0) {
      setPendingAmountValidation('El saldo pendiente debe ser $0,00.')
      isValid = false
    }

    return isValid
  }

  const onSubmit = (paymentOrderData: Data) => {
    if (validate()) {
      setModalData(paymentOrderData)
      submitPaymentOrderOrOpenModal(
        !payedAmount || payedAmount < pendingAmountsSum,
        paymentOrderData,
        paymentTicketItems,
        taxItemsData,
        paymentItemsData
      )
    }
  }

  const onCancel = () => {
    navigate(-1)
    cleanSelectedTickets()
  }

  const onSaveTaxItem = (newData: Data) => {
    let newTaxItemsData = taxItemsData
    if (currentTaxItem) {
      const { taxCode } = currentTaxItem
      newTaxItemsData = taxItemsData.map(taxItemData => taxItemMatches(taxItemData, define(taxCode) as string) ? newData : taxItemData)
    } else {
      newTaxItemsData = [newData, ...taxItemsData]
    }
    setTaxItemsData(newTaxItemsData)
  }

  const onSavePaymentItem = (newData: Data) => {
    let newPaymentItemsData = paymentItemsData
    if (currentPaymentItem) {
      const { paymentTypeId, sourceId } = currentPaymentItem
      newPaymentItemsData = paymentItemsData.map(paymentItemData =>
        paymentItemMatches(paymentItemData, define(paymentTypeId) as string, define(sourceId) as string) ?
        newData : paymentItemData
      )
    } else {
      newPaymentItemsData = [newData, ...paymentItemsData]
    }
    setPaymentItemsData(newPaymentItemsData)
  }

  const onSubmitModal = (paymentOrderData: Data) => {
    submitPaymentOrder(
      paymentOrderData,
      paymentTicketItems,
      taxItemsData,
      paymentItemsData
    )
  }

  return (
    <>
      <DataTypeForm
        formId="payment-order-form"
        fields={fields}
        createMode
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
      <TaxItemFormModal
        taxItem={currentTaxItem}
        total={payedAmount}
        vatAmount={payedVatAmount}
        title="Agregar Retención"
        taxes={retentions}
        onSubmit={onSaveTaxItem}
      />
      <PaymentItemFormModal
        paymentItem={currentPaymentItem}
        pendingAmount={pendingAmount}
        cashFunds={cashFunds}
        creditCards={creditCards}
        checks={checkList}
        onSubmit={onSavePaymentItem}
      />
      <PartialPaymentModal
        data={modalData}
        table={firstTicket.parentTable as TableName}
        paymentTicketItems={paymentTicketItems}
        onSubmit={onSubmitModal}
      />
    </>
  )
}
