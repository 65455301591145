import { ReducerName, ReducerStateActions, ReducerStates, ReducerState, TextParams } from './types'
import { DataType, TableName, TablesData } from '../../tables'
import { useSelector } from 'react-redux'
import { define } from '../../utils/typeUtils'
import { Store } from 'redux'
import { useOperationsStateActions } from '../../modules/operations'
import { useProductsStateActions } from '../../modules/products'
import { usePurchasesStateActions } from '../../modules/purchases'
import { useSalesStateActions } from '../../modules/sales'
import { useStockStateActions } from '../../modules/stock'
import { useAdministrationStateActions } from '../../modules/administration'
import { useSystemStateActions } from '../../modules/system'
import { useManagerStateActions } from '../../modules/manager'
import { FieldValue, O, useUIStateActions } from '../../features/ui'

const useFindReducerAction = () => {
    const administrationStateActions = useAdministrationStateActions()
    const managerStateActions = useManagerStateActions()
    const operationsStateActions = useOperationsStateActions()
    const productsStateActions = useProductsStateActions()
    const purchasesStateActions = usePurchasesStateActions()
    const salesStateActions = useSalesStateActions()
    const stockStateActions = useStockStateActions()
    const systemStateActions = useSystemStateActions()
    const uiStateActions = useUIStateActions()

    return (tableName: TableName, isUpdater: boolean): ReducerStateActions => {
        const { reducerName, updater, remover } = define(TablesData.get(tableName))
        const reducerAction = (isUpdater ? updater() : remover()) as keyof ReducerStateActions
        
        switch (reducerName) {
            case ReducerName.ADMINISTRATION_REDUCER:
                return administrationStateActions[reducerAction]
            case ReducerName.MANAGER_REDUCER:
                return managerStateActions[reducerAction]
            case ReducerName.OPERATIONS_REDUCER:
                return operationsStateActions[reducerAction]
            case ReducerName.PRODUCTS_REDUCER:
                return productsStateActions[reducerAction]
            case ReducerName.PURCHASES_REDUCER:
                return purchasesStateActions[reducerAction]
            case ReducerName.SALES_REDUCER:
                return salesStateActions[reducerAction]
            case ReducerName.STOCK_REDUCER:
                return stockStateActions[reducerAction]
            case ReducerName.SYSTEM_REDUCER:
                return systemStateActions[reducerAction]
            case ReducerName.UI_REDUCER:
                return uiStateActions[reducerAction]
            default:
                return null
        }
    }
}

export const useFindReducerUpdater = () => {
    const findReducerAction = useFindReducerAction()
    return (tableName: TableName): ReducerStateActions => {
        return findReducerAction(tableName, true)
    }
}

export const useFindReducerRemover = () => {
    const findReducerAction = useFindReducerAction()
    return (tableName: TableName): ReducerStateActions => {
        return findReducerAction(tableName, false)
    }
}

const useReducerStates = () => {
    const reducerSelector = (reducerName: string) => (store: Store) => store[reducerName as keyof Store] as unknown as ReducerState
    const administrationReducer = useSelector(reducerSelector(ReducerName.ADMINISTRATION_REDUCER))
    const managerReducer = useSelector(reducerSelector(ReducerName.MANAGER_REDUCER))
    const operationsReducer = useSelector(reducerSelector(ReducerName.OPERATIONS_REDUCER))
    const productsReducer = useSelector(reducerSelector(ReducerName.PRODUCTS_REDUCER))
    const purchasesReducer = useSelector(reducerSelector(ReducerName.PURCHASES_REDUCER))
    const salesReducer = useSelector(reducerSelector(ReducerName.SALES_REDUCER))
    const stockReducer = useSelector(reducerSelector(ReducerName.STOCK_REDUCER))
    const systemReducer = useSelector(reducerSelector(ReducerName.SYSTEM_REDUCER))
    const uiReducer = useSelector(reducerSelector(ReducerName.UI_REDUCER))
    
    const reducerStates: ReducerStates = {
        administrationReducer,
        managerReducer,
        operationsReducer,
        productsReducer,
        purchasesReducer,
        salesReducer,
        stockReducer,
        systemReducer,
        uiReducer
    }

    return (tableName: TableName) => {
        const { reducerName } = define(TablesData.get(tableName))
        return reducerStates[reducerName as keyof ReducerState]
    }
}

export const useFindListState = () => {
    const reducerStates = useReducerStates()
    
    return (tableName: TableName): DataType[] => {
        const { stateName } = define(TablesData.get(tableName))
        const reducerState = reducerStates(tableName)
        return define(reducerState)[stateName as keyof ReducerState] || []
    }
}

export const useFind = () => {
    const findListState = useFindListState()
    
    return (tableName: TableName, value?: FieldValue, attr: string = 'id'): O<DataType> => {
        if (!value) {
            return undefined
        }
        const listState: DataType[] = findListState(tableName)
        return listState.find(element => element[attr as keyof DataType] === value)
    }
}

export const useFindMultipleByFilter = () => {
    const findListState = useFindListState()
    
    return (tableName: TableName, filter: (dataType: DataType) => boolean): DataType[] => {
        const listState: DataType[] = findListState(tableName)
        return listState.filter(element => filter(element))
    }
}

export const useFindByParams = () => {
    const findMultipleByParams = useFindMultipleByParams()
    
    return (tableName: TableName, params: TextParams): O<DataType> => {
        const propNames = Object.keys(params)
        const propValues = Object.values(params)
        if (propNames.length === 0 || propValues.every(value => value === undefined || value === null)) {
            return undefined
        }
        
        const dataTypes: DataType[] = findMultipleByParams(tableName, params)
        return dataTypes.length > 0 ? dataTypes[0] : undefined
    }
}

export const useFindMultipleByParams = () => {
    const findListState = useFindListState()
    
    return (tableName: TableName, params: TextParams, list: DataType[] = []): DataType[] => {
        const propNames = Object.keys(params)
        const propValues = Object.values(params)
        if (propNames.length === 0 || propValues.every(value => value === undefined || value === null)) {
            return []
        }
        const dataTypes: DataType[] = list.length > 0 ? list : findListState(tableName)
        return dataTypes.filter(dataType => {
            return propNames.every(propName => {
                return params[propName] === undefined || dataType[propName as keyof DataType] === params[propName]
            })
        })
    }
}

export const useFindByIds = () => {
    const findListState = useFindListState()
    
    return (tableName: TableName, ids: string[]): DataType[] => {
        const dataTypes: DataType[] = findListState(tableName)
        return dataTypes.filter(dataType => ids.includes(dataType.id))
    }
}

export const useStateHooks = () => {
    const reducerStates = useReducerStates()
    const findReducerAction = useFindReducerAction()

    const getList = (table: TableName): DataType[] => {
        const { stateName } = define(TablesData.get(table))
        const reducerState = reducerStates(table)
        return define(reducerState)[stateName as keyof ReducerState] || []
    }

    const find = (
        table: TableName,
        value?: FieldValue,
        attr: string = 'id'
    ): O<DataType> => {
        if (!value) {
            return undefined
        }
        const listState: DataType[] = getList(table)
        return listState.find(element => element[attr as keyof DataType] === value)
    }

    const findByIds = (
        table: TableName,
        ids: string[]
    ): DataType[] => {
        const listState: DataType[] = getList(table)
        return listState.filter(element => ids.includes(element.id))
    }

    const findByParams = (
        table: TableName,
        params: TextParams,
        list: DataType[] = []
    ): DataType[] => {
        const propNames = Object.keys(params)
        const propValues = Object.values(params)
        if (propNames.length === 0 || propValues.every(value => value === undefined || value === null)) {
            return []
        }
        const dataTypes: DataType[] = list.length > 0 ? list : getList(table)
        return dataTypes.filter(dataType =>
            propNames.every(propName =>
                params[propName] === undefined || dataType[propName as keyof DataType] === params[propName]
            )
        )
    }

    const getUpdater = (table: TableName): ReducerStateActions => findReducerAction(table, true)
    
    const getRemover = (table: TableName): ReducerStateActions => findReducerAction(table, false)

    return {
        getList,
        find,
        findByIds,
        findByParams,
        getUpdater,
        getRemover
    }
}
