import styles from './downloadPurchasePDFModal.module.css'
import { fileName, usePDFActions } from "../../../../../features/files"
import { Modal, ModalId } from "../../../../../features/ui"
import { PurchaseOrder } from "../../../state/types"
import { FileTile, FileType } from "../../../../../features/ui"
import { useEffect, useState } from "react"
import { PurchasePDF } from '../purchase-pdf/purchasePDF'
import { TableName } from '../../../../../tables'

type Props = {
    purchase?: PurchaseOrder
}

export const DownloadPurchasePDFModal = (props: Props) => {
    const { purchase } = props
    
    const [pdfName, setPdfName] = useState<string>()

    const { generatePDF } = usePDFActions()

    useEffect(() => {
        purchase && setPdfName(fileName(TableName.PURCHASE_ORDERS, purchase.code.toString()))
    }, [purchase])

    const pdfId = 'purchase-pdf'
    
    const onSubmit = () => {
        pdfName && generatePDF(pdfId, pdfName)
    }

    const renderHiddenPDF = () => purchase ? (
        <div className={styles.hidden}>
            <PurchasePDF
                id={pdfId}
                purchase={purchase}
            />
        </div>
    ) : <></>
    
    return (
        <Modal
            modalId={ModalId.DOWNLOAD_PURCHASE_PDF}
            title="Descargar Orden"
            submitLabel="Descargar"
            submitIcon="download"
            onSubmit={onSubmit}
        >
            <FileTile fileType={FileType.PDF} fileName={pdfName}/>
            {renderHiddenPDF()}
        </Modal>
    )
}
