import { useNavigate } from "react-router-dom"
import { DataType } from "../../../../tables"
import { define } from "../../../../utils/typeUtils"
import { RoutePath, useRoutesHooks } from "../../../routes"
import { Action, Column, Data, List, ModalId, O, useUIActions } from "../../../ui"
import { CannotRemoveModal } from "../cannot-remove-modal/cannotRemoveModal"
import { RemoveModal } from "../remove-modal/removeModal"
import { useEffect, useState } from "react"
import { useStateHooks } from "../../../../state"

type Props = {
    columns: Column[]
    rows?: Data[]
    rowId?: string
    currentDataTypeParam?: DataType
    createLabel?: string
    showDetail?: boolean
    readOnly?: boolean
    minItems?: number
    actions?: (rowIdValue: string) => Action[]
    onCreate?: () => void
    onEdit?: (rowIdValue?: string) => void
    onRemove?: (rowIdValue?: string) => void
    isEditable?: (rowIdValue?: string) => void
}

export const DataTypeList = (props: Props) => {
    const { columns, rows, rowId = 'id', currentDataTypeParam, actions, createLabel, showDetail = true, readOnly, minItems = 0, onCreate, onEdit, onRemove, isEditable } = props
    
    const { tableName } = useRoutesHooks().parseUrl()
    const definedTableName = define(tableName)

    const [currentDataType, setCurrentDataType] = useState<O<DataType>>(currentDataTypeParam)
    
    const { getList } = useStateHooks()
    const dataTypes: DataType[] = getList(definedTableName)
    const tableRows = rows || dataTypes as Data[]

    const navigate = useNavigate()

    const { toggleModal } = useUIActions()

    useEffect(() => {
        setCurrentDataType(currentDataTypeParam)
    }, [currentDataTypeParam])

    const find = (id?: string) => dataTypes.find(dataType => dataType.id === define(id))

    const defaultOnCreate = () => {
        navigate(RoutePath.CREATE)
    }

    const defaultOnView = (id?: string) => {
        if (id) {
            navigate(id)
        }
    }

    const defaultOnEdit = (id?: string) => {
        navigate(`${id}/${RoutePath.EDIT}`)
    }

    const defaultOnRemove = (id?: string) => {
        setCurrentDataType(find(id))
        toggleModal(ModalId.REMOVE)
    }

    const defaultActions = (rowIdValue?: string): Action[] => {
        const editable = isEditable ? isEditable(rowIdValue) : true

        const actionList: Action[] = []
        if (showDetail) {
            const viewAction: Action = {
                icon: 'eye-fill',
                kind: 'tertiary',
                tooltip: 'Ver Detalle',
                onClick: defaultOnView
            }
            actionList.push(viewAction)
        }
        if (!readOnly) {
            const editAction = {
                icon: 'pencil-fill',
                tooltip: 'Editar',
                disabled: !editable,
                onClick: onEdit || defaultOnEdit
            }
            actionList.push(editAction)
            if (tableRows.length > minItems) {
                const removeAction: Action = {
                    icon: 'trash-fill',
                    kind: 'secondary',
                    tooltip: 'Eliminar',
                    disabled: !editable,
                    onClick: onRemove || defaultOnRemove
                }
                actionList.push(removeAction)
            }
        }

        return actionList
    }

    return (<>
        <List
            columns={columns}
            rows={tableRows}
            rowId={rowId}
            createLabel={createLabel}
            readOnly={readOnly}
            minItems={minItems}
            actions={actions || defaultActions}
            onCreate={onCreate || defaultOnCreate}
            onEdit={onEdit || defaultOnEdit}
            onRemove={onRemove}
        />
        <RemoveModal
            tableName={definedTableName}
            dataType={currentDataType}
        />
        <CannotRemoveModal />
    </>)
}
