import { useEffect, useState } from "react"
import { ConstrufyAdminPanel } from "../../../../modules/construfy-admin"
import { useLoad } from "./load"
import { ElementLoader } from "../element-loader/elementLoader"

export const AdminElement = () => {
  const [ready, setReady] = useState(false)

  const load = useLoad()

  useEffect(() => {
    load().then(() => setReady(true))
  }, [])

  return (
    <ElementLoader ready={ready}>
      <ConstrufyAdminPanel />
    </ElementLoader>
  )
}
