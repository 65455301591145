import { useState } from "react"
import { FieldType, UIPermission, Action, Column, FieldValue, SpinnerDiv, Data, Badge, useUIActions, ModalId, ButtonKind, Async } from "../../../../../features/ui"
import { DataType, TableName } from "../../../../../tables"
import { useSystemStateHooks } from "../../../../system"
import { PurchaseItem, PurchaseOrder } from "../../../state/types"
import { define, toNumber } from "../../../../../utils/typeUtils"
import { DataTypeList, Labels } from "../../../../../features/data-types"
import { DeliveryStatus, PaymentStatus, usePriceBreakdownHooks } from "../../../../operations"
import { useBranchLoaders } from "../../../../manager"
import { PurchaseFilters } from "./purchaseFilters"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "../../../../../features/routes"
import { DownloadPurchasePDFModal } from "../download-purchase-pdf-modal/downloadPurchasePDFModal"
import { Ticket, UploadAdditionalTicketModal } from "../../../../administration"
import { useSupplierLoaders } from "../../../loaders/supplierLoaders"

type Props = {
    purchases: PurchaseOrder[]
    purchaseItems: PurchaseItem[]
    tickets: Ticket[]
}

export const PurchaseList = (props: Props) => {
    const { purchases, purchaseItems, tickets } = props

    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [currentPurchase, setCurrentPurchase] = useState<PurchaseOrder>()

    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_PURCHASES)
    const { getPriceBreakdownFromItems } = usePriceBreakdownHooks()
    const navigate = useNavigate()

    const branchLoaders = useBranchLoaders()
    const supplierLoaders = useSupplierLoaders()

    const { toggleModal } = useUIActions()

    const findPurchase = (code?: string) => purchases.find(purchase => purchase.code === toNumber(code))
    const findTickets = (parentId?: string) => tickets.filter(ticket => ticket.parentId === parentId)

    const columns: Column[] = [
        {
            name: 'code',
            type: FieldType.NUMBER,
            label: '#',
            render: (value?: FieldValue) => `#${value}`
        },
        {
            name: 'deliveryBranchId',
            type: FieldType.TABLE,
            table: TableName.BRANCHES,
            label: 'Sucursal de Recepción',
            render: (value?: FieldValue) => {
                const branch = branchLoaders().findBranch(value as string)
                return <Badge label={define(branch).name} />
            }
        },
        {
            name: 'creationDate',
            type: FieldType.DATE,
            label: 'Fecha de Creación'
        },
        {
            name: 'deliveryDate',
            type: FieldType.DATE,
            label: 'Fecha de Recepción'
        },
        {
            name: 'supplierId',
            type: FieldType.TABLE,
            table: TableName.SUPPLIERS,
            label: 'Proveedor',
            render: (value?: FieldValue) => <Async
                loadFn={async () => {
                    const supplier = await supplierLoaders().loadSupplier(value as string)
                    return <Badge label={define(supplier).name} />
                }}
            />
        },
        {
            name: 'deliveryStatus',
            type: FieldType.TEXT,
            label: 'Estado de Entrega',
            render: (value?: FieldValue) => Labels.deliveryStatusLabel(define(value) as DeliveryStatus)
        },
        {
            name: 'paymentStatus',
            type: FieldType.TEXT,
            label: 'Estado de Pago',
            render: (value?: FieldValue) => Labels.paymentStatusLabel(define(value) as PaymentStatus)
        }
    ]

    const onView = (code?: string) => {
        const purchase = findPurchase(code)
        purchase && navigate(purchase.id)
    }

    const onDownloadPDF = (code?: string) => {
        setCurrentPurchase(findPurchase(code))
        toggleModal(ModalId.DOWNLOAD_PURCHASE_PDF)
    }

    const onEdit = (code?: string) => {
        const purchase = findPurchase(code)
        purchase && navigate(`${purchase.id}/${RoutePath.EDIT}`)
    }

    const onUploadTicket = (code?: string) => {
        const purchase = findPurchase(code)
        setCurrentPurchase(purchase)
        const purchaseTickets = findTickets(purchase?.id)
        if (purchaseTickets.length > 0) {
            toggleModal(ModalId.UPLOAD_ADDITIONAL_TICKET)
        } else if (purchase) {
            navigate(`${purchase.id}/${RoutePath.TICKETS}/${RoutePath.CREATE}`)
        }
    }

    const onArchive = (code?: string) => {
        findPurchase(code)
    }

    const actions = (code?: string) => {
        const purchase = findPurchase(code)
        const items = purchaseItems.filter(purchaseItem => purchaseItem.purchaseId === purchase?.id)
        const purchaseTickets = findTickets(purchase?.id)
        const isDeliveryPending = purchase?.deliveryStatus === DeliveryStatus.PENDING
        const isPaymentPending = purchase?.paymentStatus === PaymentStatus.PENDING
        const isEditable = isDeliveryPending && isPaymentPending && purchaseTickets.length === 0
        const isDelivered = purchase?.deliveryStatus === DeliveryStatus.DELIVERED
        const ticketsSubtotal = purchaseTickets.reduce((sum, ticket) => sum + ticket.subtotal, 0)
        const purchaseSubtotal = getPriceBreakdownFromItems(items).subtotal
        const isInvoiceable = ticketsSubtotal < purchaseSubtotal
        
        const actionList: Action[] = [
            { icon: 'eye-fill', kind: 'tertiary', tooltip: 'Ver Detalle', onClick: onView },
            { icon: 'download', kind: 'tertiary', tooltip: 'Descargar', onClick: onDownloadPDF }
        ]
        if (!readOnly) {
            if (isEditable) {
                actionList.push({ icon: 'pencil-fill', tooltip: 'Editar', onClick: onEdit })
            }
            if (isInvoiceable) {
                actionList.push({ icon: 'upload', tooltip: 'Subir Factura', onClick: onUploadTicket })
            }
            if (isDelivered) {
                actionList.push({ icon: 'archive', kind: 'secondary' as ButtonKind, tooltip: 'Archivar', onClick: onArchive })
            }
        }

        return actionList
    }

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRows(dataTypes as PurchaseOrder[])
        setAsyncDataLoaded(true)
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <>
            <PurchaseFilters
                purchases={purchases}
                onSearchStart={onSearchStart}
                onSearchDone={onSearchDone}
            />
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    rowId="code"
                    currentDataTypeParam={currentPurchase}
                    actions={actions}
                    readOnly={readOnly}
                />
            )}
            <DownloadPurchasePDFModal purchase={currentPurchase} />
            <UploadAdditionalTicketModal routePathPrefix={currentPurchase?.id} />
        </>
    )
}
