import { Labels, useDescriptorHooks } from "../../../../../features/data-types"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { Action, DetailFieldProps, DetailPanel, FieldType, FieldValue, ModalId, PDFTabContent, Tab, UIPermission, useGetCrumbs, useUIActions } from "../../../../../features/ui"
import { TableName } from "../../../../../tables"
import { define } from "../../../../../utils/typeUtils"
import { useSystemStateHooks } from "../../../../system"
import { PaymentOrder, PaymentOrderStatus, Ticket } from "../../../state/types"
import { TaxItemsTable } from "../../tax/tax-items-table/taxItemsTable"
import { ConfirmPaymentFormModal } from "../confirm-payment-modal/confirmPaymentFormModal"
import { PaymentOrderTicketsInfo } from "../payment-order-tickets-info/paymentOrderTicketsInfo"
import { PaymentItemsTable } from "../payment-items-table/paymentItemsTable"
import { DownloadPaymentOrderPDFModal } from "../download-payment-order-pdf-modal/downloadPaymentOrderPDFModal"
import { PaymentOrderPDF } from "../payment-order-pdf/paymentOrderPDF"
import { DownloadReceiptModal } from "../../../../common"
import { DownloadRetentionCertificatePDFModal } from "../download-retention-certificate-pdf-modal/downloadRetentionCertificatePDFModal"
import { RetentionCertificatePDF } from "../retention-certificate-pdf/retentionCertificatePDF"
import { useEffect, useState } from "react"
import { useTicketLoaders } from "../../../loaders/ticketLoaders"

export const PaymentOrderDetail = () => {
  const { dataType } = useRoutesHooks().parseUrl()
  const paymentOrder = define(dataType) as PaymentOrder
  const { ticketItems, paymentItems, taxItems, ...data } = paymentOrder

  const [tickets, setTickets] = useState<Ticket[]>([])

  const { userHasPermission } = useSystemStateHooks()
  const readOnly = !userHasPermission(UIPermission.SAVE_PAYMENT_ORDERS)
  const descriptorHooks = useDescriptorHooks()

  const ticketLoaders = useTicketLoaders()

  const { openModal } = useUIActions()
  
  const init = async () => {
    const { loadParentTickets } = ticketLoaders()
    const loadedTickets = await loadParentTickets(paymentOrder.id)
    setTickets(loadedTickets)
  }

  useEffect(() => {
    init()
  }, [])

  const payedAmounts = new Map(paymentOrder.ticketItems.map(ticketItem => [ticketItem.ticketId, ticketItem.payedAmount]))
  const crumbs = useGetCrumbs('Órdenes de Pago', RoutePath.PAYMENT_ORDERS, descriptorHooks.paymentOrder.code(paymentOrder))

  const fields: DetailFieldProps[] = [
    {
      name: 'tickets',
      type: FieldType.TEXT,
      label: 'Facturas',
      labelSpace: 2,
      render: () => <PaymentOrderTicketsInfo
        tickets={tickets}
        amounts={payedAmounts}
      />
    },
    {
      name: 'payedAmount',
      type: FieldType.PRICE,
      label: 'Importe',
      labelSpace: 2,
    },
    {
      name: 'creationDate',
      type: FieldType.DATE,
      label: 'Fecha',
      labelSpace: 2,
    },
    {
      name: 'paymentItems',
      type: FieldType.TABLE,
      label: 'Medios de Pago',
      labelSpace: 2,
      render: () => (
        <PaymentItemsTable
          rows={paymentOrder.paymentItems}
          readOnly
        />
      )
    },
    {
      name: 'status',
      type: FieldType.TEXT,
      label: 'Estado',
      labelSpace: 2,
      render: (value?: FieldValue) => <>{Labels.paymentOrderStatusLabel(define(value) as PaymentOrderStatus)}</>
    }
  ]
  if (paymentOrder.taxItems.length > 0) {
    const taxItemsField = {
      name: 'taxItems',
      type: FieldType.TABLE,
      label: 'Retenciones',
      labelSpace: 2,
      render: () => (
        <TaxItemsTable
          rows={paymentOrder.taxItems}
          readOnly
        />
      )
    }
    fields.splice(3, 0, taxItemsField)
  }

  const onDownloadPDF = () => openModal(ModalId.DOWNLOAD_PAYMENT_ORDER_PDF)
  
  const onDownloadReceipt = () => openModal(ModalId.DOWNLOAD_RECEIPT)

  const onDownloadRetentionCertificatePDF = () => openModal(ModalId.DOWNLOAD_RETENTION_CERTIFICATE_PDF)

  const onConfirmPayment = () => openModal(ModalId.CONFIRM_PAYMENT_FORM)

  const actions: Action[] = [
    { label: 'Orden de Pago', kind: 'tertiary', icon: 'download', onClick: onDownloadPDF }
  ]
  const isPayed = paymentOrder.status === PaymentOrderStatus.PAYED
  const showRetentionsCertificate = isPayed && paymentOrder.taxItems.length > 0

  if (paymentOrder.receiptUrl) {
    actions.push(
      { label: 'Comp. de Pago', icon: 'download', kind: 'senary', onClick: onDownloadReceipt }
    )
  }
  if (showRetentionsCertificate) {
    actions.push(
      { label: 'Cert. de Retención', icon: 'download', kind: 'senary', onClick: onDownloadRetentionCertificatePDF }
    )
  }
  if (!readOnly && !isPayed) {
    actions.push(
      { label: 'Confirmar Pago', icon: 'check-lg', onClick: onConfirmPayment }
    )
  }

  const tabs: Tab[] = [
    {
      id: 'pdf',
      title: 'PDF',
      content: (
        <PDFTabContent>
          <PaymentOrderPDF
            id="payment-order-pdf-preview"
            paymentOrder={paymentOrder}
            previewMode
          />
        </PDFTabContent>
      )
    }
  ]
  if (showRetentionsCertificate) {
    tabs.push({
      id: 'retention-certificate',
      title: 'Cert. de Retención',
      content: (
        <PDFTabContent>
          <RetentionCertificatePDF
            id="retention-certificate-pdf-preview"
            paymentOrder={paymentOrder}
            previewMode
          />
        </PDFTabContent>
      )
    })
  }

  return (<>
    <DetailPanel
      tableName={TableName.PAYMENT_ORDERS}
      crumbs={crumbs}
      fields={fields}
      data={data}
      icon='cash-coin'
      tabs={tabs}
      readOnly={readOnly}
      actions={actions}
    />
    <DownloadPaymentOrderPDFModal paymentOrder={paymentOrder} />
    <DownloadReceiptModal operation={paymentOrder} />
    <DownloadRetentionCertificatePDFModal paymentOrder={paymentOrder} />
    <ConfirmPaymentFormModal paymentOrder={paymentOrder} />
  </>)
}
