import { O } from '../../../features/ui'
import { TableName } from '../../../tables'
import { useLoaders } from '../../common'
import { useSystemStateHooks } from '../../system'
import { Product } from '../state/types'

export const useProductLoaders = () => {
    const systemHooks = useSystemStateHooks()
    
    const loaders = useLoaders()

    return (companyId = systemHooks.companyId) => {
        const loadAllProducts = () => {
            const { loadAll } = loaders(companyId)
            return loadAll(TableName.PRODUCTS) as Promise<Product[]>
        }

        const loadProduct = (id: string) => {
            const { load } = loaders(companyId)
            return load(TableName.PRODUCTS, id) as Promise<O<Product>>
        }

        const loadProductsByIds = (ids: string[]) => {
            const { loadByIds } = loaders()
            return loadByIds(TableName.PRODUCTS, ids) as Promise<Product[]>
        }

        return {
            loadAllProducts,
            loadProduct,
            loadProductsByIds
        }
    }
}
