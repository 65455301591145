import { DataType, TableName } from '../../../tables'
import { TextParams } from '../../../state/reducers/types'
import { DynamoUserApi } from '../../../services'
import { useSystemStateHooks } from '../../system'
import { useStateHooks } from '../../../state'
import { useDefaultDbActions } from './defaultDbActions'
import { firstItem } from '../../../utils/listUtils'

export const useFetchActions = () => {
    const systemHooks = useSystemStateHooks()
    const { getUpdater } = useStateHooks()

    const defaultDbActions = useDefaultDbActions()

    const updater = (tableName: TableName) => getUpdater(tableName) as any as (list: DataType[]) => void

    return (companyId = systemHooks.companyId) => {
        const fetchAll = async (
            table: TableName,
            limit?: number,
            startKey?: TextParams
        ) => {
            const dbActions = defaultDbActions(DynamoUserApi, companyId)
            const list = (await dbActions.getAll(table, limit, startKey)).dataTypes
            list.length > 0 && updater(table)(list)
            return list
        }

        const fetch = async (
            table: TableName,
            id: string
        ) => {
            const dbActions = defaultDbActions(DynamoUserApi, companyId)
            const list = await dbActions.getByParams(table, { id })
            list.length > 0 && updater(table)(list)
            return firstItem(list)
        }

        const fetchByIds = async (
            table: TableName,
            ids: string[]
        ) => {
            if (ids.length === 0) {
                return []
            }
            const dbActions = defaultDbActions(DynamoUserApi, companyId)
            const list = await dbActions.getByIds(table, ids)
            list.length > 0 && updater(table)(list)
            return list
        }

        const fetchByParams = async (
            table: TableName,
            params: TextParams
        ) => {
            const dbActions = defaultDbActions(DynamoUserApi, companyId)
            const list = await dbActions.getByParams(table, params)
            list.length > 0 && updater(table)(list)
            return list
        }

        const count = (
            table: TableName,
            params: TextParams
        ) => {
            const dbActions = defaultDbActions(DynamoUserApi, companyId)
            return dbActions.count(table, params)
        }

        return {
            fetchAll,
            fetch,
            fetchByIds,
            fetchByParams,
            count
        }
    }
}
