import { Emitter, Event } from "../../../../../features/event-emitter"
import { Data, O } from "../../../../../features/ui"
import { define } from "../../../../../utils/typeUtils"
import { useAdministrationActions } from "../../../actions/administrationActions"
import { Area, Employee } from "../../../state/types"

export const useActions = () => {
    const administrationActions = useAdministrationActions()

    const submitEmployee = async (
        employeeData: Data,
        createMode: boolean
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const { companyId, id, name, identifier, branchId, area, email, address, phone } = employeeData
        const employee: Employee = {
            companyId: define(companyId) as string,
            id: define(id) as string,
            name: define(name) as string,
            branchId: define(branchId) as string,
            identifier: define(identifier) as number,
            area: define(area) as Area,
            email: define(email) as string,
            address: address as O<string>,
            phone: phone as O<number>
        }
        
        await administrationActions().saveEmployee(employee)
        Emitter.emit(Event.DB_SUBMIT_DONE, createMode)
    }
    
    return { submitEmployee }
}
