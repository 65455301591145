import { DynamoUserApi } from "../../services"
import { AttributeMap, useDynamoActions } from "../../services/dynamo"
import { toListResponse } from "../../services/utils"
import { TableName, TablesData } from "../../tables"
import { define } from "../../utils/typeUtils"

export const useRenamedTableMigration = () => {
    const dynamoActions = useDynamoActions()
    
    const createNewTable = (
        newTable: TableName,
        hasTKey: boolean
    ) => {
        const newTableData = define(TablesData.get(newTable))
        const tables = [{ name: newTableData.dbTableName, hasTKey }]
        DynamoUserApi.createTables(tables)
    }

    const fillNewTable = async (
        currentDbTableName: string,
        newTable: TableName
    ) => {
        const newTableData = define(TablesData.get(newTable))
        
        const { getAll, createOrUpdate } = dynamoActions(DynamoUserApi)
        
        const listResponse = await getAll(currentDbTableName)
        const { dataTypes } = (toListResponse(listResponse))
        await createOrUpdate(newTableData.dbTableName, dataTypes as AttributeMap[])
    }

    const removeCurrentTable = (
        currentDbTableName: string
    ) => {
        DynamoUserApi.removeTable(currentDbTableName)
    }

    return {
        createNewTable,
        fillNewTable,
        removeCurrentTable
    }
}
