import AWS from 'aws-sdk'
import {
    KeyParams,
    BaseCallback,
    RangeParams,
    ListResponse,
    DynamoApiConfig
} from './types'
import { ErrorType } from '../../modules/common'
import { AttributeMap, BatchWriteItemRequestMap } from 'aws-sdk/clients/dynamodb'
import { ParamsProvider } from './paramsProvider'
import { CallbackProvider } from './callbackProvider'
import { awsEndpoint, awsRegion } from '../constants'
import DocumentClient = AWS.DynamoDB.DocumentClient
import { O } from '../../features/ui'

export class DynamoApi {
    private readonly config: DynamoApiConfig
    private readonly client: DocumentClient
    private readonly paramsProvider: ParamsProvider
    private readonly callbackProvider: CallbackProvider

    constructor(
        readonly accessKeyId: string,
        readonly secretAccessKey: string,
        tenantKey: string,
        uniqueKey: string,
        readonly sessionToken?: string
    ) {
        this.config = {
            region: awsRegion,
            endpoint: awsEndpoint,
            credentials: {
                accessKeyId,
                secretAccessKey,
                sessionToken
            },
            protocol: 'https'
        }
        this.client = new DocumentClient(this.config as DocumentClient.DocumentClientOptions)
        this.paramsProvider = new ParamsProvider(tenantKey, uniqueKey)
        this.callbackProvider = new CallbackProvider()
    }

    private dynamoError(error: Error) {
        return new Error(`Dynamo Error: ${(error as ErrorType).message}`)
    }

    getAll(
        table: string,
        tKeyValue?: string,
        limit?: number,
        startKey?: KeyParams,
        propName?: string
    ) {
        return new Promise<ListResponse>((resolve, reject) => {
            try {
                if (tKeyValue) {
                    const queryParams = this.paramsProvider.queryParams(table, tKeyValue, limit, startKey, propName)
                    const onQuery = (result: ListResponse) => resolve(result)
                    this.client.query(queryParams, this.callbackProvider.paginatedListCallback(onQuery))
                } else {
                    const scanParams = this.paramsProvider.scanParams(table, undefined, limit, startKey)
                    const onScan = (result: ListResponse) => resolve(result)
                    this.client.scan(scanParams, this.callbackProvider.paginatedListCallback(onScan))
                }
            } catch (error) {
                reject(this.dynamoError(error as Error))
            }
        })
    }

    get(
        table: string,
        uKeyValue: string,
        tKeyValue?: string
    ) {
        return new Promise<O<AttributeMap>>((resolve, reject) => {
            try {
                const getParams = this.paramsProvider.getParams(table, uKeyValue, tKeyValue)
                const onGet = (result?: AttributeMap) => resolve(result)
                this.client.get(getParams, this.callbackProvider.itemCallback(onGet))
            } catch (error) {
                reject(this.dynamoError(error as Error))
            }
        })
    }

    getByParams(
        table: string,
        params: KeyParams,
        tKeyValue?: string
    ) {
        return new Promise<AttributeMap[]>((resolve, reject) => {
            try {
                const scanParams = this.paramsProvider.scanParams(table, tKeyValue, undefined, undefined, params)
                const onScan = (result: AttributeMap[]) => resolve(result)
                this.client.scan(scanParams, this.callbackProvider.listCallback(onScan))
            } catch (error) {
                reject(this.dynamoError(error as Error))
            }
        })
    }

    getMultiple(
        table: string,
        uKeyValues: string[],
        tKeyValue?: string
    ) {
        return new Promise<AttributeMap[]>((resolve, reject) => {
            try {
                const batchGetParams = this.paramsProvider.batchGetParams(table, uKeyValues, tKeyValue)
                const onBatchGet = (result: AttributeMap[]) => resolve(result)
                this.client.batchGet(batchGetParams, this.callbackProvider.listResponsesCallback(table, onBatchGet))
            } catch (error) {
                reject(this.dynamoError(error as Error))
            }
        })
    }

    getFiltered(
        table: string,
        tKeyValue: string,
        propFilters?: KeyParams,
        rangeFilters?: RangeParams
    ) {
        return new Promise<ListResponse>((resolve, reject) => {
            try {
                if (tKeyValue) {
                    const queryParams = this.paramsProvider.queryParams(table, tKeyValue, undefined, undefined, undefined, propFilters, rangeFilters)
                    const onQuery = (result: ListResponse) => resolve(result)
                    this.client.query(queryParams, this.callbackProvider.paginatedListCallback(onQuery))
                }
            } catch (error) {
                reject(this.dynamoError(error as Error))
            }
        })
    }

    count(
        table: string,
        params: KeyParams,
        tKeyValue?: string
    ) {
        return new Promise<number>((resolve, reject) => {
            try {
                const scanParams = this.paramsProvider.scanParams(table, tKeyValue, undefined, undefined, params, true)
                this.client.scan(scanParams, this.callbackProvider.countCallback(resolve))
            } catch (error) {
                reject(this.dynamoError(error as Error))
            }
        })
    }

    createOrUpdate(
        table: string,
        list: AttributeMap[]
    ) {
        return new Promise<BatchWriteItemRequestMap>((resolve, reject) => {
            try {
                const batchWriteParams = this.paramsProvider.batchCreateOrUpdateParams(table, list)
                const onBatchWrite = (unprocessedItems: BatchWriteItemRequestMap) => resolve(unprocessedItems)
                this.client.batchWrite(batchWriteParams, this.callbackProvider.crudMultipleCallback(onBatchWrite))
            } catch (error) {
                reject(this.dynamoError(error as Error))
            }
        })
    }

    remove(
        table: string,
        uKeyValues: string[],
        tKeyValue?: string
    ) {
        return new Promise<BatchWriteItemRequestMap>((resolve, reject) => {
            try {
                const batchWriteParams = this.paramsProvider.batchDeleteParams(table, uKeyValues, tKeyValue)
                const onBatchWrite = (unprocessedItems: BatchWriteItemRequestMap) => resolve(unprocessedItems)
                this.client.batchWrite(batchWriteParams, this.callbackProvider.crudMultipleCallback(onBatchWrite))
            } catch (error) {
                reject(this.dynamoError(error as Error))
            }
        })
    }

    createTables(tables: { name: string, hasTKey: boolean }[], callback?: BaseCallback) {
        try {
            const db = new AWS.DynamoDB(this.config)
            tables.forEach(table => {
                db.createTable(this.paramsProvider.createTablesParams(table.name, table.hasTKey), this.callbackProvider.baseCallback(callback))
            })
        } catch (error) {
            throw this.dynamoError(error as Error)
        }
    }

    removeTable(table: string, callback?: BaseCallback) {
        try {
            const db = new AWS.DynamoDB(this.config)
            db.deleteTable(this.paramsProvider.deleteTableParams(table), this.callbackProvider.baseCallback(callback))
        } catch (error) {
            throw this.dynamoError(error as Error)
        }
    }
}
