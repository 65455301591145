import { useLoaders, useSaveActions } from "../../common"
import { DataType, TableName } from "../../../tables"
import { Expense, PaymentCategory, PaymentOrder, PaymentOrderStatus, Ticket, Transaction, TransactionDestinationType, TransactionSourceType } from "../state/types"
import { useTransactionActions } from "./transactionActions_NEW"
import { define } from "../../../utils/typeUtils"
import { usePaymentOrderLoaders } from "../loaders/paymentOrderLoaders"
import { newId, useDataTypeFileActions } from "../../../features/data-types"
import { Currency } from "../../../tables/types/types"
import { useCashFundActions } from "./cashFundActions"
import { useCheckActions } from "./checkActions"
import { useTicketLoaders } from "../loaders/ticketLoaders"
import { useTicketActions } from "./ticketActions"
import { useSystemStateHooks } from "../../system"
import { useTicketHooks } from "../hooks/ticketHooks"
import { PurchaseOrder } from "../../purchases"
import { PaymentStatus } from "../../operations"

export const usePaymentOrderActions = () => {
    const systemHooks = useSystemStateHooks()
    const { getParentPendingAmount } = useTicketHooks()

    const loaders = useLoaders()
    const paymentOrderLoaders = usePaymentOrderLoaders()
    const ticketLoaders = useTicketLoaders()
    
    const saveActions = useSaveActions()
    const dataTypeFileActions = useDataTypeFileActions()
    const { reserveInCashFund, substractReservedFromCashFund } = useCashFundActions()
    const checkActions = useCheckActions()
    const { saveTransaction } = useTransactionActions()
    const ticketActions = useTicketActions()

    return (companyId = systemHooks.companyId) => {
        const savePaymentOrder = (paymentOrder: PaymentOrder) => {
            const { save } = saveActions(companyId)
            return save(TableName.PAYMENT_ORDERS, paymentOrder) as Promise<PaymentOrder>
        }
        
        const createPaymentOrder = async (paymentOrder: PaymentOrder) => {
            const savedPaymentOrder = await savePaymentOrder(paymentOrder)

            paymentOrder.paymentItems.forEach(async paymentItem => {
                let sourceType: TransactionSourceType = TransactionSourceType.CREDIT_CARD
                if (paymentItem.paymentCategory === PaymentCategory.CASH_FUND) {
                    sourceType = TransactionSourceType.CASH_FUND
                    await reserveInCashFund(paymentItem.sourceId, paymentItem.amount)
                } else if (paymentItem.paymentCategory === PaymentCategory.CHECK) {
                    sourceType = TransactionSourceType.CHECK
                    const { reserveCheck } = checkActions(companyId)
                    await reserveCheck(paymentItem.sourceId)
                }

                const transaction: Transaction = {
                    companyId: define(companyId),
                    id: newId(),
                    date: new Date().toISOString(),
                    sourceType,
                    sourceId: paymentItem.sourceId,
                    amount: paymentItem.amount,
                    destinationType: TransactionDestinationType.PAYMENT_ORDER,
                    destinationId: savedPaymentOrder.id,
                    currency: Currency.ARS
                }
                await saveTransaction(transaction)
            })

            paymentOrder.ticketItems.forEach(async ticketItem => {
                const { loadTicket } = ticketLoaders(companyId)
                const loadedTicket = define(await loadTicket(ticketItem.ticketId, true))
                
                const updatedTicket: Ticket = {
                    ...loadedTicket,
                    payedAmount: loadedTicket.payedAmount + ticketItem.payedAmount
                }          
                const { saveTicket } = ticketActions(companyId)      
                await saveTicket(updatedTicket)
            })

            return savedPaymentOrder
        }

        const confirmPaymentOrder = async (
            paymentOrderId: string,
            receiptFile?: File
        ) => {
            const { loadPaymentOrder } = paymentOrderLoaders(companyId)
            const loadedPaymentOrder = await loadPaymentOrder(paymentOrderId, true)

            const paymentOrder: PaymentOrder = {
                ...define(loadedPaymentOrder),
                status: PaymentOrderStatus.PAYED
            }

            const { saveWithFile } = dataTypeFileActions(companyId)
            const savedPaymentOrder = await saveWithFile(TableName.PAYMENT_ORDERS, paymentOrder, 'receiptUrl' as keyof DataType, receiptFile)

            paymentOrder.paymentItems.forEach(async paymentItem => {
                if (paymentItem.paymentCategory === PaymentCategory.CASH_FUND) {
                    await substractReservedFromCashFund(paymentItem.sourceId, paymentItem.amount)
                } else if (paymentItem.paymentCategory === PaymentCategory.CHECK) {
                    const { endorseCheck } = checkActions(companyId)
                    await endorseCheck(paymentItem.sourceId)
                }
            })

            paymentOrder.ticketItems.forEach(async ticketItem => {
                const { loadTicket } = ticketLoaders(companyId)
                const loadedTicket = define(await loadTicket(ticketItem.ticketId, true))
                const { parentTable, parentId } = loadedTicket
                const { load } = loaders(companyId)
                const parent = define(await load(parentTable as TableName, parentId, true)) as PurchaseOrder | Expense
                const pendingAmount = await getParentPendingAmount(parentId)
                const paymentStatus = pendingAmount === 0 ? PaymentStatus.PAYED : PaymentStatus.PARTIALLY_PAYED

                const updatedParent = {
                    ...parent,
                    paymentStatus
                }
                const { save } = saveActions(companyId)
                await save(parentTable as TableName, updatedParent)
            })

            return savedPaymentOrder
        }

        return {
            createPaymentOrder,
            confirmPaymentOrder,
        }
    }
}
