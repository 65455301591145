import { Transaction, TransactionDestinationType, TransactionSourceType } from "../../../state/types"
import { Data, ModalId, showToast, useUIActions } from "../../../../../features/ui"
import { Currency } from "../../../../../tables/types/types"
import { useSystemStateHooks } from "../../../../system"
import { define } from "../../../../../utils/typeUtils"
import { useNavigate } from "react-router-dom"
import { newId } from "../../../../../features/data-types"
import { useCashFundActions } from "../../../actions/cashFundActions"
import { Emitter, Event } from "../../../../../features/event-emitter"

export const useActions = () => {
    const { companyId } = useSystemStateHooks()
    const navigate = useNavigate()

    const { transferFromCashFund } = useCashFundActions()
    const { closeModal } = useUIActions()

    const submitTransfer = async (
        transferData: Data,
        concept: string
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const { sourceId, destinationId, amount } = transferData
        const transaction: Transaction = {
            companyId: define(companyId),
            id: newId(),
            date: new Date().toISOString(),
            sourceType: TransactionSourceType.CASH_FUND,
            sourceId: define(sourceId) as string,
            amount: define(amount) as number,
            destinationType: TransactionDestinationType.CASH_FUND,
            destinationId: define(destinationId) as string,
            currency: Currency.ARS,
            concept
        }
        
        await transferFromCashFund(transaction)
        
        closeModal(ModalId.SPINNER)
        showToast('La transferencia fue completada.')
        navigate(-1)
    }
    
    return {
        submitTransfer
    }
}
