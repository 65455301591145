import { O } from '../../../features/ui'
import { TableName } from '../../../tables'
import { useLoaders } from '../../common'
import { useSystemStateHooks } from '../../system'
import { Ticket } from '../state/types'

export const useTicketLoaders = () => {
    const systemHooks = useSystemStateHooks()

    const loaders = useLoaders()

    return (companyId = systemHooks.companyId) => {
        const loadAllTickets = () => {
            const { loadAll } = loaders(companyId)
            return loadAll(TableName.TICKETS) as Promise<Ticket[]>
        }

        const loadTicket = (
            id: string,
            dbRequest = false
        ) => {
            const { load } = loaders(companyId)
            return load(TableName.TICKETS, id, dbRequest) as Promise<O<Ticket>>
        }

        const loadParentTickets = (
            parentId: string,
            dbRequest = false
        ) => {
            const { loadByParams } = loaders(companyId)
            return loadByParams(TableName.TICKETS, { parentId }, dbRequest) as Promise<Ticket[]>
        }

        const loadTicketsByIds = (ids: string[]) => {
            const { loadByIds } = loaders()
            return loadByIds(TableName.TICKETS, ids) as Promise<Ticket[]>
        }

        const loadTicketsByParents = async (parentIds: string[]): Promise<Ticket[]> => {
            const tickets = await Promise.all(parentIds.map(parentId => loadParentTickets(parentId)))
            return tickets.flat()
        }
        
        return {
            loadAllTickets,
            loadTicket,
            loadParentTickets,
            loadTicketsByIds,
            loadTicketsByParents
        }
    }
}
