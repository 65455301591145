import { useEffect, useState } from "react"
import { PanelWrapper, FormField, Data, useUIStateActions, FieldType, FieldValue, option, useUIState, ModalId, useUIActions, useGetCrumbs, Badge, TextArea, O } from "../../../../../features/ui"
import { TableName } from "../../../../../tables"
import { useActions } from "./actions"
import { DataTypeForm, QuickFormModal, Standards } from "../../../../../features/data-types"
import { useGenerateNextValue } from "../../../../../tables/hooks"
import { Branch, useManagerState } from "../../../../manager"
import { define } from "../../../../../utils/typeUtils"
import { useFind } from "../../../../../state/reducers/hooks"
import { usePurchasesState, Supplier, SupplierQuickForm } from "../../../../purchases"
import { useAdministrationState } from "../../../hooks/administrationHooks"
import { Expense } from "../../../state/types"
import { useNavigate } from "react-router-dom"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"

export const ExpenseForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const expense = dataType as O<Expense>
    const createMode = !expense

    const { openedModal } = useUIState()
    const { currentBranch } = useManagerState()
    const { expenseTypes } = useAdministrationState()
    const { suppliers } = usePurchasesState()
    const branchId = currentBranch?.id
    
    const [code, setCode] = useState(expense?.code || 1)
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [registeredSupplier, setRegisteredSupplier] = useState<boolean>(!!expense?.supplierId)
    const [supplierId, setSupplierId] = useState<O<string>>(expense?.supplierId)
    const [refundable, setRefundable] = useState<boolean>(!!expense?.refundEmployeeId)
    const [refundEmployeeId, setRefundEmployeeId] = useState<O<string>>(expense?.refundEmployeeId)
    const [detail, setDetail] = useState<O<string>>(expense?.detail)
    const [key, setKey] = useState(0)
    
    const navigate = useNavigate()
    const generateNextValue = useGenerateNextValue()
    const find = useFind()

    const { submitExpense } = useActions()
    const { setDisableBranchSelector } = useUIStateActions()
    const { toggleModal } = useUIActions()

    const init = async () => {
        setDisableBranchSelector(true)
        if (createMode) {
            setCode(await generateNextValue(TableName.EXPENSES, 'code'))
        }
        setAsyncDataLoaded(true)
    }

    useEffect(() => {
        init()
        return () => {
            setDisableBranchSelector(false)
        }
    }, [])

    useEffect(() => setKey(key + 1), [openedModal])
    
    const expenseLabel = expense ? `: ${expense.name}` : ''
    const title = `${createMode ? 'Crear' : 'Editar'} Gasto${expenseLabel}`

    const crumbs = useGetCrumbs('Gastos', RoutePath.EXPENSES, expense?.name, expense?.id, createMode ? 'Crear' : 'Editar')

    const onRegisteredSupplierChange = (value?: FieldValue) => setRegisteredSupplier(!!value)

    const onRefundableChange = (value?: FieldValue) => setRefundable(!!value)
    
    const onDetailChange = (value?: FieldValue) => setDetail(value as O<string>)

    const typeOptions = expenseTypes.filter(expenseType => expenseType.name !== Standards.SalaryExpenseTypeName).map(type => option(type.id, type.name))

    const onChangeSupplier = (value?: FieldValue) => setSupplierId(value as O<string>)

    const onChangeRefundEmployee = (value?: FieldValue) => setRefundEmployeeId(value as O<string>)

    const onCreateNewSupplier = () => toggleModal(ModalId.QUICK_FORM)

    const fields: FormField[] = [
        {
            name: 'expenseTypeId',
            type: FieldType.TABLE,
            table: TableName.EXPENSE_TYPES,
            label: 'Tipo',
            options: typeOptions
        },
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre'
        },
        {
            name: 'branchId',
            type: FieldType.TABLE,
            table: TableName.BRANCHES,
            label: 'Sucursal',
            render: () => {
                const branch = define(find(TableName.BRANCHES, branchId))as Branch
                return <Badge label={branch.name} />
            }
        },
        {
            name: 'registeredSupplier',
            type: FieldType.BOOLEAN,
            label: 'Establecer Proveedor',
            defaultValue: registeredSupplier,
            onChange: onRegisteredSupplierChange
        },
        {
            name: 'refundable',
            type: FieldType.BOOLEAN,
            label: 'Reembolsable a Empleado',
            defaultValue: refundable,
            onChange: onRefundableChange
        },
        {
            name: 'date',
            type: FieldType.DATE,
            label: 'Fecha',
            defaultValue: new Date(),
            max: new Date()
        },
        {
            name: 'detail',
            type: FieldType.TEXT,
            label: 'Detalle',
            render: () => <TextArea
                id="detail"
                defaultValue={detail}
                optional
                onChange={onDetailChange}
            />
        },
        {
            name: 'amount',
            type: FieldType.PRICE,
            label: 'Monto'
        }
    ]
    
    if (registeredSupplier) {
        const supplierOptions = suppliers
            .filter(supplier => !supplier.productSupplier)
            .map(supplier => option(supplier.id, supplier.name))

        const supplierField = {
            name: 'supplierId',
            type: FieldType.TABLE,
            table: TableName.SUPPLIERS,
            value: supplierId,
            label: 'Proveedor',
            options: supplierOptions,
            onChange: onChangeSupplier,
            onCreateNew: onCreateNewSupplier
        }

        fields.splice(4, 0, supplierField)
    }
    if (refundable) {
        const refundEmployeeField = {
            name: 'refundEmployeeId',
            type: FieldType.TABLE,
            table: TableName.EMPLOYEES,
            value: refundEmployeeId,
            label: 'Empleado',
            onChange: onChangeRefundEmployee,
        }

        fields.splice(5 + (registeredSupplier ? 1 : 0), 0, refundEmployeeField)
    }

    const onSubmit = (expenseData: Data) => {
        submitExpense(
            expenseData,
            createMode,
            code,
            define(branchId),
            registeredSupplier ? expenseData.supplierId as string : undefined,
            refundable ? expenseData.refundEmployeeId as string : undefined,
            detail
        )
    }

    const onCancel = () => navigate(-1)

    const onSubmitSupplier = (supplier: Supplier) => {
        setSupplierId(supplier.id)
    }
    
    return (
        <PanelWrapper title={title} crumbs={crumbs}>
            <DataTypeForm
               formId="expense-form"
               fields={fields}
               createMode={createMode}
               disableSubmit={!asyncDataLoaded}
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
            <QuickFormModal title="Crear Proveedor">
                <SupplierQuickForm
                    key={key}
                    productSupplier={false}
                    onSubmit={onSubmitSupplier}
                />
            </QuickFormModal>
        </PanelWrapper>
    )
}
