import { newId } from "../utils/dataTypesUtils"
import { UnitType, UnitTypeCategory } from "../../../modules/stock"

export const DefaultUnitTypeId = '1'

export const unitTypes = (): UnitType[] => [
    { id: DefaultUnitTypeId, name: 'Unidad', abbreviation: 'u', category: UnitTypeCategory.BASIC },
    { id: newId(), name: 'Milímetro', abbreviation: 'mm', category: UnitTypeCategory.LENGTH },
    { id: newId(), name: 'Centímetro', abbreviation: 'cm', category: UnitTypeCategory.LENGTH },
    { id: newId(), name: 'Metro', abbreviation: 'm', category: UnitTypeCategory.LENGTH },
    { id: newId(), name: 'Kilómetro', abbreviation: 'km', category: UnitTypeCategory.LENGTH },
    { id: newId(), name: 'Pulgada', abbreviation: 'in', category: UnitTypeCategory.LENGTH },
    { id: newId(), name: 'Pie', abbreviation: 'ft', category: UnitTypeCategory.LENGTH },
    { id: newId(), name: 'Miligramo', abbreviation: 'mg', category: UnitTypeCategory.WEIGHT },
    { id: newId(), name: 'Gramo', abbreviation: 'g', category: UnitTypeCategory.WEIGHT },
    { id: newId(), name: 'Kilogramo', abbreviation: 'kg', category: UnitTypeCategory.WEIGHT },
    { id: newId(), name: 'Tonelada', abbreviation: 't', category: UnitTypeCategory.WEIGHT },
    { id: newId(), name: 'Libra', abbreviation: 'lb', category: UnitTypeCategory.WEIGHT },
    { id: newId(), name: 'Onza', abbreviation: 'oz', category: UnitTypeCategory.WEIGHT },
    { id: newId(), name: 'Milímetro Cuadrado', abbreviation: 'mm2', category: UnitTypeCategory.AREA },
    { id: newId(), name: 'Centímetro Cuadrado', abbreviation: 'cm2', category: UnitTypeCategory.AREA },
    { id: newId(), name: 'Metro Cuadrado', abbreviation: 'm2', category: UnitTypeCategory.AREA },
    { id: newId(), name: 'Hectárea', abbreviation: 'ha', category: UnitTypeCategory.AREA },
    { id: newId(), name: 'Pie Cuadrado', abbreviation: 'ft2', category: UnitTypeCategory.AREA },
    { id: newId(), name: 'Pulgada Cuadrada', abbreviation: 'in2', category: UnitTypeCategory.AREA },
    { id: newId(), name: 'Mililitro', abbreviation: 'ml', category: UnitTypeCategory.VOLUME },
    { id: newId(), name: 'Litro', abbreviation: 'l', category: UnitTypeCategory.VOLUME },
    { id: newId(), name: 'Milímetro Cúbico', abbreviation: 'mm3', category: UnitTypeCategory.VOLUME },
    { id: newId(), name: 'Centímetro Cúbico', abbreviation: 'cm3', category: UnitTypeCategory.VOLUME },
    { id: newId(), name: 'Metro Cúbico', abbreviation: 'm3', category: UnitTypeCategory.VOLUME },
    { id: newId(), name: 'Galón', abbreviation: 'gal', category: UnitTypeCategory.VOLUME },
    { id: newId(), name: 'Pie Cúbico', abbreviation: 'ft3', category: UnitTypeCategory.VOLUME },
]
