import { useEffect, useState } from "react"
import { PanelLoader } from "../../../../../features/ui"
import { SalaryList } from "./salaryList"
import { Expense, Ticket } from "../../../state/types"
import { useExpenseLoaders } from "../../../loaders/expenseLoaders"
import { useTicketLoaders } from "../../../loaders/ticketLoaders"
import { useManagerState } from "../../../../manager"
import { define } from "../../../../../utils/typeUtils"
import { ListLoaderWrapper } from "../../../../../features/data-types"
import { TableName } from "../../../../../tables"

export const Loader = () => {
    const { currentBranch } = useManagerState()
    const branchId = define(currentBranch).id

    const [salaries, setSalaries] = useState<Expense[]>([])
    const [tickets, setTickets] = useState<Ticket[]>([])
    const [ready, setReady] = useState(false)

    const expenseLoaders = useExpenseLoaders()
    const ticketLoaders = useTicketLoaders()

    const populate = async () => {
        const { loadBranchExpenses } = expenseLoaders()
        const loadedExpenses = await loadBranchExpenses(branchId)
        setSalaries(loadedExpenses)
        const expenseIds = loadedExpenses.map(expense => expense.id)
        const { loadTicketsByParents } = ticketLoaders()
        setTickets(await loadTicketsByParents(expenseIds))
    }

    useEffect(() => {
        setReady(false)
        populate().then(() => setReady(true))
    }, [branchId])

    return (
        <PanelLoader
            title="Sueldos Liquidados"
            ready={ready}
            wide
        >
            <SalaryList
                salaries={salaries}
                tickets={tickets}
            />
        </PanelLoader>
    )
}

export const SalaryListLoader = () => (
    <ListLoaderWrapper table={TableName.EXPENSES}>
        <Loader />
    </ListLoaderWrapper>
)
