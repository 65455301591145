import { useDataTypesHooks } from '../../../features/data-types'
import { O } from '../../../features/ui'
import { useStateHooks } from '../../../state'
import { TextParams } from '../../../state/reducers/types'
import { DataType, TableName } from '../../../tables'
import { dedupe } from '../../../utils/listUtils'
import { useSystemStateHooks } from '../../system'
import { useFetchActions } from '../actions/fetchActions'

export const useLoaders = () => {
    const systemHooks = useSystemStateHooks()
    const { getList, find, findByIds, findByParams } = useStateHooks()
    const { hasLoaded, addToLoaded } = useDataTypesHooks()

    const fetchActions = useFetchActions()

    return (companyId = systemHooks.companyId) => {
        const loadAll = async (
            table: TableName,
            dbRequest = false
        ): Promise<DataType[]> => {
            let list = getList(table)
            if (dbRequest || !hasLoaded(table) || list.length === 0) {
                const { fetchAll } = fetchActions(companyId)
                list = await fetchAll(table)
                addToLoaded(table)
            }
            return list
        }

        const load = async (
            table: TableName,
            id: string,
            dbRequest = false
        ): Promise<O<DataType>> => {
            let dataType = find(table, id)
            if (dbRequest || !dataType) {
                const { fetch } = fetchActions(companyId)
                dataType = await fetch(table, id)
            }
            return dataType
        }

        const loadByIds = async (
            table: TableName,
            ids: string[],
            dbRequest = false
        ): Promise<DataType[]> => {
            if (ids.length === 0) {
                return []
            }
            const dedupedIds = dedupe(ids)
            let dataTypes = findByIds(table, dedupedIds)
            if (dbRequest || dataTypes.length < dedupedIds.length) {
                const { fetchByIds } = fetchActions(companyId)
                dataTypes = await fetchByIds(table, dedupedIds)
            }
            return dataTypes
        }

        const loadByParams = async (
            table: TableName,
            params: TextParams,
            dbRequest = false
        ): Promise<DataType[]> => {
            let dataTypes = findByParams(table, params)
            if (dbRequest || dataTypes.length === 0) {
                const { fetchByParams } = fetchActions(companyId)
                dataTypes = await fetchByParams(table, params)
            }
            return dataTypes
        }

        const count = (
            table: TableName,
            params: TextParams
        ): Promise<number> => {
            const { count } = fetchActions(companyId)
            return count(table, params)
        }
        
        return {
            loadAll,
            load,
            loadByIds,
            loadByParams,
            count
        }
    }
}
