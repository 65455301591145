import { SalaryExpenseTypeName } from "../../../features/data-types"
import { O } from "../../../features/ui"
import { useStateHooks } from "../../../state/reducers/hooks"
import { TableName } from "../../../tables"
import { dedupe } from "../../../utils/listUtils"
import { define } from "../../../utils/typeUtils"
import { PurchaseOrder } from "../../purchases"
import { useTicketLoaders } from "../loaders/ticketLoaders"
import { Expense, ExpenseType, Ticket } from "../state/types"

export const useTicketHooks = () => {
    const { find } = useStateHooks()

    const ticketLoaders = useTicketLoaders()

    const isPurchase = (table: TableName | string) => table === TableName.PURCHASE_ORDERS
    
    const isExpense = (table: TableName | string) => table === TableName.EXPENSES
    
    const isSalary = (
        parentTable: TableName | string,
        parentId: string
    ) => {
        if (isExpense(parentTable)) {
            const expense = findParent(parentTable, parentId) as O<Expense>
            const expenseType = find(TableName.EXPENSE_TYPES, expense?.expenseTypeId) as O<ExpenseType>
            return expenseType?.name === SalaryExpenseTypeName
        }
        return false
    }

    const findParent = (
        parentTable: TableName | string,
        parentId: string
    ) => {
        return find(parentTable as TableName, parentId) as O<PurchaseOrder> | O<Expense>
    }

    const getPurchaseIds = (tickets: Ticket[]) => {
        const purchaseIds = tickets.filter(({ parentTable }) => isPurchase(parentTable)).map(({ parentId }) => parentId)
        return dedupe(purchaseIds)
    }

    const getExpenseIds = (tickets: Ticket[]) => {
        const purchaseIds = tickets.filter(({ parentTable }) => isExpense(parentTable)).map(({ parentId }) => parentId)
        return dedupe(purchaseIds)
    }

    const getSupplierId = (ticket: Ticket) => {
        return findParent(ticket.parentTable, ticket.parentId)?.supplierId
    }

    const getBranchId = (ticket: Ticket) => {
        const parent = define(findParent(ticket.parentTable, ticket.parentId))
        return isPurchase(ticket.parentTable) ? (parent as PurchaseOrder).deliveryBranchId : (parent as Expense).branchId
    }

    const getTicketPendingAmount = (ticket: Ticket) => ticket.total - ticket.payedAmount

    const getParentPendingAmount = async (parentId: string) => {
        const { loadParentTickets } = ticketLoaders()
        const tickets = await loadParentTickets(parentId, true)
        return tickets.reduce((sum, ticket) => sum + getTicketPendingAmount(ticket), 0)
    }

    const getVatYield = (tickets: Ticket[]) => {
        const totalsSum = tickets.reduce((sum, ticket) => sum + ticket.total, 0)
        const vatAmountsSum = tickets.reduce((sum, ticket) => sum + ticket.vatAmount, 0)
        return vatAmountsSum / totalsSum
    }

    return {
        isPurchase,
        isExpense,
        isSalary,
        findParent,
        getPurchaseIds,
        getExpenseIds,
        getSupplierId,
        getBranchId,
        getTicketPendingAmount,
        getParentPendingAmount,
        getVatYield
    }
}
