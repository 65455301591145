import { TableName } from "../../../tables"
import { useLoaders } from "../../common"
import { TransactionDestinationType, TransactionSourceType } from "../state/types"

export const useTransactionHooks = () => {
    const loaders = useLoaders()

    const getSourceTable = (sourceType: TransactionSourceType) => {
        return new Map<TransactionSourceType, TableName>()
            .set(TransactionSourceType.CASH_FUND, TableName.CASH_FUNDS)
            .set(TransactionSourceType.CHECK, TableName.CHECKS)
            .set(TransactionSourceType.CREDIT_CARD, TableName.CREDIT_CARDS)
            .get(sourceType)
    }

    const getSource = async (
        sourceType: TransactionSourceType,
        sourceId?: string
    ) => {
        const { load } = loaders()
        const table = getSourceTable(sourceType)
        return table && sourceId ? load(table, sourceId) : undefined
    }

    const getDestinationTable = (destinationType: TransactionDestinationType) => {
        return new Map<TransactionDestinationType, TableName>()
            .set(TransactionDestinationType.CASH_FUND, TableName.CASH_FUNDS)
            .set(TransactionDestinationType.PAYMENT_ORDER, TableName.PAYMENT_ORDERS)
            .get(destinationType)
    }

    const getDestination = async (
        destinationType: TransactionDestinationType,
        destinationId?: string
    ) => {
        const { load } = loaders()
        const table = getDestinationTable(destinationType)
        return table && destinationId ? load(table, destinationId) : undefined
    }

    return {
        getSourceTable,
        getSource,
        getDestinationTable,
        getDestination
    }
}
