import { useEffect, useMemo, useState } from "react"
import { PanelWrapper, FormField, Data, ModalId, useUIActions, FieldType, Validatable, Spinner, useGetCrumbs, O, FieldValue } from "../../../../../features/ui"
import { Budget, BudgetItem, Customer, CustomerType } from "../../../state/types"
import { useSalesActions } from "../../../actions/salesActions"
import { DataType, TableName } from "../../../../../tables"
import { CustomerSearchField } from "../../../../../components/fields/search-field/customer-search-field/customerSearchField"
import { define } from "../../../../../utils/typeUtils"
import { DataTypeForm, useDescriptorHooks } from "../../../../../features/data-types"
import { Product } from "../../../../products"
import { useGenerateNextValue } from "../../../../../tables/hooks"
import { Operation, OperationItemFormModal, OperationItemsTable, usePricesHooks, PriceBreakdown, usePriceBreakdownHooks } from '../../../../operations'
import { useActions } from "./actions"
import { useSalesHooks } from "../../../hooks/salesHooks"
import { BudgetPriceList } from "../budget-price-list/budgetPriceList"
import { BudgetPriceItem } from "../../../hooks/types"
import { useNavigate } from "react-router-dom"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { useFind } from "../../../../../state/reducers/hooks"
import { SellerField } from "../../seller-field/sellerField"

export const RefreshBudgetForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const budget = define(dataType) as Budget
    
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [code, setCode] = useState(budget?.code || 1)
    const [sellerId, setSellerId] = useState<O<string>>(budget.sellerId)
    const [customer, setCustomer] = useState<Customer>()
    const [budgetItemsData, setBudgetItemsData] = useState<Data[]>([])
    const [currentBudgetItem, setCurrentBudgetItem] = useState<Data>()
    const [priceItems, setPriceItems] = useState<BudgetPriceItem[]>([])
    const [validations, setValidations] = useState<string[]>([])

    const { getBudgetPricesList } = useSalesHooks()
    const pricesHooks = usePricesHooks()
    const { getPriceBreakdownFromItems } = usePriceBreakdownHooks()
    const descriptorHooks = useDescriptorHooks()
    const generateNextValue = useGenerateNextValue()
    const navigate = useNavigate()
    const find = useFind()

    const { refreshBudget } = useActions()
    const salesActions = useSalesActions()
    const { toggleModal } = useUIActions()

    const setCustomerFromId = async (id?: string) => {
        let stateCustomer: O<Customer>
        if (id) {
            stateCustomer = await salesActions().fetchCustomer(id) as O<Customer>
        }
        setCustomer(stateCustomer)
    }

    const init = async () => {
        setCode(await generateNextValue(TableName.BUDGETS, 'code'))
        setPriceItems(getBudgetPricesList(priceBreakdown.total))
        const toDefaultBudgetItemData = (budgetItemDT: DataType)  => {
            const budgetItem = budgetItemDT as BudgetItem
            const price = pricesHooks.product.basePrice(budgetItem.productId)
            return {
                ...budgetItemDT,
                id: undefined,
                budgetId: undefined,
                price
            } as Data
        }
        const budgetItems = await salesActions().fetchBudgetItemsByOrder(budget.id) as BudgetItem[]
        setBudgetItemsData(budgetItems.map(toDefaultBudgetItemData))
        await setCustomerFromId(budget.customerId)
        setAsyncDataLoaded(true)
    }

    useEffect(() => {
        init()
    }, [])

    const priceBreakdown = useMemo(() => {
        const customerType = find(TableName.CUSTOMER_TYPES, customer?.customerTypeId) as O<CustomerType>
        return getPriceBreakdownFromItems(budgetItemsData, customerType?.yield)
    }, [budgetItemsData, customer])

    useEffect(() => {
        setPriceItems(getBudgetPricesList(priceBreakdown.total))
    }, [priceBreakdown])
    
    const crumbs = useGetCrumbs(
        'Presupuestos',
        RoutePath.BUDGETS,
        descriptorHooks.budget.code(budget),
        budget.id,
        'Actualizar'
    )

    const filterProducts = (product: Product) =>
        currentBudgetItem?.productId === product.id || !budgetItemsData.map(budgetItemData => budgetItemData.productId).includes(product.id)

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <Spinner size='m'/>

    const onChangeSeller = (value?: FieldValue) => setSellerId(value as O<string>)

    const fields: FormField[] = [
        {
            name: 'date',
            type: FieldType.DATE,
            label: 'Fecha',
            defaultValue: new Date(),
            disabled: true
        },
        SellerField({
            defaultValue: sellerId,
            onChange: onChangeSeller
        }),
        {
            name: 'customerId',
            type: FieldType.TABLE,
            table: TableName.CUSTOMERS,
            label: 'Cliente',
            render: () => renderAsync(
                <CustomerSearchField
                    id='customerId'
                    defaultValue={customer?.id}
                    onChange={setCustomerFromId}
                />
            )
        },
        {
            name: 'budgetItems',
            type: FieldType.TABLE,
            table: TableName.BUDGET_ITEMS,
            label: 'Productos',
            render: () => renderAsync(
                <Validatable validations={validations}>
                    <OperationItemsTable
                        rows={budgetItemsData as Data[]}
                        operation={Operation.SALE}
                        onCreate={onCreateBudgetItem}
                        onEditRow={onEditBudgetItem}
                        onRemoveRow={onRemoveBudgetItem}
                    />
                </Validatable>
            )
        },
        {
            name: 'priceBreakdown',
            type: FieldType.PRICE,
            label: 'Precio',
            render: () => <PriceBreakdown data={priceBreakdown} />
        }
    ]

    if (priceItems.length > 0 && priceBreakdown.total !== 0) {
        fields.push({
            name: 'priceList',
            type: FieldType.TABLE,
            table: TableName.PAYMENT_TYPES,
            label: 'Medios de Pago',
            render: () => renderAsync(
                <BudgetPriceList priceItems={priceItems} collapsable />
            )
        })
    }

    const onSaveBudgetItem = (newData: Data) => {
        const newBudgetItemsData = currentBudgetItem ?
            budgetItemsData.map(budgetItemData => budgetItemData.productId === currentBudgetItem.productId ? newData : budgetItemData) :
            [newData, ...budgetItemsData]
        setBudgetItemsData(newBudgetItemsData)
    }

    const openFormModal = () => toggleModal(ModalId.OPERATION_ITEM_FORM)

    const onCreateBudgetItem = () => {
        setCurrentBudgetItem(undefined)
        setValidations([])
        openFormModal()
    }

    const onEditBudgetItem = (productId: string) => {
        setCurrentBudgetItem(budgetItemsData.find(budgetItemData => budgetItemData.productId === productId))
        openFormModal()
    }

    const onRemoveBudgetItem = (productId: string) => {
        const newBudgetItemsData = budgetItemsData.filter(budgetItemData => budgetItemData.productId !== productId)
        setBudgetItemsData(newBudgetItemsData)
    }

    const validate = (): boolean => {
        if (budgetItemsData.length === 0) {
            setValidations(['Debe agregar al menos 1 producto'])
            return false
        }

        return true
    }

    const onSubmit = (budgetData: Data) => {
        if (validate()) {
            refreshBudget(
                budgetData,
                code,
                define(sellerId),
                define(customer).id,
                budgetItemsData
            )
        }
    }

    const onCancel = () => navigate(-1)

    return (
        <PanelWrapper
            title={`Actualizar Presupuesto #${budget.code}`}
            crumbs={crumbs}
            wide
        >
            <DataTypeForm
               formId="budget-form"
               fields={fields}
               createMode={true}
               createLabel="Crear Nuevo"
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
            <OperationItemFormModal
                operationItem={currentBudgetItem}
                operation={Operation.SALE}
                filterProducts={filterProducts}
                onSubmit={onSaveBudgetItem}
            />
        </PanelWrapper>
    )
}
