import { Data } from "../../../../features/data-types"
import { Emitter, Event } from "../../../../features/event-emitter"
import { TableName } from "../../../../tables"
import { define } from "../../../../utils/typeUtils"
import { useSalesActions } from "../../actions/salesActions"
import { HoardOrder, SaleOperation, SaleOrder } from "../../state/types"

export const useActions = () => {
    const salesActions = useSalesActions()

    const submitReceipt = async (
        table: TableName,
        operation: SaleOperation,
        paymentData: Data,
        totalAmount: number,
        receiptFile: File
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const { paymentTypeId, destinationId } = paymentData

        if (table === TableName.SALE_ORDERS) {
            await salesActions().confirmSalePayment(
                operation as SaleOrder,
                receiptFile,
                define(paymentTypeId) as string,
                define(destinationId) as string,
                totalAmount
            )
        } else {
            await salesActions().confirmHoardPayment(
                operation as HoardOrder,
                receiptFile,
                define(paymentTypeId) as string,
                define(destinationId) as string,
                totalAmount
            )
        }

        Emitter.emit(Event.DB_SUBMIT_DONE, false, false)
    }
    
    return {
        submitReceipt
    }
}
