import styles from './salePDFPage.module.css'
import { Customer, SaleItem, SaleOrder } from "../../../state/types"
import { define } from '../../../../../utils/typeUtils'
import { useEffect, useMemo, useState } from 'react'
import { Employee, useEmployeeLoaders, usePaymentTypeLoaders } from '../../../../administration'
import { useProductLoaders } from '../../../../products'
import { useDataTypesHooks, useDescriptorHooks } from '../../../../../features/data-types'
import { useBranchLoaders } from '../../../../manager'
import { PriceBreakdown, usePriceBreakdownHooks } from '../../../../operations'
import { FieldType, PDFHeader } from '../../../../../features/ui'
import { SaleItemPDFRow } from './types'
import { useCustomerLoaders } from '../../../loaders/customerLoaders'
import { useCustomerTypeLoaders } from '../../../loaders/customerTypeLoaders'

type Props = {
    pageIndex: number
    sale: SaleOrder
    saleItems: SaleItem[]
    pagesAmount: number
}

export const SalePDFPage = (props: Props) => {
    const { pageIndex, sale, saleItems, pagesAmount } = props
    const isLastPage = pageIndex === pagesAmount - 1
    
    const [customer, setCustomer] = useState<Customer>()
    const [seller, setSeller] = useState<Employee>()
    const [rows, setRows] = useState<SaleItemPDFRow[]>([])

    const { formatValue } = useDataTypesHooks()
    const descriptorHooks = useDescriptorHooks()
    const { getPriceBreakdownFromItems } = usePriceBreakdownHooks()

    const branchLoaders = useBranchLoaders()
    const productLoaders = useProductLoaders()
    const { loadCustomer } = useCustomerLoaders()
    const customerTypeLoaders = useCustomerTypeLoaders()
    const employeeLoaders = useEmployeeLoaders()
    const paymentTypeLoaders = usePaymentTypeLoaders()

    const branch = useMemo(() => define(branchLoaders().findBranch(sale.branchId)), [sale])
    const customerType = useMemo(() => customerTypeLoaders().findCustomerType(customer?.customerTypeId), [customer])
    const paymentType = useMemo(() => define(paymentTypeLoaders().findPaymentType(sale.paymentTypeId)), [sale])
    const priceBreakdown = useMemo(() => getPriceBreakdownFromItems(saleItems, customerType?.yield), [saleItems, customerType])
    const adjustedPriceBreakdown = getPriceBreakdownFromItems(saleItems, customerType?.yield, paymentType?.yield)

    const loadRows = async () => {
        const productIds = saleItems.map(saleItem => saleItem.productId)
        const { loadProductsByIds } = productLoaders()
        const products = await loadProductsByIds(productIds)
        return saleItems.map(saleItem => {
            const product = define(products.find(product => product.id === saleItem.productId))
            const itemPriceBreakdown = define(priceBreakdown.items.find(item => item.productId === product.id))
            return {
                product: product.name,
                content: descriptorHooks.product.content(product),
                unitSubtotal: formatValue(itemPriceBreakdown.unitSubtotal, FieldType.PRICE),
                unitTotal: formatValue(itemPriceBreakdown.unitTotal, FieldType.PRICE),
                quantity: descriptorHooks.product.unitsDetail(saleItem.quantity, product),
                total: formatValue(itemPriceBreakdown.total, FieldType.PRICE)
            }
        })
    }

    useEffect(() => {
        employeeLoaders().loadEmployee(sale.sellerId).then(setSeller)
        loadCustomer(sale.customerId).then(setCustomer)
        loadRows().then(newRows => setRows(newRows))
    }, [sale, saleItems])

    const columns = [
        { name: 'product', label: 'Producto' },
        { name: 'content', label: 'Contenido' },
        { name: 'unitSubtotal', label: 'P. Unitario s/IVA' },
        { name: 'unitTotal', label: 'P. Unitario' },
        { name: 'quantity', label: 'Cantidad' },
        { name: 'total', label: 'P. Total' },
    ]

    return (
        <div className={styles.container}>
            <div className={styles.body}>
                <PDFHeader noValidTicketLabel />

                {pageIndex === 0 && (<>
                    <div className={styles.row}>
                        <span className={styles.saleTitle}>
                            Venta #{sale.code}
                        </span>
                        <div className={styles.date}>
                            Fecha: {formatValue(new Date(sale.creationDate), FieldType.DATE)}
                        </div>
                    </div>

                    <div className={`${styles.row} ${styles.alignStart}`}>
                        <div className={styles.infoSection}>
                            <span>Cliente: {customer?.name}</span>
                            <span>Dirección: {customer?.address || '-'}</span>
                            <span>DNI/CUIT: {customer?.identifier}</span>
                            <span>Teléfono: {customer?.phone || '-'}</span>
                        </div>
                        <div className={`${styles.infoSection} ${styles.rightSection}`}>
                            <span>Vendedor: {seller?.name}</span>
                            <span>Teléfono: {seller?.phone || '-'}</span>
                        </div>
                    </div>

                    <div className={`${styles.row} ${styles.alignStart}`}>
                        <div className={styles.infoSection}>
                            <span>Sucursal: {branch.name}</span>
                            <span>Dirección: {branch.address}</span>
                        </div>
                        <div className={`${styles.infoSection} ${styles.rightSection}`}>
                            <span>Fecha de Entrega est.: {formatValue(new Date(sale.deliveryDate), FieldType.DATE)}</span>
                        </div>
                    </div>

                    <div className={`${styles.row} ${styles.alignStart}`}>
                        <div className={styles.infoSection}>
                            <span>Medio de Pago: {paymentType.name} ({formatValue(paymentType.yield, FieldType.PERCENTAGE)})</span>
                        </div>
                    </div>
                </>)}

                <div className={styles.tableRow}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                {columns.map((column, index) => (
                                    <th key={index}>
                                        {column.label}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                                {rows.map((row, index) => (
                                    <tr key={index}>
                                        {columns.map((column, index) => (
                                            <td key={index}>
                                                {row[column.name as keyof SaleItemPDFRow]}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                <div className={styles.pagination}>
                    {pageIndex + 1} / {pagesAmount}
                </div>

                {isLastPage && (
                    <div className={`${styles.row} ${styles.priceBreakdown}`}>
                        <PriceBreakdown data={adjustedPriceBreakdown} />
                    </div>
                )}

                <div className={styles.footer}>
                    <div className={styles.separator} />
                    <div className={`${styles.row} ${styles.footerRow}`}>
                        <span>
                            Sucursal {branch.name}: {branch.address}{branch.email ? ` - Mail: ${branch.email}` : ''}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
