import { useSaveActions } from "../../common"
import { TableName } from "../../../tables"
import { CashFund, Transaction } from "../state/types"
import { useTransactionActions } from "./transactionActions_NEW"
import { define } from "../../../utils/typeUtils"
import { useCashFundLoaders } from "../loaders/cashFundLoaders"

export const useCashFundActions = () => {
    const cashFundLoaders = useCashFundLoaders()
    
    const saveActions = useSaveActions()
    const { saveTransaction } = useTransactionActions()

    const saveCashFund = (cashFund: CashFund) => {
        const { save } = saveActions()
        return save(TableName.CASH_FUNDS, cashFund) as Promise<CashFund>
    }

    const updateAmount = async (
        cashFundId: string,
        availableAmountDiff = 0,
        reservedAmountDiff = 0
    ) => {
        const { loadCashFund } = cashFundLoaders()        
        const cashFund = await loadCashFund(cashFundId, true) as CashFund
        const updatedFund: CashFund = {
            ...cashFund,
            availableAmount: cashFund.availableAmount + availableAmountDiff,
            reservedAmount: cashFund.reservedAmount + reservedAmountDiff
        }
        
        return saveCashFund(updatedFund)
    }

    const addToCashFund = async (
        cashFundId: string,
        amount: number
    ) => {
        return updateAmount(cashFundId, amount)
    }

    const substractFromCashFund = async (
        cashFundId: string,
        amount: number
    ) => {
        return updateAmount(cashFundId, -amount)
    }

    const reserveInCashFund = (
        cashFundId: string,
        amount: number
    ) => {
        return updateAmount(cashFundId, -amount, amount)
    }

    const substractReservedFromCashFund = (
        cashFundId: string,
        amount: number
    ) => {
        return updateAmount(cashFundId, 0, -amount)
    }

    const manualAddToCashFund = async (transaction: Transaction) => {
        await addToCashFund(define(transaction.destinationId), transaction.amount)
        return saveTransaction(transaction)
    }

    const manualSubstractFromCashFund = async (transaction: Transaction) => {
        await substractFromCashFund(define(transaction.sourceId), transaction.amount)
        return saveTransaction(transaction)
    }

    const transferFromCashFund = async (transaction: Transaction) => {
        await substractFromCashFund(define(transaction.sourceId), transaction.amount)
        await addToCashFund(define(transaction.destinationId), transaction.amount)
        return saveTransaction(transaction)
    }

    const removeCashFund = (id: string) => {
        const { remove } = saveActions()
        return remove(TableName.CASH_FUNDS, id)
    }

    return {
        saveCashFund,
        addToCashFund,
        reserveInCashFund,
        substractReservedFromCashFund,
        manualAddToCashFund,
        manualSubstractFromCashFund,
        transferFromCashFund,
        removeCashFund
    }
}
