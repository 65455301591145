import styles from './confirmSalePaymentForm.module.css'
import { Form, FormField, ModalId, useUIActions, FieldType, FileField, Validatable, Data, FieldValue, Spinner, O } from "../../../../features/ui"
import { define } from "../../../../utils/typeUtils"
import { useState } from "react"
import { toList } from "../../../../utils/listUtils"
import { useActions } from "./actions"
import { fileName } from '../../../../features/files'
import { TableName } from '../../../../tables'
import { SaleOperation } from '../../state/types'
import { useDataTypesHooks, useDescriptorHooks } from '../../../../features/data-types'
import { PaymentTypeField } from '../../../administration'
import { usePriceBreakdownHooks } from '../../../operations'

type Props = {
    table: TableName
    operation?: SaleOperation
}

export const ConfirmSalePaymentForm = (props: Props) => {
    const { table, operation } = props
    
    const [receiptFile, setReceiptFile] = useState<File>()
    const [receiptValidation, setReceiptValidation] = useState<string>()
    const [paymentTypeId, setPaymentTypeId] = useState<O<string>>(operation?.paymentTypeId)

    const { getOperationPriceBreakdown } = usePriceBreakdownHooks()
    const descriptorHooks = useDescriptorHooks()
    const { formatValue } = useDataTypesHooks()

    const { submitReceipt } = useActions()
    const { toggleModal } = useUIActions()

    if (!operation) return <Spinner />
    
    const isSale = table === TableName.SALE_ORDERS
    
    const code = isSale ? descriptorHooks.sale.code(operation) : descriptorHooks.purchase.code(operation)
    const totalAmount = operation && getOperationPriceBreakdown(table, operation, paymentTypeId).total

    const onChangePaymentType = (value?: FieldValue) => setPaymentTypeId(value as O<string>)

    const onChangeFile = (value?: File) => {
        setReceiptValidation(undefined)
        setReceiptFile(value)
    }

    const fields: FormField[] = [
        {
            name: 'code',
            type: FieldType.NUMBER,
            label: isSale ? 'Venta' : 'Acopio',
            render: () => <>{code}</>
        },
        PaymentTypeField({
            defaultValue: paymentTypeId,
            narrow: false,
            onChange: onChangePaymentType
        }),
        {
            name: 'destinationId',
            type: FieldType.TABLE,
            table: TableName.CASH_FUNDS,
            label: 'Caja Destino'
        },
        {
            name: 'totalAmount',
            type: FieldType.PRICE,
            label: 'Monto Pagado',
            render: () => <>{formatValue(totalAmount, FieldType.PRICE)}</>
        },
        {
            name: 'receiptUrl',
            type: FieldType.FILE,
            label: 'Comprobante de Pago',
            hint: 'Formato: PDF',
            render: () => (
              <Validatable validations={toList(receiptValidation)} left className={styles.receiptValidation}>
                <FileField
                    id="receiptUrl"
                    fileName={fileName(table, operation.code.toString(), undefined, undefined, 'Comprobante-Pago')}
                    onChange={onChangeFile}
                />
              </Validatable>
            )
        }
    ]
    
    const validate = (): boolean => {
        if (!receiptFile) {
          setReceiptValidation('Debe subir el comprobante')
          return false
        }
    
        return true
    }

    const onSubmit = (paymentData: Data) => {
        if (validate()) {
            submitReceipt(
                table,
                operation,
                paymentData,
                define(totalAmount),
                define(receiptFile)
            )
        }
    }

    const handleCancel = () => toggleModal(ModalId.CONFIRM_SALE_PAYMENT_FORM)

    return (
        <Form
            formId="confirm-sale-payment-form"
            fields={fields}
            createMode={false}
            updateLabel="Confirmar"
            onSubmit={onSubmit}
            onCancel={handleCancel}
        />
    )
}
