import { OrderDelivery } from "./types"

export enum ActionType {
    UPDATE_DELIVERIES = 'updateDeliveries',
    REMOVE_DELIVERIES = 'removeDeliveries',
    SET_CURRENT_DELIVERY = 'setCurrentDelivery'
}

interface UpdateDeliveries {
    type: ActionType.UPDATE_DELIVERIES
    payload: OrderDelivery[]
}

interface RemoveDeliveries {
    type: ActionType.REMOVE_DELIVERIES
    payload: string[]
}

interface SetCurrentDelivery {
    type: ActionType.SET_CURRENT_DELIVERY
    payload: OrderDelivery
}

export type OperationsAction =
    SetCurrentDelivery |    
    OperationsUpdateAction |
    OperationsRemoveAction

export type OperationsUpdateAction =
    UpdateDeliveries

export type OperationsRemoveAction =
    RemoveDeliveries
