import { useEffect, useMemo, useState } from 'react'
import { DataType, TableName } from '../../../../tables'
import { FieldType, FieldValue, option, FilterField, Filters, O } from '../../../../features/ui'
import { NO_SUPPLIER_ID, NO_SUPPLIER_LABEL, usePurchasesState } from '../../../purchases'
import { define } from '../../../../utils/typeUtils'
import { useAdministrationState } from '../../hooks/administrationHooks'
import { Expense, Ticket, TicketType } from '../../state/types'
import { Labels } from '../../../../features/data-types'
import { useTicketHooks } from '../../hooks/ticketHooks'

type Props = {
    tickets: Ticket[]
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const PendingPaymentFilters = (props: Props) => {
    const { tickets, onSearchStart, onSearchDone } = props

    const [supplierId, setSupplierId] = useState<string>()
    const [employeeId, setEmployeeId] = useState<string>()
    const [type, setType] = useState<TicketType>()
    const [dueDateFrom, setDueDateFrom] = useState<Date>()
    const [dueDateTo, setDueDateTo] = useState<Date>()

    const { suppliers } = usePurchasesState()
    const { employees } = useAdministrationState()

    const { findParent, isExpense } = useTicketHooks()

    useEffect(() => search(supplierId, employeeId, type, dueDateFrom, dueDateTo), [])

    const findTicketParent = (ticket: Ticket) => define(findParent(ticket.parentTable as TableName, ticket.parentId))

    const matchSupplierId = (ticket: Ticket, supplierIdParam: string) => {
        const parent = findTicketParent(ticket)
        return (parent.supplierId || NO_SUPPLIER_ID) === supplierIdParam
    }

    const matchEmployeeId = (ticket: Ticket, employeeIdParam: string) => {
        const expense = isExpense(ticket.parentTable) ? findTicketParent(ticket) as Expense : undefined
        return expense?.refundEmployeeId === employeeIdParam
    }

    const search = (
        supplierIdParam?: string,
        employeeIdParam?: string,
        typeParam?: TicketType,
        dueDateFromParam?: Date,
        dueDateToParam?: Date
    ) => {
        onSearchStart()
        let ticketList = tickets.filter(ticket => ticket.total > ticket.payedAmount)
        ticketList = ticketList.filter(ticket => {
            return !supplierIdParam || matchSupplierId(ticket, supplierIdParam)
        })
        ticketList = ticketList.filter(ticket => !employeeIdParam || matchEmployeeId(ticket, employeeIdParam))
        ticketList = ticketList.filter(ticket => !typeParam || ticket.type === typeParam)
        ticketList = dueDateFromParam ? ticketList.filter(ticket => new Date(ticket.dueDate) >= dueDateFromParam) : ticketList
        ticketList = dueDateToParam ? ticketList.filter(ticket => new Date(ticket.dueDate) <= dueDateToParam) : ticketList
        ticketList = ticketList.sort((ticket_A: Ticket, ticket_B: Ticket) => {
            const codesDiff = findTicketParent(ticket_B).code - findTicketParent(ticket_A).code
            return codesDiff !== 0 ? codesDiff : ticket_A.dueDate.localeCompare(ticket_B.dueDate)
        })
        setTimeout(() => onSearchDone(ticketList), 100)
    }

    const supplierOptions = useMemo(() => suppliers
        .map(supplier => option(supplier.id, supplier.name))
        .concat(option(NO_SUPPLIER_ID, NO_SUPPLIER_LABEL))
    , [])

    const onChangeType = (value?: FieldValue) => {
        const newType = value as O<TicketType>
        if (newType !== type) {
            setType(newType)
            search(supplierId, employeeId, newType, dueDateFrom, dueDateTo)
        }
    }

    const onChangeSupplier = (value?: FieldValue) => {
        const newSupplierId = value as O<string>
        if (newSupplierId !== supplierId) {
            setSupplierId(newSupplierId)
            search(newSupplierId, employeeId, type, dueDateFrom, dueDateTo)
        }
    }

    const onChangeEmployee = (value?: FieldValue) => {
        const newEmployeeId = value as O<string>
        if (newEmployeeId !== employeeId) {
            setEmployeeId(newEmployeeId)
            search(supplierId, newEmployeeId, type, dueDateFrom, dueDateTo)
        }
    }

    const onChangeDueDateFrom = (value?: FieldValue) => {
        const newDueDateFrom = value as O<Date>
        newDueDateFrom?.setHours(11, 59, 59)
        if (newDueDateFrom !== dueDateFrom) {
            setDueDateFrom(newDueDateFrom)
            search(supplierId, employeeId, type, newDueDateFrom, dueDateTo)
        }
    }
    
    const onChangeDueDateTo = (value?: FieldValue) => {
        const newDueDateTo = value as O<Date>
        newDueDateTo?.setHours(11, 59, 59)
        if (newDueDateTo !== dueDateTo) {
            setDueDateTo(newDueDateTo)
            search(supplierId, employeeId, type, dueDateFrom, newDueDateTo)
        }
    }

    const filterFields: FilterField[] = [
        {
            name: 'supplier',
            type: FieldType.SELECT,
            options: supplierOptions,
            label: 'Proveedor',
            space: 3,
            onChange: onChangeSupplier
        },
        {
            name: 'employee',
            type: FieldType.SELECT,
            options: employees.map(employee => option(employee.id, employee.name)),
            label: 'Empleado a Reintegrar',
            space: 3,
            onChange: onChangeEmployee
        },
        {
            name: 'type',
            type: FieldType.SELECT,
            options: Object.values(TicketType).map(type => option(type, Labels.ticketTypeLabel(type))),
            label: 'Tipo de Factura',
            space: 3,
            onChange: onChangeType
        },
        {
            name: 'dueDateFrom',
            type: FieldType.DATE,
            label: 'Vencimiento: Desde',
            space: 3,
            onChange: onChangeDueDateFrom
        },
        {
            name: 'dueDateTo',
            type: FieldType.DATE,
            label: 'Vencimiento: Hasta',
            space: 3,
            onChange: onChangeDueDateTo
        }
    ]

    return (
        <Filters
            fields={filterFields}
            firstRowLength={3}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
