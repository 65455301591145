import { useMemo } from "react"
import { useDescriptorHooks } from "../../../../../features/data-types"
import { Badge, Async, Link } from "../../../../../features/ui"
import { Transaction, TransactionSourceType } from "../../../state/types"
import { useTransactionHooks } from "../../../hooks/transactionHooks"
import { TableName } from "../../../../../tables"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { useNavigate } from "react-router-dom"

type Props = {
    transaction: Transaction
}

export const TransactionSource = (props: Props) => {
    const { transaction } = props

    const descriptorHooks = useDescriptorHooks()
    const { getSourceTable } = useTransactionHooks()
    const { getRoutePath } = useRoutesHooks()
    const navigate = useNavigate()

    const sourceTable = useMemo(() => getSourceTable(transaction.sourceType), [transaction.destinationType])

    const icon = useMemo(() => sourceTable === TableName.CASH_FUNDS ? 'inbox' : (sourceTable === TableName.CREDIT_CARDS ? 'credit-card-2-front' : 'card-text'), [sourceTable])

    const goToSource = () => {
        const tablePath = sourceTable === TableName.CASH_FUNDS ? RoutePath.CASH_FUNDS : (sourceTable === TableName.CREDIT_CARDS ? RoutePath.CREDIT_CARDS : RoutePath.CHECKS)
        const idPath = sourceTable !== TableName.CASH_FUNDS ? transaction.sourceId : undefined
        navigate(getRoutePath(tablePath, idPath))
    }

    return <Async
        loadFn={async () => {
            return transaction.sourceType === TransactionSourceType.MANUAL_ADDITION ?
                <Badge label="Adición Manual" /> :
                <Link
                    text={await descriptorHooks.transaction.source(transaction)}
                    icon={icon}
                    kind="primary"
                    onClick={goToSource}
                />
        }}
    />
}
