import styles from './downloadSalePDFModal.module.css'
import { fileName, usePDFActions } from "../../../../../features/files"
import { Modal, ModalId } from "../../../../../features/ui"
import { SaleOrder } from "../../../state/types"
import { SalePDF } from "../sale-pdf/salePDF"
import { FileTile, FileType } from "../../../../../features/ui"
import { useEffect, useState } from "react"
import { TableName } from '../../../../../tables'

type Props = {
    sale?: SaleOrder
}

export const DownloadSalePDFModal = (props: Props) => {
    const { sale } = props
    
    const [pdfName, setPdfName] = useState<string>()

    const { generatePDF } = usePDFActions()

    useEffect(() => {
        sale && setPdfName(fileName(TableName.SALE_ORDERS, sale.code.toString()))
    }, [sale])

    const pdfId = 'sale-pdf'
    
    const onSubmit = () => {
        pdfName && generatePDF(pdfId, pdfName)
    }

    const renderHiddenPDF = () => sale ? (
        <div className={styles.hidden}>
            <SalePDF
                id={pdfId}
                sale={sale}
            />
        </div>
    ) : <></>
    
    return (
        <Modal
            modalId={ModalId.DOWNLOAD_SALE_PDF}
            title="Descargar Venta"
            submitLabel="Descargar"
            submitIcon="download"
            onSubmit={onSubmit}
        >
            <FileTile fileType={FileType.PDF} fileName={pdfName}/>
            {renderHiddenPDF()}
        </Modal>
    )
}
