import { useEffect, useState } from "react"
import { Modal, ModalId, useUIState } from "../../../../../features/ui"
import { UploadDeliveryNoteForm } from "./uploadDeliveryNoteForm"
import { PurchaseOrder } from "../../../state/types"

type Props = {
    purchase: PurchaseOrder
}

export const UploadDeliveryNoteFormModal = (props: Props) => {
    const { purchase } = props
    const [key, setKey] = useState(0)
    const { openedModal } = useUIState()

    useEffect(() => setKey(key + 1), [openedModal])
    
    return (
        <Modal
            modalId={ModalId.UPLOAD_DELIVERY_NOTE}
            title="Subir Remito de Recepción"
            widthSize="m"
            actions={[]}
        >
            <UploadDeliveryNoteForm
                key={key}
                purchase={purchase}
            />
        </Modal>
    )
}
