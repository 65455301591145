import { useEffect, useState } from "react"
import { DataType } from "../../../../../tables"
import { usePurchasesHooks, usePurchasesState } from "../../../hooks/purchasesHooks"
import { TaxCategory } from "../../../../operations"
import { FieldType, FieldValue, FilterField, Filters, O, option } from "../../../../../features/ui"
import { Labels } from "../../../../../features/data-types"

type Props = {
    productSupplier?: boolean
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const SupplierFilters = (props: Props) => {
    const { productSupplier, onSearchStart, onSearchDone } = props

    const purchasesState = usePurchasesState()
    const suppliers = purchasesState.suppliers.filter(supplier => supplier.productSupplier === productSupplier)

    const [searchText, setSearchText] = useState<string>()
    const [taxCategory, setTaxCategory] = useState<TaxCategory>()

    const { searchSuppliers } = usePurchasesHooks()

    useEffect(() => {
        search(searchText, taxCategory)
    }, [purchasesState.suppliers.length])

    const search = (searchTextParam?: string, taxCategoryParam?: string) => {
        onSearchStart()
        let supplierList = suppliers.filter(supplier => !taxCategoryParam || supplier.taxCategory === taxCategoryParam)
        supplierList = searchSuppliers(supplierList, searchTextParam)
        setTimeout(() => onSearchDone(supplierList), 100)
    }

    const handleSearch = (value?: FieldValue) => {
        const newSearchText = value as O<string>
        setSearchText(newSearchText)
        search(newSearchText, taxCategory)
    }

    const onChangeTaxCategory = (value?: FieldValue) => {
        const newTaxCategory = value as O<TaxCategory>
        if (newTaxCategory !== taxCategory) {
            setTaxCategory(newTaxCategory)
            search(searchText, newTaxCategory)
        }
    }

    const searchField: FilterField = {
        name: 'search',
        type: FieldType.TEXT,
        placeholder: 'Buscar por nombre, código o CUIT / CUIL',
        icon: 'search',
        onChange: handleSearch
    }

    const filterFields: FilterField[] = [
        {
            name: 'taxCategory',
            type: FieldType.SELECT,
            options: Object.values(TaxCategory).map(taxCategory => option(taxCategory, Labels.taxCategoryLabel(taxCategory))),
            label: 'Categoría Fiscal',
            onChange: onChangeTaxCategory
        }
    ]

    return (
        <Filters
            searchField={searchField}
            fields={filterFields}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
