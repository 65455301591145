import { DataTypeList, useDescriptorHooks } from "../../../../features/data-types"
import { Badge, Column, Data, FieldType, FieldValue, Spinner, SpinnerDiv, UIPermission, O } from "../../../../features/ui"
import { useFind } from "../../../../state/reducers/hooks"
import { DataType, TableName } from "../../../../tables"
import { define } from "../../../../utils/typeUtils"
import { Supplier } from "../../../purchases"
import { useSystemStateHooks } from "../../../system"
import { Category, Product } from "../../state/types"
import { usePricesHooks } from "../../../operations"
import { useState } from "react"
import { ProductFilters } from "./productFilters"

type Props = {
    products: Product[]
}

export const ProductList = (props: Props) => {
    const { products } = props

    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)

    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_PRODUCTS)
    const descriptorHooks = useDescriptorHooks()
    const pricesHooks = usePricesHooks()
    const find = useFind()

    const columns: Column[] = [
        {
            name: 'imageUrl',
            type: FieldType.IMAGE,
            label: ' '
        },
        {
            name: 'code',
            type: FieldType.NUMBER,
            label: '#',
            render: (value?: FieldValue) => `#${value}`
        },
        {
            name: 'supplierCode',
            type: FieldType.TEXT,
            label: 'Código Proveedor'
        },
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre'
        },
        {
            name: 'supplierId',
            type: FieldType.TABLE,
            table: TableName.SUPPLIERS,
            label: 'Proveedor',
            render: (value?: FieldValue) => {
                const supplier = define(find(TableName.SUPPLIERS, value as string)) as Supplier
                return <Badge label={supplier.name} />
            }
        },
        {
            name: 'categoryId',
            type: FieldType.TABLE,
            table: TableName.CATEGORIES,
            label: 'Categoría',
            render: (value?: FieldValue) => {
                const category = define(find(TableName.CATEGORIES, value as string)) as Category
                return <Badge label={category.name} />
            }
        },
        {
            name: 'units',
            type: FieldType.TEXT,
            label: 'Contenido',
            render: (value?: FieldValue) => {
                const product = find(TableName.PRODUCTS, value as string) as O<Product>
                return product ? descriptorHooks.product.content(product) : <Spinner size='s'/>
            }
        },
        {
            name: 'salePrice',
            type: FieldType.PRICE,
            label: 'Precio de Lista'
        }
    ]
    if (userHasPermission(UIPermission.READ_PRODUCT_PURCHASE_PRICE)) {
        const purchasePriceColumn = {
            name: 'purchasePrice',
            type: FieldType.PRICE,
            label: 'Precio de Compra'
        }
        columns.splice(6, 0, purchasePriceColumn)
    }

    const toRow = (product: Product) => ({
        ...product,
        units: product.id,
        purchasePrice: pricesHooks.product.purchasePrice(product.id),
        salePrice: pricesHooks.product.basePrice(product.id)
    })

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        const productList = dataTypes as Product[]
        setRows(productList.map(toRow))
        setAsyncDataLoaded(true)
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <>
            <ProductFilters
                products={products}
                onSearchStart={onSearchStart}
                onSearchDone={onSearchDone}
            />
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    readOnly={readOnly}
                />
            )}
        </>
    )
}
