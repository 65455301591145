import { O } from '../../../features/ui'
import { useStateHooks } from '../../../state'
import { TableName } from '../../../tables'
import { firstItem } from '../../../utils/listUtils'
import { useLoaders } from '../../common'
import { useSystemStateHooks } from '../../system'
import { Settings, SettingsModule } from '../state/types'

export const useSettingsLoaders = () => {
    const systemHooks = useSystemStateHooks()
    const { getList, findByParams } = useStateHooks()

    const loaders = useLoaders()

    return (companyId = systemHooks.companyId) => {
        const findAllSettings = () => getList(TableName.SETTINGS) as Settings[]

        const findModuleSettings = (module: SettingsModule) => firstItem(findByParams(TableName.SETTINGS, { module })) as O<Settings>

        const loadAllSettings = (dbRequest = false) => {
            const { loadAll } = loaders(companyId)
            return loadAll(TableName.SETTINGS, dbRequest) as Promise<Settings[]>
        }
        
        return {
            findAllSettings,
            findModuleSettings,
            loadAllSettings
        }
    }
}
