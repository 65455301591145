import { useState } from "react"
import { FieldType, Action, Column, FieldValue, SpinnerDiv, Data, ModalId, useUIActions, List, Badge, ButtonKind, PanelWrapper, O } from "../../../../../features/ui"
import { useFind } from "../../../../../state/reducers/hooks"
import { define } from "../../../../../utils/typeUtils"
import { InvoiceStatus, DownloadInvoiceModal, useEmployeeLoaders } from "../../../../administration"
import { HoardOrder, SaleOperation, SaleOrder } from "../../../../sales"
import { DataType, TableName } from "../../../../../tables"
import { InvoiceFilters } from "./invoiceFilters"
import { UploadInvoiceFormModal } from "../upload-invoice-modal/uploadInvoiceFormModal"
import { Labels, useDescriptorHooks } from "../../../../../features/data-types"
import { InvoiceDataModal } from "../invoice-data-modal/invoiceDataModal"

export const InvoiceList = () => {
    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [currentTable, setCurrentTable] = useState<TableName>(TableName.SALE_ORDERS)
    const [currentOperation, setCurrentOperation] = useState<SaleOperation>()

    const descriptorHooks = useDescriptorHooks()
    const find = useFind()

    const employeeLoaders = useEmployeeLoaders()

    const { openModal } = useUIActions()

    const setRowsState = (operations: (SaleOperation)[]) => {
        const newRows = operations.map(operation => ({
            id: operation.id,
            sellerId: operation.sellerId,
            customerId: operation.customerId,
            invoiceStatus: operation.invoiceStatus
        }))
        setRows(newRows)
        setAsyncDataLoaded(true)
    }

    const columns: Column[] = [
        {
            name: 'id',
            type: FieldType.NUMBER,
            label: '#',
            render: (value?: FieldValue) => {
                const id = define(value) as string
                const sale = findSale(id)
                const prefix = sale ? 'Venta' : 'Acopio'
                return `${prefix} #${sale?.code || findHoard(id)?.code}`
            }
        },
        {
            name: 'sellerId',
            type: FieldType.TABLE,
            table: TableName.EMPLOYEES,
            label: 'Vendedor',
            render: (value?: FieldValue) => {
                const seller = employeeLoaders().findEmployee(define(value) as string)
                return descriptorHooks.employee.info(define(seller))
            }
        },
        {
            name: 'customerId',
            type: FieldType.TABLE,
            table: TableName.CUSTOMERS,
            label: 'Cliente'
        },
        {
            name: 'invoiceStatus',
            type: FieldType.TEXT,
            label: 'Estado Factura',
            render: (value?: FieldValue) => <Badge label={Labels.invoiceStatusLabel(define(value) as InvoiceStatus)} />
        }
    ]

    const findSale = (id?: string) => find(TableName.SALE_ORDERS, id) as O<SaleOrder>
    const findHoard = (id?: string) => find(TableName.HOARD_ORDERS, id) as O<HoardOrder>
    const findOperation = (id?: string) => findSale(id) || findHoard(id)

    const onViewData = (id?: string) => {
        const sale = findSale(id)
        setCurrentOperation(sale || findHoard(id))
        setCurrentTable(sale ? TableName.SALE_ORDERS : TableName.HOARD_ORDERS)
        openModal(ModalId.INVOICE)
    }

    const onDownload = (id?: string) => {
        const sale = findSale(id)
        setCurrentOperation(sale || findHoard(id))
        setCurrentTable(sale ? TableName.SALE_ORDERS : TableName.HOARD_ORDERS)
        openModal(ModalId.DOWNLOAD_INVOICE)
    }

    const onUpload = (id?: string) => {
        const sale = findSale(id)
        setCurrentOperation(sale || findHoard(id))
        setCurrentTable(sale ? TableName.SALE_ORDERS : TableName.HOARD_ORDERS)
        openModal(ModalId.UPLOAD_INVOICE)
    }

    const actions = (id?: string) => {
        const actionList: Action[] = []
        const operation = findOperation(id)
        if (operation?.invoiceStatus === InvoiceStatus.INVOICED) {
            actionList.push({ kind: 'tertiary', tooltip: 'Descargar Factura', icon: 'download', onClick: onDownload })
        } else if (operation?.invoiceStatus === InvoiceStatus.REQUESTED) {
            const viewDataAction = { icon: 'eye-fill', kind: 'tertiary' as ButtonKind, tooltip: 'Ver Datos', onClick: onViewData }
            const uploadAction = { icon: 'upload', tooltip: 'Subir Factura', onClick: onUpload }
            actionList.push(viewDataAction, uploadAction)
        }

        return actionList
    }

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRowsState(dataTypes as SaleOperation[])
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <PanelWrapper title="Subir Facturas ARCA">
            <InvoiceFilters onSearchStart={onSearchStart} onSearchDone={onSearchDone}/>
            {renderAsync(
                <List
                    columns={columns}
                    rows={rows}
                    rowId="id"
                    actions={actions}
                />
            )}
            <InvoiceDataModal 
                table={currentTable}
                operation={currentOperation}
            />
            <UploadInvoiceFormModal
                table={currentTable}
                operation={currentOperation}
            />
            <DownloadInvoiceModal operation={currentOperation} />
        </PanelWrapper>
    )
}
