import { Action, TableActions, readPermissions } from "../../../features/roles";
import { TableName } from "../../../tables";

export const purchasesPermissions = (): TableActions[] => readPermissions().concat([
    { tableName: TableName.CATEGORIES, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.EMPLOYEES, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.LOTS, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.MEASURES, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.ORDER_DELIVERIES, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.PRODUCTS, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.PRODUCT_PRICES, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.PURCHASE_ITEMS, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.PURCHASE_ORDERS, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.SETTINGS, actions: [Action.SAVE] },
    { tableName: TableName.STOCK, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.SUPPLIERS, actions: [Action.SAVE, Action.REMOVE] },
    { tableName: TableName.TICKETS, actions: [Action.SAVE, Action.REMOVE] } // update company policies
])
