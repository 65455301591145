import { useEffect, useState } from "react"
import { PanelLoader } from "../../../../../features/ui"
import { CreditCardList } from "./creditCardList"
import { useCreditCardLoaders } from "../../../loaders/creditCardLoaders"
import { CreditCard } from "../../../state/types"

export const CreditCardListLoader = () => {
    const [creditCards, setCreditCards] = useState<CreditCard[]>([])
    const [ready, setReady] = useState(false)

    const creditCardLoaders = useCreditCardLoaders()

    const populate = async () => {
        const { loadAllCreditCards } = creditCardLoaders()
        setCreditCards(await loadAllCreditCards())
    }

    useEffect(() => {
        populate().then(() => setReady(true))
    }, [])

    return (
        <PanelLoader
            title="Tarjetas"
            ready={ready}
            wide
        >
            <CreditCardList creditCards={creditCards} />
        </PanelLoader>
    )
}
