import { Emitter, Event } from "../../../../../features/event-emitter"
import { OrderDelivery, useOperationsActions } from "../../../../operations"

export const useActions = () => {
    const operationsActions = useOperationsActions()

    const submitDeliveryNote = async (
        delivery: OrderDelivery,
        deliveryNoteFile: File
    ) => {
        Emitter.emit(Event.DB_SUBMIT)
        await operationsActions().saveDelivery(delivery, deliveryNoteFile)
        Emitter.emit(Event.DB_SUBMIT_DONE, false, false)
    }
    
    return {
        submitDeliveryNote
    }
}
