import { useStateHooks } from "../../../state/reducers/hooks"
import { TableName } from "../../../tables"
import { define, round } from "../../../utils/typeUtils"
import { Branch } from "../../manager"
import { usePriceBreakdownHooks } from "../../operations"
import { Customer, SaleOperation } from "../../sales"
import { useSystemState } from "../../system"
import { InvoiceData } from "../state/types"

export const useInvoiceHooks = () => {
    const { company } = useSystemState()
    
    const { find } = useStateHooks()
    const { getOperationPriceBreakdown } = usePriceBreakdownHooks()

    const getInvoiceData = (
        table: TableName,
        operation: SaleOperation
    ): InvoiceData => {
        const definedCompany = define(company)
        const branch = define(find(TableName.BRANCHES, operation.branchId)) as Branch
        const customer = define(find(TableName.CUSTOMERS, operation.customerId)) as Customer
        const priceBreakdown = getOperationPriceBreakdown(table, operation)

        return {
            companyLegalName: definedCompany.legalName,
            companyTaxId: definedCompany.taxId,
            companyAddress: branch.address || '',
            customerLegalName: customer.name,
            customerIdentifierType: customer.identifierType,
            customerIdentifier: customer.identifier,
            customerPhone: customer.phone,
            subtotal: round(priceBreakdown.totalWithoutVAT),
            vatAmount: round(priceBreakdown.vatAmount),
            total: round(priceBreakdown.total)
        }
    }

    return {
        getInvoiceData
    }
}
