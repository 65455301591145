import { useDataTypesHooks } from '../../../features/data-types'
import { O } from '../../../features/ui'
import { TableName } from '../../../tables'
import { useLoaders } from '../../common'
import { useSystemStateHooks } from '../../system'
import { PurchaseItem, PurchaseOrder } from '../state/types'

export const usePurchaseLoaders = () => {
    const systemHooks = useSystemStateHooks()
    const { hasLoaded, addToLoaded } = useDataTypesHooks()

    const loaders = useLoaders()

    return (companyId = systemHooks.companyId) => {
        const loadAllPurchases = () => {
            const { loadAll } = loaders(companyId)
            return loadAll(TableName.PURCHASE_ORDERS) as Promise<PurchaseOrder[]>
        }

        const loadPurchase = (id: string) => {
            const { load } = loaders(companyId)
            return load(TableName.PURCHASE_ORDERS, id) as Promise<O<PurchaseOrder>>
        }

        const loadBranchPurchases = async (deliveryBranchId: string) => {
            const { loadByParams } = loaders(companyId)
            const purchases = await loadByParams(TableName.PURCHASE_ORDERS, { deliveryBranchId }, !hasLoaded(TableName.PURCHASE_ORDERS)) as PurchaseOrder[]
            addToLoaded(TableName.PURCHASE_ORDERS)
            return purchases
        }

        const loadPurchaseItems = (purchaseId: string) => {
            const { loadByParams } = loaders()
            return loadByParams(TableName.PURCHASE_ITEMS, { purchaseId }) as Promise<PurchaseItem[]>
        }

        const loadPurchaseItemsByPurchases = async (purchaseIds: string[]): Promise<PurchaseItem[]> => {
            const purchaseItems = await Promise.all(purchaseIds.map(purchaseId => loadPurchaseItems(purchaseId)))
            return purchaseItems.flat()
        }

        return {
            loadAllPurchases,
            loadPurchase,
            loadBranchPurchases,
            loadPurchaseItemsByPurchases
        }
    }
}
