import { Data, FieldType, FieldValue, Form, FormField, O, TextArea } from "../../../../../features/ui"
import { CashFund } from "../../../state/types"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { define } from "../../../../../utils/typeUtils"
import { useNavigate } from "react-router-dom"
import { useActions } from "./actions"
import { useDataTypesHooks } from "../../../../../features/data-types"
import { useState } from "react"

export const EditCashFundAmountForm = () => {
    const { dataType, pathParts } = useRoutesHooks().parseUrl()
    const cashFund = define(dataType) as CashFund
    const addMode = pathParts[3] === RoutePath.ADD

    const [concept, setConcept] = useState<string>()

    const navigate = useNavigate()
    const { formatValue } = useDataTypesHooks()

    const { updateCashFundAmount } = useActions()

    const onConceptChange = (value?: FieldValue) => {
        setConcept(value as O<string>)
    }

    const fields: FormField[] = [
        {
            name: 'cashFund',
            type: FieldType.TEXT,
            label: 'Caja',
            render: () => <>{cashFund.name}</>
        },
        {
            name: 'availableAmount',
            type: FieldType.TEXT,
            label: ' ',
            render: () => <>Fondos: {formatValue(cashFund.availableAmount, FieldType.PRICE)}</>
        },
        {
            name: 'amount',
            type: FieldType.PRICE,
            label: `Monto a ${addMode ? 'Agregar' : 'Retirar'}`,
            max: !addMode ? cashFund.availableAmount : undefined
        },
        {
            name: 'concept',
            type: FieldType.TEXT,
            label: 'Concepto',
            render: () => <TextArea
                id="concept"
                onChange={onConceptChange}
            />
        }
    ]

    const onSubmit = (data: Data) => {
        updateCashFundAmount(cashFund, data, define(concept), addMode)
    }

    const onCancel = () => navigate(-1)

    return (
        <Form
            formId="edit-cash-fund-amount-form" 
            fields={fields}
            createMode={false}
            onSubmit={onSubmit}
            onCancel={onCancel}
        />
    )
}
