import styles from './construfyAdminPanel.module.css'
import { FieldValue, option, SelectField, Sidebar, SpinnerDiv, SpinnerModal } from '../../../../features/ui'
import { Outlet } from 'react-router-dom'
import { Company, useSystemActions, useSystemState, useSystemStateActions } from '../../../system'
import { useEffect, useState } from 'react'
import { useConstrufyAdminActions } from '../../actions/construfyAdminActions'
import { useConstrufyAdminHooks } from '../../hooks/construfyAdminHooks'

export const ConstrufyAdminPanel = () => {
  const { company } = useSystemState()
  
  const [companies, setCompanies] = useState<Company[]>([])
  
  const { setCompany } = useSystemStateActions()

  const systemActions = useSystemActions()
  const {} = useConstrufyAdminActions()

  const init = async () => {
    const stateCompanies = await systemActions().fetchAllCompanies()
    setCompanies(stateCompanies)
  }

  useEffect(() => {
    init()
  }, [])

  const { getMenu } = useConstrufyAdminHooks()
  const menu = getMenu()

  const onChangeCompany = (value?: FieldValue) => {
    const stateCompany = companies.find(company => company.id === value)
    stateCompany && setCompany(stateCompany)
  }
  
  return (
    <>
      <Sidebar menu={menu} />
      <div className={styles.leftMargin}>
        {companies.length > 0 ? (
          <>
            <div className={styles.selectContainer}>
              <SelectField
                id="companyId"
                value={company?.id}
                options={companies.map(company => option(company.id, company.name))}
                onChange={onChangeCompany}
              />
            </div>
            <Outlet />
          </>
        ) : (
          <SpinnerDiv />
        )}
      </div>
      <SpinnerModal />
    </>
  )
}
