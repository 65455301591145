import { useState } from "react"
import { DataTypeList, Labels, useDataTypesHooks } from "../../../../../features/data-types"
import { Column, Data, FieldType, FieldValue, FormatType, SpinnerDiv, UIPermission, Badge, O } from "../../../../../features/ui"
import { define } from "../../../../../utils/typeUtils"
import { useSystemStateHooks } from "../../../../system"
import { Area, Employee } from "../../../state/types"
import { EmployeeFilters } from "./employeeFilters"
import { DataType, TableName } from "../../../../../tables"

type Props = {
    employees: Employee[]
}

export const EmployeeList = (props: Props) => {
    const { employees } = props

    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)

    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_EMPLOYEES)
    const { formatCustomValue } = useDataTypesHooks()

    const columns: Column[] = [
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre'
        },
        {
            name: 'area',
            type: FieldType.TEXT,
            label: 'Área',
            render: (value?: FieldValue) => <Badge label={Labels.areaLabel(define(value) as Area)} />
        },
        {
            name: 'branchId',
            type: FieldType.TABLE,
            table: TableName.BRANCHES,
            label: 'Sucursal'
        },
        {
            name: 'identifier',
            type: FieldType.NUMBER,
            label: 'DNI',
            render: (value?: FieldValue) => {
                const identifier = value as O<number>
                return formatCustomValue(identifier, FormatType.DNI) || <Badge label="Vacío" />
            }
        },
        {
            name: 'email',
            type: FieldType.TEXT,
            label: 'Email'
        }
    ]

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRows(dataTypes as Employee[])
        setAsyncDataLoaded(true)
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <>
            <EmployeeFilters
                employees={employees}
                onSearchStart={onSearchStart}
                onSearchDone={onSearchDone}
            />
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    readOnly={readOnly}
                />
            )}
        </>
    )
}
