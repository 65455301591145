import { useEffect, useState } from "react"
import { useDescriptorHooks } from "../../../../../features/data-types"
import { Modal, ModalId, Spinner } from "../../../../../features/ui"
import { InvoiceDataDiv } from "../../../../administration"
import { TableName } from "../../../../../tables"
import { HoardItem, SaleItem, useSalesActions, SaleOperation } from "../../../../sales"

type Props = {
    table: TableName
    operation?: SaleOperation
}

export const InvoiceDataModal = (props: Props) => {
    const { table, operation } = props
    const isSale = table === TableName.SALE_ORDERS

    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)

    const descriptorHooks =  useDescriptorHooks()

    const salesActions = useSalesActions()

    const loadOperation = async () => {
        setAsyncDataLoaded(false)
        if (operation) {
            const { fetchSaleItemsByOrder, fetchHoardItemsByOrder, fetchCustomer } = salesActions()
            if (isSale) {
                await fetchSaleItemsByOrder(operation.id) as SaleItem[]
            } else {
                await fetchHoardItemsByOrder(operation.id) as HoardItem[]
            }
            await fetchCustomer(operation.customerId)
            setAsyncDataLoaded(true)
        }
    }
    
    useEffect(() => {
        loadOperation()
    }, [operation])

    const tableLabel = isSale ? 'Venta' : 'Acopio'
    const operationLabel = operation ? `${tableLabel} ${descriptorHooks.dataType(table, operation)}` : ''
    const title = `Datos de ${operationLabel}`

    const renderBody = () => asyncDataLoaded ? <>
        Datos para facturación en ARCA:<br />
        <br />
        <InvoiceDataDiv
            table={table}
            operation={operation}
        />
    </> : <Spinner size="m" />

    return (
        <Modal
            modalId={ModalId.INVOICE}
            title={title}
            widthSize="m"
            cancelLabel="Cerrar"
        >
            {renderBody()}
        </Modal>
    )
}
