import { useNavigate } from "react-router-dom"
import { Modal, ModalId } from "../../../../../features/ui"
import { RoutePath } from "../../../../../features/routes"

type Props = {
    routePathPrefix?: string
}

export const UploadAdditionalTicketModal = (props: Props) => {
    const { routePathPrefix } = props

    const navigate = useNavigate()

    const title = `Subir Factura Adicional?`
    
    const renderBody = () => <>
        Ya existe una factura cargada para esta orden. <br />
        Deseas subir una factura adicional?
    </>
    
    const onSubmit = () => {
        const prefix = routePathPrefix ? routePathPrefix + '/' : ''
        navigate(`${prefix}${RoutePath.TICKETS}/${RoutePath.CREATE}`)
    }

    return (
        <Modal
            modalId={ModalId.UPLOAD_ADDITIONAL_TICKET}
            title={title}
            submitLabel="Subir Factura"
            onSubmit={onSubmit}
        >
            <>{renderBody()}</>
        </Modal>
    )
}
