import { useState } from "react"
import { FieldType, UIPermission, Column, FieldValue, SpinnerDiv, useUIActions, ModalId, Action, Data, Badge, ButtonKind, O } from "../../../../../features/ui"
import { DataType, TableName } from "../../../../../tables"
import { useSystemStateHooks } from "../../../../system"
import { useFind } from "../../../../../state/reducers/hooks"
import { define, toNumber } from "../../../../../utils/typeUtils"
import { DataTypeList, Labels } from "../../../../../features/data-types"
import { NO_SUPPLIER_LABEL, Supplier } from "../../../../purchases"
import { Expense, ExpenseType, Ticket } from "../../../state/types"
import { ExpenseFilters } from "./expenseFilters"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "../../../../../features/routes"
import { PaymentStatus } from "../../../../operations"
import { UploadAdditionalTicketModal } from "../../ticket/upload-additional-ticket-modal/uploadAdditionalTicketModal"

type Props = {
    expenses: Expense[]
    tickets: Ticket[]
}

export const ExpenseList = (props: Props) => {
    const { expenses, tickets } = props

    const [rows, setRows] = useState<Data[]>([])
    const [currentExpense, setCurrentExpense] = useState<Expense>()
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)

    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_EXPENSES)
    const navigate = useNavigate()
    const find = useFind()

    const { toggleModal } = useUIActions()

    const columns: Column[] = [
        { name: 'code', type: FieldType.NUMBER, label: '#', render: (value?: FieldValue) => `#${value}` },
        { name: 'name', type: FieldType.TEXT, label: 'Nombre' },
        {
            name: 'expenseTypeId',
            type: FieldType.TABLE,
            table: TableName.EXPENSE_TYPES,
            label: 'Tipo',
            render: (value?: FieldValue) => {
                const expenseType = define(find(TableName.EXPENSE_TYPES, value as string)) as ExpenseType
                return <Badge label={expenseType.name} />
            }
        },
        { name: 'date', type: FieldType.DATE, label: 'Fecha' },
        {
            name: 'supplierId',
            type: FieldType.TABLE,
            table: TableName.SUPPLIERS,
            label: 'Proveedor',
            render: (value?: FieldValue) => {
                const supplier = find(TableName.SUPPLIERS, value as O<string>) as O<Supplier>
                return <Badge label={supplier?.name || NO_SUPPLIER_LABEL} />
            }
        },
        {
            name: 'amount',
            type: FieldType.PRICE,
            label: 'Monto'
        },
        {
            name: 'paymentStatus',
            type: FieldType.TEXT,
            label: 'Estado de Pago',
            render: (value?: FieldValue) => Labels.paymentStatusLabel(define(value) as PaymentStatus)
        }
    ]

    const findExpense = (code?: string) => expenses.find(expense => expense.code === toNumber(code))
    const findTickets = (parentId?: string) => tickets.filter(ticket => ticket.parentId === parentId)
    
    const onView = (code?: string) => {
        const expense = findExpense(code)
        expense && navigate(expense.id)
    }

    const onEdit = (code?: string) => {
        const expense = findExpense(code)
        expense && navigate(`${expense.id}/${RoutePath.EDIT}`)
    }

    const onUploadTicket = (code?: string) => {
        const expense = findExpense(code)
        setCurrentExpense(expense)
        const expenseTickets = findTickets(expense?.id)
        if (expenseTickets.length > 0) {
            toggleModal(ModalId.UPLOAD_ADDITIONAL_TICKET)
        } else if (expense) {
            navigate(`${expense.id}/${RoutePath.TICKETS}/${RoutePath.CREATE}`)
        }
    }

    const onRemove = (code?: string) => {
        setCurrentExpense(findExpense(code))
        toggleModal(ModalId.REMOVE)
    }

    const actions = (code?: string): Action[] => {
        const expense = findExpense(code)
        const expenseTickets = findTickets(expense?.id)
        const isPaymentPending = expense?.paymentStatus === PaymentStatus.PENDING
        const isEditable = isPaymentPending && expenseTickets.length === 0
        const ticketsTotal = expenseTickets.reduce((sum, ticket) => sum + ticket.total, 0)
        const isInvoiceable = ticketsTotal < (expense?.amount || 0)

        const actionList: Action[] = [
            { icon: 'eye-fill', kind: 'tertiary' as ButtonKind, tooltip: 'Ver Detalle', onClick: onView }
        ]
        if (!readOnly && isPaymentPending) {
            if (isEditable) {
                actionList.push({ icon: 'pencil-fill', tooltip: 'Editar', onClick: onEdit })
            }
            if (isInvoiceable)  {
                actionList.push({ icon: 'upload', tooltip: 'Subir Factura/ Comprobante', narrowTooltip: true, onClick: onUploadTicket })
            }
            if (isEditable) {
                actionList.push({ icon: 'trash-fill', kind: 'secondary', tooltip: 'Eliminar', onClick: onRemove })
            }
        }

        return actionList
    }

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRows(dataTypes as Expense[])
        setAsyncDataLoaded(true)
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <>
            <ExpenseFilters
                expenses={expenses}
                onSearchStart={onSearchStart}
                onSearchDone={onSearchDone}
            />
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    rowId="code"
                    currentDataTypeParam={currentExpense}
                    actions={actions}
                    readOnly={readOnly}
                />
            )}
            <UploadAdditionalTicketModal routePathPrefix={currentExpense?.id} />
        </>
    )
}
