import { useDescriptorHooks } from "../../../../../features/data-types"
import { Modal, ModalId } from "../../../../../features/ui"
import { TableName } from "../../../../../tables"
import { SaleOperation } from "../../../../sales"
import { InvoiceDataDiv } from "../invoice-data-div/invoiceDataDiv"
import { useActions } from "./actions"

type Props = {
    table: TableName
    operation?: SaleOperation
}

export const InvoiceModal = (props: Props) => {
    const { table, operation } = props

    const descriptorHooks = useDescriptorHooks()

    const { requestInvoice } = useActions()

    const isSale = table === TableName.SALE_ORDERS
    const titleLabel = isSale ? 'Venta' : 'Acopio'
    const dataTypeLabel = operation ? ` ${descriptorHooks.dataType(table, operation)}` : ''
    const title = `Facturar ${titleLabel}${dataTypeLabel}`

    const renderBody = () => <>
        Se iniciará el proceso de facturación en ARCA con los siguientes datos:<br />
        <br />
        <InvoiceDataDiv
            table={table}
            operation={operation}
        />
        <br />
        Deseas confirmar la facturación?
    </>

    const onSubmit = () => {
        operation && requestInvoice(isSale, operation)
    }

    return (
        <Modal
            modalId={ModalId.INVOICE}
            title={title}
            submitLabel="Confirmar"
            widthSize="m"
            onSubmit={onSubmit}
        >
            {renderBody()}
        </Modal>
    )
}
