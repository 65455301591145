import { DownloadFileModal, ModalId } from "../../../../../features/ui"
import { DataType } from "../../../../../tables"
import { Ticket } from "../../../state/types"

type Props = {
    ticket?: Ticket
    ticketLabel?: string
}

export const DownloadTicketModal = (props: Props) => {
    const { ticket, ticketLabel = 'Factura' } = props

    return (
        <DownloadFileModal
            modalId={ModalId.DOWNLOAD_TICKET}
            dataType={ticket}
            fileProp={'ticketUrl' as keyof DataType}
            title={`Descargar ${ticketLabel}`}
        />
    )
}
