import { useState } from "react"
import { FieldType, UIPermission, Action, Column, FieldValue, SpinnerDiv, Data, Badge, Async } from "../../../../features/ui"
import { useSystemStateHooks } from "../../../system"
import { define, toNumber } from "../../../../utils/typeUtils"
import { DataTypeList, Labels } from "../../../../features/data-types"
import { DeliveryStatus } from "../../../operations"
import { OpenWizardModal, useWizardActions } from "../../../../features/wizard"
import { useBranchLoaders } from "../../../manager"
import { useNavigate } from "react-router-dom"
import { RoutePath, useRoutesHooks } from "../../../../features/routes"
import { PurchaseFilters, PurchaseOrder, useSupplierLoaders, purchaseWizardKey } from "../../../purchases"
import { DataType, TableName } from "../../../../tables"

type Props = {
    purchases: PurchaseOrder[]
}

export const PurchaseReceptionList = (props: Props) => {
    const { purchases } = props

    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [currentPurchase, setCurrentPurchase] = useState<PurchaseOrder>()
    const wizardKey = currentPurchase ? purchaseWizardKey(currentPurchase) : undefined

    const { userHasPermission } = useSystemStateHooks()
    const hasDeliveryPermission = userHasPermission(UIPermission.SAVE_PURCHASE_DELIVERIES)
    const navigate = useNavigate()
    const { getRoutePath } = useRoutesHooks()

    const branchLoaders = useBranchLoaders()
    const supplierLoaders = useSupplierLoaders()

    const { goToWizard } = useWizardActions()

    const findPurchase = (code?: string) => purchases.find(purchase => purchase.code === toNumber(code))
    const deliveryPath = (purchase?: PurchaseOrder) => purchase ? `${getRoutePath(RoutePath.PURCHASES, purchase.id)}/${RoutePath.DELIVERY}` : '#'

    const columns: Column[] = [
        {
            name: 'code',
            type: FieldType.NUMBER,
            label: '#',
            render: (value?: FieldValue) => `#${value}`
        },
        {
            name: 'deliveryBranchId',
            type: FieldType.TABLE,
            table: TableName.BRANCHES,
            label: 'Sucursal de Recepción',
            render: (value?: FieldValue) => {
                const branch = branchLoaders().findBranch(value as string)
                return <Badge label={define(branch).name} />
            }
        },
        {
            name: 'deliveryDate',
            type: FieldType.DATE,
            label: 'Fecha de Recepción'
        },
        {
            name: 'supplierId',
            type: FieldType.TABLE,
            table: TableName.SUPPLIERS,
            label: 'Proveedor',
            render: (value?: FieldValue) => <Async
                loadFn={async () => {
                    const supplier = await supplierLoaders().loadSupplier(value as string)
                    return <Badge label={define(supplier).name} />
                }}
            />
        },
        {
            name: 'deliveryStatus',
            type: FieldType.TEXT,
            label: 'Estado de Entrega',
            render: (value?: FieldValue) => Labels.deliveryStatusLabel(define(value) as DeliveryStatus)
        }
    ]

    const onView = (code?: string) => {
        const purchase = findPurchase(code)
        purchase && navigate(getRoutePath(RoutePath.PURCHASES, purchase.id))
    }

    const onDeliver = (code?: string) => {
        const purchase = findPurchase(code)
        setCurrentPurchase(purchase)
        purchase && goToWizard(purchaseWizardKey(purchase), deliveryPath(purchase))
    }

    const actions = (code?: string) => {
        const purchase = findPurchase(code)
        const isDelivered = purchase?.deliveryStatus === DeliveryStatus.DELIVERED

        const actionList: Action[] = [
            { icon: 'eye-fill', kind: 'tertiary', tooltip: 'Ver Detalle', onClick: onView },
        ]
        if (hasDeliveryPermission && !isDelivered) {
            actionList.push({ icon: 'truck', tooltip: 'Registrar Recepción', narrowTooltip: true, onClick: onDeliver })
        }

        return actionList
    }

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRows(dataTypes as PurchaseOrder[])
        setAsyncDataLoaded(true)
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <>
            <PurchaseFilters
                purchases={purchases}
                onSearchStart={onSearchStart}
                onSearchDone={onSearchDone}
            />
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    rowId="code"
                    currentDataTypeParam={currentPurchase}
                    actions={actions}
                    readOnly
                />
            )}
            <OpenWizardModal
                wizardKey={wizardKey}
                path={deliveryPath(currentPurchase)}
            />
        </>
    )
}
