import { useEffect, useState } from "react"
import { PanelLoader } from "../../../../../features/ui"
import { Employee } from "../../../state/types"
import { EmployeeList } from "./employeeList"
import { useEmployeeLoaders } from "../../../loaders/employeeLoaders"
import { TableName } from "../../../../../tables"
import { ListLoaderWrapper } from "../../../../../features/data-types"

export const Loader = () => {
    const [employees, setEmployees] = useState<Employee[]>([])
    const [ready, setReady] = useState(false)

    const employeeLoaders = useEmployeeLoaders()

    const populate = async () => {
        const { loadAllEmployees } = employeeLoaders()
        setEmployees(await loadAllEmployees())
    }

    useEffect(() => {
        populate().then(() => setReady(true))
    }, [])

    return (
        <PanelLoader
            title="Empleados"
            ready={ready}
            wide
        >
            <EmployeeList employees={employees} />
        </PanelLoader>
    )
}

export const EmployeeListLoader = () => (
    <ListLoaderWrapper table={TableName.EMPLOYEES}>
        <Loader />
    </ListLoaderWrapper>
)
