import { PaymentOrderStatus, CashFundType, CheckType, TaxType, Period, Area, PaymentCategory, TaxBase, CheckStatus, TicketType, InvoiceDataProp, InvoiceStatus } from "../../../modules/administration"
import { AdjustmentType, DeliveryStatus, PaymentStatus, TaxCategory } from "../../../modules/operations"
import { LotAttribute, MeasureType } from "../../../modules/products"
import { CustomerCategory, HoardType } from "../../../modules/sales"
import { IdentifierType, RequestStatus, UserRole } from "../../../modules/system"
import { define } from "../../../utils/typeUtils"

export const areaLabel = (area: Area) => {
    const labels = new Map<Area, string>()
        .set(Area.MANAGMENT, 'Dirección')    
        .set(Area.ADMINISTRATION, 'Administración')
        .set(Area.PURCHASES, 'Compras')
        .set(Area.SALES, 'Ventas')
        .set(Area.WAREHOUSE, 'Depósito')
        .set(Area.HR, 'Recursos Humanos')
    return define(labels.get(area))
}

const userRoleLabel = (role: UserRole) => {
    const labels = new Map<UserRole, string>()
        .set(UserRole.MANAGER, 'Manager')    
        .set(UserRole.ADMINISTRATION, 'Administración')
        .set(UserRole.PURCHASES, 'Compras')
        .set(UserRole.SALES, 'Ventas')
        .set(UserRole.WAREHOUSE, 'Depósito')
    return define(labels.get(role))
}

const identifierTypeLabel = (type: IdentifierType) => {
    const labels = new Map<IdentifierType, string>()
        .set(IdentifierType.PERSONAL_ID, 'DNI')    
        .set(IdentifierType.TAX_ID, 'CUIT / CUIL')
    return define(labels.get(type))
}

const requestStatusLabel = (status: RequestStatus) => {
    const labels = new Map<RequestStatus, string>()
        .set(RequestStatus.APPROVED, 'Aprobada')
        .set(RequestStatus.DENIED, 'Rechazada')
        .set(RequestStatus.PENDING, 'Pendiente')
    return define(labels.get(status))
}

const adjustmentTypeLabel = (type: AdjustmentType) => {
    const labels = new Map<AdjustmentType, string>()
        .set(AdjustmentType.DISCOUNT, 'Descuento')
        .set(AdjustmentType.SURCHARGE, 'Recargo')
    return define(labels.get(type))
}

const hoardTypeLabel = (type: HoardType, summarized = false) => {
    const labels = new Map<HoardType, string>()
        .set(HoardType.FINANCIAL, `${summarized ? '' : 'Acopio '}Financiero`)
        .set(HoardType.PRODUCT, `${summarized ? 'De' : 'Acopio de'} Productos`)
    return define(labels.get(type))
}

const deliveryStatusLabel = (status: DeliveryStatus) => {
    const labels = new Map<DeliveryStatus, string>()
        .set(DeliveryStatus.PENDING, 'Pendiente')
        .set(DeliveryStatus.PARTIALLY_DELIVERED, 'Parcialmente Entregado')
        .set(DeliveryStatus.DELIVERED, 'Entregado')
        .set(DeliveryStatus.CANCELLED, 'Cancelado')
    return define(labels.get(status))
}

const lotAttributeLabel = (attr: LotAttribute) => {
    const labels = new Map<LotAttribute, string>()
        .set(LotAttribute.TONE, 'Tono')
        .set(LotAttribute.CALIBER, 'Calibre')
        .set(LotAttribute.DUE_DATE, 'Fecha de Vto.')
    return define(labels.get(attr))
}

const periodLabel = (period: Period) => {
    const labels = new Map<Period, string>()
        .set(Period.DAILY, 'Diario')
        .set(Period.WEEKLY, 'Semanal')
        .set(Period.MONTHLY, 'Mensual')
        .set(Period.ANNUAL, 'Anual')
        .set(Period.PER_OPERATION, 'Por Operación')
    return define(labels.get(period))
}

const taxTypeLabel = (type: TaxType) => {
    const labels = new Map<TaxType, string>()
        .set(TaxType.SALE_TAX, 'Impuesto de Venta')
        .set(TaxType.VAT, 'IVA')
        .set(TaxType.PERCEPTION, 'Percepción')
        .set(TaxType.RETENTION, 'Retención')
    return define(labels.get(type))
}

const taxBaseLabel = (taxBase: TaxBase) => {
    const labels = new Map<TaxBase, string>()
        .set(TaxBase.PRICE_WITHOUT_VAT, 'Precio sin IVA')
        .set(TaxBase.VAT, 'IVA')
        .set(TaxBase.PRICE_WITH_VAT, 'Precio + IVA')
    return define(labels.get(taxBase))
}

const cashFundTypeLabel = (type: CashFundType) => {
    const labels = new Map<CashFundType, string>()
        .set(CashFundType.CASH, 'Efectivo')    
        .set(CashFundType.BANCK_ACCOUNT, 'Cuenta de Banco')
    return define(labels.get(type))
}

const checkTypeLabel = (type: CheckType) => {
    const labels = new Map<CheckType, string>()
        .set(CheckType.PHYSICAL, 'Físico')    
        .set(CheckType.E_CHEQ, 'E-Cheq')
    return define(labels.get(type))
}

const checkStatusLabel = (status: CheckStatus) => {
    const labels = new Map<CheckStatus, string>()
        .set(CheckStatus.ENABLED, 'Habilitado')    
        .set(CheckStatus.RESERVED, 'Reservado')
        .set(CheckStatus.ENDORSED, 'Endosado')
    return define(labels.get(status))
}

const taxCategoryLabel = (taxCategory: TaxCategory) => {
    const labels = new Map<TaxCategory, string>()
        .set(TaxCategory.CONSUMER, 'Consumidor Final')
        .set(TaxCategory.REGISTERED, 'Responsable Inscripto')
        .set(TaxCategory.REGISTERED_AGENT, 'Ag. Ret. Responsable Inscripto')
        .set(TaxCategory.REGISTERED_FC, 'FCE Responsable Inscripto')
        .set(TaxCategory.EXEMPT, 'Exento')
        .set(TaxCategory.EXEMPT_FC, 'FCE Exento')
        .set(TaxCategory.SINGLE_TAXPAYER, 'Monotributista')
        .set(TaxCategory.M_TICKET, 'Repsonsable Factura M')
        .set(TaxCategory.NON_REGISTERED, 'No Inscripto')
    return define(labels.get(taxCategory))
}

const customerCategoryLabel = (category: CustomerCategory) => {
    const labels = new Map<CustomerCategory, string>()
        .set(CustomerCategory.NATURAL, 'Persona Física')
        .set(CustomerCategory.LEGAL, 'Persona Jurídica')
    return define(labels.get(category))
}

const measureTypeLabel = (type: MeasureType) => {
    const labels = new Map<MeasureType, string>()
        .set(MeasureType.AREA, 'Superficie')
        .set(MeasureType.DEPTH, 'Profundidad')
        .set(MeasureType.DIAMETER, 'Diametro')
        .set(MeasureType.HEIGHT, 'Altura')
        .set(MeasureType.THICKNESS, 'Espesor')
        .set(MeasureType.VOLUME, 'Volumen')
        .set(MeasureType.WEIGHT, 'Peso')
        .set(MeasureType.WIDTH, 'Ancho')
    return define(labels.get(type))
}

const ticketTypeLabel = (type: TicketType) => {
    const labels = new Map<TicketType, string>()
        .set(TicketType.TICKET_A, 'Factura A')    
        .set(TicketType.TICKET_B, 'Factura B (Consumidor Final)')    
        .set(TicketType.TICKET_C, 'Factura C (Monotributista)')
        .set(TicketType.RECEIPT_X, 'Recibo X')
        .set(TicketType.CREDIT_NOTE, 'Nota de Crédito')
        .set(TicketType.SALARY, 'Recibo de Sueldo')
    return define(labels.get(type))
}

const paymentCategoryLabel = (category: PaymentCategory) => {
    const labels = new Map<PaymentCategory, string>()
        .set(PaymentCategory.CASH_FUND, 'Caja/Cuenta')
        .set(PaymentCategory.CHECK, 'Cheque')
        .set(PaymentCategory.CREDIT_CARD, 'Tarjeta de Crédito')
    return define(labels.get(category))
}

const paymentOrderStatusLabel = (status: PaymentOrderStatus) => {
    const labels = new Map<PaymentOrderStatus, string>()
        .set(PaymentOrderStatus.EMITTED, 'Emitida')    
        .set(PaymentOrderStatus.PAYED, 'Pagada')
    return define(labels.get(status))
}

const paymentStatusLabel = (status: PaymentStatus) => {
    const labels = new Map<PaymentStatus, string>()
        .set(PaymentStatus.PENDING, 'Pendiente')    
        .set(PaymentStatus.PARTIALLY_PAYED, 'Parcialmente Pagado')
        .set(PaymentStatus.PAYED, 'Pagado')
        .set(PaymentStatus.CANCELLED, 'Cancelado')
    return define(labels.get(status))
}

const invoiceStatusLabel = (status: InvoiceStatus) => {
    const labels = new Map<InvoiceStatus, string>()
        .set(InvoiceStatus.UNINVOICED, 'No Facturado')    
        .set(InvoiceStatus.REQUESTED, 'Factura Solicitada')
        .set(InvoiceStatus.INVOICED, 'Facturado')
    return define(labels.get(status))
}

const invoiceDataPropLabel = (prop: InvoiceDataProp) => {
    const labels = new Map<InvoiceDataProp, string>()
        .set(InvoiceDataProp.COMPANY_LEGAL_NAME, 'Razón Social')
        .set(InvoiceDataProp.COMPANY_TAX_ID, 'CUIT')
        .set(InvoiceDataProp.COMPANY_ADDRESS, 'Dirección')
        .set(InvoiceDataProp.CUSTOMER_LEGAL_NAME, 'Nombre / Razón Social')
        .set(InvoiceDataProp.CUSTOMER_IDENTIFIER, 'DNI / CUIT')
        .set(InvoiceDataProp.CUSTOMER_PHONE, 'Teléfono')
        .set(InvoiceDataProp.SUBTOTAL, 'Subtotal')
        .set(InvoiceDataProp.VAT_AMOUNT, 'IVA')
        .set(InvoiceDataProp.TOTAL, 'Total')
    return define(labels.get(prop))
}

export const Labels = {
    areaLabel,
    userRoleLabel,
    identifierTypeLabel,
    requestStatusLabel,
    adjustmentTypeLabel,
    hoardTypeLabel,
    deliveryStatusLabel,
    lotAttributeLabel,
    periodLabel,
    taxTypeLabel,
    taxBaseLabel,
    cashFundTypeLabel,
    checkTypeLabel,
    checkStatusLabel,
    taxCategoryLabel,
    customerCategoryLabel,
    measureTypeLabel,
    ticketTypeLabel,
    paymentCategoryLabel,
    paymentOrderStatusLabel,
    paymentStatusLabel,
    invoiceStatusLabel,
    invoiceDataPropLabel
}
