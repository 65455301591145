import { useSaveActions } from "../../common"
import { TableName } from "../../../tables"
import { PaymentType } from "../state/types"
import { usePaymentTypeLoaders } from "../loaders/paymentTypeLoaders"

export const usePaymentTypeActions = () => {
    const paymentTypeLoaders = usePaymentTypeLoaders()
    
    const saveActions = useSaveActions()

    const savePaymentType = async (paymentType: PaymentType) => {
        const { save } = saveActions()
        return save(TableName.PAYMENT_TYPES, paymentType) as Promise<PaymentType>
    }

    const removePaymentType = async (id: string) => {
        const { loadAllPaymentTypes } = paymentTypeLoaders()
        const paymentTypes = await loadAllPaymentTypes()
        if (paymentTypes.length > 1) {
            const { remove } = saveActions()
            return remove(TableName.PAYMENT_TYPES, id)
        }
    }

    return {
        savePaymentType,
        removePaymentType
    }
}
