import { Emitter, Event } from "../../../../../features/event-emitter"
import { usePaymentOrderActions } from "../../../actions/paymentOrderActions"

export const useActions = () => {
    const paymentOrderActions = usePaymentOrderActions()

    const submitReceipt = async (
        paymentOrderId: string,
        receiptFile: File
    ) => {
        Emitter.emit(Event.DB_SUBMIT)
        
        const { confirmPaymentOrder } = paymentOrderActions()
        await confirmPaymentOrder(paymentOrderId, receiptFile)
        Emitter.emit(Event.DB_SUBMIT_DONE, false, false)
    }
    
    return {
        submitReceipt
    }
}
