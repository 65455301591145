import { define } from "../../../../utils/typeUtils"
import { ModalId, useUIActions } from "../../../../features/ui"
import { TableName } from "../../../../tables"
import { useDataTypeActions } from "../../actions/dataTypeActions"
import { Emitter, Event } from "../../../event-emitter"

export const useActions = () => {
    const { getRemoveAction, getRemoveValidation } = useDataTypeActions()
    const { openModal, closeModal } = useUIActions()

    const removeDataType = async (
        tableName: TableName,
        id: string
    ) => {
        Emitter.emit(Event.DB_REMOVE)
        
        const removeValidation = getRemoveValidation(tableName)
        const canRemove = !removeValidation || await removeValidation(id)
        if (canRemove) {
            const removeAction = define(getRemoveAction(tableName))
            await removeAction(id)

            Emitter.emit(Event.DB_REMOVE_DONE)
        } else {
            closeModal(ModalId.SPINNER)
            setTimeout(() => openModal(ModalId.MESSAGE), 500)
        }
    }
    
    return {
        removeDataType
    }
}
