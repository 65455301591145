import styles from './uploadInvoiceForm.module.css'
import { Form, FormField, ModalId, useUIActions, FieldType, FileField, Validatable, Spinner } from "../../../../../features/ui"
import { useState } from "react"
import { toList } from "../../../../../utils/listUtils"
import { useActions } from "./actions"
import { TableName } from '../../../../../tables'
import { fileName } from '../../../../../features/files'
import { define } from '../../../../../utils/typeUtils'
import { SaleOperation } from '../../../../sales'

type Props = {
    table: TableName
    operation?: SaleOperation
}

export const UploadInvoiceForm = (props: Props) => {
    const { table, operation } = props
    
    const [invoiceFile, setInvoiceFile] = useState<File>()
    const [invoiceValidation, setInvoiceValidation] = useState<string>()

    const { submitInvoice } = useActions()
    const { toggleModal } = useUIActions()

    if (!operation) return <Spinner />

    const onChangeFile = (value?: File) => {
        setInvoiceValidation(undefined)
        setInvoiceFile(value)
    }

    const fields: FormField[] = [
        {
            name: 'operation',
            type: FieldType.TEXT,
            label: table === TableName.SALE_ORDERS ? 'Venta' : 'Acopio',
            render: () => <>#{operation.code}</>
        },
        {
            name: 'invoiceUrl',
            type: FieldType.FILE,
            label: 'Factura',
            hint: 'Formato: PDF',
            render: () => (
                <Validatable validations={toList(invoiceValidation)} left className={styles.invoiceValidation}>
                    <FileField
                        id="invoiceUrl"
                        fileName={fileName(table, operation.code.toString(), undefined, undefined, 'Factura')}
                        onChange={onChangeFile}
                    />
                </Validatable>
            )
        }
    ]
    
    const validate = (): boolean => {
        if (!invoiceFile) {
            setInvoiceValidation('Debe subir la factura')
            return false
        }
    
        return true
    }

    const onSubmit = () => {
        if (validate()) {
            submitInvoice(table, operation, define(invoiceFile))
        }
    }

    const handleCancel = () => toggleModal(ModalId.UPLOAD_INVOICE)

    return (
        <Form
            formId="upload-invoice-form"
            fields={fields}
            createMode={false}
            updateLabel="Confirmar"
            onSubmit={onSubmit}
            onCancel={handleCancel}
        />
    )
}
