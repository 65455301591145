import { useEffect, useState } from "react"
import { FieldType, FieldValue, FilterField, Filters, O } from "../../../../../features/ui"
import { DataType } from '../../../../../tables'
import { useAdministrationHooks, useAdministrationState } from "../../../hooks/administrationHooks"

type Props = {
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const PaymentTypeFilters = (props: Props) => {
    const { onSearchStart, onSearchDone } = props

    const { paymentTypes } = useAdministrationState()

    const [listLength, setListLength] = useState<number>(-1)
    const [searchText, setSearchText] = useState<string>()

    const { searchPaymentTypes } = useAdministrationHooks()

    useEffect(() => {
        if (paymentTypes.length !== listLength) {
            search(searchText)
        }
        setListLength(paymentTypes.length)
    }, [paymentTypes])

    const search = (searchTextParam?: string) => {
        onSearchStart()
        const paymentTypeList = searchPaymentTypes(paymentTypes, searchTextParam)
        setTimeout(() => onSearchDone(paymentTypeList), 100)
    }

    const handleSearch = (value?: FieldValue) => {
        const newSearchText = value as O<string>
        setSearchText(newSearchText)
        search(newSearchText)
    }

    const searchField: FilterField = {
        name: 'search',
        type: FieldType.TEXT,
        placeholder: 'Buscar por nombre',
        icon: 'search',
        space: 6,
        onChange: handleSearch
    }

    return (
        <Filters
            searchField={searchField}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
