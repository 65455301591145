import { useNavigate } from "react-router-dom"
import { Action, ButtonKind, DetailFieldProps, DetailPanel, FieldType, FieldValue, ModalId, Tab, UIPermission, useGetCrumbs, useUIActions } from "../../../../../features/ui"
import { TableName } from "../../../../../tables"
import { useSystemStateHooks } from "../../../../system"
import { Expense, Ticket } from "../../../state/types"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { define } from "../../../../../utils/typeUtils"
import { Labels } from "../../../../../features/data-types"
import { UploadExpenseTicketModal } from "../upload-expense-ticket-modal/uploadExpenseTicketModal"
import { PaymentStatus } from "../../../../operations"
import { useEffect, useState } from "react"
import { UploadAdditionalTicketModal } from "../../ticket/upload-additional-ticket-modal/uploadAdditionalTicketModal"
import { TicketList } from "../../ticket/ticket-list/ticketList"
import { useTicketActions } from "../../../actions/ticketActions"
import { DownloadTicketModal } from "../../ticket/download-ticket-modal/downloadTicketModal"

export const ExpenseDetail = () => {
  const { dataType } = useRoutesHooks().parseUrl()
  const expense = define(dataType) as Expense

  const [tickets, setTickets] = useState<Ticket[]>([])
  const [currentTicket, setCurrentTicket] = useState<Ticket>()
  
  const { userHasPermission } = useSystemStateHooks()
  const readOnly = !userHasPermission(UIPermission.SAVE_EXPENSES)
  const navigate = useNavigate()

  const { toggleModal } = useUIActions()
  const ticketActions = useTicketActions()

  const crumbs = useGetCrumbs('Gastos', RoutePath.EXPENSES, expense?.name)

  const init = async () => {
    const { fetchTicketsByParentId } = ticketActions()
    const stateTickets = await fetchTicketsByParentId(expense?.id) as Ticket[]
    setTickets(stateTickets)
  }

  useEffect(() => {
    init()
  }, [])

  const fields: DetailFieldProps[] = [
    {
      name: 'code',
      type: FieldType.NUMBER,
      label: 'Código',
      render: (value?: FieldValue) => <>#{value}</>
    },
    { name: 'expenseTypeId', type: FieldType.TABLE, table: TableName.EXPENSE_TYPES, label: 'Tipo' },
    { name: 'date', type: FieldType.DATE, label: 'Fecha' },
    { name: 'branchId', type: FieldType.TABLE, table: TableName.BRANCHES, label: 'Sucursal' },
    {
      name: 'amount',
      type: FieldType.PRICE,
      label: 'Monto'
    },
    {
      name: 'paymentStatus',
      type: FieldType.TEXT,
      label: 'Estado de Pago',
      render: (value?: FieldValue) => <>{Labels.paymentStatusLabel(define(value) as PaymentStatus)}</>
    }
  ]
  if (expense.detail) {
    const detailField = {
      name: 'detail',
      type: FieldType.TEXT,
      label: 'Detalle'
    }
    fields.splice(4, 0, detailField)
  }
  if (expense.supplierId) {
    const supplierField = {
      name: 'supplierId',
      type: FieldType.TABLE,
      table: TableName.SUPPLIERS,
      label: 'Proveedor'
    }
    fields.splice(4, 0, supplierField)
  }
  if (expense.refundEmployeeId) {
    const refundEmployeeField = {
      name: 'refundEmployeeId',
      type: FieldType.TABLE,
      table: TableName.EMPLOYEES,
      label: 'Empleado'
    }
    fields.splice(4 + (expense.supplierId ? 1 : 0), 0, refundEmployeeField)
  }

  const onEdit = () => navigate(RoutePath.EDIT)

  const onUploadTicket = () => {
    if (tickets.length > 0) {
      toggleModal(ModalId.UPLOAD_ADDITIONAL_TICKET)
    } else {
      navigate(`${RoutePath.TICKETS}/${RoutePath.CREATE}`)
    }
  }

  const onRemove = () => toggleModal(ModalId.REMOVE)

  const actions: Action[] = []
  const isPaymentPending = expense.paymentStatus === PaymentStatus.PENDING
  const isEditable = isPaymentPending && tickets.length === 0
  const ticketsTotal = tickets.reduce((sum, ticket) => sum + ticket.total, 0)
  const isInvoiceable = ticketsTotal < expense.amount
  if (!readOnly) {
    if (isEditable) {
      actions.push({ label: 'Editar Gasto', icon: 'pencil-fill', onClick: onEdit })
    }
    if (isInvoiceable) {
      actions.push({ label: 'Factura / Comp.', icon: 'upload', onClick: onUploadTicket })
    }
    if (isEditable) {
      actions.push({ label: 'Eliminar', kind: 'secondary' as ButtonKind, icon: 'trash-fill', onClick: onRemove })
    }
  }

  const tabs: Tab[] = []
  if (tickets.length > 0) {
    tabs.push({
      id: 'tickets',
      title: 'Facturas / Comprobantes',
      content: <TicketList
        tickets={tickets}
        ticketLabel="Factura/Comp."
        onChangeCurrentTicket={setCurrentTicket}
      />
    })
  }

  return (<>
    <DetailPanel
      title={`Gasto: ${expense.name}`}
      tableName={TableName.EXPENSES}
      crumbs={crumbs}
      fields={fields}
      data={expense}
      icon='receipt'
      tabs={tabs}
      readOnly={readOnly}
      actions={actions}
    />
    <DownloadTicketModal
      ticket={currentTicket}
      ticketLabel="Factura / Comprobante"
    />
    <UploadExpenseTicketModal expense={expense} />
    <UploadAdditionalTicketModal />
  </>)
}
