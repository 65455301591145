import { useEffect, useState } from "react"
import { PanelLoader, useGetCrumbs } from "../../../../../features/ui"
import { RoutePath, useRoutesHooks } from "../../../../../features/routes"
import { CashFund } from "../../../state/types"
import { define } from "../../../../../utils/typeUtils"
import { EditCashFundAmountForm } from "./editCashFundAmountForm"
import { useCashFundLoaders } from "../../../loaders/cashFundLoaders"

export const EditCashFundAmountFormLoader = () => {  
  const { dataType, pathParts } = useRoutesHooks().parseUrl()
  const cashFund = define(dataType) as CashFund
  const addMode = pathParts[3] === RoutePath.ADD

  const [ready, setReady] = useState(false)

  const cashFundLoaders = useCashFundLoaders()

  const title = `${addMode ? 'Agregar Dinero a' : 'Retirar Dinero de'} Caja: ${cashFund.name}`

  const populate = async () => {
    const { loadCashFund } = cashFundLoaders()
    cashFund && await loadCashFund(cashFund.id, true)
  }

  useEffect(() => {
    populate().then(() => setReady(true))
  }, [])

  const crumbs = useGetCrumbs('Cajas', RoutePath.CASH_FUNDS, cashFund.name, undefined, addMode ? 'Agregar Dinero' : 'Retirar Dinero')

  return (
    <PanelLoader title={title} crumbs={crumbs} ready={ready}>
      <EditCashFundAmountForm />
    </PanelLoader>
  )
}
