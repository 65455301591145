import { useDataTypesHooks } from '../../../features/data-types'
import { O } from '../../../features/ui'
import { TableName } from '../../../tables'
import { useLoaders } from '../../common'
import { useSystemStateHooks } from '../../system'
import { Expense } from '../state/types'

export const useExpenseLoaders = () => {
    const systemHooks = useSystemStateHooks()
    const { hasLoaded, addToLoaded } = useDataTypesHooks()

    const loaders = useLoaders()

    return (companyId = systemHooks.companyId) => {
        const loadAllExpenses = () => {
            const { loadAll } = loaders(companyId)
            return loadAll(TableName.EXPENSES) as Promise<Expense[]>
        }

        const loadExpense = (id: string) => {
            const { load } = loaders(companyId)
            return load(TableName.EXPENSES, id) as Promise<O<Expense>>
        }

        const loadBranchExpenses = async (branchId: string) => {
            const { loadByParams } = loaders(companyId)
            const expenses = await loadByParams(TableName.EXPENSES, { branchId }, !hasLoaded(TableName.EXPENSES)) as Expense[]
            addToLoaded(TableName.EXPENSES)
            return expenses
        }
        
        return {
            loadAllExpenses,
            loadExpense,
            loadBranchExpenses
        }
    }
}
