import { useEffect, useState } from "react"
import { Action, PanelLoader, UIPermission } from "../../../../features/ui"
import { ListLoaderWrapper } from "../../../../features/data-types"
import { TableName } from "../../../../tables"
import { Product } from "../../state/types"
import { useProductLoaders } from "../../loaders/productLoaders"
import { ProductList } from "./productList"
import { useSystemStateHooks } from "../../../system"
import { RoutePath } from "../../../../features/routes"
import { useNavigate } from "react-router-dom"

const Loader = () => {
    const [products, setProducts] = useState<Product[]>([])
    const [ready, setReady] = useState(false)

    const { userHasPermission } = useSystemStateHooks()
    const navigate = useNavigate()

    const productLoaders = useProductLoaders()

    const populate = async () => {
        const { loadAllProducts } = productLoaders()
        const loadedProducts = await loadAllProducts()
        setProducts(loadedProducts)
    }

    useEffect(() => {
        populate().then(() => setReady(true))
    }, [])

    const onUpdatePrices = () => navigate(RoutePath.PRICES)

    const headerActions: Action[] = userHasPermission(UIPermission.SAVE_PRODUCT_PRICES) ? [
        { label: 'Actualizar Precios', kind: 'tertiary', onClick: onUpdatePrices }
    ]: []

    return (
        <PanelLoader
            title="Productos"
            actions={headerActions}
            ready={ready}
            wide
        >
            <ProductList products={products} />
        </PanelLoader>
    )
}

export const ProductListLoader = () => (
    <ListLoaderWrapper table={TableName.PRODUCTS}>
        <Loader />
    </ListLoaderWrapper>
)
