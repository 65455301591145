import styles from './downloadSaleFilesModal.module.css'
import { useMemo } from 'react'
import { DownloadFilesModal, fileName, fileNameFromUrl, useFileActions, usePDFActions } from '../../../../features/files'
import { TableName } from '../../../../tables'
import { define } from '../../../../utils/typeUtils'
import { InvoiceStatus } from '../../../administration'
import { HoardOrder, SaleOperation, SaleOrder } from '../../state/types'
import { HoardPDF } from '../hoard/hoard-pdf/hoardPDF'
import { SalePDF } from '../sale/sale-pdf/salePDF'
import { PaymentStatus } from '../../../operations'

type Props = {
    table: TableName
    operation?: SaleOperation
}

export const DownloadSaleFilesModal = (props: Props) => {
    const { table, operation } = props
    const isSale = table === TableName.SALE_ORDERS

    const { generatePDF } = usePDFActions()
    const { downloadFile } = useFileActions()

    const label = isSale ? 'Venta' : 'Acopio'
    const pdfId = 'operation-pdf'
    const operationFileName = useMemo(() => operation && fileName(table, operation.code.toString()), [operation])

    const isPayed = operation?.paymentStatus === PaymentStatus.PAYED
    const isInvoiced = operation?.invoiceStatus === InvoiceStatus.INVOICED

    const filesData = []
    if (operationFileName) {
        filesData.push({
            id: 'operation',
            label: `Orden de ${label}`,
            fileName: operationFileName,
            onDownload: () => generatePDF(pdfId, operationFileName)
        })
    }
    if (isPayed && operation?.receiptUrl) {
        filesData.push({
            id: 'receipt',
            label: `Comprobante de Pago`,
            fileName: define(fileNameFromUrl(operation.receiptUrl)),
            onDownload: () => downloadFile(define(operation.receiptUrl))
        })
    }
    if (isInvoiced && operation?.invoiceUrl) {
        filesData.push({
            id: 'invoice',
            label: `Factura de ${label}`,
            fileName: define(fileNameFromUrl(operation.invoiceUrl)),
            onDownload: () => downloadFile(define(operation.invoiceUrl))
        })
    }

    const renderHiddenPDF = () => operation ? (
        <div className={styles.hidden}>
            {isSale ?
                <SalePDF id={pdfId} sale={operation as SaleOrder} /> :
                <HoardPDF id={pdfId} hoard={operation as HoardOrder} />
            }
        </div>
    ) : <></>

    return (
        <DownloadFilesModal
            operationId={operation?.id}
            filesData={filesData}
        >
            {renderHiddenPDF()}
        </DownloadFilesModal>
    )
}
