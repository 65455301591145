import { O } from '../../../features/ui'
import { useStateHooks } from '../../../state'
import { TableName } from '../../../tables'
import { firstItem } from '../../../utils/listUtils'
import { useLoaders } from '../../common'
import { useSystemStateHooks } from '../../system'
import { Tax } from '../state/types'

export const useTaxLoaders = () => {
    const systemHooks = useSystemStateHooks()
    const { getList, find, findByParams } = useStateHooks()
    
    const loaders = useLoaders()

    return (companyId = systemHooks.companyId) => {
        const findAllTaxes = () => getList(TableName.TAXES) as Tax[]

        const findTax = (id?: string) => find(TableName.TAXES, id) as O<Tax>

        const findTaxByCode = (code: string) => firstItem(findByParams(TableName.TAXES, { code })) as O<Tax>

        const loadAllTaxes = () => {
            const { loadAll } = loaders(companyId)
            return loadAll(TableName.TAXES) as Promise<Tax[]>
        }

        const loadTax = (id: string) => {
            const { load } = loaders(companyId)
            return load(TableName.TAXES, id) as Promise<O<Tax>>
        }

        return {
            findAllTaxes,
            findTax,
            findTaxByCode,
            loadAllTaxes,
            loadTax
        }
    }
}
