import { newId } from "../utils/dataTypesUtils"
import { PaymentType, PaymentCategory } from "../../../modules/administration"

export const paymentTypes = (companyId: string): PaymentType[] => [
    { companyId, id: newId(), name: 'Transferencia', category: PaymentCategory.CASH_FUND, yield: 0 },
    { companyId, id: newId(), name: 'Tarjeta de Débito', category: PaymentCategory.CASH_FUND, yield: 0 },
    { companyId, id: newId(), name: 'Efectivo', category: PaymentCategory.CASH_FUND, yield: -5 },
    { companyId, id: newId(), name: 'Tarjeta de Crédito - 1 cuota', category: PaymentCategory.CREDIT_CARD, yield: 0 },
    { companyId, id: newId(), name: 'Tarjeta de Crédito - 3 cuotas', category: PaymentCategory.CREDIT_CARD, yield: 5 },
    { companyId, id: newId(), name: 'Tarjeta de Crédito - 6 cuotas', category: PaymentCategory.CREDIT_CARD, yield: 10 },
    { companyId, id: newId(), name: 'Tarjeta de Crédito - 9 cuotas', category: PaymentCategory.CREDIT_CARD, yield: 15 },
    { companyId, id: newId(), name: 'Tarjeta de Crédito - 12 cuotas', category: PaymentCategory.CREDIT_CARD, yield: 20 },
    { companyId, id: newId(), name: 'Cheque', category: PaymentCategory.CHECK, yield: 0 },
    { companyId, id: newId(), name: 'E-Cheq', category: PaymentCategory.CHECK, yield: 0 }
]
