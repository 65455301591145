import { TableName } from "../../../../tables"
import { useEffectAfterMount } from "../../../ui"
import { cloneElement, useState } from "react"
import { useStateHooks } from "../../../../state"

type Props = {
    table: TableName
    children: JSX.Element
}

export const ListLoaderWrapper = (props: Props) => {
    const { table, children } = props
    
    const [key, setKey] = useState(0)
    
    const { getList } = useStateHooks()
    const length = getList(table).length

    useEffectAfterMount(() => {
        setTimeout(() => setKey((prevKey) => prevKey + 1), 1000)
    }, [length])

    return cloneElement(children, { key })
}
