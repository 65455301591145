import { useState } from "react"
import { PanelWrapper, FieldType, UIPermission, Action, Column, FieldValue, Data, SpinnerDiv, useUIActions, ModalId, StaticValue, Badge, ButtonKind } from "../../../../../features/ui"
import { DataType, TableName } from "../../../../../tables"
import { useSystemStateHooks } from "../../../../system"
import { HoardOrder, HoardType } from "../../../state/types"
import { define, toNumber } from "../../../../../utils/typeUtils"
import { DataTypeList, Labels } from "../../../../../features/data-types"
import { DeliveryStatus, PaymentStatus } from "../../../../operations"
import { HoardFilters } from "./hoardFilters"
import { RoutePath } from "../../../../../features/routes"
import { useNavigate } from "react-router-dom"
import { useFind } from "../../../../../state/reducers/hooks"
import { InvoiceStatus } from "../../../../administration"
import { DownloadSaleFilesModal } from "../../download-sale-files-modal/downloadSaleFilesModal"

export const HoardList = () => {
    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
    const [currentHoard, setCurrentHoard] = useState<HoardOrder>()

    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_HOARDS)
    const hasInvoicePermission = userHasPermission(UIPermission.INVOICE_HOARDS)
    const navigate = useNavigate()
    const find = useFind()

    const { openModal } = useUIActions()

    const findHoard = (code?: string) => define(find(TableName.HOARD_ORDERS, toNumber(code), 'code') as HoardOrder)

    const setRowsState = (hoards: Data[]) => {
        setRows(hoards)
        setAsyncDataLoaded(true)
    }

    const columns: Column[] = [
        { name: 'code', type: FieldType.NUMBER, label: '#', render: (value?: FieldValue) => `#${value}` },
        {
            name: 'type',
            type: FieldType.TEXT,
            label: 'Tipo',
            render: (value?: FieldValue) => Labels.hoardTypeLabel(define(value) as HoardType, true)
        },
        { name: 'creationDate', type: FieldType.DATE, label: 'Fecha de Creación' },
        { name: 'dueDate', type: FieldType.DATE, label: 'Fecha Límite' },
        {
            name: 'value',
            type: FieldType.PRICE,
            label: 'Acopio Total',
            render: (value?: FieldValue) => value ?
                <StaticValue type={FieldType.PRICE} value={value} /> :
                <Badge label="Ver en detalle" />
        },
        { name: 'sellerId', type: FieldType.TABLE, table: TableName.EMPLOYEES, label: 'Vendedor' },
        { name: 'customerId', type: FieldType.TABLE, table: TableName.CUSTOMERS, label: 'Cliente' },
        {
            name: 'deliveryStatus',
            type: FieldType.TEXT,
            label: 'Estado de Entrega',
            render: (value?: FieldValue) => Labels.deliveryStatusLabel(define(value) as DeliveryStatus)
        },
        {
            name: 'paymentStatus',
            type: FieldType.TEXT,
            label: 'Estado de Pago',
            render: (value?: FieldValue) => Labels.paymentStatusLabel(define(value) as PaymentStatus)
        },
        {
            name: 'invoiceStatus',
            type: FieldType.TEXT,
            label: 'Facturación',
            render: (value?: FieldValue) => Labels.invoiceStatusLabel(define(value) as InvoiceStatus)
        }
    ]

    const onView = (code?: string) => {
        const hoard = findHoard(code)
        if (hoard) {
            navigate(hoard.id)
        }
    }

    const onDownloadFiles = (code?: string) => {
        setCurrentHoard(findHoard(code))
        openModal(ModalId.DOWNLOAD_FILES)
    }

    const onEdit = (code?: string) => {
        const hoard = findHoard(code)
        navigate(`${hoard.id}/${RoutePath.EDIT}`)
    }

    const onArchive = (code?: string) => {
        findHoard(code)
    }

    const onConfirmPayment = (code?: string) => {
        const hoard = findHoard(code)
        if (hoard) {
            navigate(hoard.id)
            setTimeout(() => openModal(ModalId.CONFIRM_SALE_PAYMENT_FORM), 500)
        }
    }

    const onInvoice = (code?: string) => {
        const hoard = findHoard(code)
        if (hoard) {
            navigate(hoard.id)
            setTimeout(() => openModal(ModalId.INVOICE), 500)
        }
    }

    const actions = (code?: string) => {
        const actionList: Action[] = [
            { icon: 'eye-fill', kind: 'tertiary', tooltip: 'Ver Detalle', onClick: onView },
            { icon: 'download', kind: 'tertiary', tooltip: 'Descargar Documentos', narrowTooltip: true, onClick: onDownloadFiles }
        ]

        const hoard = findHoard(code)
        const isInvoiced = hoard.invoiceStatus === InvoiceStatus.INVOICED
        const isInvoiceable = hoard.invoiceStatus === InvoiceStatus.UNINVOICED
        const isEditable = hoard.deliveryStatus === DeliveryStatus.PENDING && hoard.paymentStatus === PaymentStatus.PENDING && isInvoiceable
        const isDelivered = hoard.deliveryStatus === DeliveryStatus.DELIVERED
        const isPayed = hoard.paymentStatus === PaymentStatus.PAYED

        if (!readOnly) {
            if (isEditable) {
                actionList.push({ icon: 'pencil-fill', tooltip: 'Editar', onClick: onEdit })
            } else if (isDelivered && isPayed && isInvoiced) {
                actionList.push({ icon: 'archive', kind: 'secondary' as ButtonKind, tooltip: 'Archivar', onClick: onArchive })
            }
            if (!isPayed) {
                actionList.push({ icon: 'check-lg', tooltip: 'Confirmar Pago', onClick: onConfirmPayment })
            }
        }
        if (hasInvoicePermission && isInvoiceable) {
            actionList.push({ icon: 'file-earmark-check', tooltip: 'Facturar', kind: 'quinary', onClick: onInvoice })
        }

        return actionList
    }

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRowsState(dataTypes as Data[])
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <PanelWrapper title="Acopios" wide>
            <HoardFilters onSearchStart={onSearchStart} onSearchDone={onSearchDone}/>
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    rowId="code"
                    currentDataTypeParam={currentHoard}
                    actions={actions}
                    readOnly={readOnly}
                />
            )}
            <DownloadSaleFilesModal
                table={TableName.HOARD_ORDERS}
                operation={currentHoard}
            />
        </PanelWrapper>
    )
}
