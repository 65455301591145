import { normalize, round } from "../../../utils/typeUtils"
import { Tax, TaxBase, TaxType } from "../state/types"
import { Labels } from "../../../features/data-types"
import { DataType } from "../../../tables"
import { useFiltersHooks } from "../../../features/ui"
import { useAdministrationState } from "./administrationHooks"

export const useTaxHooks = () => {
    const { taxes } = useAdministrationState()
    const { search } = useFiltersHooks()

    const getSaleTaxes = () => taxes.filter(tax => tax.type === TaxType.SALE_TAX)

    const getTotalSaleTax = () => getSaleTaxes().reduce((sum, tax) => sum + tax.yield, 0)
    
    const searchTaxes = (
        taxList: Tax[],
        text = '',
        showAll = false
    ): Tax[] => {
        const valuesFn = (taxDT: DataType) => {
            const { name, type, code, legalCode } = taxDT as Tax
            return [
                normalize(name),
                normalize(Labels.taxTypeLabel(type)),
                normalize(code),
                normalize(legalCode)
            ]
        }
        const sortFn = (taxDT_A: DataType, taxDT_B: DataType) => 
            (taxDT_A as Tax).name.localeCompare((taxDT_B as Tax).name)

        return search(taxList, valuesFn, sortFn, text, showAll) as Tax[]
    }

    const calculateTaxBaseAmount = (
        tax: Tax,
        total: number,
        vatAmount: number
    ) => {
        return tax.taxBase === TaxBase.PRICE_WITH_VAT ? total : (tax.taxBase === TaxBase.VAT ? vatAmount : total - vatAmount)
    }
    
    const calculateTaxAmount = (
        tax: Tax,
        total: number,
        vatAmount: number
    ) => {
        return round(calculateTaxBaseAmount(tax, total, vatAmount) * tax.yield / 100)
    }

    return {
        getTotalSaleTax,
        searchTaxes,
        calculateTaxBaseAmount,
        calculateTaxAmount
    }
}
