import { RoutePath } from "../../../features/routes"
import { MenuItem, useFiltersHooks } from "../../../features/ui"
import { useFind } from "../../../state/reducers/hooks"
import { DataType, TableName } from "../../../tables/types/types"
import { define, normalize } from "../../../utils/typeUtils"
import { Employee, InvoiceStatus } from "../../administration"
import { Customer, SaleOperation } from "../../sales"

export const useConstrufyAdminHooks = () => {
    const { search } = useFiltersHooks()
    const find = useFind()

    const getMenu = (): MenuItem[] => {
        return [
            {
                id: 'invoices',
                label: 'Facturas ARCA',
                icon: 'file-earmark-check',
                path: RoutePath.INVOICES,
            }
        ]
    }

    const searchSalesAndHoards = (
        list: DataType[],
        text = '',
        showAll = false
    ): DataType[] => {
        const valuesFn = (dataType: DataType) => {
            const { code, sellerId, customerId } = dataType as SaleOperation
            const seller = define(find(TableName.EMPLOYEES, sellerId)) as Employee
            const customer = define(find(TableName.CUSTOMERS, customerId)) as Customer
            return [
                code.toString(),
                normalize(seller.name),
                normalize(customer.name)
            ]
        }
        const sortFn = (dataType_A: DataType, dataType_B: DataType) => {
            const statusPriorities = new Map<InvoiceStatus, number>()
                .set(InvoiceStatus.REQUESTED, 1)
                .set(InvoiceStatus.INVOICED, 2)
                .set(InvoiceStatus.UNINVOICED, 3)
            const status_A = (dataType_A as SaleOperation).invoiceStatus
            const status_B = (dataType_B as SaleOperation).invoiceStatus
            const statusOrder = (statusPriorities.get(status_A) || 0) - (statusPriorities.get(status_B) || 0)
            return statusOrder || (dataType_B as SaleOperation).code - (dataType_A as SaleOperation).code
        }

        return search(list, valuesFn, sortFn, text, showAll)
    }

    return {
        getMenu,
        searchSalesAndHoards
    }
}
