import styles from './paymentOrderTicketsInfo.module.css'
import { useDataTypesHooks, useDescriptorHooks } from '../../../../../features/data-types'
import { Async, FieldType, Link } from '../../../../../features/ui'
import { TableName } from '../../../../../tables'
import { define } from '../../../../../utils/typeUtils'
import { Ticket } from '../../../state/types'
import { useTicketHooks } from '../../../hooks/ticketHooks'
import { useNavigate } from 'react-router-dom'
import { RoutePath, useRoutesHooks } from '../../../../../features/routes'

type Props = {
    tickets: Ticket[]
    amounts: Map<string, number>
    showLinks?: boolean
}

export const PaymentOrderTicketsInfo = (props: Props) => {
    const { tickets, amounts, showLinks = true } = props

    const { findParent, isPurchase, isSalary } = useTicketHooks()
    const descriptorHooks = useDescriptorHooks()
    const { formatValue } = useDataTypesHooks()
    const { getRoutePath } = useRoutesHooks()
    const navigate = useNavigate()
    
    const goToParent = (tablePath: RoutePath, parentId: string) => () => {
        navigate(getRoutePath(tablePath, parentId))
    }

    const renderTicket = (ticket: Ticket, index: number) => {
        return <Async
            key={index}
            loadFn={async () => {
                const code = descriptorHooks.ticket.code(ticket)
                const table = ticket.parentTable as TableName
                const isPurchaseTicket = isPurchase(table)
                const isSalaryTicket = isSalary(table, ticket.parentId)
                const parent = define(findParent(table, ticket.parentId))

                const parentLabel = isPurchaseTicket ? 'Compra' : (isSalaryTicket ? 'Sueldo' : 'Gasto')
                const parentInfo = `${parentLabel} ${await descriptorHooks.dataType2(table, parent)}`
                const icon = isPurchaseTicket ? 'cart3' : 'receipt'
                const tablePath = isPurchaseTicket ? RoutePath.PURCHASES : (isSalaryTicket ? RoutePath.SALARIES : RoutePath.EXPENSES)

                const dueDate = formatValue(new Date(ticket.dueDate), FieldType.DATE)
                const ticketAmount = formatValue(amounts.get(ticket.id), FieldType.PRICE)

                return (
                    <div key={index} className={styles.ticketContainer}>
                        {showLinks ? <Link
                            text={parentInfo}
                            icon={icon}
                            kind="primary"
                            onClick={goToParent(tablePath, parent.id)}
                        /> : parentInfo}
                        <> - Factura {code} - Vto.: {dueDate} - {ticketAmount}</>
                    </div>
                )
            }}
        />
    }

    return (
        <div className={styles.container}>
            {tickets
                .sort((ticket_A, ticket_B) => ticket_A.dueDate.localeCompare(ticket_B.dueDate))
                .map(renderTicket)
            }
        </div>
    )
}
