import { O } from '../../../features/ui'
import { TableName } from '../../../tables'
import { useLoaders } from '../../common'
import { useSystemStateHooks } from '../../system'
import { Supplier } from '../state/types'

export const useSupplierLoaders = () => {
    const systemHooks = useSystemStateHooks()
    const loaders = useLoaders()

    return (companyId = systemHooks.companyId) => {
        const loadAllSuppliers = () => {
            const { loadAll } = loaders(companyId)
            return loadAll(TableName.SUPPLIERS) as Promise<Supplier[]>
        }

        const loadSupplier = (id: string) => {
            const { load } = loaders(companyId)
            return load(TableName.SUPPLIERS, id) as Promise<O<Supplier>>
        }
        
        return {
            loadAllSuppliers,
            loadSupplier
        }
    }
}
