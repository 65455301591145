import { HoardOrder, HoardItem } from "../../../state/types"
import { HoardPDFPage } from './hoardPDFPage'
import { useEffect, useState } from "react"
import { ItemsPDFWrapper } from '../../../../../features/ui'
import { DataType } from '../../../../../tables'
import { useHoardLoaders } from "../../../loaders/hoardLoaders"

type Props = {
    id: string
    hoard: HoardOrder
    previewMode?: boolean
}

export const HoardPDF = (props: Props) => {
    const { id, hoard, previewMode } = props

    const [hoardItems, setHoardItems] = useState<HoardItem[]>([])

    const { loadHoardItems } = useHoardLoaders()

    useEffect(() => {
        loadHoardItems(hoard.id).then(setHoardItems)
    }, [hoard])

    const renderPDFPage = (
        pageIndex: number,
        dataType: DataType,
        items: DataType[],
        pagesAmount: number
    ) => (
        <HoardPDFPage
            pageIndex={pageIndex}
            hoard={dataType as HoardOrder}
            hoardItems={items as HoardItem[]}
            pagesAmount={pagesAmount}
        />
    )

    return (
        <ItemsPDFWrapper
            id={id}
            dataType={hoard}
            items={hoardItems}
            previewMode={previewMode}
            renderPDFPage={renderPDFPage}
        />
    )
}
