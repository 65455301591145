import { Emitter, Event } from "../../../../features/event-emitter"
import { Data, O } from "../../../../features/ui"
import { useProductsActions } from "../../actions/productsActions"
import { Category } from "../../state/types"

export const useActions = () => {
    const productsActions = useProductsActions()

    const submitCategory = async (
        categoryData: Data,
        createMode: boolean,
        imageFile?: File
    ) => {
        Emitter.emit(Event.DB_SUBMIT)

        const { companyId, id, name, imageUrl } = categoryData
        const category: Category = {
            companyId: companyId as string,
            id: id as string,
            name: name as string,
            imageUrl: imageUrl as O<string>
        }
        
        await productsActions().saveCategory(category, imageFile)
        Emitter.emit(Event.DB_SUBMIT_DONE, createMode)
    }
    
    return { submitCategory }
}
