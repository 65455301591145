import { DataType } from '../../../../tables'
import { useStockHooks } from "../../hooks/stockHooks"
import { FieldType, FieldValue, FilterField, Filters, O } from "../../../../features/ui"
import { useEffect, useState } from 'react'
import { useManagerState } from '../../../manager'

type Props = {
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const StockFilters = (props: Props) => {
    const { onSearchStart, onSearchDone } = props

    const { currentBranch } = useManagerState()

    const [searchText, setSearchText] = useState<string>()

    const { getCurrentBranchStock, searchStockList } = useStockHooks()
    
    useEffect(() => search(searchText), [currentBranch])

    const search = (searchTextParam?: string) => {
        onSearchStart()
        const stockList = searchStockList(getCurrentBranchStock(), searchTextParam)
        setTimeout(() => onSearchDone(stockList), 100)
    }

    const handleSearch = (value?: FieldValue) => {
        const newSearchText = value as O<string>
        setSearchText(newSearchText)
        search(newSearchText)
    }

    const searchField: FilterField = {
        name: 'search',
        type: FieldType.TEXT,
        placeholder: 'Buscar producto por nombre, código o proveedor',
        icon: 'search',
        onChange: handleSearch
    }

    return (
        <Filters
            searchField={searchField}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
