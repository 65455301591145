import { DownloadFileModal, ModalId } from '../../../../features/ui'
import { DataType } from '../../../../tables'
import { PaymentOrder } from '../../../administration'
import { SaleOperation } from '../../../sales'

type Props = {
    operation?: SaleOperation | PaymentOrder
}

export const DownloadReceiptModal = (props: Props) => {
    const { operation } = props

    return (
        <DownloadFileModal
            modalId={ModalId.DOWNLOAD_RECEIPT}
            dataType={operation}
            fileProp={'receiptUrl' as keyof DataType}
            title="Descargar Comprobante"
        />
    )
}
