import { useRef, useState } from "react"
import { Button } from "../button/button"
import { FileType } from "../file-tile/types"

type Props = {
    id: string
    fileTypes: FileType[]
    onChange: (value?: File) => void
    onRemove?: () => void
}

export const UploadButton = (props: Props) => {
    const { id, fileTypes, onChange, onRemove } = props

    const inputRef = useRef<HTMLInputElement | null>(null)
    const [uploadRef, setUploadRef] = useState<HTMLLabelElement | null>()

    const toAccept = (types: FileType[]) => {
        const map = new Map<FileType, string>()
            .set(FileType.PDF, 'application/pdf')
            .set(FileType.JPEG, 'image/jpeg')
            .set(FileType.JPG, 'image/jpg')
            .set(FileType.PNG, 'image/png')
        return types.length > 0 ? types.map(type => map.get(type)).join(',') : '*'
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (files) {
            const file = files[0]
            onChange(file)
        }
    }

    const handleClickUpload = () => uploadRef?.click()

    const clearInput = () => {
        if (inputRef.current) {
            inputRef.current.value = ''
            onRemove && onRemove()
        }
    }

    return (<>
        <input
            ref={inputRef}
            type="file"
            id={id}
            accept={toAccept(fileTypes)}
            hidden
            onChange={handleFileChange}
        />
        <label htmlFor={id} ref={ref => setUploadRef(ref)}>
            <Button
                icon='upload'
                tooltip="Seleccionar Archivo"
                rightMargin
                onClick={handleClickUpload}
            />
        </label>
        {onRemove && (
            <Button
                icon='trash-fill'
                kind='secondary'
                tooltip='Eliminar'
                disabled={!inputRef.current?.value}
                rightMargin
                onClick={clearInput}
            />
        )}
    </>)
}
