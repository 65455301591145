import { useEffect, useState } from 'react'
import { DataType } from '../../../../../tables'
import { Expense } from '../../../state/types'
import { useAdministrationHooks } from '../../../hooks/administrationHooks'
import { FieldType, FieldValue, FilterField, Filters, O, option } from '../../../../../features/ui'
import { useManagerState } from '../../../../manager'
import { define } from '../../../../../utils/typeUtils'
import { Labels } from '../../../../../features/data-types'
import { PaymentStatus } from '../../../../operations'
import { useExpenseHooks } from '../../../hooks/expenseHooks'

type Props = {
    salaries: Expense[]
    onSearchStart: () => void
    onSearchDone: (dataTypes: DataType[]) => void
}

export const SalaryFilters = (props: Props) => {
    const { salaries, onSearchStart, onSearchDone } = props

    const [searchText, setSearchText] = useState<string>()
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>()
    const [dateFrom, setDateFrom] = useState<Date>()
    const [dateTo, setDateTo] = useState<Date>()

    const { currentBranch } = useManagerState()
    const currentBranchId = define(currentBranch).id

    const { getSalaryExpenseTypeId } = useAdministrationHooks()
    const { searchExpenses } = useExpenseHooks()

    const isSalaryExpense = (expense: Expense) => expense.expenseTypeId === getSalaryExpenseTypeId()

    useEffect(() => {
        search(searchText, paymentStatus, dateFrom, dateTo)
    }, [salaries])

    const search = (
        searchTextParam?: string,
        paymentStatusParam?: string,
        dateFromParam?: Date,
        dateToParam?: Date
    ) => {
        onSearchStart()
        let expenseList = salaries.filter(expense => expense.branchId === currentBranchId).filter(isSalaryExpense)
        expenseList = expenseList.filter(expense => !paymentStatusParam || expense.paymentStatus === paymentStatusParam)
        expenseList = dateFromParam ? expenseList.filter(expense => new Date(expense.date) >= dateFromParam) : expenseList
        expenseList = dateToParam ? expenseList.filter(expense => new Date(expense.date) <= dateToParam) : expenseList
        expenseList = searchExpenses(expenseList, searchTextParam)
        setTimeout(() => onSearchDone(expenseList), 100)
    }

    const handleSearch = (value?: FieldValue) => {
        const newSearchText = value as O<string>
        setSearchText(newSearchText)
        search(newSearchText, paymentStatus, dateFrom, dateTo)
    }

    const onChangePaymentStatus = (value?: FieldValue) => {
        const newPaymentStatus = value as O<PaymentStatus>
        if (newPaymentStatus !== paymentStatus) {
            setPaymentStatus(newPaymentStatus)
            search(searchText, newPaymentStatus, dateFrom, dateTo)
        }
    }

    const onChangeDateFrom = (value?: FieldValue) => {
        const newDateFrom = value as O<Date>
        newDateFrom?.setHours(11, 59, 59)
        if (newDateFrom !== dateFrom) {
            setDateFrom(newDateFrom)
            search(searchText, paymentStatus, newDateFrom, dateTo)
        }
    }
    
    const onChangeDateTo = (value?: FieldValue) => {
        const newDateTo = value as O<Date>
        newDateTo?.setHours(11, 59, 59)
        if (newDateTo !== dateTo) {
            setDateTo(newDateTo)
            search(searchText, paymentStatus, dateFrom, newDateTo)
        }
    }

    const searchField: FilterField = {
        name: 'search',
        type: FieldType.TEXT,
        placeholder: 'Buscar por código o nombre',
        icon: 'search',
        onChange: handleSearch
    }

    const filterFields: FilterField[] = [
        {
            name: 'paymentStatus',
            type: FieldType.SELECT,
            options: Object.values(PaymentStatus).map(status => option(status, Labels.paymentStatusLabel(status))),
            label: 'Estado',
            space: 3,
            onChange: onChangePaymentStatus
        },
        {
            name: 'dateFrom',
            type: FieldType.DATE,
            label: 'Fecha: Desde',
            space: 2,
            onChange: onChangeDateFrom
        },
        {
            name: 'dateTo',
            type: FieldType.DATE,
            label: 'Fecha: Hasta',
            space: 2,
            onChange: onChangeDateTo
        }
    ]

    return (
        <Filters
            searchField={searchField}
            fields={filterFields}
            onSearchStart={onSearchStart}
            onSearchDone={onSearchDone}
        />
    )
}
