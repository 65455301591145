import { O } from '../../../features/ui'
import { TableName } from '../../../tables'
import { useLoaders } from '../../common'
import { useSystemStateHooks } from '../../system'
import { SaleItem, SaleOrder } from '../state/types'

export const useSaleLoaders = () => {
    const systemHooks = useSystemStateHooks()

    const loaders = useLoaders()

    return (companyId = systemHooks.companyId) => {
        const loadAllSales = () => {
            const { loadAll } = loaders(companyId)
            return loadAll(TableName.SALE_ORDERS) as Promise<SaleOrder[]>
        }

        const loadSale = (id: string) => {
            const { load } = loaders(companyId)
            return load(TableName.SALE_ORDERS, id) as Promise<O<SaleOrder>>
        }

        const loadSaleItems = (saleId: string) => {
            const { loadByParams } = loaders(companyId)
            return loadByParams(TableName.SALE_ITEMS, { saleId }) as Promise<SaleItem[]>
        }

        return {
            loadAllSales,
            loadSale,
            loadSaleItems
        }
    }
}
