import { newId } from "../utils/dataTypesUtils"
import { Category } from "../../../modules/products"

export const categories = (companyId: string): Category[] => [
    { companyId, id: newId(), name: 'Ladrillo' },
    { companyId, id: newId(), name: 'Espejos' },
    { companyId, id: newId(), name: 'Hierro (varillas, mallas, perfiles)' },
    { companyId, id: newId(), name: 'Ladrillo de Vidrio' },
    { companyId, id: newId(), name: 'Sanitarios' },
    { companyId, id: newId(), name: 'Tornillería' },
    { companyId, id: newId(), name: 'Caños (PVC, hierro, cobre, acero, aluminio)' },
    { companyId, id: newId(), name: 'Pisos y Revestimientos' },
    { companyId, id: newId(), name: 'Termotanques' },
    { companyId, id: newId(), name: 'Perfiles (aluminio, PVC, fibra)' },
    { companyId, id: newId(), name: 'Alambre' },
    { companyId, id: newId(), name: 'Cascote' },
    { companyId, id: newId(), name: 'Madera (tablones, vigas, machimbre)' },
    { companyId, id: newId(), name: 'Muebles de Cocina' },
    { companyId, id: newId(), name: 'Perfiles de Madera (Zócalos)' },
    { companyId, id: newId(), name: 'Piedra' },
    { companyId, id: newId(), name: 'Mamparas' },
    { companyId, id: newId(), name: 'Clavos' },
    { companyId, id: newId(), name: 'Bachas Cocina' },
    { companyId, id: newId(), name: 'Chapa' },
    { companyId, id: newId(), name: 'Pintura' },
    { companyId, id: newId(), name: 'Vigas' },
    { companyId, id: newId(), name: 'Bachas Baño' },
    { companyId, id: newId(), name: 'Grifería' },
    { companyId, id: newId(), name: 'Hormigón' },
    { companyId, id: newId(), name: 'Cal' },
    { companyId, id: newId(), name: 'Aislantes térmicos y acústicos' },
    { companyId, id: newId(), name: 'Impermeabilizante' },
    { companyId, id: newId(), name: 'Fibra de Vidrio' },
    { companyId, id: newId(), name: 'Tejas' },
    { companyId, id: newId(), name: 'Vidrios' },
    { companyId, id: newId(), name: 'Placas' },
    { companyId, id: newId(), name: 'Acero (varillas, mallas, perfiles)' },
    { companyId, id: newId(), name: 'Revoque' },
    { companyId, id: newId(), name: 'Alambrillas' },
    { companyId, id: newId(), name: 'Herramientas Colocación' },
    { companyId, id: newId(), name: 'Aberturas' },
    { companyId, id: newId(), name: 'Arena' },
    { companyId, id: newId(), name: 'Adhesivos y Selladores' },
    { companyId, id: newId(), name: 'Caños de Cemento' },
    { companyId, id: newId(), name: 'Muebles de Baño (Vanitory)' },
    { companyId, id: newId(), name: 'Yeso' },
    { companyId, id: newId(), name: 'Mallas (aluminio, PVC, plástico, fibra)' },
    { companyId, id: newId(), name: 'Tanques' },
    { companyId, id: newId(), name: 'Accesorios Baño' },
    { companyId, id: newId(), name: 'Parrillas' },
    { companyId, id: newId(), name: 'Cemento' },
    { companyId, id: newId(), name: 'Bañeras y Platos de Ducha' }
]
