import { useFindMultipleByParams } from "../../../state/reducers/hooks"
import { TableName } from "../../../tables"
import { HoardItem, SaleItem } from "../../sales"
import { PurchaseItem } from "../../purchases"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import * as actionCreators from "../state/actionCreators"
import { State } from "../../../state"
import { OperationsState } from "../state/reducer"
import { OrderDelivery } from "../state/types"

export const useOperationsState = (): OperationsState => useSelector((state: State) => state.operationsReducer)

export const useOperationsStateActions = () => bindActionCreators(actionCreators, useDispatch())

export const useOperationsHooks = () => {
    const findMultipleByParams = useFindMultipleByParams()

    const getPendingQuantity = (orderItem: HoardItem | PurchaseItem | SaleItem, deliveries: OrderDelivery[]) => {
        const isHoardItem = orderItem.hasOwnProperty('hoardId')
        const isPurchaseItem = orderItem.hasOwnProperty('purchaseId')
        const isSaleItem = orderItem.hasOwnProperty('saleId')
        const params = {
            ...(isHoardItem && { orderId: (orderItem as HoardItem).hoardId }),
            ...(isPurchaseItem && { orderId: (orderItem as PurchaseItem).purchaseId }),
            ...(isSaleItem && { orderId: (orderItem as SaleItem).saleId })
        }
        const itemDeliveries = findMultipleByParams(TableName.ORDER_DELIVERIES, params, deliveries) as OrderDelivery[]
        const delivered = itemDeliveries.map(delivery =>
            delivery.deliveredItems.find(item => item.itemId === orderItem.id)?.delivered || 0
        )
        const deliveredQuantity = delivered.reduce((sum, delivered) => sum + delivered, 0)
        
        return orderItem.quantity - deliveredQuantity
    }

    return {
        getPendingQuantity
    }
}
