import { useDefaultModuleActions } from "../../common"
import { Data, RemoveChildrenFn, SaveChildrenFn, newId, useChildrenActions } from "../../../features/data-types"
import { DynamoUserApi } from "../../../services"
import { TextParams } from "../../../state/reducers/types"
import { TableName } from "../../../tables"
import { Currency } from "../../../tables/types/types"
import { define } from "../../../utils/typeUtils"
import { Settings, SettingsDataType, SettingsModule, Ticket, useAdministrationActions, useSettingsLoaders, useTicketActions } from "../../administration"
import { OrderDelivery, DeliveryStatus, useOperationsActions, useOperationsHooks } from "../../operations"
import { Lot, useProductsActions } from "../../products"
import { useStockActions } from "../../stock"
import { useSystemStateHooks } from "../../system"
import { PurchaseItem, PurchaseOrder, Supplier } from "../state/types"

export const usePurchasesActions = () => {
    const stateCompanyId = useSystemStateHooks().companyId
    const { getPendingQuantity } = useOperationsHooks()

    const settingsLoaders = useSettingsLoaders()

    const defaultModuleActions = useDefaultModuleActions()
    const ticketActions = useTicketActions()
    const administrationActions = useAdministrationActions()
    const operationsActions = useOperationsActions()
    const productsActions = useProductsActions()
    const stockActions = useStockActions()
    const { submitChildren } = useChildrenActions()

    return (paramCompanyId?: string) => {
        const companyId = paramCompanyId || stateCompanyId
        const { fetchAll, fetchByParams, fetchMultipleByParams, fetchByIds, count, save, saveMultiple, remove, removeMultiple } = defaultModuleActions(DynamoUserApi, define(companyId))
        const { fetchTicketsByParentId, removeTicket } = ticketActions(companyId)
        const { saveProduct, removeProduct, saveCategory, removeCategory, saveProductPrices, fetchLot, saveLot } = productsActions(companyId)
        const { saveSettings } = administrationActions(companyId)

        const savePurchasesSetting = async (propName: string, value: SettingsDataType) => {
            const { findModuleSettings } = settingsLoaders(companyId)
            const settings = findModuleSettings(SettingsModule.PURCHASES)
            const updatedSettings: Settings = settings ? {
                ...settings,
                [propName]: value
            } : {
                companyId: define(companyId),
                id: newId(),
                module: SettingsModule.PURCHASES,
                [propName]: value
            }
            
            return await saveSettings(updatedSettings) as Settings
        }

        const fetchAllPurchases = (deliveryBranchId: string) => {
            return fetchMultipleByParams(TableName.PURCHASE_ORDERS, { deliveryBranchId })
        }

        const fetchPurchases = (ids: string[]) => {
            return fetchByIds(TableName.PURCHASE_ORDERS, ids)
        }

        const fetchPurchase = (id: string) => {
            return fetchByParams(TableName.PURCHASE_ORDERS, { id })
        }

        const countPurchases = (propName: string, propValue: string) => {
            return count(TableName.PURCHASE_ORDERS, { [propName]: propValue })
        }
        
        const savePurchase = (purchase: PurchaseOrder) => {
            return save(TableName.PURCHASE_ORDERS, purchase)
        }

        const savePurchaseWithItems = async (
            purchase: PurchaseOrder,
            purchaseItemsData: Data[]
        ) => {
            const savedPurchase = await savePurchase(purchase) as PurchaseOrder

            const purchaseItems = purchaseItemsData.map(purchaseItemData => ({
                ...purchaseItemData,
                companyId: define(companyId),
                id: newId(),
                purchaseId: purchase.id,
                currency: Currency.ARS
            })) as PurchaseItem[]
            
            const statePurchaseItems = await fetchPurchaseItemsByOrder(purchase.id) as PurchaseItem[]
            await submitChildren(statePurchaseItems, purchaseItems, savePurchaseItems as SaveChildrenFn, removePurchaseItems as RemoveChildrenFn)
            return savedPurchase
        }

        const removePurchase = async (id: string) => {
            const { fetchDeliveries, removeDeliveries } = operationsActions()
            
            const statePurchase = define(await fetchPurchase(id)) as PurchaseOrder
            if (statePurchase.deliveryStatus !== DeliveryStatus.DELIVERED) {
                await remove(TableName.PURCHASE_ORDERS, id)
                
                const purchaseItems = await fetchPurchaseItemsByOrder(id) as PurchaseItem[]
                await removePurchaseItems(purchaseItems)
                
                const tickets = await fetchTicketsByParentId(statePurchase.id) as Ticket[]
                tickets.forEach(async ticket => {
                    await removeTicket(ticket.id)
                })

                const deliveries = await fetchDeliveries(id) as OrderDelivery[]
                const deliveryIds = deliveries.map(delivery => delivery.id)
                
                return removeDeliveries(deliveryIds)
            }
        }

        const fetchPurchaseItemsByOrder = (purchaseId: string) => {
            return fetchMultipleByParams(TableName.PURCHASE_ITEMS, { purchaseId })
        }

        const countPurchaseItems = (propName: string, propValue: string) => {
            return count(TableName.PURCHASE_ITEMS, { [propName]: propValue })
        }

        const savePurchaseItems = (purchaseItems: PurchaseItem[]) => {
            return saveMultiple(TableName.PURCHASE_ITEMS, purchaseItems)
        }

        const removePurchaseItems = (purchaseItems: PurchaseItem[]) => {
            return removeMultiple(TableName.PURCHASE_ITEMS, purchaseItems.map(purchaseItem => purchaseItem.id))
        }

        const savePurchaseDelivery = async (
            purchase: PurchaseOrder,
            purchaseItems: PurchaseItem[],
            delivery: OrderDelivery,
            lotData: Partial<Lot>[]
        ) => {
            const { fetchDeliveries, saveDelivery } = operationsActions()
            const { increaseStock } = stockActions()

            const deliveries = await fetchDeliveries(purchase.id) as OrderDelivery[]
            const savedDelivery = await saveDelivery(delivery) as OrderDelivery

            let pendingItemsCount = 0
            savedDelivery.deliveredItems.forEach(deliveredItem => {
                const deliveredPurchaseItem = purchaseItems.find(purchaseItem => purchaseItem.id === deliveredItem.itemId)
                const pendingQuantity = getPendingQuantity(define(deliveredPurchaseItem) as PurchaseItem, deliveries)
                deliveredItem.delivered < pendingQuantity && pendingItemsCount++
            })
            const updatedPurchase: PurchaseOrder = {
                ...purchase,
                deliveryBranchId: savedDelivery.branchId,
                deliveryStatus: pendingItemsCount === 0 ? DeliveryStatus.DELIVERED : DeliveryStatus.PARTIALLY_DELIVERED
            }

            const savedPurchase = await savePurchase(updatedPurchase) as PurchaseOrder

            savedDelivery.deliveredItems.forEach(async deliveredItem => {
                const productId = define(purchaseItems.find(purchaseItem => purchaseItem.id === deliveredItem.itemId)?.productId)
                const lotDataItem = lotData.find(lotDataItem => lotDataItem.productId === productId)

                if (lotDataItem?.id) {
                    const stateLot = await define(fetchLot(lotDataItem.id as string)) as Lot
                    await increaseStock(productId, savedDelivery.branchId, deliveredItem.delivered, stateLot.id)
                } else {
                    const lot: Lot = {
                        companyId: define(companyId),
                        id: newId(),
                        code: define(lotDataItem?.code) as string,
                        productId,
                        attributes: lotDataItem?.attributes
                    }
                    const savedLot = await saveLot(lot) as Lot
                    await increaseStock(productId, savedDelivery.branchId, deliveredItem.delivered, savedLot.id)
                }
            })

            return savedPurchase
        }

        const fetchAllSuppliers = async (limit?: number, startKey?: TextParams) => {
            return fetchAll(TableName.SUPPLIERS, limit, startKey)
        }

        const fetchSupplier = (id: string) => {
            return fetchByParams(TableName.SUPPLIERS, { id })
        }

        const saveSupplier = async (supplier: Supplier) => {
            return save(TableName.SUPPLIERS, supplier)
        }

        const saveSuppliers = (suppliers: Supplier[]) => {
            return saveMultiple(TableName.SUPPLIERS, suppliers)
        }

        const removeSupplier = (id: string) => {
            return remove(TableName.SUPPLIERS, id)
        }

        return {
            savePurchasesSetting,
            fetchAllPurchases,
            fetchPurchases,
            fetchPurchase,
            countPurchases,
            savePurchase,
            savePurchaseWithItems,
            removePurchase,
            fetchPurchaseItemsByOrder,
            countPurchaseItems,
            savePurchaseDelivery,
            fetchAllSuppliers,
            fetchSupplier,
            saveSupplier,
            saveSuppliers,
            removeSupplier,
            saveProduct,
            removeProduct,
            saveCategory,
            removeCategory,
            saveProductPrices
        }
    }
}
