import { O } from "../features/ui"

export const getNextFreeNumber = (values: number[]) =>
    values.length > 0 ? Math.max(...values) + 1 : 1

export const sorted = (list: string[]) =>
    list.sort((value1, value2) => value1.toUpperCase() > value2.toUpperCase() ? 1 : -1)

export const firstItem = <T>(list: T[]): O<T> => list.length > 0 ? list[0] : undefined

export const toList = <T>(item?: T): T[] => item !== undefined ? [item] : []

export const dedupe = <T>(list: T[]): T[] => Array.from(new Set(list))