import { O } from '../../../features/ui'
import { useStateHooks } from '../../../state'
import { TableName } from '../../../tables'
import { useLoaders } from '../../common'
import { useSystemStateHooks } from '../../system'
import { CashFund } from '../state/types'

export const useCashFundLoaders = () => {
    const systemHooks = useSystemStateHooks()
    const { getList, find } = useStateHooks()

    const loaders = useLoaders()

    return (companyId = systemHooks.companyId) => {
        const findAllCashFunds = () => getList(TableName.CASH_FUNDS) as CashFund[]

        const findCashFund = (id?: string) => find(TableName.CASH_FUNDS, id) as O<CashFund>

        const loadAllCashFunds = (dbRequest = false) => {
            const { loadAll } = loaders(companyId)
            return loadAll(TableName.CASH_FUNDS, dbRequest) as Promise<CashFund[]>
        }

        const loadCashFund = (
            id: string,
            dbRequest = false
        ) => {
            const { load } = loaders(companyId)
            return load(TableName.CASH_FUNDS, id, dbRequest) as Promise<O<CashFund>>
        }
        
        return {
            findAllCashFunds,
            findCashFund,
            loadAllCashFunds,
            loadCashFund
        }
    }
}
