import { DataType } from "../../../../tables"
import { O, SpinnerDiv } from "../.."
import { useStateHooks } from "../../../../state"
import { getRouteFromTableName, RoutePath, useRoutesHooks } from "../../../routes"
import { useNavigate } from "react-router-dom"
import { define } from "../../../../utils/typeUtils"

type Props = {
    parentPath?: RoutePath
    children: JSX.Element
}

export const DetailWrapper = (props: Props) => {
    const { parentPath, children } = props

    const { parseUrl, getRoutePath } = useRoutesHooks()
    const { dataTypeId, tableName } = parseUrl()
    const definedTable = define(tableName)
  
    const { find } = useStateHooks()
    const navigate = useNavigate()
  
    const dataType = find(definedTable, dataTypeId) as O<DataType>
    const tableRoutePath = parentPath || define(getRouteFromTableName(definedTable))
  
    if (!dataType) {
        setTimeout(() => navigate(getRoutePath(tableRoutePath)), 1000)
    }
  
    return dataType ? children : <SpinnerDiv />
}
