import { O } from '../../../features/ui'
import { useStateHooks } from '../../../state'
import { TableName } from '../../../tables'
import { useLoaders } from '../../common'
import { useSystemStateHooks } from '../../system'
import { Area, Employee } from '../state/types'

export const useEmployeeLoaders = () => {
    const systemHooks = useSystemStateHooks()
    const { getList, find } = useStateHooks()

    const loaders = useLoaders()

    return (companyId = systemHooks.companyId) => {
        const findAllEmployees = () => getList(TableName.EMPLOYEES) as Employee[]

        const findEmployee = (id?: string) => find(TableName.EMPLOYEES, id) as O<Employee>

        const findAreasEmployees = (areas: Area[]) => findAllEmployees().filter(employee => areas.includes(employee.area))

        const loadAllEmployees = () => {
            const { loadAll } = loaders(companyId)
            return loadAll(TableName.EMPLOYEES) as Promise<Employee[]>
        }

        const loadEmployee = (id: string) => {
            const { load } = loaders(companyId)
            return load(TableName.EMPLOYEES, id) as Promise<O<Employee>>
        }
        
        return {
            findAllEmployees,
            findEmployee,
            findAreasEmployees,
            loadAllEmployees,
            loadEmployee
        }
    }
}
