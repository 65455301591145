import { Data, ModalId, O, useUIActions, useUIStateActions } from "../../../../../features/ui"
import { WizardPanel, useWizardHooks } from "../../../../../features/wizard"
import { define } from "../../../../../utils/typeUtils"
import { HoardOrder } from "../../../state/types"
import { hoardWizardKey } from "../../../utils/salesUtils"
import { Operation, OperationItemFormModal, usePricesHooks } from "../../../../operations"
import { useDescriptorHooks } from "../../../../../features/data-types"
import { useActions } from "./actions"
import { ReactElement, useEffect, useState } from "react"
import { HoardInfoStep } from "./hoardInfoStep"
import { HoardSelectItemsStep } from "./hoardSelectItemsStep"
import { Lot, Product } from "../../../../products"
import { TableName } from "../../../../../tables"
import { useFind } from "../../../../../state/reducers/hooks"
import { useRoutesHooks } from "../../../../../features/routes"
import { useSalesHooks } from "../../../hooks/salesHooks"

export const FinancialHoardDeliveryForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const hoard = define(dataType) as HoardOrder
    const wizardKey = hoardWizardKey(hoard)
    
    const [steps, setSteps] = useState<ReactElement[]>([])
    const [currentHoardItem, setCurrentHoardItem] = useState<Data>()
    const [branchId, setBranchId] = useState<string>()
    const [hoardItemsData, setHoardItemsData] = useState<Data[]>([])
    const [validations, setValidations] = useState<string[]>([])
    
    const { getSettings } = useSalesHooks()
    const hoardIgnoredProductIds = (getSettings()?.hoardIgnoredProductIds || []) as string[]
    const { getStepData } = useWizardHooks()
    const descriptorHooks = useDescriptorHooks()
    const pricesHooks = usePricesHooks()
    const find = useFind()

    const { getBranchId, validateFinancialHoardDelivery, finishFinancialHoardDelivery } = useActions()
    const { setDisableBranchSelector } = useUIStateActions()
    const { toggleModal } = useUIActions()

    const onCreateHoardItem = () => {
        setCurrentHoardItem(undefined)
        setValidations([])
    }

    const onEditHoardItem = (productId: string, lotId?: string) => {
        setCurrentHoardItem(hoardItemsData.find(hoardItemData => hoardItemMatches(hoardItemData, productId, lotId)))
        setValidations([])
    }

    const onRemoveHoardItem = (productId: string, lotId?: string) => {
        const newHoardItemsData = hoardItemsData.filter(hoardItemData => !hoardItemMatches(hoardItemData, productId, lotId))
        setHoardItemsData(newHoardItemsData)
        setValidations([])
    }

    useEffect(() => {
        setDisableBranchSelector(true)
        
        return () => {
            setDisableBranchSelector(false)
        }
    }, [])

    useEffect(() => {
        const infoStep = <HoardInfoStep
            wizardKey={wizardKey}
            stepIndex={0}
            hoard={hoard}
        />
        const selectItemStep = <HoardSelectItemsStep
            wizardKey={wizardKey}
            stepIndex={1}
            hoard={hoard}
            hoardItemsData={hoardItemsData}
            onCreateHoardItem={onCreateHoardItem}
            onEditHoardItem={onEditHoardItem}
            onRemoveHoardItem={onRemoveHoardItem}
        />
        
        setSteps([infoStep, selectItemStep])
    }, [hoardItemsData, validations])

    const filterProducts = (product: Product) =>
        !hoardIgnoredProductIds.includes(product.id) &&
        (currentHoardItem?.productId === product.id || !hoardItemsData.map(hoardItemData => hoardItemData.productId).includes(product.id))
    const filterLots = (lot: Lot) =>
        currentHoardItem?.lotId === lot.id || !hoardItemsData.map(hoardItemData => hoardItemData.lotId).includes(lot.id)

    const hoardItemMatches = (data: Data, productId: string, lotId?: string) =>
        data.productId === productId && data.lotId === lotId

    const title = `Entrega de Acopio ${descriptorHooks.hoard.code(hoard)}`
    const salePricesList = Object.keys(hoard.purchasePricesList)
        .filter(productId => find(TableName.PRODUCTS, productId))
        .map(productId => {
            const purchasePrice = hoard.purchasePricesList[productId]
            return {
                productId: productId,
                price: pricesHooks.product.basePrice(productId, purchasePrice)
            }
        })

    const onGoToStep = (stepIndex: number) => {
        if (stepIndex === 1) {
            const defaultHoardItemsDataStr = getStepData(wizardKey, 1)?.hoardItemsData
            const defaultHoardItemsData: Data[] = defaultHoardItemsDataStr ? JSON.parse(defaultHoardItemsDataStr) : []
            setBranchId(getBranchId(wizardKey, hoard))
            setHoardItemsData(defaultHoardItemsData)
        }
    }

    const onFinish = async () => {
        const itemValidations = await validateFinancialHoardDelivery(hoardItemsData, define(branchId))
        if (itemValidations.length > 0) {
            setValidations(itemValidations)
            toggleModal(ModalId.VALIDATION)
        } else {
            await finishFinancialHoardDelivery(wizardKey, hoard)
        }
    }

    const onSaveHoardItem = (newData: Data) => {
        let newHoardItemsData = hoardItemsData
        if (currentHoardItem) {
            const { productId, lotId } = currentHoardItem
            newHoardItemsData = hoardItemsData.map(hoardItemData => hoardItemMatches(hoardItemData, define(productId) as string, lotId as O<string>) ? newData : hoardItemData)
        } else {
            newHoardItemsData = [newData, ...hoardItemsData]
        }
        setHoardItemsData(newHoardItemsData)
    }

    return (<>
        <WizardPanel
            wizardKey={wizardKey}
            title={title}
            steps={steps}
            wide
            validations={validations}
            onGoToStep={onGoToStep}
            onFinish={onFinish}
        />
        <OperationItemFormModal
            operationItem={currentHoardItem}
            operation={Operation.SALE}
            branchId={branchId}
            withLots
            pricesList={salePricesList}
            filterProducts={filterProducts}
            filterLots={filterLots}
            onSubmit={onSaveHoardItem}
        />
    </>)
}
