import { useMemo, useState } from "react"
import { DataTypeList } from "../../../../../features/data-types"
import { Column, Data, FieldType, FieldValue, PanelWrapper, SpinnerDiv } from "../../../../../features/ui"
import { Transaction } from "../../../state/types"
import { DataType } from "../../../../../tables"
import { TransactionFilters } from "./transactionFilters"
import { define } from "../../../../../utils/typeUtils"
import { TransactionSource } from "../transaction-source/transactionSource"
import { TransactionDestination } from "../transaction-destination/transactionDestination"

export const TransactionList = () => {
    const [transactions, setTransactions] = useState<Transaction[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)

    const rows: Data[] = useMemo(() => transactions.map(transaction => {
        return {
            ...transaction,
            sourceId: transaction.id,
            destinationId: transaction.id
        }
    }), [transactions])

    const setRowsState = (transactions: Transaction[]) => {
        setTransactions(transactions)
        setAsyncDataLoaded(true)
    }

    const columns: Column[] = [
        {
            name: 'date',
            type: FieldType.DATE,
            label: 'Fecha'
        },
        {
            name: 'sourceId',
            type: FieldType.TABLE,
            label: 'Origen',
            render: (value?: FieldValue) => {
                const transaction = define(transactions.find(transaction => transaction.id === value))
                return <TransactionSource transaction={transaction} />
            }
        },
        {
            name: 'amount',
            type: FieldType.PRICE,
            label: 'Monto'
        },
        {
            name: 'destinationId',
            type: FieldType.TABLE,
            label: 'Destino',
            render: (value?: FieldValue) => {
                const transaction = define(transactions.find(transaction => transaction.id === value))
                return <TransactionDestination transaction={transaction} />
            }
        }
    ]

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRowsState(dataTypes as Transaction[])
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <PanelWrapper title="Transacciones" wide>
            <TransactionFilters onSearchStart={onSearchStart} onSearchDone={onSearchDone}/>
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    readOnly
                />
            )}
        </PanelWrapper>
    )
}
