import styles from './customerForm.module.css'
import { DataTypeForm, Labels, validateIdentifier, validatePhone, validateTaxId } from "../../../../../features/data-types"
import { PanelWrapper, Data, FieldType, FieldValue, FormField, NumberField, SelectField, Validatable, option, useGetCrumbs, O } from "../../../../../features/ui"
import { useActions } from "./actions"
import { Customer, CustomerCategory } from "../../../state/types"
import { TableName } from "../../../../../tables"
import { IdentifierType } from '../../../../system'
import { useState } from 'react'
import { define } from '../../../../../utils/typeUtils'
import { useNavigate } from 'react-router-dom'
import { RoutePath, useRoutesHooks } from '../../../../../features/routes'
import { toList } from '../../../../../utils/listUtils'

export const CustomerForm = () => {
    const { dataType } = useRoutesHooks().parseUrl()
    const customer = dataType as O<Customer>
    const createMode = !customer

    const [identifierType, setIdentifierType] = useState<O<IdentifierType>>(customer?.identifierType || IdentifierType.PERSONAL_ID)
    const [identifier, setIdentifier] = useState<O<number>>(customer?.identifier)
    const [identifierValidation, setIdentifierValidation] = useState<string>()

    const navigate = useNavigate()

    const { submitCustomer } = useActions()

    const customerLabel = customer ? `: ${customer.name}` : ''
    const title = `${createMode ? 'Crear' : 'Editar'} Cliente${customerLabel}`

    const crumbs = useGetCrumbs('Clientes', RoutePath.CUSTOMERS, customer?.name, undefined, createMode ? 'Crear' : 'Editar')

    const onChangeIdentifierType = (value?: FieldValue) => {
        setIdentifierType(value as O<IdentifierType>)
        setIdentifierValidation(undefined)
    }

    const onChangeIdentifier = (value?: FieldValue) => {
        setIdentifier(value as O<number>)
        setIdentifierValidation(undefined)
    }

    const fields: FormField[] = [
        {
            name: 'category',
            type: FieldType.SELECT,
            label: 'Cateogría',
            options: Object.values(CustomerCategory).map(category => option(category, Labels.customerCategoryLabel(category)))
        },
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre / Razón Social'
        },
        {
            name: 'identifier',
            type: FieldType.NUMBER,
            label: 'Documento',
            render: () => {
                return <div className={styles.identifierContainer}>
                    <SelectField
                        id="identifierType"
                        defaultValue={identifierType}
                        options={Object.values(IdentifierType).map(type => option(type, Labels.identifierTypeLabel(type)))}
                        className={styles.identifierType}
                        onChange={onChangeIdentifierType}
                    />
                    <div>
                        <Validatable validations={toList(identifierValidation)} left >
                            <NumberField
                                id="identifier"
                                defaultValue={identifier}
                                onChange={onChangeIdentifier}
                            />
                        </Validatable>
                    </div>
                </div>
            }
        },
        {
            name: 'customerTypeId',
            type: FieldType.TABLE,
            table: TableName.CUSTOMER_TYPES,
            label: 'Tipo de Cliente'
        },
        {
            name: 'email',
            type: FieldType.TEXT,
            label: 'Email',
            optional: true
        },
        {
            name: 'address',
            type: FieldType.TEXT,
            label: 'Domicilio',
            optional: true
        },
        {
            name: 'phone',
            type: FieldType.NUMBER,
            label: 'Teléfono',
            optional: true,
            validate: validatePhone
        }
    ]

    const validate = (): boolean => {
        const isPersonalId = identifierType === IdentifierType.PERSONAL_ID
        const data = isPersonalId ? { identifier } : { taxId: identifier }
        const validationMessage = isPersonalId ? validateIdentifier(data) : validateTaxId(data)
        validationMessage && setIdentifierValidation(validationMessage)
        return !validationMessage
    }

    const onSubmit = (customerData: Data) => {
        if (validate()) {
            submitCustomer(
                customerData,
                createMode,
                define(identifierType),
                define(identifier)
            )
        }
    }

    const onCancel = () => navigate(-1)

    return (
        <PanelWrapper title={title} crumbs={crumbs}>
            <DataTypeForm
               formId="customer-form"
               fields={fields}
               createMode={createMode}
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
        </PanelWrapper>
    )
}
