import styles from './paymentOrderPDFPage.module.css'
import { useSystemState } from '../../../../system'
import { define } from '../../../../../utils/typeUtils'
import { useDataTypesHooks } from '../../../../../features/data-types'
import { FieldType, PDFHeader } from '../../../../../features/ui'
import { useEffect, useMemo, useState } from 'react'
import { PaymentItemData } from './types'
import { PaymentOrder } from '../../../state/types'
import { usePaymentOrderHooks } from '../../../hooks/paymentOrderHooks'
import { usePaymentTypeLoaders } from '../../../loaders/paymentTypeLoaders'
import { useTaxLoaders } from '../../../loaders/taxLoaders'

type Props = {
    paymentOrder: PaymentOrder
}

export const PaymentOrderPDFPage2 = (props: Props) => {
    const { paymentOrder } = props

    const { company } = useSystemState()
    const definedCompany = define(company)

    const [paymentItemsData, setPaymentItemsData] = useState<PaymentItemData[]>([])

    const { formatValue } = useDataTypesHooks()
    const { describeSource } = usePaymentOrderHooks()
    
    const paymentTypeLoaders = usePaymentTypeLoaders()
    const taxLoaders = useTaxLoaders()

    const loadPaymentItemsData = async () => {
        const { findPaymentType } = paymentTypeLoaders()
        return Promise.all(
            paymentOrder.paymentItems.map(async paymentItem => {
                const paymentType = define(findPaymentType(paymentItem.paymentTypeId))
                const reference = await describeSource(paymentItem.sourceId)
                return { ...paymentItem, paymentType: paymentType.name, reference }
            })
        )
    }

    useEffect(() => {
        loadPaymentItemsData().then(newItemsData => setPaymentItemsData(newItemsData))
    }, [paymentOrder])

    const taxItemsData = useMemo(() => paymentOrder.taxItems.map(taxItem => {
        const tax = taxLoaders().findTaxByCode(taxItem.taxCode)
        return tax && { ...taxItem, reference: tax.name }
    }), [paymentOrder])

    const ticketItemsPayedAmount = useMemo(() => paymentOrder.ticketItems.reduce((sum, ticketItem) => sum + ticketItem.payedAmount, 0), [paymentOrder])

    const debitCreditItems = useMemo(() => {
        const items = []
        taxItemsData.map(taxItemData => {
            taxItemData && items.push({ concept: `Retención: ${taxItemData.reference}`, debit: 0, credit: taxItemData.amount })
        })
        paymentItemsData.map(paymentItemData => {
            paymentItemData && items.push({ concept: `Pago: ${paymentItemData.reference}`, debit: 0, credit: paymentItemData.amount })
        })
        items.push({ concept: 'Imp. a Cancelar', debit: ticketItemsPayedAmount, credit: 0 })
        return items
    }, [paymentItemsData, taxItemsData])

    const debitAmount = useMemo(() => debitCreditItems.reduce((sum, debitCreditItem) => sum + debitCreditItem.debit, 0), [debitCreditItems])
    const creditAmount = useMemo(() => debitCreditItems.reduce((sum, debitCreditItem) => sum + debitCreditItem.credit, 0), [debitCreditItems])
    
    return (
        <div className={styles.container}>
            <div className={styles.body}>
                <PDFHeader />

                <div className={`${styles.row}`}>
                    <span>Recibī de {definedCompany.legalName} los valores/comprobantes que se detallan precedentemente.</span>
                </div>

                <div className={`${styles.row} ${styles.signRow}`}>
                    <div className={styles.signContainer}>
                        <div className={styles.placeholder} />
                        <span>Firma</span>
                    </div>
                    <div className={styles.signContainer}>
                        <div className={styles.placeholder} />
                        <span>Aclaración</span>
                    </div>
                </div>
                
                <div className={`${styles.row} ${styles.infoRow} ${styles.infoTable}`}>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Debe</th>
                                <th>Haber</th>
                            </tr>
                        </thead>
                        <tbody>
                            {debitCreditItems.map((debitCreditItem, index) => (
                                <tr key={index}>
                                    <td className={styles.conceptTd}>{debitCreditItem.concept}</td>
                                    <td>{formatValue(debitCreditItem.debit, FieldType.PRICE)}</td>
                                    <td>{formatValue(debitCreditItem.credit, FieldType.PRICE)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <table className={styles.totalTable}>
                        <tbody>
                            <tr>
                                <td className={styles.conceptTd}>Totales:</td>
                                <td>{formatValue(debitAmount, FieldType.PRICE)}</td>
                                <td>{formatValue(creditAmount, FieldType.PRICE)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className={styles.footer}>
                    <div className={styles.separator} />
                    <div className={`${styles.row} ${styles.footerRow}`}>
                        <div className={styles.signContainer}>
                            <div className={styles.placeholder} />
                            <span>Autorizó</span>
                        </div>
                        <div className={styles.signContainer}>
                            <div className={styles.placeholder} />
                            <span>Confeccionó</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
