import { useState } from "react"
import { Action, ButtonKind, Column, FieldType, FieldValue, ModalId, SpinnerDiv, UIPermission, useUIActions } from "../../../../../features/ui"
import { Data, DataTypeList, Labels } from "../../../../../features/data-types"
import { useSystemStateHooks } from "../../../../system"
import { DataType } from "../../../../../tables"
import { PaymentOrderFilters } from "./paymentOrderFilters"
import { define } from "../../../../../utils/typeUtils"
import { PaymentOrder, PaymentOrderStatus } from "../../../state/types"
import { useNavigate } from "react-router-dom"
import { ConfirmPaymentFormModal } from "../confirm-payment-modal/confirmPaymentFormModal"
import { DownloadPaymentOrderFilesModal } from "../download-payment-order-files-modal/downloadPaymentOrderFilesModal"

type Props = {
    paymentOrders: PaymentOrder[]
}

export const PaymentOrderList = (props: Props) => {
    const { paymentOrders } = props

    const [rows, setRows] = useState<Data[]>([])
    const [currentPaymentOrder, setCurrentPaymentOrder] = useState<PaymentOrder>()
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)

    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_PAYMENT_ORDERS)
    const navigate = useNavigate()

    const { openModal } = useUIActions()

    const columns: Column[] = [
        {
            name: 'code',
            type: FieldType.NUMBER,
            label: '#',
            render: (value?: FieldValue) => `#${value}`
        },
        {
            name: 'payedAmount',
            type: FieldType.PRICE,
            label: 'Importe'
        },
        {
            name: 'creationDate',
            type: FieldType.DATE,
            label: 'Fecha'
        },
        {
            name: 'status',
            type: FieldType.TEXT,
            label: 'Estado',
            render: (value?: FieldValue) => Labels.paymentOrderStatusLabel(define(value) as PaymentOrderStatus)
        }
    ]

    const findPaymentOrder = (id?: string) => define(paymentOrders.find(paymentOrder => paymentOrder.id === id))

    const onView = (id?: string) => navigate(define(id))

    const onDownloadFiles = (id?: string) => {
        setCurrentPaymentOrder(findPaymentOrder(id))
        openModal(ModalId.DOWNLOAD_FILES)
    }

    const onConfirmPayment = (id?: string) => {
        setCurrentPaymentOrder(findPaymentOrder(id))
        openModal(ModalId.CONFIRM_PAYMENT_FORM)
    }

    const actions = (id?: string): Action[] => {
        const paymentOrder = findPaymentOrder(id)
        const isPaymentEmitted = paymentOrder.status === PaymentOrderStatus.EMITTED

        const actionList: Action[] = [
            { icon: 'eye-fill', kind: 'tertiary' as ButtonKind, tooltip: 'Ver Detalle', onClick: onView },
            { icon: 'download', kind: 'tertiary', tooltip: 'Descargar Documentos', narrowTooltip: true, onClick: onDownloadFiles }
        ]
        if (!readOnly && isPaymentEmitted) {
            actionList.push({ icon: 'check-lg', tooltip: 'Confirmar Pago', onClick: onConfirmPayment })
        }

        return actionList
    }

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRows(dataTypes as Data[])
        setAsyncDataLoaded(true)
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <>
            <PaymentOrderFilters
                paymentOrders={paymentOrders}
                onSearchStart={onSearchStart}
                onSearchDone={onSearchDone}
            />
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    actions={actions}
                    readOnly
                />
            )}
            <DownloadPaymentOrderFilesModal paymentOrder={currentPaymentOrder} />
            <ConfirmPaymentFormModal paymentOrder={currentPaymentOrder} />
        </>
    )
}
