import { useEffect, useState } from "react"
import { PanelLoader } from "../../../../../features/ui"
import { Check } from "../../../state/types"
import { ListLoaderWrapper } from "../../../../../features/data-types"
import { TableName } from "../../../../../tables"
import { CheckList } from "./checkList"
import { useCheckLoaders } from "../../../loaders/checkLoaders"

const Loader = () => {
    const [checks, setChecks] = useState<Check[]>([])
    const [ready, setReady] = useState(false)

    const checkLoaders = useCheckLoaders()

    const populate = async () => {
        const { loadAllChecks } = checkLoaders()
        const loadedChecks = await loadAllChecks()
        setChecks(loadedChecks)
    }

    useEffect(() => {
        populate().then(() => setReady(true))
    }, [])

    return (
        <PanelLoader
            title="Cheques"
            ready={ready}
            wide
        >
            <CheckList checks={checks} />
        </PanelLoader>
    )
}

export const CheckListLoader = () => (
    <ListLoaderWrapper table={TableName.CHECKS}>
        <Loader />
    </ListLoaderWrapper>
)
