import { TableName } from '../../../../../tables'
import { Data, UIPermission, DetailPanel, DetailFieldProps, FieldType, FieldValue, Action, Spinner, ModalId, useUIActions, Tab, useGetCrumbs, SpinnerDiv, PDFTabContent } from '../../../../../features/ui'
import { HoardItem, HoardOrder, HoardType } from '../../../state/types'
import { define } from '../../../../../utils/typeUtils'
import { useSalesActions } from '../../../actions/salesActions'
import { useEffect, useState } from 'react'
import { Operation, OperationItemsTable, DeliveryStatus, CloseOperationModal, DeliveryList, useOperationsActions, OrderDelivery, PendingItemsList, PriceBreakdown, usePriceBreakdownHooks, PaymentStatus } from '../../../../operations'
import { useSystemStateHooks } from '../../../../system'
import { DownloadInvoiceModal, InvoiceModal, InvoiceRequestedModal, InvoiceStatus, useEmployeeLoaders } from '../../../../administration'
import { hoardWizardKey } from '../../../utils/salesUtils'
import { OpenWizardModal, useWizardActions } from '../../../../../features/wizard'
import { RoutePath, useRoutesHooks } from '../../../../../features/routes'
import { useDescriptorHooks, Labels } from '../../../../../features/data-types'
import { HoardValues } from '../hoard-values/hoardValues'
import { HoardPDF } from '../hoard-pdf/hoardPDF'
import { DownloadHoardPDFModal } from '../download-hoard-pdf-modal/downloadHoardPDFModal'
import { useNavigate } from 'react-router-dom'
import { ConfirmSalePaymentFormModal } from '../../confirm-sale-payment-modal/confirmSalePaymentFormModal'
import { DownloadReceiptModal } from '../../../../common'

export const HoardDetail = () => {
  const { dataType } = useRoutesHooks().parseUrl()
  const hoard = define(dataType) as HoardOrder
  const wizardKey = hoardWizardKey(hoard)
  const { purchasePricesList, ...rest } = hoard
  const hoardData = rest as Data

  const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)
  const [hoardItems, setHoardItems] = useState<HoardItem[]>([])
  const [deliveries, setDeliveries] = useState<OrderDelivery[]>([])

  const { userHasPermission } = useSystemStateHooks()
  const { getOperationPriceBreakdown } = usePriceBreakdownHooks()
  const descriptorHooks = useDescriptorHooks()
  const navigate = useNavigate()

  const employeeLoaders = useEmployeeLoaders()

  const { goToWizard } = useWizardActions()
  const salesActions = useSalesActions()
  const operationsActions = useOperationsActions()
  const { openModal } = useUIActions()

  const hoardCode = descriptorHooks.hoard.code(hoard)

  const title = `${Labels.hoardTypeLabel(hoard.type)}: ${hoardCode}`
  const readOnly = !userHasPermission(UIPermission.SAVE_HOARDS)
  const hasDeliveryPermission = userHasPermission(UIPermission.SAVE_HOARD_DELIVERIES)
  const hasInvoicePermission = userHasPermission(UIPermission.INVOICE_HOARDS)
  const isProductHoard = hoard.type === HoardType.PRODUCT

  const init = async () => {
    const stateHoardItems = await salesActions().fetchHoardItemsByOrder(hoard.id) as HoardItem[]
    setHoardItems(stateHoardItems)
    const stateDeliveries = await operationsActions().fetchDeliveries(hoard.id) as OrderDelivery[]
    setDeliveries(stateDeliveries)
    await salesActions().fetchCustomer(hoard.customerId)
    setAsyncDataLoaded(true)
  }

  useEffect(() => {
    init()
  }, [])

  const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <Spinner size='m'/>

  const crumbs = useGetCrumbs('Acopios', RoutePath.HOARDS, hoardCode)

  if (!hoard) return <SpinnerDiv />

  const priceBreakdown = getOperationPriceBreakdown(TableName.HOARD_ORDERS, hoard)

  const fields: DetailFieldProps[] = [
    { name: 'branchId', type: FieldType.TABLE, table: TableName.BRANCHES, label: 'Sucursal de Entrega' },
    {
      name: 'sellerId',
      type: FieldType.TABLE,
      table: TableName.EMPLOYEES,
      label: 'Vendedor',
      render: (value?: FieldValue) => {
        const seller = employeeLoaders().findEmployee(define(value) as string)
        return <>{descriptorHooks.employee.info(define(seller))}</>
      }
    },
    { name: 'customerId', type: FieldType.TABLE, table: TableName.CUSTOMERS, label: 'Cliente' },
    { name: 'dueDate', type: FieldType.DATE, label: 'Fecha Límite' },
    { name: 'paymentTypeId', type: FieldType.TABLE, table: TableName.PAYMENT_TYPES, label: 'Medio de Pago' },
    {
      name: 'priceBreakdown',
      type: FieldType.PRICE,
      label: 'Precio',
      render: () => renderAsync(
        <PriceBreakdown data={priceBreakdown} />
      )
    },
    {
      name: 'deliveryStatus',
      type: FieldType.TEXT,
      label: 'Estado de Entrega',
      render: (value?: FieldValue) => <>{Labels.deliveryStatusLabel(define(value) as DeliveryStatus)}</>
    },
    {
      name: 'paymentStatus',
      type: FieldType.TEXT,
      label: 'Estado de Pago',
      render: (value?: FieldValue) => <>{Labels.paymentStatusLabel(define(value) as PaymentStatus)}</>
    },
    {
      name: 'invoiceStatus',
      type: FieldType.TEXT,
      label: 'Facturación',
      render: (value?: FieldValue) => <>{Labels.invoiceStatusLabel(define(value) as InvoiceStatus)}</>
    }
  ]
  if (hoardItems.length > 0) {
    const itemsField = {
      name: 'hoardItems',
      type: FieldType.TABLE,
      table: TableName.HOARD_ITEMS,
      render: () => renderAsync(
        <OperationItemsTable
          rows={hoardItems as Data[]}
          operation={Operation.SALE}
          readOnly
        />
      )
    }
    fields.splice(2, 0, itemsField)

    if (isProductHoard && hoard.deliveryStatus !== DeliveryStatus.DELIVERED && deliveries.length > 0) {
      const pendingItemsField = {
        name: 'pendingItems',
        type: FieldType.TABLE,
        table: TableName.HOARD_ITEMS,
        render: () => renderAsync(
          <PendingItemsList orderId={hoard.id} operation={Operation.HOARD} />
        )
      }
      fields.splice(3, 0, pendingItemsField)
    }
  }
  if (!isProductHoard && hoard.deliveryStatus === DeliveryStatus.PARTIALLY_DELIVERED) {
    const hoardValuesField = {
      name: 'hoardValues',
      type: FieldType.TABLE,
      label: ' ',
      render: () => renderAsync(
        <HoardValues
          hoard={hoard}  
          hoardItems={hoardItems}
        />
      )
    }
    fields.push(hoardValuesField)
  }

  const onDownloadInvoice = () => openModal(ModalId.DOWNLOAD_INVOICE)

  const onDownloadPDF = () => openModal(ModalId.DOWNLOAD_HOARD_PDF)

  const onEdit = () => navigate(RoutePath.EDIT)

  const onArchive = () => {}

  const onDeliver = () => goToWizard(hoardWizardKey(hoard), RoutePath.DELIVERY)

  const onDownloadReceipt = () => openModal(ModalId.DOWNLOAD_RECEIPT)

  const onConfirmPayment = () => openModal(ModalId.CONFIRM_SALE_PAYMENT_FORM)

  const onClose = () => openModal(ModalId.CLOSE_OPERATION)

  const onInvoice = () => openModal(ModalId.INVOICE)

  const actions: Action[] = []
  const isInvoiced = hoard.invoiceStatus === InvoiceStatus.INVOICED
  const isInvoiceable = hoard.invoiceStatus === InvoiceStatus.UNINVOICED
  const isEditable = hoard.deliveryStatus === DeliveryStatus.PENDING && hoard.paymentStatus === PaymentStatus.PENDING && isInvoiceable
  const isDelivered = hoard.deliveryStatus === DeliveryStatus.DELIVERED
  const isPayed = hoard.paymentStatus === PaymentStatus.PAYED

  if (isPayed) {
    actions.push({ label: 'Comp. de Pago', icon: 'download', kind: 'tertiary', onClick: onDownloadReceipt })
  }
  if (isInvoiced) {
    actions.push({ label: 'Descargar Factura', kind: 'senary', icon: 'download', onClick: onDownloadInvoice })
  } else {
    actions.unshift({ label: 'Descargar Acopio', kind: 'tertiary', icon: 'download', onClick: onDownloadPDF })
  }
  if (!readOnly) {
    if (isEditable) {
      actions.push({ label: 'Editar Acopio', icon: 'pencil-fill', onClick: onEdit })
    } else if (isDelivered && isPayed && isInvoiced) {
      actions.push({ label: 'Archivar', icon: 'archive', kind: 'secondary', onClick: onArchive })
    }
    if (!isPayed) {
      actions.push({ label: 'Confirmar Pago', icon: 'check-lg', onClick: onConfirmPayment })
    }
  }
  if (hasDeliveryPermission && !isDelivered && isPayed) {
    const deliverAction = { label: 'Registrar Entrega', icon: 'truck', onClick: onDeliver }
    const closeAction = { label: 'Cerrar', icon: 'bag-check-fill', onClick: onClose }
    actions.push(deliverAction, closeAction)
  }
  if (hasInvoicePermission && isInvoiceable) {
    actions.push({ label: 'Facturar', icon: 'file-earmark-check', kind: 'quinary', onClick: onInvoice })
  }

  const renderPDFContent = (
    <PDFTabContent>
      <HoardPDF
        id="hoard-pdf-preview"
        hoard={hoard}
        previewMode
      />
    </PDFTabContent>
  )

  const tabs: Tab[] = [
    { id: 'pdf', title: 'PDF', content: renderPDFContent }
  ]
  if (deliveries.length > 0) {
    tabs.push({
      id: 'deliveries',
      title: 'Entregas',
      content: <DeliveryList
        orderId={hoard.id}
        operation={Operation.HOARD}
        readOnly
      />
    })
  }

  return (<>
    <DetailPanel
      title={title}
      tableName={TableName.HOARD_ORDERS}
      crumbs={crumbs}
      fields={fields}
      data={hoardData}
      icon='layers'
      imgSpace={3}
      tabs={tabs}
      readOnly={readOnly}
      actions={actions}
    />
    <DownloadInvoiceModal operation={hoard} />
    <DownloadHoardPDFModal hoard={hoard} />
    <DownloadReceiptModal operation={hoard} />
    <ConfirmSalePaymentFormModal
      table={TableName.HOARD_ORDERS}
      operation={hoard}
    />
    <OpenWizardModal
      wizardKey={wizardKey}
      path={RoutePath.DELIVERY}
    />
    <CloseOperationModal
      operationType={Operation.HOARD}
      operation={hoard}
    />
    <InvoiceModal
      table={TableName.HOARD_ORDERS}
      operation={hoard}
    />
    <InvoiceRequestedModal />
  </>)
}
