import { useEffect, useState } from "react"
import { PanelLoader } from "../../../../../features/ui"
import { ListLoaderWrapper } from "../../../../../features/data-types"
import { TableName } from "../../../../../tables"
import { Budget } from "../../../state/types"
import { useBudgetLoaders } from "../../../loaders/budgetLoaders"
import { BudgetList } from "./budgetList"

const Loader = () => {
    const [budgets, setBudgets] = useState<Budget[]>([])
    const [ready, setReady] = useState(false)

    const budgetLoaders = useBudgetLoaders()

    const populate = async () => {
        const { loadAllBudgets } = budgetLoaders()
        const loadedBudgets = await loadAllBudgets()
        setBudgets(loadedBudgets)
    }

    useEffect(() => {
        populate().then(() => setReady(true))
    }, [])

    return (
        <PanelLoader
            title="Presupuestos"
            ready={ready}
            wide
        >
            <BudgetList budgets={budgets} />
        </PanelLoader>
    )
}

export const BudgetListLoader = () => (
    <ListLoaderWrapper table={TableName.BUDGETS}>
        <Loader />
    </ListLoaderWrapper>
)
