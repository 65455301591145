import { useSelector } from "react-redux"
import { SalesState } from "../state/reducer"
import { State } from "../../../state"
import { bindActionCreators } from "redux"
import { useDispatch } from "react-redux"
import * as actionCreators from '../state/actionCreators'
import { Employee, Settings, SettingsModule, useAdministrationHooks, useAdministrationState } from "../../administration"
import { BudgetPriceItem } from "./types"
import { Budget, Customer, CustomerType, HoardOrder, SaleOrder } from "../state/types"
import { define, normalize } from "../../../utils/typeUtils"
import { DataType, TableName } from "../../../tables"
import { useFind } from "../../../state/reducers/hooks"
import { O, useFiltersHooks } from "../../../features/ui"

export const useSalesState = (): SalesState => useSelector((state: State) => state.salesReducer)

export const useSalesStateActions = () => bindActionCreators(actionCreators, useDispatch())

export const useSalesHooks = () => {
    const { sales, hoards } = useSalesState()
    const { settings, paymentTypes } = useAdministrationState()
    const { applyPaymentTypeAdjustment } = useAdministrationHooks()
    const { search } = useFiltersHooks()
    const find = useFind()

    const getSettings = (): O<Settings> => settings.find(setting => setting.module === SettingsModule.SALES)

    const getBranchSales = (branchId: string) => sales.filter(sale => sale.branchId === branchId)

    const getBranchHoards = (branchId: string) => hoards.filter(hoard => hoard.branchId === branchId)

    const getBudgetPricesList = (basePrice: number): BudgetPriceItem[] => {
        const list: BudgetPriceItem[] = []

        paymentTypes.forEach(paymentType => {
            list.push({
                paymentType: paymentType,
                price: applyPaymentTypeAdjustment(basePrice, paymentType)
            })
        })

        const budgetPriceList = list.sort((a, b) => a.price - b.price)
        return budgetPriceList
    }

    const searchBudgets = (
        budgets: Budget[],
        text = '',
        showAll = false
    ): Budget[] => {
        const valuesFn = (budgetDT: DataType) => {
            const { code, sellerId, customerId } = budgetDT as Budget
            const seller = define(find(TableName.EMPLOYEES, sellerId)) as Employee
            const customer = define(find(TableName.CUSTOMERS, customerId)) as Customer
            return [
                code.toString(),
                normalize(seller.name),
                normalize(customer.name)
            ]
        }
        const sortFn = (budgetDT_A: DataType, budgetDT_B: DataType) => 
            (budgetDT_B as Budget).code > (budgetDT_A as Budget).code ? 1 : -1

        return search(budgets, valuesFn, sortFn, text, showAll) as Budget[]
    }

    const searchSales = (
        saleList: SaleOrder[],
        text = '',
        showAll = false
    ): SaleOrder[] => {
        const valuesFn = (saleDT: DataType) => {
            const { code, sellerId, customerId } = saleDT as SaleOrder
            const seller = define(find(TableName.EMPLOYEES, sellerId)) as Employee
            const customer = define(find(TableName.CUSTOMERS, customerId)) as Customer
            return [
                code.toString(),
                normalize(seller.name),
                normalize(customer.name)
            ]
        }
        const sortFn = (saleDT_A: DataType, saleDT_B: DataType) => 
            (saleDT_B as SaleOrder).code > (saleDT_A as SaleOrder).code ? 1 : -1

        return search(saleList, valuesFn, sortFn, text, showAll) as SaleOrder[]
    }

    const searchHoards = (
        hoardList: HoardOrder[],
        text = '',
        showAll = false
    ): HoardOrder[] => {
        const valuesFn = (hoardDT: DataType) => {
            const { code, sellerId, customerId } = hoardDT as HoardOrder
            const seller = define(find(TableName.EMPLOYEES, sellerId)) as Employee
            const customer = define(find(TableName.CUSTOMERS, customerId)) as Customer
            return [
                code.toString(),
                normalize(seller.name),
                normalize(customer.name)
            ]
        }
        const sortFn = (hoardDT_A: DataType, hoardDT_B: DataType) => 
            (hoardDT_B as HoardOrder).code > (hoardDT_A as HoardOrder).code ? 1 : -1

        return search(hoardList, valuesFn, sortFn, text, showAll) as HoardOrder[]
    }

    const searchCustomers = (
        customers: Customer[],
        text = '',
        showAll = false
    ): Customer[] => {
        const valuesFn = (customerDT: DataType) => {
            const { name, identifier, email } = customerDT as Customer
            return [
                normalize(name),
                identifier.toString(),
                email ? normalize(email) : ''
            ]
        }
        const sortFn = (customerDT_A: DataType, customerDT_B: DataType) => 
            (customerDT_A as Customer).name.localeCompare((customerDT_B as Customer).name)

        return search(customers, valuesFn, sortFn, text, showAll) as Customer[]
    }

    const searchCustomerTypes = (
        customerTypes: CustomerType[],
        text = '',
        showAll = false
    ): CustomerType[] => {
        const valuesFn = (customerTypeDT: DataType) => {
            const { name } = customerTypeDT as CustomerType
            return [normalize(name)]
        }
        const sortFn = (customerTypeDT_A: DataType, customerTypeDT_B: DataType) => 
            (customerTypeDT_A as CustomerType).name.localeCompare((customerTypeDT_B as CustomerType).name)

        return search(customerTypes, valuesFn, sortFn, text, showAll) as CustomerType[]
    }

    return {
        getSettings,
        getBranchSales,
        getBranchHoards,
        getBudgetPricesList,
        searchBudgets,
        searchSales,
        searchHoards,
        searchCustomers,
        searchCustomerTypes
    }
}
