import { useEffect, useState } from "react"
import { DataType } from '../../../../../tables'
import { PaymentOrder, Ticket } from '../../../state/types'
import { PaymentOrderPDFPage1 } from "./paymentOrderPDFPage1"
import { PagesPDFWrapper } from "../../../../../features/ui"
import { PaymentOrderPDFPage2 } from "./paymentOrderPDFPage2"
import { useTicketActions } from "../../../actions/ticketActions"
import { Supplier, usePurchasesActions } from "../../../../purchases"
import { useTicketHooks } from "../../../hooks/ticketHooks"
import { firstItem } from "../../../../../utils/listUtils"
import { define } from "../../../../../utils/typeUtils"

type Props = {
    id: string
    paymentOrder: PaymentOrder
    previewMode?: boolean
}

export const PaymentOrderPDF = (props: Props) => {
    const { id, paymentOrder, previewMode } = props

    const [tickets, setTickets] = useState<Ticket[]>([])
    const [supplier, setSupplier] = useState<Supplier>()

    const { getSupplierId } = useTicketHooks()

    const ticketActions = useTicketActions()
    const purchasesActions = usePurchasesActions()

    const init = async () => {
        const ticketIds = paymentOrder.ticketItems.map(ticketItem => ticketItem.ticketId)
        const stateTickets = await ticketActions().fetchTickets(ticketIds) as Ticket[]
        setTickets(stateTickets)
        const supplierId = getSupplierId(define(firstItem(stateTickets)))
        const stateSupplier = supplierId ? await purchasesActions().fetchSupplier(supplierId) as Supplier : undefined
        setSupplier(stateSupplier)
    }

    useEffect(() => {
        init()
    }, [paymentOrder])

    const renderPage1 = (_: number, dataType: DataType) => (
        <PaymentOrderPDFPage1
            paymentOrder={dataType as PaymentOrder}
            tickets={tickets}
            supplier={supplier}
        />
    )
    const renderPage2 = (_: number, dataType: DataType) => (
        <PaymentOrderPDFPage2
            paymentOrder={dataType as PaymentOrder}
        />
    )

    const renderPages = [renderPage1, renderPage2]

    return (
        <PagesPDFWrapper
            id={id}
            dataType={paymentOrder}
            renderPages={renderPages}
            previewMode={previewMode}
        />
    )
}
