import { Data } from "../../../../../features/data-types"
import { Table, Section, Column, FieldType, FieldValue, Async } from "../../../../../features/ui"
import { define } from "../../../../../utils/typeUtils"
import { useAdministrationState } from "../../../hooks/administrationHooks"
import { usePaymentOrderHooks } from "../../../hooks/paymentOrderHooks"
import { usePaymentTypeLoaders } from "../../../loaders/paymentTypeLoaders"

export type Props = {
    rows: Data[]
    readOnly?: boolean
    disableCreate?: boolean
    onCreate?: () => void
    onEditRow?: (paymentTypeId: string, sourceId: string) => void
    onRemoveRow?: (paymentTypeId: string, sourceId: string) => void
}

export const PaymentItemsTable = (props: Props) => {
    const { rows, readOnly, disableCreate, onCreate, onEditRow, onRemoveRow } = props

    const { paymentTypes } = useAdministrationState()

    const { describeSource } = usePaymentOrderHooks()

    const paymentTypeLoaders = usePaymentTypeLoaders()

    const paymentItemKey = (paymentTypeId: string, sourceId: string) => `${paymentTypeId}:${sourceId}`

    const parsePaymentItemKey = (key: string): { paymentTypeId: string, sourceId: string } => {
        const keyParts = key.split(':')
        const paymentTypeId = keyParts[0]
        const sourceId = keyParts[1]
        return { paymentTypeId, sourceId }
    }

    const columns: Column[] = [
        {
            name: 'paymentItemKey',
            type: FieldType.TEXT,
            label: 'Medio de Pago',
            render: (value?: FieldValue) => {
                const { findPaymentType } = paymentTypeLoaders()
                const { paymentTypeId } = parsePaymentItemKey(define(value) as string)
                const paymentType = define(findPaymentType(paymentTypeId))
                return paymentType.name
            }
        },
        {
            name: 'sourceId',
            type: FieldType.TABLE,
            label: 'Origen',
            render: (value?: FieldValue) => <Async
                loadFn={async () => <>{await describeSource(define(value) as string)}</>}
            />
        },
        {
            name: 'amount',
            type: FieldType.PRICE,
            label: 'Monto'
        }
    ]

    const itemRows = rows.map(row => {
        const paymentTypeId = define(row.paymentTypeId) as string
        const sourceId = define(row.sourceId) as string
        return {
            ...row,
            paymentItemKey: paymentItemKey(paymentTypeId, sourceId)
        }
    })

    const handleEditRow = (rowIdValue: string) => {
        const { paymentTypeId, sourceId } = parsePaymentItemKey(rowIdValue)
        onEditRow && onEditRow(paymentTypeId, sourceId)
    }

    const handleRemoveRow = (rowIdValue: string) => {
        const { paymentTypeId, sourceId } = parsePaymentItemKey(rowIdValue)
        onRemoveRow && onRemoveRow(paymentTypeId, sourceId)
    }

    return (
        <Section
            id='paymentItems'
            space={12}
        >
            <Table
                columns={columns}
                rows={itemRows}
                rowId='paymentItemKey'
                readOnly={readOnly}
                createIcon="plus"
                disableCreate={disableCreate || rows.length >= paymentTypes.length}
                small
                onCreate={onCreate}
                onEdit={handleEditRow}
                onRemove={handleRemoveRow}
            />
        </Section>
    )
}
