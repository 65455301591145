import { useEffect, useMemo, useState } from "react"
import { FormField, Data, useUIStateActions, ModalId, useUIActions, FieldValue, FieldType, Validatable, Badge, O } from "../../../../../features/ui"
import { Budget, BudgetItem, Customer, CustomerType, SaleItem, SaleOrder } from "../../../state/types"
import { PaymentType, useAdministrationState, PaymentTypeField, usePaymentTypeLoaders } from "../../../../administration"
import { TableName } from "../../../../../tables"
import { CustomerSearchField } from "../../../../../components/fields/search-field/customer-search-field/customerSearchField"
import { useActions } from "./actions"
import { define } from "../../../../../utils/typeUtils"
import { DataTypeForm } from "../../../../../features/data-types"
import { Lot, Product } from "../../../../products"
import { useGenerateNextValue } from "../../../../../tables/hooks"
import { Operation, OperationItemsTable, usePricesHooks, OperationItemFormModal, PriceBreakdown, usePriceBreakdownHooks } from '../../../../operations'
import { useFind } from "../../../../../state/reducers/hooks"
import { useSalesUIHooks } from "../../salesUiHooks"
import { Branch, useManagerState } from "../../../../manager"
import { useNavigate } from "react-router-dom"
import { useSalesState } from "../../../hooks/salesHooks"
import { SellerField } from "../../seller-field/sellerField"

type Props = {
    tableName: TableName
    dataType?: SaleOrder | Budget
    items?: (SaleItem | BudgetItem)[]
}

export const SaleForm = (props: Props) => {
    const { tableName, dataType, items = [] } = props
    const isSale = tableName === TableName.SALE_ORDERS
    const sale = isSale && dataType ? dataType as SaleOrder : undefined
    const budget = !isSale && dataType ? dataType as Budget : undefined
    const createMode = !sale

    const { customers } = useSalesState()
    const { currentBranch } = useManagerState()
    const branchId = currentBranch?.id
    const { currentEmployee } = useAdministrationState()
    
    const pricesHooks = usePricesHooks()

    const defaultCustomer = customers.find(customerItem => customerItem.id === (sale?.customerId || budget?.customerId))
    const defaultSaleItemsData = items.map(item => ({
        productId: item.productId,
        lotId: isSale ? (item as SaleItem).lotId : undefined,
        price: pricesHooks.product.basePrice(item.productId),
        quantity: item.quantity
    }))

    const [code, setCode] = useState<number>(sale?.code || 1)
    const [sellerId, setSellerId] = useState<O<string>>(sale?.sellerId || currentEmployee?.id)
    const [customer, setCustomer] = useState<O<Customer>>(defaultCustomer)
    const [saleItemsData, setSaleItemsData] = useState<Data[]>(defaultSaleItemsData)
    const [currentSaleItem, setCurrentSaleItem] = useState<Data>()
    const [paymentType, setPaymentType] = useState<PaymentType>()
    const [itemsValidations, setItemsValidations] = useState<string[]>([])

    const { validateItemsData } = useSalesUIHooks()
    const { getPriceBreakdownFromItems } = usePriceBreakdownHooks()
    const generateNextValue = useGenerateNextValue()
    const navigate = useNavigate()
    const find = useFind()

    const paymentTypeLoaders = usePaymentTypeLoaders()

    const { submitSale } = useActions()
    const { setDisableBranchSelector } = useUIStateActions()
    const { toggleModal } = useUIActions()

    const init = async () => {
        setDisableBranchSelector(true)
        if (createMode) {
            setCode(await generateNextValue(TableName.SALE_ORDERS, 'code'))
        }
    }

    useEffect(() => {
        init()
        return () => {
            setDisableBranchSelector(false)
        }
    }, [])

    const saleItemMatches = (data: Data, productId: string, lotId?: string) => data.productId === productId && data.lotId === lotId
    const filterProducts = (product: Product) => currentSaleItem?.productId === product.id ||
        !saleItemsData.map(saleItemData => saleItemData.productId).includes(product.id)
    const filterLots = (lot: Lot) => currentSaleItem?.lotId === lot.id ||
        !saleItemsData.map(saleItemData => saleItemData.lotId).includes(lot.id)
    const priceBreakdown = useMemo(() => {
        const customerType = find(TableName.CUSTOMER_TYPES, customer?.customerTypeId) as O<CustomerType>
        return getPriceBreakdownFromItems(saleItemsData, customerType?.yield, paymentType?.yield)
    }, [saleItemsData, customer, paymentType])

    const openFormModal = () => toggleModal(ModalId.OPERATION_ITEM_FORM)

    const onChangeSeller = (value?: FieldValue) => setSellerId(value as O<string>)

    const onChangeCustomer = (value?: string) => {
        const newCustomer = find(TableName.CUSTOMERS, value) as O<Customer>
        setCustomer(newCustomer)
    }

    const onChangePaymentType = (value?: FieldValue) => {
        const { findPaymentType } = paymentTypeLoaders()
        setPaymentType(findPaymentType(value as O<string>))
    }

    const onCreateSaleItem = () => {
        setCurrentSaleItem(undefined)
        setItemsValidations([])
        openFormModal()
    }

    const onEditSaleItem = (productId: string, lotId?: string) => {
        setCurrentSaleItem(saleItemsData.find(saleItemData => saleItemMatches(saleItemData, productId, lotId)))
        setItemsValidations([])
        openFormModal()
    }

    const onRemoveSaleItem = (productId: string, lotId?: string) => {
        const newSaleItemsData = saleItemsData.filter(saleItemData => !saleItemMatches(saleItemData, productId, lotId))
        setSaleItemsData(newSaleItemsData)
        setItemsValidations([])
    }

    const fields: FormField[] = [
        {
            name: 'branchId',
            type: FieldType.TABLE,
            table: TableName.BRANCHES,
            label: 'Sucursal de Entrega',
            render: () => {
                const branch = define(find(TableName.BRANCHES, branchId))as Branch
                return <Badge label={branch.name} />
            }
        },
        SellerField({
            defaultValue: sellerId,
            onChange: onChangeSeller
        }),
        {
            name: 'customerId',
            type: FieldType.TABLE,
            table: TableName.CUSTOMERS,
            label: 'Cliente',
            render: () => (
                <CustomerSearchField
                    id='customerId'
                    defaultValue={customer?.id}
                    onChange={onChangeCustomer}
                />
            )
        },
        {
            name: 'deliveryDate',
            type: FieldType.DATE,
            defaultValue: new Date(),
            label: 'Fecha de Entrega',
            min: new Date()
        },
        {
            name: 'saleItems',
            type: FieldType.TABLE,
            table: TableName.SALE_ITEMS,
            render: () => (
                <Validatable validations={itemsValidations}>
                    <OperationItemsTable
                        rows={saleItemsData}
                        operation={Operation.SALE}
                        readOnly={!createMode}
                        onCreate={onCreateSaleItem}
                        onEditRow={onEditSaleItem}
                        onRemoveRow={onRemoveSaleItem}
                    />
                </Validatable>
            )
        },
        PaymentTypeField({
            onChange: onChangePaymentType
        }),
        {
            name: 'priceBreakdown',
            type: FieldType.PRICE,
            label: 'Precio',
            render: () => <PriceBreakdown data={priceBreakdown} />
        }
    ]

    const validate = async () => {
        if (createMode) {
            const itemValidations = await validateItemsData(saleItemsData, define(branchId))
            setItemsValidations(itemValidations)
            return itemValidations.length === 0
        }

        return true
    }

    const onSubmit = async (saleData: Data) => {
        const valid = await validate()
        if (valid) {
            submitSale(
                saleData,
                code,
                define(branchId),
                define(sellerId),
                define(customer).id,
                saleItemsData,
                createMode
            )
        }
    }

    const onCancel = () => navigate(-1)

    const onSaveSaleItem = (newData: Data) => {
        let newSaleItemsData = saleItemsData
        if (currentSaleItem) {
            const { productId, lotId } = currentSaleItem
            newSaleItemsData = saleItemsData.map(saleItemData => saleItemMatches(saleItemData, define(productId) as string, lotId as O<string>) ? newData : saleItemData)  
        } else {
            newSaleItemsData = [newData, ...saleItemsData]
        }
        setSaleItemsData(newSaleItemsData)
    }
    
    return (
        <>
            <DataTypeForm
               formId="sale-form"
               fields={fields}
               createMode={createMode}
               onSubmit={onSubmit}
               onCancel={onCancel}
            />
            <OperationItemFormModal
                operationItem={currentSaleItem}
                operation={Operation.SALE}
                withLots
                filterProducts={filterProducts}
                filterLots={filterLots}
                onSubmit={onSaveSaleItem}
            />
        </>
    )
}
