import { useState } from "react"
import { Column, FieldType, FieldValue, FormatType, SpinnerDiv, UIPermission } from "../../../../../features/ui"
import { Data, DataTypeList, Labels, useDataTypesHooks } from "../../../../../features/data-types"
import { define } from "../../../../../utils/typeUtils"
import { Check, CheckStatus, CheckType } from "../../../state/types"
import { useSystemStateHooks } from "../../../../system"
import { DataType } from "../../../../../tables"
import { CheckFilters } from "./checkFilters"

type Props = {
    checks: Check[]
}

export const CheckList = (props: Props) => {
    const { checks } = props

    const [rows, setRows] = useState<Data[]>([])
    const [asyncDataLoaded, setAsyncDataLoaded] = useState(false)

    const { userHasPermission } = useSystemStateHooks()
    const readOnly = !userHasPermission(UIPermission.SAVE_CHECKS)
    const { formatCustomValue } = useDataTypesHooks()

    const columns: Column[] = [
        {
            name: 'type',
            type: FieldType.TEXT,
            label: 'Tipo',
            render: (value?: FieldValue) => Labels.checkTypeLabel(define(value) as CheckType)
        },
        {
            name: 'name',
            type: FieldType.TEXT,
            label: 'Nombre / Razón Social'
        },
        {
            name: 'amount',
            type: FieldType.PRICE,
            label: 'Monto'
        },
        {
            name: 'paymentDate',
            type: FieldType.DATE,
            label: 'Fecha de Pago'
        },
        {
            name: 'taxId',
            type: FieldType.NUMBER,
            label: 'CUIT / CUIL',
            render: (value?: FieldValue) => {
                const taxId = define(value) as number
                return formatCustomValue(taxId, FormatType.CUIT_CUIL)
            }
        },
        {
            name: 'status',
            type: FieldType.TEXT,
            label: 'Estado',
            render: (value?: FieldValue) => Labels.checkStatusLabel(define(value) as CheckStatus)
        }
    ]

    const onSearchStart = () => setAsyncDataLoaded(false)

    const onSearchDone = (dataTypes: DataType[]) => {
        setRows(dataTypes as Check[])
        setAsyncDataLoaded(true)
    }

    const renderAsync = (element: JSX.Element) => asyncDataLoaded ? element : <SpinnerDiv />

    return (
        <>
            <CheckFilters
                checks={checks}
                onSearchStart={onSearchStart}
                onSearchDone={onSearchDone}
            />
            {renderAsync(
                <DataTypeList
                    columns={columns}
                    rows={rows}
                    readOnly={readOnly}
                />
            )}
        </>
    )
}
